// @flow
import * as Sentry from '@sentry/browser';
import { getWorkspaceApiBaseURL } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';

export const SEARCHABLE_AUDIENCE_IMPORT_READ_REQUEST = 'SEARCHABLE_AUDIENCE_IMPORT_READ_REQUEST';
export const SEARCHABLE_AUDIENCE_IMPORT_READ_SUCCESS = 'SEARCHABLE_AUDIENCE_IMPORT_READ_SUCCESS';
export const SEARCHABLE_AUDIENCE_IMPORT_READ_FAILURE = 'SEARCHABLE_AUDIENCE_IMPORT_READ_FAILURE';

const readSearchableAudienceImportRequest = () => ({
  type: SEARCHABLE_AUDIENCE_IMPORT_READ_REQUEST,
});

const readSearchableAudienceImportSuccess = (importDetails) => ({
  type: SEARCHABLE_AUDIENCE_IMPORT_READ_SUCCESS,
  payload: importDetails,
});

const readSearchableAudienceImportFailure = (error) => ({
  type: SEARCHABLE_AUDIENCE_IMPORT_READ_FAILURE,
  error,
});

export const readSearchableAudienceImport = (importId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(readSearchableAudienceImportRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getWorkspaceApiBaseURL('v1')}/management/searchable-audiences/imports/${importId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(readSearchableAudienceImportSuccess(response));

    return true;
  } catch (error) {
    dispatch(readSearchableAudienceImportFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
