import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import {
  getAllUsageByOrganisation,
  loadUsageDomains,
  getAllUsageByUser,
} from '../../actions';

import Breadcrumb from '../../components/Breadcrumb';
import OverallUsageByUserTable from '../UsageLogs/UsageTableTypes/OverallUsageByUserTable';
import OverallUsageByOrganisationTable from '../UsageLogs/UsageTableTypes/OverallUsageByOrganisationTable';
import OverallUsageBarChart from '../UsageLogs/OverallUsageBarChart';
import {
  mapOrganisationAccessToPermission,
  permissions,
  restrictUserPermissions
} from "roy-morgan-auth";

let defaultDomainOption = {
  value: '',
  text: 'All Domains',
};

export default function OverallUsageLogs() {
  const dispatch = useDispatch();
  const [domain, setDomain] = useState(defaultDomainOption);
  const [rmEmailLogFilter, setRMEmailLogFilter] = useState(true);
  const usageDomains = useSelector((state) => state.usageDomains.result);
  const accountTypeId = useSelector(
    (state) => state.auth.user.user.accountTypeId
  );

  const overallTotalUsageByOrg = useSelector(
    (state) => state.overallTotalUsageByOrg
  );

  const overallTotalUsageByUser = useSelector(
    (state) => state.overallTotalUsageByUser
  );

  // Table Type
  const [usageTableType, setUsageTableType] = useState('organisation');
  const usageTypeDropdown = [
    { value: 'organisation', text: 'Organisation' },
    { value: 'user', text: 'User' },
  ];

  const formattedDomains = useMemo(() => {
    return usageDomains.map((domain) => ({
      value: domain,
      text: domain,
    }));
  }, [usageDomains]);

  const unsortedDomainOptions = [defaultDomainOption, ...formattedDomains];
  // Gets unsorted domain options array of objects and sorts alphabetically based on the "text" value
  const domainOptions = unsortedDomainOptions.sort((a, b) =>
    a.text > b.text ? 1 : -1
  );

  const permission = mapOrganisationAccessToPermission(true, accountTypeId);
  const isGlobalAdmin = restrictUserPermissions([
    permissions.INTERNAL_ADMIN,
    permissions.BUSINESS_ADMIN,
    permissions.SUPPORT_ADMIN
  ], permission);

  useEffect(() => {
    dispatch(loadUsageDomains());
    dispatch(
      getAllUsageByOrganisation(domain.value, isGlobalAdmin, rmEmailLogFilter)
    );
    dispatch(getAllUsageByUser(domain.value, isGlobalAdmin, rmEmailLogFilter));
  }, []);

  const handleDomainDropdown = (e, value) => {
    const selectedOption = domainOptions.find(
      (option) => option.value === value.value
    );
    if (selectedOption) {
      setDomain(selectedOption);
      if (usageTableType === 'organisation') {
        dispatch(
          getAllUsageByOrganisation(
            selectedOption.value,
            isGlobalAdmin,
            value.checked
          )
        );
      } else {
        dispatch(
          getAllUsageByUser(selectedOption.value, isGlobalAdmin, value.checked)
        );
      }
    } else {
      setDomain(defaultDomainOption);
    }
  };

  const toggleCheckboxFilter = (e, value) => {
    setRMEmailLogFilter(value.checked);
    if (usageTableType === 'organisation') {
      dispatch(
        getAllUsageByOrganisation(domain.value, isGlobalAdmin, value.checked),
      );
    } else {
      dispatch(getAllUsageByUser(domain.value, isGlobalAdmin, value.checked));
    }
  };

  const handleSetUsageTable = (selectedOption) => {
    let newUsageType = selectedOption.value;
    if (newUsageType === 'organisation' || newUsageType === 'user') {
      setUsageTableType(newUsageType);
      if (usageTableType === 'organisation') {
        dispatch(
          getAllUsageByOrganisation(domain.value, isGlobalAdmin, rmEmailLogFilter),
        );
      } else {
        dispatch(getAllUsageByUser(domain.value, isGlobalAdmin, rmEmailLogFilter));
      }
    }
  };

  const isFetching =
    usageTableType === 'organisation'
      ? overallTotalUsageByOrg.isFetching
      : overallTotalUsageByUser.isFetching;
  const results =
    usageTableType === 'organisation'
      ? overallTotalUsageByOrg.result
      : overallTotalUsageByUser.result;

  return (
    <Container>
      <Grid centered stackable>
        <Grid.Column>
          <div className="page-content">
            <Breadcrumb currentContext="Overall Usage Logs" />
            <div className="table-container">
              <Grid>
                <Grid.Column width={4}>
                  <div className="usage-logs__field-and-button-wrapper">
                    <div className="usage-logs__field-wrapper">
                      <label
                        htmlFor="domainFilter"
                        className="usage-logs__label"
                      >
                        Filter Domain
                      </label>
                      <Dropdown
                        onChange={(e, value) => handleDomainDropdown(e, value)}
                        className="usage-logs__dropdown"
                        name="domainFilter"
                        placeholder="Filter by domain"
                        selection
                        options={domainOptions}
                      />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column width={4}>
                  <div className="usage-logs__field-and-button-wrapper">
                    <div className="usage-logs__field-wrapper">
                      <label
                        htmlFor="domainFilter"
                        className="usage-logs__label"
                      >
                        Report By
                      </label>
                      <Dropdown
                        onChange={(e, value) => handleSetUsageTable(value)}
                        className="usage-logs__dropdown"
                        name="usageTypeType"
                        placeholder="Group Logs By"
                        selection
                        defaultValue={usageTableType}
                        options={usageTypeDropdown}
                      />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column width={4}>
                  <div
                    className="usage-logs__field-wrapper"
                    style={{ float: 'right' }}
                  >
                    <Checkbox
                      label="Exclude Usage By Roy Morgan Users"
                      name="rmUsers"
                      type="checkbox"
                      className="usage-logs__rm-usage-filter-cbox"
                      checked={rmEmailLogFilter}
                      onChange={(e, value) => toggleCheckboxFilter(e, value)}
                    />
                  </div>
                </Grid.Column>
              </Grid>
              <Grid>
                <Grid.Column width={12}>
                  <div>
                    <OverallUsageBarChart
                      isFetching={isFetching}
                      results={results}
                    />
                    {usageTableType === 'organisation' ? (
                      <OverallUsageByOrganisationTable
                        isFetching={isFetching}
                        results={results}
                      />
                    ) : (
                      <OverallUsageByUserTable
                        isFetching={isFetching}
                        results={results}
                      />
                    )}
                  </div>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
