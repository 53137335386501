// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import type { ThunkAction } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const COUPON_TYPES_LOAD_REQUEST = 'COUPON_TYPES_LOAD_REQUEST';
export const COUPON_TYPES_LOAD_SUCCESS = 'COUPON_TYPES_LOAD_SUCCESS';
export const COUPON_TYPES_LOAD_FAILURE = 'COUPON_TYPES_LOAD_FAILURE';

const loadCouponTypesRequest = (): any => ({
  type: COUPON_TYPES_LOAD_REQUEST,
});

const loadCouponTypesSuccess = (options: any): any => ({
  type: COUPON_TYPES_LOAD_SUCCESS,
  payload: options,
});

const loadCouponTypesFailure = (error: string): any => ({
  type: COUPON_TYPES_LOAD_FAILURE,
  error,
});

export const loadCouponTypes = (): ThunkAction => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadCouponTypesRequest());

    const response: any[] | Error = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/options/coupon-types`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload: any = normaliseArrayOfObjects(response);

    dispatch(loadCouponTypesSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadCouponTypesFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
