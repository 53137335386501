// @flow
import React from 'react';
import { connect } from 'react-redux';
import { ResponsiveDropdown } from 'roy-morgan-header';
import { Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { managementFeatures } from '../helpers';
import {
  getPermissionInFeature,
  permissions,
  restrictUserPermissions,
} from 'roy-morgan-auth';
import type { RouterHistory, Location } from 'react-router-dom';

type Props = {
  organisationId: number,
  teamId: number,
  featureAccess: Object,
  history: RouterHistory,
  location: Location,
};

class HeaderMenuItems extends React.Component<Props> {
  render() {
    const {
      featureAccess,
      history,
      location: { pathname },
    } = this.props;
    const {
      INTERNAL_ADMIN,
      BUSINESS_ADMIN,
      SUPPORT_ADMIN,
      STANDARD_USER,
      ACCOUNT_MANAGER_ADMIN,
    } = permissions;

    const onlineStoreManagementPermission = getPermissionInFeature(
      managementFeatures.ONLINE_STORE_MANAGEMENT,
      featureAccess
    );

    const workSpaceManagementPermission = getPermissionInFeature(
      managementFeatures.WORKSPACE_MANAGEMENT,
      featureAccess
    );

    const orgManagementPermission = getPermissionInFeature(
      managementFeatures.LIVE_MANAGEMENT,
      featureAccess
    );

    const hasOnlineStoreManagementAccess = restrictUserPermissions(
      [INTERNAL_ADMIN, STANDARD_USER],
      onlineStoreManagementPermission
    );

    const hasWorkSpaceManagementAccess = restrictUserPermissions(
      [INTERNAL_ADMIN, STANDARD_USER],
      workSpaceManagementPermission
    );

    const hasOrgManagementAccess = restrictUserPermissions(
      [INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN, STANDARD_USER],
      orgManagementPermission
    );

    const hasGlobalAdminAccess = restrictUserPermissions(
      [INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN],
      orgManagementPermission
    );

    const hasSuperAdminAccess = restrictUserPermissions(
      [INTERNAL_ADMIN],
      orgManagementPermission
    );

    let mobileBreakpoint = 851;

    const storeItems = {
      url: '/storeitems',
      title: 'Store',
      parent: 0,
      children: [
        {
          url: '/storeitems',
          title: 'Store Items',
          parent: 74,
          target: '_self',
        },

        {
          url: '/storetreecategories',
          title: 'Store Categories',
          parent: 74,
          target: '_self',
        },

        {
          url: '/storeorders',
          title: 'Store Orders',
          parent: 74,
          target: '_self',
        },

        {
          url: '/storeorderitems',
          title: 'Store Order Items',
          parent: 74,
          target: '_self',
        },

        {
          url: '/storecoupons',
          title: 'Store Coupons',
          parent: 74,
          target: '_self',
        },
      ],
    };

    const workSpaceItems = {
      title: 'WorkSpace',
      parent: 0,
      children: [
        {
          url: '/workspace/searchable-audience-imports',
          title: 'Searchable Audience Imports',
          target: '_self',
        }
      ]
    }

    const reviewItems = {
      title: 'Review',
      parent: 0,
      children: [
        {
          url: '/usage-logs',
          title: 'Usage',
          target: '_self',
        },
        {
          url: '/email-notifications',
          title: 'Mail Delivery',
          target: '_self',
        },
      ],
    };

    return (
      <React.Fragment>
        {hasOnlineStoreManagementAccess && (
          <Menu.Item
            as={NavLink}
            exact
            to="/"
            name="dashboard"
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          />
        )}
        {hasOnlineStoreManagementAccess && (
          <ResponsiveDropdown
            menuItem={storeItems}
            pathname={pathname}
            history={history}
            mobileBreakpoint={mobileBreakpoint}
          />
        )}
        {hasWorkSpaceManagementAccess && (
          <ResponsiveDropdown
            menuItem={workSpaceItems}
            pathname={pathname}
            history={history}
            mobileBreakpoint={mobileBreakpoint}
          />
        )}
        {hasOrgManagementAccess && (
          <Menu.Item
            as={NavLink}
            to="/organisations"
            name={
              hasGlobalAdminAccess ? 'organisations' : 'account'
            }
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          />
        )}
        {hasGlobalAdminAccess && (
          <Menu.Item
            as={NavLink}
            to="/users"
            name="users"
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          />
        )}
        {hasGlobalAdminAccess && (
          <ResponsiveDropdown
            menuItem={reviewItems}
            pathname={pathname}
            history={history}
            mobileBreakpoint={mobileBreakpoint}
          />
        )}
        {hasSuperAdminAccess && (
          <Menu.Item
            as={NavLink}
            to="/api-subscriptions"
            name="API Subscriptions"
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    featureAccess: state.auth.authorisation,
  };
};

// $FlowFixMe
export default (connect(mapStateToProps, null, null, { pure: false })(
  HeaderMenuItems
): any);
