// @flow
import * as actions from '../actions/onlineStore';
import { createSelector } from 'reselect';
// import type { OnlineStoreOrdersActions, OnlineStoreOrders } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: any = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const onlineStoreOrders = (
  state: any = initialState,
  action: any
): any => {
  switch (action.type) {
    case actions.ONLINE_STORE_ORDERS_LOAD_REQUEST:
    case actions.ONLINE_STORE_ORDER_READ_REQUEST:
    case actions.ONLINE_STORE_ORDER_ITEM_UPLOAD_REQUEST:
    case actions.ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_REQUEST:
    case actions.ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ONLINE_STORE_ORDERS_LOAD_FAILURE:
    case actions.ONLINE_STORE_ORDER_READ_FAILURE:
    case actions.ONLINE_STORE_ORDER_UPDATE_FAILURE:
    case actions.ONLINE_STORE_ORDER_ITEM_UPLOAD_FAILURE:
    case actions.ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_FAILURE:
    case actions.ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.ONLINE_STORE_ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINE_STORE_ORDER_READ_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          [action.payload.id]: action.payload,
        },
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINE_STORE_ORDER_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ONLINE_STORE_ORDER_UPDATE_SUCCESS:
    case actions.ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINE_STORE_ORDER_ITEM_UPLOAD_SUCCESS:
      return {
        ...state,
        byId:
          state.byId[action.payload.orderId] &&
          state.byId[action.payload.orderId].items
            ? {
                ...state.byId,
                [action.payload.orderId]: {
                  ...state.byId[action.payload.orderId],
                  items: {
                    ...state.byId[action.payload.orderId].items,
                    byId: {
                      ...state.byId[action.payload.orderId].items.byId,
                      [action.payload.id]: {
                        ...action.payload,
                      },
                    },
                  },
                },
              }
            : state.byId,
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOnlineStoreOrdersSortedByStatus: any = createSelector(
  (state) => state.onlineStoreOrders.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        let statusDiff = items[a].orderStatusId - items[b].orderStatusId;
        let aDate = new Date(items[a].deliveryDate);
        let bDate = new Date(items[b].deliveryDate);
        return statusDiff === 0 ? bDate - aDate : statusDiff;
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
