import {permissions, userAccountTypes} from "roy-morgan-auth";

export const canUpdatePasswordForAnotherUser = (user, permission) => {
  const { SUPER_ADMIN, BUSINESS_ADMIN } = userAccountTypes;

  switch (permission) {
    case permissions.INTERNAL_ADMIN:
      return true;
    case permissions.BUSINESS_ADMIN:
      return user.accountTypeId !== SUPER_ADMIN;
    case permissions.SUPPORT_ADMIN:
      return user.accountTypeId !== SUPER_ADMIN && user.accountTypeId !== BUSINESS_ADMIN;
    default:
      return false;
  }
};
