// @flow

export const getWorkspaceApiBaseURL = (version: string): string => {
	const env = process.env.REACT_APP_RM_ENV
	  ? process.env.REACT_APP_RM_ENV
	  : 'dev';
  
	return `https://workspace-api.${_getDomain(env)}.com/${version}`;
};

export const searchableAudienceImportStatus = {
	NOT_IMPORTED: 1,
	IMPORTING: 2,
	IMPORTED: 3,
	IMPORT_ERROR: 4,
};

export const toSearchableAudienceImportStatus = (statusId: number): string => {
	const { NOT_IMPORTED, IMPORTING, IMPORTED, IMPORT_ERROR } = searchableAudienceImportStatus;

	switch(statusId) {
		case NOT_IMPORTED:
			return 'Not Imported';
		case IMPORTING:
			return 'Importing';
		case IMPORTED:
			return 'Successfully Imported';
		case IMPORT_ERROR:
			return 'Import Failed';
		default:
			return '';
	}
};

const _getDomain = (env: string): string => {
	switch (env) {
	  case 'prod':
		return 'roymorgan';
	  case 'uat':
		return 'roymorganuat';
	  case 'local':
	  case 'dev':
	  default:
		return 'roymorgandev';
	}
};
