//@flow
import React from 'react';
import InlineSvg from './InlineSvg';
import { Link } from 'react-router-dom';
import type { Element } from 'React';

type Props = {
  topLevelContext?: string,
  currentContext: string,
  backLink?: string,
  titleClass?: string,
};

class Breadcumb extends React.Component<Props> {
  render(): Element<'div'> {
    const {
      topLevelContext,
      currentContext,
      backLink,
      titleClass,
    } = this.props;
    return (
      <div className="breadcrumb">
        <span>
          {backLink ? (
            <Link
              to={backLink}
              data-testid="breadcrumb-link"
              className="breadcrumb__back-link"
            >
              <InlineSvg name="arrow-left" colour="paleSky" />
              <span data-testid="top-level">{topLevelContext || 'Back'}</span>
            </Link>
          ) : (
            ''
          )}
          <h1
            className={titleClass ? titleClass : 'breadcrumb__current-title'}
            data-testid="current-title"
          >
            {currentContext}
          </h1>
        </span>
      </div>
    );
  }
}

export default Breadcumb;
