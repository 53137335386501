// @flow
import type { ApiSubscriptions } from '../types';
import * as actions from '../actions/apiSubscriptions';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: ApiSubscriptions = {
  items: [],
  isFetching: false,
  error: undefined
};

/* Reducer */

export const apiSubscriptions = (
  state: ApiSubscriptions = initialState,
  action: Object
): ApiSubscriptions => {
  switch (action.type) {
    case actions.API_SUBSCRIPTIONS_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.API_SUBSCRIPTIONS_LOAD_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.API_SUBSCRIPTIONS_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};