// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_TEAM_FORCE_VERIFY_REQUEST =
  'ORGANISATION_TEAM_FORCE_VERIFY_REQUEST';
export const ORGANISATION_TEAM_FORCE_VERIFY_SUCCESS =
  'ORGANISATION_TEAM_FORCE_VERIFY_SUCCESS';
export const ORGANISATION_TEAM_FORCE_VERIFY_FAILURE =
  'ORGANISATION_TEAM_FORCE_VERIFY_FAILURE';

const forceVerifyOrganisationTeamRequest = () => ({
  type: ORGANISATION_TEAM_FORCE_VERIFY_REQUEST,
});

const forceVerifyOrganisationTeamSuccess = (message) => ({
  type: ORGANISATION_TEAM_FORCE_VERIFY_SUCCESS,
  message,
});

const forceVerifyOrganisationTeamFailure = (error) => ({
  type: ORGANISATION_TEAM_FORCE_VERIFY_FAILURE,
  error,
});

export const forceVerifyOrganisationTeam = (
  organisationId: number,
  teamId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(forceVerifyOrganisationTeamRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}/forceverify`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(forceVerifyOrganisationTeamSuccess(response));

    return true;
  } catch (error) {
    dispatch(forceVerifyOrganisationTeamFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
