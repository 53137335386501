//@flow
import React from 'react';
import { Button } from 'semantic-ui-react';
import * as uuid from 'uuid';
import type { Element } from 'React';

type Props = {
  onSelect?: Function,
};

class FileButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.id = uuid.v1();
  }

  id: any;

  render(): Element<'div'> {
    return (
      <div>
        <Button {...this.props} as="label" htmlFor={this.id} />
        <input
          hidden
          id={this.id}
          multiple
          type="file"
          onChange={() => this.onChangeFile()}
        />
      </div>
    );
  }

  onChangeFile() {
    const fileButton = document.getElementById(this.id);
    // $FlowFixMe
    const file = fileButton ? fileButton.files[0] : null;
    if (this.props.onSelect) {
      this.props.onSelect(file);
    }
  }
}

export default FileButton;
