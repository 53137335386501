// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINESTORETREECATEGORY_REMOVE_REQUEST =
  'ONLINESTORETREECATEGORY_REMOVE_REQUEST';
export const ONLINESTORETREECATEGORY_REMOVE_SUCCESS =
  'ONLINESTORETREECATEGORY_REMOVE_SUCCESS';
export const ONLINESTORETREECATEGORY_REMOVE_FAILURE =
  'ONLINESTORETREECATEGORY_REMOVE_FAILURE';

function removeOnlineStoreCategoryRequest() {
  return {
    type: ONLINESTORETREECATEGORY_REMOVE_REQUEST,
  };
}

function removeOnlineStoreCategorySuccess(categoryId: number) {
  return {
    type: ONLINESTORETREECATEGORY_REMOVE_SUCCESS,
    id: categoryId,
  };
}

function removeOnlineStoreCategoryFailure(error: string) {
  return {
    type: ONLINESTORETREECATEGORY_REMOVE_FAILURE,
    error: error,
  };
}

export const removeOnlineStoreTreeCategory = (
  categoryId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOnlineStoreCategoryRequest());

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/category/${categoryId}`,
    });

    dispatch(removeOnlineStoreCategorySuccess(categoryId));
    return response;
  } catch (error) {
    error.message = error.message || 'System Internal Error';
    error.errorMessage = true;
    dispatch(removeOnlineStoreCategoryFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
