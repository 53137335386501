// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_ORGANISATIONS_LOAD_REQUEST = 'API_ORGANISATIONS_LOAD_REQUEST';
export const API_ORGANISATIONS_LOAD_SUCCESS = 'API_ORGANISATIONS_LOAD_SUCCESS';
export const API_ORGANISATIONS_LOAD_FAILURE = 'API_ORGANISATIONS_LOAD_FAILURE';

const loadOrganisationsWithApiSubscriptionRequest = () => ({
  type: API_ORGANISATIONS_LOAD_REQUEST,
});

const loadOrganisationsWithApiSubscriptionSuccess = (organisations) => ({
  type: API_ORGANISATIONS_LOAD_SUCCESS,
  payload: organisations,
});

const loadOrganisationsWithApiSubscriptionFailure = (error) => ({
  type: API_ORGANISATIONS_LOAD_FAILURE,
  error,
});

export const loadOrganisationsWithApiSubscription = (apiId: number): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationsWithApiSubscriptionRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/apis/${apiId}/organisations`,
    });

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOrganisationsWithApiSubscriptionSuccess(normalisedPayload));
  } catch (error) {
    dispatch(loadOrganisationsWithApiSubscriptionFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};
