// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_ACCOUNT_MANAGERS_LOAD_REQUEST =
  'ORGANISATION_ACCOUNT_MANAGERS_LOAD_REQUEST';
export const ORGANISATION_ACCOUNT_MANAGERS_LOAD_SUCCESS =
  'ORGANISATION_ACCOUNT_MANAGERS_LOAD_SUCCESS';
export const ORGANISATION_ACCOUNT_MANAGERS_LOAD_FAILURE =
  'ORGANISATION_ACCOUNT_MANAGERS_LOAD_FAILURE';

const loadOrganisationAccountManagersRequest = () => ({
  type: ORGANISATION_ACCOUNT_MANAGERS_LOAD_REQUEST,
});

const loadOrganisationAccountManagersSuccess = (accountManagers) => ({
  type: ORGANISATION_ACCOUNT_MANAGERS_LOAD_SUCCESS,
  payload: accountManagers,
});

const loadOrganisationAccountManagersFailure = (error) => ({
  type: ORGANISATION_ACCOUNT_MANAGERS_LOAD_FAILURE,
  error,
});

export const loadOrganisationAccountManagers = (
  organisationId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationAccountManagersRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/account-managers`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOrganisationAccountManagersSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationAccountManagersFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
