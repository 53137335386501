// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINESTORETREECATEGORIES_LOAD_REQUEST =
  'ONLINESTORETREECATEGORIES_LOAD_REQUEST';
export const ONLINESTORETREECATEGORIES_LOAD_SUCCESS =
  'ONLINESTORETREECATEGORIES_LOAD_SUCCESS';
export const ONLINESTORETREECATEGORIES_LOAD_FAILURE =
  'ONLINESTORETREECATEGORIES_LOAD_FAILURE';

const loadOnlineStoreTreeCategoriesRequest = () => ({
  type: ONLINESTORETREECATEGORIES_LOAD_REQUEST,
});

const loadOnlineStoreTreeCategoriesSuccess = (OnlineStoreTreeCategories) => ({
  type: ONLINESTORETREECATEGORIES_LOAD_SUCCESS,
  payload: OnlineStoreTreeCategories,
});

const loadOnlineStoreTreeCategoriesFailure = (error) => ({
  type: ONLINESTORETREECATEGORIES_LOAD_FAILURE,
  error,
});

export const loadOnlineStoreTreeCategories = (): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOnlineStoreTreeCategoriesRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/management/category`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOnlineStoreTreeCategoriesSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOnlineStoreTreeCategoriesFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
