// @flow
import React, { useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { totalUserUsage } from '../../actions/organisations/usageLogs';

type Props = {
  organisationId: number,
  userId: number,
  domain?: { value?: string, text?: string },
};

export default function UsageBarChart({
  organisationId,
  userId,
  domain,
}: Props): any {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.userTotalUsage);
  const data = response.result;
  const domainValue = domain && domain.value ? domain.value : '';

  // Sort the array based on the date property
  data.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    return dateA - dateB;
  });

  const highestValue = data.reduce(function (a, b) {
    return Math.max(parseInt(a), parseInt(b.count));
  }, 0);

  useEffect(() => {
    dispatch(totalUserUsage(organisationId, userId, domainValue));
  }, [dispatch, organisationId, userId, domainValue]);

  return (
    <>
      <h3>Engagement, Last 12 Months</h3>
      {response.isFetching ? (
        <div className="usage-bar-chart__loader-wrapper">
          <Loader active inline="centered" />
        </div>
      ) : (
        <>
          <ResponsiveContainer
            className="usage-bar-chart__responsive-container"
            width="100%"
            height={300}
          >
            <BarChart
              width={850}
              height={500}
              data={data}
              margin={{
                top: 15,
                right: 5,
                left: 5,
                bottom: 5,
              }}
            >
              <XAxis
                dataKey="date"
                angle={90}
                label={{
                  value: 'Week',
                  position: 'insideBottom',
                }}
                // $FlowFixMe
                tick={0}
              />
              <YAxis
                dataKey="count"
                domain={[0, highestValue]}
                // $FlowFixMe
                label={<YAxisLabel>Total Pings</YAxisLabel>}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="count" fill="#0f849e" barSize={30} />
            </BarChart>
          </ResponsiveContainer>
          <p className="usage-bar-chart__call-out">
            In this chart, engagement is approximated by aggregating the number
            of of observations of a unique user&apos;s presence on a unique
            subdomain, during any unique hour hour of the day. This is what is
            being counted as a &apos;ping&apos;. Although this is biased to
            favour occasional, sparse usage over infrequent, intense usage, this
            is the best available proxy to track longitudinal product
            engagement. It is not, in itself, intended to be a measure of
            interest in, enthusiasm for or satisfaction with a product.
          </p>
        </>
      )}
    </>
  );
}

const YAxisLabel = (props: {
  viewBox: {
    x: number,
    y: number,
    height: number,
    width: number,
  },
  children: any,
}) => {
  const { x, height, y, width } = props.viewBox;
  const cx = x + width / 2 - 10;
  const cy = height / 2 + y;
  const rot = `270 ${cx} ${cy}`;
  return (
    <text x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle">
      {props.children}
    </text>
  );
};

// const CustomizedTick = (props: {
//   x: number,
//   y: number,
//   payload: { value: string },
// }) => {
//   const { x, y, payload } = props;
//   const { value } = payload;
//   const cx = x + 5;
//   const cy = y + 90;
//   const rot = `270 ${cx} ${cy}`;
//   return (
//     <text x={cx} y={cy} transform={`rotate(${rot})`} fill="#666">
//       {value}
//     </text>
//   );
// };

const CustomTooltip = (props: {
  payload?: Array<{ payload: { count: number, date: string } }>,
}) => {
  return (
    <div
      className="custom-tooltip"
      style={{ background: '#4d4d4d', padding: '15px' }}
    >
      {props.payload &&
        props.payload.map((item, i) => (
          <React.Fragment key={i}>
            <p style={{ color: '#fff' }}>Week starting: {item.payload.date}</p>
            <p style={{ color: '#fff' }}>Total Pings: {item.payload.count}</p>
          </React.Fragment>
        ))}
    </div>
  );
};
