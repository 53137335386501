// @flow
import type { OverallTotalUsageByOrg, Action } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */
const initialState: OverallTotalUsageByOrg = {
  result: [],
  isFetching: false,
  error: undefined,
};

/* Reducer */
export const overallTotalUsageByOrg = (
  state: OverallTotalUsageByOrg = initialState,
  action: Action
): OverallTotalUsageByOrg => {
  switch (action.type) {
    case 'ALL_ORGS_USAGE_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'ALL_ORGS_USAGE_SUCCESS':
      return {
        ...state,
        result: action.payload,
        isFetching: false,
        error: undefined,
      };
    case 'ALL_ORGS_USAGE_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
