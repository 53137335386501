// @flow
import { createSelector } from 'reselect';
import type { OrganisationsWithApiSubscription } from '../types';
import * as actions from '../actions/apiSubscriptions';
// $FlowFixMe
import { getItemsSortedByName } from '../helpers';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: OrganisationsWithApiSubscription = {
  order: [],
  byId: {},
  isFetching: false,
  error: undefined
};

/* Reducer */

export const organisationsWithApiSubscription = (
  state: OrganisationsWithApiSubscription = initialState,
  action: Object
): OrganisationsWithApiSubscription => {
  switch (action.type) {
    case actions.API_ORGANISATIONS_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.API_ORGANISATION_UPDATE_REQUEST:
    case actions.API_ORGANISATION_UPDATE_KEY_REQUEST:
    case actions.API_ORGANISATION_REMOVE_REQUEST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            isFetching: true
          },
        },
        isFetching: false,
        error: undefined
      };
    case actions.API_ORGANISATIONS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.API_ORGANISATION_ADD_SUCCESS:
    case actions.API_ORGANISATION_UPDATE_SUCCESS:
    case actions.API_ORGANISATION_UPDATE_KEY_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
            isFetching: false
          }
        },
        order: [...state.order, action.payload.id],
        isFetching: false,
        error: undefined,
      };
    case actions.API_ORGANISATION_REMOVE_SUCCESS:
      delete state.byId[action.payload.id];
      state.order = state.order.filter(e => e !== action.payload.id);
      return {
        ...state,
        byId: {
          ...state.byId
        },
        order: state.order,
        isFetching: false,
        error: undefined
      };
    case actions.API_ORGANISATIONS_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.API_ORGANISATION_UPDATE_FAILURE:
    case actions.API_ORGANISATION_UPDATE_KEY_FAILURE:
    case actions.API_ORGANISATION_REMOVE_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            isFetching: false
          },
        },
        isFetching: false,
        error: undefined, //error is reported in flash message (i.e. saved in messages state)
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOrganisationsWithApiSubscription: any = createSelector(
  (state) => state.organisationsWithApiSubscription,
  (items) => {
      let organisations = getItemsSortedByName(items);

      return {
        items: organisations, 
        isFetching: items.isFetching, 
        error: items.error
      }
  }
);
