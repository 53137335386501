// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers/index';
import { callAPI, refreshToken } from '../../../middleware/index';
// import type { ThunkAction, Dispatch, Action } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINESTOREITEMS_LOAD_REQUEST = 'ONLINESTOREITEMS_LOAD_REQUEST';
export const ONLINESTOREITEMS_LOAD_SUCCESS = 'ONLINESTOREITEMS_LOAD_SUCCESS';
export const ONLINESTOREITEMS_LOAD_FAILURE = 'ONLINESTOREITEMS_LOAD_FAILURE';

const loadOnlineStoreItemsRequest = () => ({
  type: ONLINESTOREITEMS_LOAD_REQUEST,
});

const loadOnlineStoreItemsSuccess = (onlineStoreItems) => ({
  type: ONLINESTOREITEMS_LOAD_SUCCESS,
  payload: onlineStoreItems,
});

const loadOnlineStoreItemsFailure = (error) => ({
  type: ONLINESTOREITEMS_LOAD_FAILURE,
  error,
});

export const loadOnlineStoreItems = (): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOnlineStoreItemsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/management/items`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOnlineStoreItemsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOnlineStoreItemsFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
