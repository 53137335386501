// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware/index';
import type { OnlineStoreItem } from '../../../types/index';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINESTOREITEM_UPDATE_REQUEST = 'ONLINESTOREITEM_UPDATE_REQUEST';
export const ONLINESTOREITEM_UPDATE_SUCCESS = 'ONLINESTOREITEM_UPDATE_SUCCESS';
export const ONLINESTOREITEM_UPDATE_FAILURE = 'ONLINESTOREITEM_UPDATE_FAILURE';

function updateOnlineStoreItemRequest() {
  return {
    type: ONLINESTOREITEM_UPDATE_REQUEST,
  };
}

function updateOnlineStoreItemSuccess(onlineStoreItem: OnlineStoreItem) {
  return {
    type: ONLINESTOREITEM_UPDATE_SUCCESS,
    payload: onlineStoreItem,
  };
}

function updateOnlineStoreItemFailure(error: string) {
  return {
    type: ONLINESTOREITEM_UPDATE_FAILURE,
    error: error,
  };
}

export const updateOnlineStoreItem = (
  onlineStoreItemId: number,
  body: any,
  storeItemFile: any,
  storeItemSampleFile: any
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOnlineStoreItemRequest());
    const today = new Date();

    // upload and update store item file
    if (storeItemFile !== null) {
      const fileName = storeItemFile.name;

      const uploadStoreItemResponse = await callAPI({
        method: 'GET',
        endpoint: `${getApiBaseURL(
          'v2',
          'store'
        )}/management/items/${onlineStoreItemId}/reportuploadurl/${fileName}`,
      });

      const uploadUrl = uploadStoreItemResponse.url;

      const headers = new Headers({
        'Content-Type': 'application/octet-stream',
      });

      const uploadResponse = await fetch(uploadUrl, {
        method: 'PUT',
        headers,
        body: storeItemFile,
      });

      if (!uploadResponse.ok) {
        let error = new Error('Error when uploading store item file');
        dispatch(updateOnlineStoreItemFailure(error.message));
        /* istanbul ignore if */
        if (process.env.NODE_ENV !== 'test') {
          Sentry.captureException(error);
          console.error(error);
        }

        return error;
      } else {
        body.reportRef = storeItemFile.name;
      }
    }

    // upload and update store item sample file
    if (storeItemSampleFile !== null) {
      const sampleFileName = storeItemSampleFile.name;

      // console.log(body.reportTypeId);

      const uploadStoreItemSampleResponse = await callAPI({
        method: 'GET',
        endpoint: `${getApiBaseURL(
          'v2',
          'store'
        )}/management/items/${onlineStoreItemId}/sampleuploadurl/${sampleFileName}`,
      });

      const uploadSampleUrl = uploadStoreItemSampleResponse.url;

      const headers = new Headers({
        'Content-Type': 'application/octet-stream',
      });

      const uploadSampleResponse = await fetch(uploadSampleUrl, {
        method: 'PUT',
        headers,
        body: storeItemSampleFile,
      });

      if (!uploadSampleResponse.ok) {
        let error = new Error('Error when uploading sample file');
        dispatch(updateOnlineStoreItemFailure(error.message));
        /* istanbul ignore if */
        if (process.env.NODE_ENV !== 'test') {
          Sentry.captureException(error);
          console.error(error);
        }

        return error;
      } else {
        body.sampleRef = storeItemSampleFile.name;
      }
    }

    const requestBody = {
      ...body,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/items/${onlineStoreItemId}`,
      body: requestBody,
    });

    dispatch(updateOnlineStoreItemSuccess(response));
    return response;
  } catch (error) {
    dispatch(updateOnlineStoreItemFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
