// @flow
import type { OrganisationUser } from '../types/organisationUsers';
import { createSelector } from 'reselect';
import type { OrganisationUsers } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: OrganisationUsers = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

export const organisationUsers = (
  state: OrganisationUsers = initialState,
  action: Object
):
  | any
  | OrganisationUsers
  | { byId: { ... }, error: void, isFetching: boolean, order: Array<any>, ... }
  | {
      byId: { [string]: OrganisationUser },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [string]: OrganisationUser },
      error: void,
      isFetching: boolean,
      order: Array<any | number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationUser },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationUser },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    } => {
  switch (action.type) {
    case 'ORGANISATION_USERS_LOAD_REQUEST':
    case 'ORGANISATION_USER_INVITE_REQUEST':
    case 'ORGANISATION_USER_READ_REQUEST':
    case 'ORGANISATION_USER_UPDATE_REQUEST':
    case 'ORGANISATION_USER_FORCE_VERIFY_REQUEST':
    case 'RESEND_INVITATION_EMAIL_ORGANISATION_USER_REQUEST':
    case 'ORGANISATION_USER_REMOVE_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'ORGANISATION_USERS_LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_USERS_LOAD_FAILURE':
    case 'ORGANISATION_USER_UPDATE_FAILURE':
    case 'ORGANISATION_USER_INVITE_FAILURE':
    case 'ORGANISATION_USER_READ_FAILURE':
    case 'ORGANISATION_USER_FORCE_VERIFY_FAILURE':
    case 'RESEND_INVITATION_EMAIL_ORGANISATION_USER_FAILURE':
    case 'ORGANISATION_USER_REMOVE_FAILURE':
      return {
        ...state,
        isFetching: false,
        // $FlowFixMe TODO(DP): Fix this
        error: action.error,
      };
    case 'ORGANISATION_USER_INVITE_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe TODO(DP): Fix this
          [action.payload.id]: action.payload,
        },
        // $FlowFixMe
        order: [...state.order, action.payload.id],
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_USER_READ_SUCCESS':
    case 'ORGANISATION_USER_UPDATE_SUCCESS':
    case 'ORGANISATION_USER_FORCE_VERIFY_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          [action.payload.id]: {
            // $FlowFixMe
            ...state.byId[action.payload.id],
            ...action.payload,
          },
        },
        // $FlowFixMe
        isFetching: false,
        error: undefined,
      };
    case 'RESEND_INVITATION_EMAIL_ORGANISATION_USER_SUCCESS':
      return {
        ...state,
        isFetching: false,
        error: undefined,
      };
    /* eslint-disable no-case-declarations */
    case 'ORGANISATION_USER_REMOVE_SUCCESS':
      let copy = state.byId;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.id];
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.id);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_CHANGE':
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const getOrganisationUsersSortedAlphabetically: any = createSelector(
  (state) => state.organisationUsers.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
