//@flow
import React from 'react';
import type { Element } from 'React';

type Props = {
  name: string,
  action: () => void
};

export default function ActionLink(props: Props) : Element<any> {
  const { action, name } = props;

  return (
    <div className='action-link'>
      {/* // TODO: replace anchor with button // */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        role="button"
        tabIndex="-1"
        onKeyPress={action}
        onClick={action}
      >
        {name}
      </a>
    </div>
  );
}

