// @flow
import type { FetchSync, State as AppState } from '../types';
import * as actions from '../actions/fetchSync';

const initialState: FetchSync = {
  isReady: {},
};

/* Reducer */

export const fetchSync = (
  state: FetchSync = initialState,
  action: Object
): FetchSync => {
  switch (action.type) {
    case actions.FETCH_SYNC_UPDATE_VALUE:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export const getIsReady = (state: AppState, key: string): boolean => {
  return state.fetchSync.isReady[key] || false;
};
