// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_USERS_LOAD_REQUEST = 'API_USERS_LOAD_REQUEST';
export const API_USERS_LOAD_SUCCESS = 'API_USERS_LOAD_SUCCESS';
export const API_USERS_LOAD_FAILURE = 'API_USERS_LOAD_FAILURE';

const loadUsersWithApiAccessRequest = () => ({
  type: API_USERS_LOAD_REQUEST,
});

const loadUsersWithApiAccessSuccess = (users) => ({
  type: API_USERS_LOAD_SUCCESS,
  payload: users,
});

const loadUsersWithApiAccessFailure = (error) => ({
  type: API_USERS_LOAD_FAILURE,
  error,
});

export const loadUsersWithApiAccess = (
  apiId: number, 
  organisationId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadUsersWithApiAccessRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/apis/${apiId}/organisations/${organisationId}/users`,
    });

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadUsersWithApiAccessSuccess(normalisedPayload));
  } catch (error) {
    dispatch(loadUsersWithApiAccessFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};
