// @flow
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  loadOnlineStoreTreeCategories,
  removeOnlineStoreTreeCategory,
  createOnlineStoreTreeCategory,
  updateOnlineStoreTreeCategory,
} from '../../actions';

import type { AuthTypes } from '../../types';
import { Container, Grid, Button, Input, Confirm } from 'semantic-ui-react';
import Breadcrumb from '../../components/Breadcrumb';
import type {
  // State as GlobalState,
  NewOnlineStoreTreeCategory,
  UpdateOnlineStoreTreeCategoryForm,
} from '../../types';
import Message from '../../components/Message';
import { managementFeatures } from '../../helpers';
import { getPermissionInFeature } from 'roy-morgan-auth';
import { getOnlineStoreTreeCategories } from '../../reducers/onlineStoreTreeCategories';
import SortableTree, {
  changeNodeAtPath,
  find as findMatch,
} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import PageLoader from '../../components/PageLoader';

type Props = {
  loadOnlineStoreTreeCategories: () => Promise<boolean>,
  removeOnlineStoreTreeCategory: (categoryId: number) => Promise<boolean>,
  createOnlineStoreTreeCategory: (
    category: NewOnlineStoreTreeCategory
  ) => Promise<boolean>,
  updateOnlineStoreTreeCategory: (
    categoryId: number,
    category: UpdateOnlineStoreTreeCategoryForm
  ) => Promise<boolean>,
  onlineTreeCategories: [],
  isFetching: boolean,
  permission: ?AuthTypes,
};

// type State = {
//   errorMessage: any,
//   confirmDeleteOpen: false,
//   confirmDeleteNode: any,
//   confirmDeletePath: any,
//   confirmDeleteMessage: any
// };

export class OnlineStoreTreeCategories extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      treeData: this.props.onlineTreeCategories,
      errorMessage: undefined,
      confirmDeleteOpen: false,
      confirmDeleteNode: undefined,
      confirmDeletePath: undefined,
      confirmDeleteMessage: undefined,
    };
  }

  setErrorMessage: (error: any) => void = (error: any) => {
    return this.setState(
      {
        ...this.state,
        errorMessage: error,
      },
      function () {}
    );
  };

  resetErrorMessage: () => void = () => {
    this.setState({
      ...this.state,
      errorMessage: undefined,
    });
  };

  onChangeDataTree: (treeData: any) => void = (treeData: any) => {
    this.setState({ treeData: treeData });
  };

  componentDidMount() {
    const { loadOnlineStoreTreeCategories } = this.props;
    loadOnlineStoreTreeCategories().then(
      (result) => {
        this.setState({
          treeData: this.props.onlineTreeCategories,
        });
      },
      (failure) => {
        Sentry.captureException(failure);
        console.error(failure);
      }
    );
  }

  expandNode: (expandedNodeId: any) => void = (expandedNodeId: any) => {
    let ddd = findMatch({
      getNodeKey: this.getNodeKey,
      treeData: this.state.treeData,
      searchQuery: expandedNodeId,
      searchMethod: (path, searchQuery) => {
        return path.node.id === expandedNodeId;
      },
      searchFocusOffset: 0,
      expandAllMatchPaths: true,
      expandFocusMatchPaths: true,
    });
    this.setState({ treeData: ddd.treeData }, function () {});
  };

  handleRefresh: (expandedNodeId: any, expandNodeFunction: any) => void = (
    expandedNodeId: any,
    expandNodeFunction: any
  ) => {
    const { loadOnlineStoreTreeCategories } = this.props;
    loadOnlineStoreTreeCategories().then(
      (result) => {
        this.setState(
          { treeData: this.props.onlineTreeCategories },
          function () {
            expandNodeFunction(expandedNodeId);
          }
        );
      },
      (failure) => {
        Sentry.captureException(failure);
        console.error(failure);
      }
    );
  };

  getRandomName: (array: any) => string = (array: any) => {
    let notFound = true;
    let counter = 1;
    let name = '';

    function matchesName(e) {
      return e.title.toLowerCase() === name.toLowerCase();
    }

    while (notFound) {
      name = 'New Category' + counter;
      notFound = false;
      if (array && array.length > 0) {
        let founded = array.find(matchesName);
        if (founded) {
          counter = counter + 1;
          notFound = true;
        }
      }
    }
    return name;
  };

  addNewRootCategory: () => void = () => {
    let treeData = this.state.treeData;
    let title = this.getRandomName(treeData);
    let topCategory = {
      name: title,
      description: '',
      levelId: 2, //magic number
      parentId: 0,
    };
    this.props.createOnlineStoreTreeCategory(topCategory).then(
      (result: any) => {
        if (result.errorMessage) {
          return this.setErrorMessage(result.message);
        } else {
          return this.handleRefresh(result.id, this.expandNode);
        }
      },
      (failure) => {
        Sentry.captureException(failure);
        console.error(failure);
      }
    );
  };

  addNewCategory: (node: any, path: any) => void = (node: any, path: any) => {
    this.resetErrorMessage();
    let title = this.getRandomName(node.children);
    let newCategory = {
      parentId: node.id,
      name: title,
      description: '',
      levelId: node.levelId + 1,
    };
    this.props.createOnlineStoreTreeCategory(newCategory).then(
      (result: any) => {
        if (result.errorMessage) {
          return this.setErrorMessage(result.message);
        } else {
          return this.handleRefresh(result.id, this.expandNode);
        }
      },
      (failure) => {
        Sentry.captureException(failure);
        console.error(failure);
      }
    );
  };

  showConfirmDelete: (node: any, path: any) => void = (
    node: any,
    path: any
  ) => {
    this.setState({
      confirmDeleteOpen: true,
      confirmDeleteNode: node,
      confirmDeletePath: path,
      confirmDeleteMessage:
        'Are you sure to delete a category ' + node.title + '?',
    });
  };

  handleConfirmDelete: () => void = () => {
    let node = this.state.confirmDeleteNode;
    let path = this.state.confirmDeletePath;
    this.deleteCategory(node, path);
    this.setState(
      {
        confirmDeleteOpen: false,
        confirmDeleteNode: undefined,
        confirmDeletePath: undefined,
        confirmDeleteMessage: undefined,
      },
      function () {}
    );
  };

  handleCancelDelete: () => void = () => {
    this.setState({
      confirmDeleteOpen: false,
      confirmDeleteNode: undefined,
      confirmDeletePath: undefined,
      confirmDeleteMessage: undefined,
    });
  };

  deleteCategory: (node: any, path: any) => void = (node: any, path: any) => {
    this.resetErrorMessage();
    this.props.removeOnlineStoreTreeCategory(node.id).then(
      (result: any) => {
        if (result.errorMessage) {
          return this.setErrorMessage(result.message);
        } else {
          return this.handleRefresh(node.parentId, this.expandNode);
        }
      },
      (failure) => {
        Sentry.captureException(failure);
        console.error(failure);
      }
    );
  };

  onChangeCategoryName: (event: any, node: any, path: any) => void = (
    event: any,
    node: any,
    path: any
  ) => {
    const temporaryChangeValue = event.target.value;
    this.setState((state) => ({
      treeData: changeNodeAtPath({
        treeData: state.treeData,
        path,
        getNodeKey: this.getNodeKey,
        newNode: { ...node, temporaryChangeValue },
      }),
    }));
  };

  onBlurChangeCategoryName: (event: any, node: any, path: any) => void = (
    event: any,
    node: any,
    path: any
  ) => {
    // remove "/" from the title
    const title = event.target.value.replace(/\//g, '');
    if (title !== node.title) {
      let updateCategory = {
        parentId: node.parentId,
        name: title,
        levelId: node.levelId,
      };
      this.props.updateOnlineStoreTreeCategory(node.id, updateCategory).then(
        (result: any) => {
          if (result.errorMessage) {
            this.setErrorMessage(result.message);
          } else {
            node.title = title;
          }
          return this.handleRefresh(node.id, this.expandNode);
        },
        (failure) => {
          Sentry.captureException(failure);
          console.error(failure);
        }
      );
    } else {
      // if the new title has "/" just refresh, do not change the title
      if (title !== event.target.value) {
        return this.handleRefresh(node.id, this.expandNode);
      }
    }
  };

  getNodeKey: (any) => any = ({ node }: any) => node.id;

  canDrop: (any) => boolean = ({
    node,
    nextParent,
    prevPath,
    nextPath,
  }: any) => {
    // different top-top parent or the same parent id
    if (prevPath[0] !== nextPath[0] || node.parentId === nextParent.id) {
      return false;
    }
    // child with the same name
    if (nextParent.children) {
      let found = nextParent.children.find(
        (item) =>
          item.title.toLowerCase() === node.title.toLowerCase() &&
          item.id !== node.id
      );
      if (found) {
        return false;
      }
    }
    return true;
  };

  canDrag: (any) => boolean = ({
    node,
    path,
    treeIndex,
    lowerSiblingCounts,
    isSearchMatch,
    isSearchFocus,
  }: any) => {
    return node.parentId > 0;
  };

  onMoveNode: (any) => void = ({
    treeData,
    node,
    prevPath,
    prevTreeIndex,
    nextPath,
    nextTreeIndex,
  }: any) => {
    //console.log(nextPath);
    let newParentId = nextPath[nextPath.length - 2];
    let newParentNodeMatches = findMatch({
      getNodeKey: this.getNodeKey,
      treeData: this.state.treeData,
      searchQuery: newParentId,
      searchMethod: (path, searchQuery) => {
        return path.node.id === newParentId;
      },
      searchFocusOffset: 0,
      expandAllMatchPaths: true,
      expandFocusMatchPaths: true,
    });
    //console.log(newParentNodeMatches);
    let newParentNode = newParentNodeMatches.matches[0].node;
    //console.log(newParentNode);
    let updateCategory = {
      parentId: newParentId,
      name: node.title,
      levelId: newParentNode.levelId + 1,
      id: node.id,
    };
    //console.log(updateCategory);
    this.props.updateOnlineStoreTreeCategory(node.id, updateCategory).then(
      (result: any) => {
        if (result.errorMessage) {
          this.setErrorMessage(result.message);
        }
        return this.handleRefresh(node.id, this.expandNode);
      },
      (failure) => {
        Sentry.captureException(failure);
        console.error(failure);
      }
    );
  };

  render(): React.Node {
    let buttons = (node, path) => {
      return [
        <Button
          color="green"
          size="mini"
          icon="add"
          compact
          onClick={() => this.addNewCategory(node, path)}
          key={0}
        />,
        <Button
          color="red"
          size="mini"
          icon="remove"
          compact
          disabled={!node.couldBeDeleted || node.children !== null}
          //onClick={() => this.deleteCategory(node, path) }
          onClick={() => this.showConfirmDelete(node, path)}
          key={1}
        />,
      ];
    };
    let fetchingPage = () => {
      if (isFetching) return <PageLoader />;
    };
    const { treeData, errorMessage } = this.state;
    const { isFetching } = this.props;
    return (
      <Container data-testid="organisations-container">
        <Confirm
          open={this.state.confirmDeleteOpen}
          onCancel={this.handleCancelDelete}
          onConfirm={this.handleConfirmDelete}
          content={this.state.confirmDeleteMessage}
        />
        <Grid centered stackable>
          <Grid.Column>
            <div className="page-content">
              <Message
                onDismiss={this.resetErrorMessage}
                content={errorMessage}
                error
                show={errorMessage !== undefined}
              />
              <Breadcrumb currentContext="Store Categories" />
              <div
                className="table-container table-container--overflow"
                style={{ height: 800 }}
              >
                <Button
                  color="green"
                  compact="true"
                  onClick={() => this.addNewRootCategory()}
                  disabled="true"
                >
                  Add Root Category
                </Button>
                <div className="tree-wrapper">
                  <SortableTree
                    // ! this has to be set to false to make current package work with react v17
                    // open issue: https://github.com/RoyMorgan/LiveManagementClient/issues/151
                    isVirtualized={false}
                    treeData={treeData}
                    onChange={this.onChangeDataTree}
                    getNodeKey={this.getNodeKey}
                    canDrop={this.canDrop}
                    canDrag={this.canDrag}
                    onMoveNode={this.onMoveNode}
                    generateNodeProps={({ node, path }) => ({
                      title: (
                        <Input
                          size="mini"
                          style={{ fontSize: '1.2rem' }}
                          value={node.temporaryChangeValue || node.title}
                          compact="true"
                          // onChange, it is fired on every key
                          // https://www.peterbe.com/plog/onchange-in-reactjs
                          // use onBlur, it is fired after the user has finished with the field
                          // in blurCategoryName, change the value of category name
                          onBlur={(event) => {
                            this.onBlurChangeCategoryName(event, node, path);
                          }}
                          onChange={(event) => {
                            this.onChangeCategoryName(event, node, path);
                          }}
                        />
                      ),
                      buttons: buttons(node, path),
                    })}
                    //onMoveNode={args => { this.handleRefresh();  console.error(args); }}
                  />
                </div>
                {fetchingPage()}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  onlineTreeCategories: getOnlineStoreTreeCategories(state),
  isFetching: state.onlineTreeCategories.isFetching,
  permission: getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    state.auth.authorisation
  ),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadOnlineStoreTreeCategories,
      removeOnlineStoreTreeCategory,
      createOnlineStoreTreeCategory,
      updateOnlineStoreTreeCategory,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreTreeCategories): any);
