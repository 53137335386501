// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_REQUEST =
  'ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_REQUEST';
export const ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_SUCCESS =
  'ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_SUCCESS';
export const ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_FAILURE =
  'ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_FAILURE';

const sendReceiptEmailRequest = () => ({
  type: ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_REQUEST,
});

const sendReceiptEmailSuccess = (onlineStoreOrder: any) => ({
  type: ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_SUCCESS,
  payload: onlineStoreOrder,
});

const sendReceiptEmailFailure = (error) => ({
  type: ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_FAILURE,
  error,
});

export const sendReceiptEmail = (OnlineStoreOrderId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(sendReceiptEmailRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/orders/${OnlineStoreOrderId}/send-receipt-email`,
    });

    dispatch(sendReceiptEmailSuccess());

    return response;
  } catch (error) {
    dispatch(sendReceiptEmailFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
