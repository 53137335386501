// @flow
import React from 'react';
import { Grid, Form, Button, Dropdown } from 'semantic-ui-react';
import type {AuthTypes, UpdateGlobalUserForm, User} from '../../types';
import StaticField from '../StaticField';
import OverflowMenu from '../OverflowMenu';
import type { Node } from 'React';
import {permissions, userAccountTypes} from "roy-morgan-auth";

type Props = {
  user: User,
  handleFormSubmit: (formData: UpdateGlobalUserForm) => void,
  accountTypes: Array<any>,
  children?: any,
  disableFields?: boolean,
  permission: ?AuthTypes
};

type State = {
  formData: UpdateGlobalUserForm,
};

class UpdateGlobalUserFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const user = this.props.user;

    this.state = {
      formData: {
        name: user.name,
        email: user.email ? user.email : '',
        phone: user.phone ? user.phone : '',
        accountTypeId: user.accountTypeId,
        isAccountManager: user.isAccountManager
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.user !== nextProps.user) {
      this.setState({
        formData: {
          name: nextProps.user.name,
          email: nextProps.user.email ? nextProps.user.email : '',
          phone: nextProps.user.phone ? nextProps.user.phone : '',
          accountTypeId: nextProps.user.accountTypeId,
          isAccountManager: nextProps.user.isAccountManager
        },
      });
    }
  }

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: any
  ) => void = (event: SyntheticInputEvent<HTMLSelectElement>, result: any) => {
    let formData = Object.assign({}, this.state.formData);
    formData[result.name] = result.value;
    this.setState({ formData });
  };

  handleBooleanDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: any
  ) => void = (event: SyntheticInputEvent<HTMLSelectElement>, result: any) => {
    const val = result.value === 'true';
    let formData = Object.assign({}, this.state.formData);
    formData[result.name] = val;
    this.setState({ formData });
  };

  getAccountTypeOptions: (
    accountTypes: Array<any>,
    user: User,
    permission: ?AuthTypes
  ) => Array<any> = (accountTypes: Array<any>, user: User, permission: ?AuthTypes) => {
    const { SUPER_ADMIN } = userAccountTypes;

    let options = [];
    switch (permission) {
      case permissions.INTERNAL_ADMIN:
        options = accountTypes;
        break;
      case permissions.BUSINESS_ADMIN:
        options = user.accountTypeId !== SUPER_ADMIN
            ? accountTypes.filter(t => t.id !== SUPER_ADMIN)
            : accountTypes.filter(t => t.id === user.accountTypeId);
        break;
      default:
        options = accountTypes.filter(t => t.id === user.accountTypeId);
    }

    return options.map((opt: any) => {
      return {
        text: opt.name,
        value: opt.id
      };
    });
  }

  render(): Node {
    const { name, email, phone, accountTypeId, isAccountManager } = this.state.formData;
    const { handleFormSubmit, user, disableFields, accountTypes, permission } = this.props;

    const accountTypeOptions = this.getAccountTypeOptions(accountTypes, user, permission);
    const accountType = accountTypes.find(t => t.id === accountTypeId);

    return (
      <Form
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >
        <Grid columns={3} stackable>
          <h3 className="light-title">Basic Information</h3>
          <Grid.Row>
            <Grid.Column>
              {disableFields ? (
                <StaticField label="Name" value={name ? name : ''} />
              ) : (
                <Form.Input
                  label="Name"
                  name="name"
                  value={name ? name : ''}
                  onChange={this.handleInputChange}
                  type="text"
                />
              )}
            </Grid.Column>
            <Grid.Column>
              <StaticField label="Email" value={email ? email : ''} />
            </Grid.Column>
            <Grid.Column>
              {disableFields ? (
                <StaticField label="Phone" value={phone ? phone : ''} />
              ) : (
                <Form.Input
                  label="Phone"
                  name="phone"
                  value={phone ? phone : ''}
                  onChange={this.handleInputChange}
                  type="tel"
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <h3 className="light-title">Account Access</h3>
          <Grid.Row>
            <Grid.Column>
              {accountTypeOptions.length > 1 ? (
                <Form.Field>
                  <label htmlFor="accountTypeId">Account Type</label>
                  <Dropdown
                    onChange={this.handleDropdownChange}
                    name="accountTypeId"
                    value={accountTypeId}
                    fluid
                    selection
                    options={accountTypeOptions}
                  />
                </Form.Field>
              ) : (
                <StaticField label="Account Type" value={accountType ? accountType.name : ''} />
              )}
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label htmlFor="isAccountManager">Can Manage Accounts?</label>
                <Dropdown
                  onChange={this.handleBooleanDropdownChange}
                  name="isAccountManager"
                  fluid
                  value={isAccountManager ? 'true' : 'false'}
                  selection
                  options={[
                    { text: 'No', value: 'false' },
                    { text: 'Yes', value: 'true' },
                  ]}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <h3 className="light-title">Account Status</h3>
          <Grid.Row>
            <Grid.Column>
              <StaticField value={user.status} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className="organisation-form__actions">
                <Button className="organisation-form__submit" type="submit">
                  Update Details
                </Button>
                {this.props.children ? (
                  <OverflowMenu dark={true} leftAlign={true}>
                    {this.props.children}
                  </OverflowMenu>
                ) : (
                  ''
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default UpdateGlobalUserFormFields;

