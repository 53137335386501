//@flow
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UsageBarChart from '../UsageLogs/UsageBarChart';
import UsageTable from '../UsageLogs/UsageTable';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { loadUsageDomains } from '../../actions';
import type { AuthTypes } from '../../types';
import DownloadActivityButton from '../../components/DownloadActivityButton';

type Props = {
  organisationId: number,
  permission: ?AuthTypes,
  organisation: Object,
};

let defaultDomainOption = {
  value: '',
  text: 'All Domains',
};

export default function UsageLogs({
  organisationId,
  organisation,
}: Props): any {
  const dispatch = useDispatch();
  const [domain, setDomain] = useState(defaultDomainOption);
  // By default, filter RM emails out of Usage Log Data
  const [rmEmailLogFilter, setRMEmailLogFilter] = useState(true);

  const usageDomains = useSelector((state) => state.usageDomains.result);

  const formattedDomains = useMemo(() => {
    return usageDomains.map((domain) => ({
      value: domain,
      text: domain,
    }));
  }, [usageDomains]);

  const unsortedDomainOptions = [defaultDomainOption, ...formattedDomains];
  // Gets unsorted domain options array of objects and sorts alphabetically based on the "text" value
  const domainOptions = unsortedDomainOptions.sort((a, b) =>
    a.text > b.text ? 1 : -1
  );

  useEffect(() => {
    dispatch(loadUsageDomains());
  }, []);

  const handleDropdown = (e, value) => {
    const selectedOption = domainOptions.find(
      (option) => option.value === value.value
    );
    if (selectedOption) {
      setDomain(selectedOption);
    } else {
      setDomain(defaultDomainOption);
    }
  };

  return (
    <div>
      <h3 className="table-actions__title">Usage Log</h3>
      <div className="usage-logs__field-and-button-wrapper">
        <div className="usage-logs__field-wrapper">
          <label htmlFor="domainFilter" className="usage-logs__label">
            Filter Domain
          </label>
          <Dropdown
            onChange={(e, value) => handleDropdown(e, value)}
            className="usage-logs__dropdown"
            name="domainFilter"
            placeholder="Filter by domain"
            selection
            options={domainOptions}
          />
        </div>
        <DownloadActivityButton
          organisationId={organisationId}
          organisationName={organisation.name}
        />
      </div>
      <div className="usage-logs__field-filter">
        <Checkbox
          label="Exclude Usage By Roy Morgan Users"
          name="rmUsers"
          type="checkbox"
          className="usage-logs__rm-usage-filter-cbox"
          checked={rmEmailLogFilter}
          onChange={(e, value) => setRMEmailLogFilter(value.checked)}
        />
      </div>
      <UsageBarChart
        domain={domain}
        organisationId={organisationId}
        rmEmailLogFilter={rmEmailLogFilter}
      />
      <UsageTable
        domain={domain}
        organisationId={organisationId}
        rmEmailLogFilter={rmEmailLogFilter}
      />
    </div>
  );
}
