// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../../helpers';
import { callAPI, refreshToken } from '../../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USER_FEATURE_UPDATE_REQUEST =
  'ORGANISATION_USER_FEATURE_UPDATE_REQUEST';
export const ORGANISATION_USER_FEATURE_UPDATE_SUCCESS =
  'ORGANISATION_USER_FEATURE_UPDATE_SUCCESS';
export const ORGANISATION_USER_FEATURE_UPDATE_FAILURE =
  'ORGANISATION_USER_FEATURE_UPDATE_FAILURE';

const updateOrganisationUserFeatureRequest = (featureId) => ({
  type: ORGANISATION_USER_FEATURE_UPDATE_REQUEST,
  payload: {
    featureId
  },
});

const updateOrganisationUserFeatureSuccess = (features) => ({
  type: ORGANISATION_USER_FEATURE_UPDATE_SUCCESS,
  payload: features,
});

const updateOrganisationUserFeatureFailure = (error, featureId) => ({
  type: ORGANISATION_USER_FEATURE_UPDATE_FAILURE,
  error,
  id: featureId,
});

export const updateOrganisationUserFeature = (
  organisationId: number,
  userId: number,
  featureId: number,
  body: {}
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationUserFeatureRequest(featureId));

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${userId}/features/${featureId}`,
      body,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);
    dispatch(updateOrganisationUserFeatureSuccess(normalisedPayload));

    return response;
  } catch (error) {
    dispatch(updateOrganisationUserFeatureFailure(error.message, featureId));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
