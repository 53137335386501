// @flow
import type { OrganisationFeature } from '../types/organisationFeatures';
import { createSelector } from 'reselect';
import type { OrganisationFeatures } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';
import { toHierarchyFeatureObject } from '../helpers';

const initialState: OrganisationFeatures = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

export const organisationFeatures = (
  state: OrganisationFeatures = initialState,
  action: Object
):
  | any
  | OrganisationFeatures
  | { byId: { ... }, error: void, isFetching: boolean, order: Array<any>, ... }
  | {
      byId: { [number]: OrganisationFeature },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [number]: OrganisationFeature },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: number]: OrganisationFeature },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: number]: OrganisationFeature },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    } => {
  switch (action.type) {
    case 'ORGANISATION_FEATURES_LOAD_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'ORGANISATION_FEATURES_LOAD_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case 'ORGANISATION_FEATURE_REMOVE_FAILURE':
    case 'ORGANISATION_FEATURE_ADD_FAILURE':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            isFetching: false,
          },
        },
        isFetching: false,
        error: action.error,
      };
    /* eslint-disable no-case-declarations */
    case 'ORGANISATION_FEATURE_REMOVE_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            isSubscribed: false,
            isFetching: false,
          },
        },
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_FEATURE_ADD_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
            isSubscribed: true,
            isFetching: false,
          },
        },
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_FEATURE_UPDATE_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
            isSubscribed: true,
            isFetchingMember: false,
            isFetchingCollaborator: false,
          },
        },
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_FEATURE_ADD_REQUEST':
    case 'ORGANISATION_FEATURE_REMOVE_REQUEST':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            isFetching: true,
          },
        },
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_FEATURE_UPDATE_REQUEST':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.featureId]: {
            ...state.byId[action.payload.featureId],
            [action.payload.isFetching]: true,
          },
        },
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_FEATURE_UPDATE_FAILURE':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.featureId]: {
            ...state.byId[action.payload.featureId],
            [action.payload.isFetching]: false,
          },
        },
        isFetching: false,
        error: action.error,
      };
    case 'ORGANISATION_FEATURES_LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
        error: undefined,
        isFetching: false,
      };
    case 'ORGANISATION_CHANGE':
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const getOrganisationFeatureHierarchy: any = createSelector(
  (state) => state.organisationFeatures.byId,
  (state) => state.organisationFeatures.order,
  (items, order) => {
    if (items === undefined) {
      return [];
    }

    let features = order
      .map(function (sortedKey) {
        return items[sortedKey];
      });
    
    return toHierarchyFeatureObject(features, f => f.isSubscribed);
  }
);