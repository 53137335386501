// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadEmailNotifications } from '../../actions/emailNotifications';
import type { Dispatch } from '../../types';
import { Container, Grid, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import TableActions from '../../components/TableActions';
import { getSortedAlphabetically } from '../../reducers/emailNotifications';
import { loadUsers } from '../../actions/globalUsers';
import PageLoader from '../../components/PageLoader';
import PaginatedTable from '../../components/PaginatedTable';
import Breadcrumb from '../../components/Breadcrumb';
import type { EmailNotification, AuthTypes } from '../../types';
import { switchTo } from 'roy-morgan-auth';
//import moment from 'moment';
import moment from 'moment-timezone';
import {
  // $FlowFixMe
  sortChangeHandler,
  // $FlowFixMe
  sortData,
} from '../../helpers';

type Props = {
  permission: ?AuthTypes,
  loadEmailNotifications: () => Promise<*>,
  loadUsers: () => Promise<*>,
  switchTo: (
    email: string,
    switchFromEmail: string,
    switchFromToken?: string
  ) => Promise<void>,
  notifications: Array<Object>,
  isFetching: boolean,
};

type State = {
  filterValue: string,
  sortColumn: string,
  sortDirection: string,
};

class EmailNotifications extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      sortColumn: '',
      sortDirection: '',
    };
  }

  componentDidMount() {
    const { loadEmailNotifications, loadUsers } = this.props;
    loadEmailNotifications();
    loadUsers();
  }

  handleFilterChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  switchToClick = (email: string, switchFromEmail: string) => {
    const { switchTo } = this.props;
    switchTo(email, switchFromEmail).then((success) =>
      window.location.replace('/')
    );
  };

  handleSortChange = (clickedColumn: any) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  render() {
    const { sortColumn, sortDirection } = this.state;
    const { notifications, isFetching } = this.props;
    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'email' ? sortDirection : null}
          onClick={this.handleSortChange('email')}
        >
          Email
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'subject' ? sortDirection : null}
          onClick={this.handleSortChange('subject')}
        >
          Subject
        </Table.HeaderCell>
        <Table.HeaderCell
          className="table__hide-on-mobile"
          sorted={sortColumn === 'timestemp' ? sortDirection : null}
          onClick={this.handleSortChange('timestemp')}
        >
          Timestamp
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'event' ? sortDirection : null}
          onClick={this.handleSortChange('event')}
        >
          Result
        </Table.HeaderCell>
        <Table.HeaderCell>Users</Table.HeaderCell>
        <Table.HeaderCell>Details</Table.HeaderCell>
      </Table.Row>
    );

    const filterValue = this.state.filterValue.toLowerCase() || '';

    let rows = notifications.filter((notification: EmailNotification) => {
      const email = notification.email.toLowerCase();
      const result = notification.event.toLowerCase();
      return (
        email === filterValue ||
        result.includes(filterValue) ||
        email === filterValue ||
        email.includes(filterValue) ||
        filterValue === ''
      );
    });
    moment.tz('Australia/Sydney');
    rows = rows.sort(this.sort).map((notification: EmailNotification) => {
      return (
        <Table.Row key={notification.id}>
          <Table.Cell>{notification.email}</Table.Cell>
          <Table.Cell>
          <div className="truncate_subject">
            {notification.subject}
          </div>
            </Table.Cell>
          <Table.Cell className="table__hide-on-mobile">
            {moment.utc(notification.timestemp)
              .format('YYYY-MM-DD HH:mm')
              .toString()}
          </Table.Cell>
          <Table.Cell className="table__capitalise">{notification.event.toLowerCase()}</Table.Cell>
          <Table.Cell>
            {notification.userId? <Link to={`/users/${notification.userId}/details`}>View</Link> : "No User"}
          </Table.Cell>
          <Table.Cell>
            <Link to={`/email-notifications/${notification.id}`}>View</Link>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Container>
        <Grid centered stackable>
          <Grid.Column>
            <div className="page-content">
              <Breadcrumb currentContext="Mail Log" />
              <div className="table-container">
                <TableActions
                  filterAction={this.handleFilterChange}
                  filterPlaceholder="Filter Notifications..."
                />
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    perPage={100}
                    headerRow={TableHeader}
                    rows={rows}
                    filterValue={filterValue}
                    sortable={true}
                  />
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    notifications: getSortedAlphabetically(state),
    isFetching: state.users.isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadEmailNotifications,
      loadUsers,
      switchTo,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(EmailNotifications): any);
