// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATIONS_LOAD_REQUEST = 'ORGANISATIONS_LOAD_REQUEST';
export const ORGANISATIONS_LOAD_SUCCESS = 'ORGANISATIONS_LOAD_SUCCESS';
export const ORGANISATIONS_LOAD_FAILURE = 'ORGANISATIONS_LOAD_FAILURE';

const loadOrganisationsRequest = () => ({
  type: ORGANISATIONS_LOAD_REQUEST,
});

const loadOrganisationsSuccess = (organisations) => ({
  type: ORGANISATIONS_LOAD_SUCCESS,
  payload: organisations,
});

const loadOrganisationsFailure = (error) => ({
  type: ORGANISATIONS_LOAD_FAILURE,
  error,
});

export const loadOrganisations = (): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationsRequest());

    const loadOrganisationsEndpoint =
      `${getApiBaseURL(
          'v2',
          'management'
        )}/organisations?excludeArchived=true`;

    const response = await callAPI({
      method: 'GET',
      endpoint: loadOrganisationsEndpoint,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOrganisationsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationsFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
