// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../../helpers';
import { callAPI, refreshToken } from '../../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USER_FEATURES_LOAD_REQUEST =
  'ORGANISATION_USER_FEATURES_LOAD_REQUEST';
export const ORGANISATION_USER_FEATURES_LOAD_SUCCESS =
  'ORGANISATION_USER_FEATURES_LOAD_SUCCESS';
export const ORGANISATION_USER_FEATURES_LOAD_FAILURE =
  'ORGANISATION_USER_FEATURES_LOAD_FAILURE';

const loadOrganisationUserFeaturesRequest = (): Object => ({
  type: ORGANISATION_USER_FEATURES_LOAD_REQUEST,
});

const loadOrganisationUserFeaturesSuccess = (features): Object => ({
  type: ORGANISATION_USER_FEATURES_LOAD_SUCCESS,
  payload: features,
});

const loadOrganisationUserFeaturesFailure = (error): Object => ({
  type: ORGANISATION_USER_FEATURES_LOAD_FAILURE,
  error,
});

export const loadOrganisationUserFeatures = (
  organisationId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationUserFeaturesRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${userId}/features`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOrganisationUserFeaturesSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationUserFeaturesFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
