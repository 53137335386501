//@flow
import React from 'react';
import TabHeader from '../../components/TabHeader';
import type {
  State as AppState,
  OrganisationTeam,
  Dispatch,
  OrganisationTeamForm,
  AuthTypes,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Confirm } from 'semantic-ui-react';
import StaticField from '../../components/StaticField';
import { Redirect } from 'react-router-dom';
import OrganisationFormFields from '../../components/OrganisationTeam/OrganisationFormFields';
import {
  updateOrganisationTeam,
  readOrganisationTeam,
  forceVerifyOrganisationTeam,
  archiveOrganisationTeam,
} from '../../actions/organisations/organisationTeams';
import PageLoader from '../../components/PageLoader';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import moment from 'moment';
import { getIsReady } from '../../reducers/fetchSync';
import { setFetchSyncReady } from '../../actions';

type Props = {
  organisationId: number,
  teamId: number,
  team: ?OrganisationTeam,
  isFetching: boolean,
  permission: ?AuthTypes,
  updateOrganisationTeam: (
    oid: number,
    teamId: number,
    formData: OrganisationTeamForm
  ) => Promise<boolean>,
  readOrganisationTeam: (oid: number, teamId: number) => Promise<boolean>,
  forceVerifyOrganisationTeam: (
    oid: number,
    teamId: number
  ) => Promise<boolean>,
  archiveOrganisationTeam: (oid: number, teamId: number) => Promise<boolean>,
  isReady: boolean,
  setTeamDetailsReady: () => void,
};

type State = {
  forceVerifyOpen: boolean,
  removeTeamOpen: boolean,
  contextChange: boolean,
};

class TeamDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      forceVerifyOpen: false,
      removeTeamOpen: false,
      contextChange: false,
    };
  }

  handleFormSubmit = (formData) => {
    this.props
      .updateOrganisationTeam(
        this.props.organisationId,
        this.props.teamId,
        formData
      )
      .then(
        (success) => {},
        (error) => {}
      );
  };

  componentDidMount() {
    this.props
      .readOrganisationTeam(this.props.organisationId, this.props.teamId)
      .finally(this.props.setTeamDetailsReady);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (typeof nextProps.team === 'undefined') {
      this.setState({
        ...this.state,
        contextChange: true,
      });
    }
  }

  setForceVerifyModalVisible = () => {
    this.setState({ ...this.state, forceVerifyOpen: true });
  };

  handleForceVerify = () => {
    this.props.forceVerifyOrganisationTeam(
      this.props.organisationId,
      this.props.teamId
    );
    this.setState({ ...this.state, forceVerifyOpen: false });
  };

  setRemoveTeamModalVisible = () => {
    this.setState({ ...this.state, removeTeamOpen: true });
  };

  handleRemoveTeam = () => {
    this.props.archiveOrganisationTeam(
      this.props.organisationId,
      this.props.teamId
    );
    this.setState({ ...this.state, removeTeamOpen: false });
  };

  render() {
    const {
      organisationId,
      team,
      isFetching,
      permission,
      isReady,
    } = this.props;
    const { INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN } = permissions;

    if (!isReady) {
      return null;
    }

    if (!team) {
      return <Redirect to={`/organisations/${organisationId}/teams`} />;
    } else {
      return (
        <div>
          <Confirm
            // This confirmation box handles the Force Email Verification Action
            content="Are you sure you want to Force Email Verification for this user?"
            open={this.state.forceVerifyOpen}
            onCancel={() =>
              this.setState({ ...this.state, forceVerifyOpen: false })
            }
            onConfirm={this.handleForceVerify}
          />
          <Confirm
            content={`Are you sure you want to remove ${
              team ? team.name : ''
            } from this organisation?`}
            open={this.state.removeTeamOpen}
            onCancel={() =>
              this.setState({ ...this.state, removeTeamOpen: false })
            }
            onConfirm={this.handleRemoveTeam}
          />
          <TabHeader
            tabHeading={`${team ? team.name : ''}`}
            backLink={`/organisations/${organisationId}/teams`}
            backText="Back to Teams"
          />
          {isFetching ? (
            <PageLoader />
          ) : (
            <div>
              <OrganisationFormFields
                handleFormSubmit={this.handleFormSubmit}
                team={team}
                permission={permission}
              >
                <li>
                  {/* // TODO: replace anchor with button // // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="0"
                    onClick={this.setForceVerifyModalVisible}
                    onKeyPress={this.setForceVerifyModalVisible}
                  >
                    Force Email Verification
                  </a>
                </li>
                <li>
                  {/* // TODO: replace anchor with button // // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onClick={this.setRemoveTeamModalVisible}
                    onKeyPress={this.setRemoveTeamModalVisible}
                  >
                    Remove Team from Organisation
                  </a>
                </li>
              </OrganisationFormFields>

              {restrictUserPermissions(
                [INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN],
                permission
              ) && (
                <div>
                  <hr />
                  <div className="additional-details">
                    <Grid columns={3} stackable>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticField
                            label="Created By"
                            value={team ? team.createdBy : ''}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <StaticField
                            label="Last Changed By"
                            value={team ? team.lastChangedBy : ''}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <StaticField
                            label="Date Created"
                            value={
                              team
                                ? moment
                                    .utc(team.dateCreated)
                                    .local()
                                    .format('YYYY-MM-DD')
                                    .toString()
                                : ''
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticField
                            label="Date Modified"
                            value={
                              team
                                ? moment
                                    .utc(team.dateUpdated)
                                    .local()
                                    .format('YYYY-MM-DD')
                                    .toString()
                                : ''
                            }
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <StaticField
                            label="Last Account Force Verify"
                            value={
                              team
                                ? moment
                                    .utc(team.lastForceVerifyAccountTime)
                                    .local()
                                    .format('YYYY-MM-DD')
                                    .toString()
                                : ''
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationTeams.isFetching,
  team: state.organisationTeams.byId[props.teamId],
  isReady: getIsReady(state, 'teamDetails'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this.
  bindActionCreators(
    {
      readOrganisationTeam,
      updateOrganisationTeam,
      archiveOrganisationTeam,
      forceVerifyOrganisationTeam,
      setTeamDetailsReady: setFetchSyncReady('teamDetails'),
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(TeamDetails): any);
