// @flow
import React from 'react';
import { Grid, Form, Dropdown, Button } from 'semantic-ui-react';
import type {
  UpdateOrganisationUserForm,
  OrganisationUser,
  OrganisationCollaborator,
  AuthTypes,
} from '../../types';
import StaticField from '../StaticField';
import OverflowMenu from '../OverflowMenu';
import type { Node } from 'React';

type Props = {
  permission: ?AuthTypes,
  user: OrganisationUser | OrganisationCollaborator,
  canHaveTeams?: boolean,
  handleFormSubmit: (formData: UpdateOrganisationUserForm) => void,
  children?: any,
  disableFields?: boolean,
};

type State = {
  formData: UpdateOrganisationUserForm,
};

class UpdateUserFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const user = this.props.user;

    this.state = {
      formData: {
        name: user.name,
        email: user.email ? user.email : '',
        phone: user.phone ? user.phone : '',
        canAccessAllTeams: user.canAccessAllTeams
          ? user.canAccessAllTeams
          : false,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.user !== nextProps.user) {
      this.setState({
        formData: {
          name: nextProps.user.name,
          email: nextProps.user.email ? nextProps.user.email : '',
          phone: nextProps.user.phone ? nextProps.user.phone : '',
          canAccessAllTeams: nextProps.user.canAccessAllTeams
            ? nextProps.user.canAccessAllTeams
            : false,
        },
      });
    }
  }

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: string, ... }
  ) => void = (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: string }
  ) => {
    const val = result.value === 'true';
    this.setState({
      formData: {
        ...this.state.formData,
        canAccessAllTeams: val,
      },
    });
  };

  render(): Node {
    const { name, email, phone , canAccessAllTeams} = this.state.formData;
    const { handleFormSubmit, user, disableFields, canHaveTeams} = this.props;

    return (
      <Form
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >
        <Grid columns={3} stackable>
          <h3 className="light-title">Basic Information</h3>
          <Grid.Row>
            <Grid.Column>
              {disableFields ? (
                <StaticField label="Name" value={name ? name : ''} />
              ) : (
                <Form.Input
                  label="Name"
                  name="name"
                  value={name ? name : ''}
                  onChange={this.handleInputChange}
                  type="text"
                />
              )}
            </Grid.Column>
            <Grid.Column>
              <StaticField label="Email" value={email ? email : ''} />
            </Grid.Column>
            <Grid.Column>
              {disableFields ? (
                <StaticField label="Phone" value={phone ? phone : ''} />
              ) : (
                <Form.Input
                  label="Phone"
                  name="phone"
                  value={phone ? phone : ''}
                  onChange={this.handleInputChange}
                  type="tel"
                />
              )}
            </Grid.Column>
          </Grid.Row>
          { canHaveTeams && (<h3 className="light-title">Team Access</h3>) }
          { canHaveTeams && (
            <Grid.Row>
              <Grid.Column>
                <div className="field">
                  <label htmlFor="canAccessAllTeams">Can Access All Teams?</label>
                  <Dropdown
                    onChange={this.handleDropdownChange}
                    name="canAccessAllTeams"
                    fluid
                    value={canAccessAllTeams ? 'true' : 'false'}
                    selection
                    options={[
                      { text: 'No', value: 'false' },
                      { text: 'Yes', value: 'true' },
                    ]}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
          <h3 className="light-title">Account Status</h3>
          <Grid.Row>
            <Grid.Column>
              <StaticField value={user.status} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className="organisation-form__actions">
                <Button className="organisation-form__submit" type="submit">
                  Update Details
                </Button>
                {this.props.children ? (
                  <OverflowMenu dark={true} leftAlign={true}>
                    {this.props.children}
                  </OverflowMenu>
                ) : (
                  ''
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default UpdateUserFormFields;
