// @flow

export const arrayToObject = (
  array: Array<Object>,
  key: string = 'id'
): { ... } =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

export const arrayOfObjectsOrder = (
  array: Array<Object>,
  key: string = 'id'
): Array<any> => {
  const order = [];
  for (let i = 0; i < array.length; i++) {
    order.push(array[i][key]);
  }
  return order;
};

export const normaliseArrayOfObjects = (
  array: Array<Object>,
  key: string = 'id'
): Object => ({
  byId: arrayToObject(array, key),
  order: arrayOfObjectsOrder(array, key),
});
