// @flow
import type { OrganisationTeamUserWithoutAccess } from '../types/organisationTeamUsersWithoutAccess';
import { createSelector } from 'reselect';
import type { OrganisationTeamUsersWithoutAccess } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */
const initialState: OrganisationTeamUsersWithoutAccess = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const organisationTeamUsersWithoutAccess = (
  state: OrganisationTeamUsersWithoutAccess = initialState,
  action: Object
):
  | any
  | OrganisationTeamUsersWithoutAccess
  | { byId: { ... }, error: void, isFetching: boolean, order: Array<any>, ... }
  | {
      byId: { [key: string]: OrganisationTeamUserWithoutAccess },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationTeamUserWithoutAccess },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    } => {
  switch (action.type) {
    case 'ORGANISATION_TEAM_USERS_WITHOUT_ACCESS_LOAD_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'ORGANISATION_TEAM_USERS_WITHOUT_ACCESS_LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_TEAM_USERS_WITHOUT_ACCESS_LOAD_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case 'ORGANISATION_CHANGE':
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */

export const getUsersSortedAlphabetically: any = createSelector(
  (state) => state.organisationTeamUsersWithoutAccess.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        // return items[ a ].name - items[ b ].name;
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
