// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import type { OnlineStoreItem } from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Table, Input, Grid } from 'semantic-ui-react';
import PaginatedTable from '../PaginatedTable';
import PageLoader from '../PageLoader';
import {
  loadOnlineStoreItemsWithoutCouponAccess,
  loadOnlineStoreCouponItems,
  addOnlineStoreItemToCoupon,
} from '../../actions/onlineStore/onlineStoreCoupons';
import { getOnlineStoreItemsWithoutCouponAccessSortedAlphabetically } from '../../reducers/onlineStoreItemsWithoutCouponAccess';

type Props = {
  couponId: number,
  items: Array<OnlineStoreItem>,
  loadOnlineStoreItemsWithoutCouponAccess: (couponid: any) => Promise<*>,
  loadOnlineStoreCouponItems: (couponId: number) => Promise<*>,
  addOnlineStoreItemToCoupon: (couponId: number, itemId: number) => Promise<*>,
};

type State = {
  itemFilterValue: string,
  isAdding: boolean,
  isAddingId?: number,
  initialLoadComplete: boolean,
};

class AddCouponItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      itemFilterValue: '',
      isAdding: false,
      isAddingId: undefined,
      initialLoadComplete: false,
    };
  }

  componentDidMount() {
    const { loadOnlineStoreItemsWithoutCouponAccess, couponId } = this.props;

    loadOnlineStoreItemsWithoutCouponAccess(couponId).then(
      (success) =>
        this.setState({
          initialLoadComplete: true,
        }),
      (error) =>
        this.setState({
          initialLoadComplete: true,
        })
    );
  }

  handleAddItem = async (itemId: number) => {
    try {
      const {
        couponId,
        addOnlineStoreItemToCoupon,
        loadOnlineStoreItemsWithoutCouponAccess,
        loadOnlineStoreCouponItems,
      } = this.props;

      this.setState({
        isAdding: true,
        isAddingId: itemId,
      });

      const addResult = await addOnlineStoreItemToCoupon(couponId, itemId);

      if (addResult) {
        await loadOnlineStoreItemsWithoutCouponAccess(couponId);
        loadOnlineStoreCouponItems(couponId);
      }

      this.setState({
        isAdding: false,
        isAddingId: undefined,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      this.setState({
        isAdding: false,
        isAddingId: undefined,
      });
    }
  };

  handleFilterChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      itemFilterValue: e.currentTarget.value,
    });
  };

  render() {
    const { items } = this.props;
    const itemFilterValue = this.state.itemFilterValue.toLowerCase();
    const { initialLoadComplete } = this.state;

    let rows: Array<any> = [];

    if (items) {
      rows = items.filter((item: any) => {
        const name = item.name.toLowerCase();
        const skuNumber = item.skuNumber;
        return (
          name === itemFilterValue ||
          name.includes(itemFilterValue) ||
          skuNumber === itemFilterValue ||
          skuNumber.includes(itemFilterValue) ||
          itemFilterValue === ''
        );
      });
      rows = rows.map((item: OnlineStoreItem) => {
        return (
          <Table.Row key={item.id}>
            <Table.Cell>{item.skuNumber}</Table.Cell>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>
              <Button
                onClick={() => this.handleAddItem(item.id)}
                loading={this.state.isAddingId === item.id}
                disabled={this.state.isAdding}
                floated="right"
              >
                Add
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell>SKU Number</Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>Name</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        <Grid className="table-actions" stackable columns={1}>
          <Grid.Column className="table-actions__right-column">
            <Input
              className="table-actions__input"
              icon="search"
              iconPosition="left"
              onChange={this.handleFilterChange}
              placeholder="Filter Items..."
              data-testid="filter-input"
            />
          </Grid.Column>
        </Grid>
        {initialLoadComplete ? (
          <PaginatedTable headerRow={tableHeader} rows={rows} />
        ) : (
          <PageLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any, props) => ({
  items: getOnlineStoreItemsWithoutCouponAccessSortedAlphabetically(state),
  isFetching: state.onlineStoreItemsWithoutCouponAccess.isFetching,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadOnlineStoreItemsWithoutCouponAccess,
      addOnlineStoreItemToCoupon,
      loadOnlineStoreCouponItems,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCouponItem): any);
