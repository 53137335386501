// @flow
import * as actions from '../actions/organisations';
import * as rootActions from '../actions';
import { createSelector } from 'reselect';
import type { AccountManagers } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: AccountManagers = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

export const organisationAccountManagers = (
  state: AccountManagers = initialState,
  action: Object
): AccountManagers => {
  switch (action.type) {
    case actions.ORGANISATION_ACCOUNT_MANAGERS_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ORGANISATION_ACCOUNT_MANAGERS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.ORGANISATION_ACCOUNT_MANAGER_ADD_SUCCESS:
       return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload
          }
        },
        order: [...state.order, action.payload.id],
        isFetching: false,
        error: undefined,
      };
    case actions.ORGANISATION_ACCOUNT_MANAGER_REMOVE_SUCCESS:
      delete state.byId[action.id];
      state.order = state.order.filter(e => e !== action.id);
      return {
        ...state,
        byId: {
          ...state.byId
        },
        order: state.order,
        isFetching: false,
        error: undefined
      };
    /* eslint-enable no-case-declarations */
    case actions.ORGANISATION_ACCOUNT_MANAGERS_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case rootActions.ORGANISATION_CHANGE:
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const getOrganisationAccountManagersSortedAlphabetically: any = createSelector(
  (state) => state.organisationAccountManagers.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
