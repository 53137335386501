//@flow
import React from 'react';
import OverflowMenu from '../../components/OverflowMenu';
import TableActions from '../../components/TableActions';
import { Table, Confirm } from 'semantic-ui-react';
import PaginatedTable from '../../components/PaginatedTable';
import { getOrganisationCollaboratorTeamsSortedAlphabetically } from '../../reducers/organisationCollaboratorTeams';
import type {
  State as AppState,
  Dispatch,
  OrganisationCollaboratorTeam,
  Organisation,
  AuthTypes,
} from '../../types';
import {
  loadOrganisationCollaboratorTeams,
  removeOrganisationCollaboratorTeam,
} from '../../actions/organisations/organisationCollaborators/organisationCollaboratorTeams';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageLoader from '../../components/PageLoader';
import { readOrganisationCollaborator, setFetchSyncReady } from '../../actions';
import { getIsReady } from '../../reducers/fetchSync';

type Props = {
  organisation: Organisation,
  collaboratorId: number,
  teams: Array<OrganisationCollaboratorTeam>,
  loadOrganisationCollaboratorTeams: (
    oid: number,
    uid: number
  ) => Promise<boolean>,
  removeOrganisationCollaboratorTeam: (
    oid: number,
    tid: number,
    uid: number
  ) => Promise<null>,
  readOrganisationCollaborator: (oid: number, uid: number) => Promise<boolean>,
  isFetching: boolean,
  permission: AuthTypes,
  isReady: boolean,
  setCollaboratorDetailsReady: () => void,
  collaborator: any
};

type State = {
  teamFilterValue: string,
  removeTeamOpen: boolean,
  removeTeamTarget?: { id: number, name: string },
};

class CollaboratorTeams extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      teamFilterValue: '',
      removeTeamOpen: false,
      removeTeamTarget: undefined,
    };
  }

  componentDidMount() {
    this.props.loadOrganisationCollaboratorTeams(
      this.props.organisation.id,
      this.props.collaboratorId
    );
    this.props.readOrganisationCollaborator(
      this.props.organisation.id,
      this.props.collaboratorId
    ).finally(this.props.setCollaboratorDetailsReady);
  }

  handleTeamSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      teamFilterValue: e.target.value,
    });
  };

  handleRemoveTeam = () => {
    if (this.state.removeTeamTarget) {
      this.props.removeOrganisationCollaboratorTeam(
        this.props.organisation.id,
        this.state.removeTeamTarget.id,
        this.props.collaboratorId
      );
    }
    this.setState({ ...this.state, removeTeamOpen: false });
  };

  setRemoveTeamModalVisible = (teamId, teamName) => {
    this.setState({
      ...this.state,
      removeTeamOpen: true,
      removeTeamTarget: { id: teamId, name: teamName },
    });
  };

  render() {
    const { teams, isFetching, isReady, collaborator } = this.props;

    const teamFilterValue = this.state.teamFilterValue.toLowerCase();
    if (!isReady) {
      return null;
    }
    const { canAccessAllTeams } = collaborator;
    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell>Team Name</Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    );

    let rows = teams.filter((team: OrganisationCollaboratorTeam) => {
      const teamName = team.name.toLowerCase();
      return (
        teamName === teamFilterValue ||
        teamName.includes(teamFilterValue) ||
        teamFilterValue === ''
      );
    });
    rows = rows.map((team: OrganisationCollaboratorTeam) => {
      return (
        <Table.Row key={team.id}>
          <Table.Cell>{team.name}</Table.Cell>
          <Table.Cell>
            {!canAccessAllTeams ? (
              <OverflowMenu>
                <li>
                  {/* // TODO: replace anchor with button // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onClick={() => {
                      this.setRemoveTeamModalVisible(team.id, team.name);
                    }}
                    onKeyPress={() => {
                      this.setRemoveTeamModalVisible(team.id, team.name);
                    }}
                  >
                    Remove From Team
                  </a>
                </li>
              </OverflowMenu>
            ) : (
              'N/A'
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <Confirm
          // This confirmation box handles the Archive Organisation Action
          open={this.state.removeTeamOpen}
          onCancel={() =>
            this.setState({ ...this.state, removeTeamOpen: false })
          }
          content={`Do you want to remove team access to ${
            this.state.removeTeamTarget && this.state.removeTeamTarget.name
              ? this.state.removeTeamTarget.name
              : ''
          }?`}
          onConfirm={this.handleRemoveTeam}
        />
        <TableActions
          title="Teams"
          filterAction={this.handleTeamSearch}
          filterPlaceholder="Search Teams..."
        />
        {isFetching ? (
          <PageLoader />
        ) : (
          <div>
            {canAccessAllTeams ? ('Collaborator has access to all teams.') : ('')}
            <PaginatedTable headerRow={TableHeader} rows={rows} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationCollaboratorTeams.isFetching,
  teams: getOrganisationCollaboratorTeamsSortedAlphabetically(state),
  collaborator: state.organisationCollaborators.byId[props.collaboratorId],
  isReady: getIsReady(state, 'collaboratorDetails'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      readOrganisationCollaborator,
      loadOrganisationCollaboratorTeams,
      removeOrganisationCollaboratorTeam,
      setCollaboratorDetailsReady: setFetchSyncReady('collaboratorDetails'),
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaboratorTeams): any);
