// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const READ_EMAIL_NOTIFICATION_REQUEST =
  'READ_EMAIL_NOTIFICATION_REQUEST';
export const READ_EMAIL_NOTIFICATION_SUCCESS =
  'READ_EMAIL_NOTIFICATION_SUCCESS';
export const READ_EMAIL_NOTIFICATION_FAILURE =
  'READ_EMAIL_NOTIFICATION_FAILURE';

const readEmailNotificationRequest = () => ({
  type: READ_EMAIL_NOTIFICATION_REQUEST,
});

const readEmailNotificationSuccess = (emailNotifications) => ({
  type: READ_EMAIL_NOTIFICATION_SUCCESS,
  payload: emailNotifications,
});

const readEmailNotificationFailure = (error) => ({
  type: READ_EMAIL_NOTIFICATION_FAILURE,
  error,
});

export const readEmailNotification = (
  emailNotificationId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(readEmailNotificationRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/email-notifications/${emailNotificationId}`,
    });

    dispatch(readEmailNotificationSuccess(response));
  } catch (error) {
    dispatch(readEmailNotificationFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};
