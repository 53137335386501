//@flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
	loadSearchableAudienceImports,
	getNextSearchableAudienceImportDetails,
	startSearchableAudienceImport
} from '../../actions';

import { getSearchableAudienceImports } from '../../reducers/workSpace';
import { Container, Grid, Table, Message, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import TableActions from '../../components/TableActions';
import Breadcrumb from '../../components/Breadcrumb';
import PageLoader from '../../components/PageLoader';
import PaginatedTable from '../../components/PaginatedTable';
import ErrorMessage from '../../components/ErrorMessage';
import moment from 'moment';

import {
	// $FlowFixMe
	NEW_sortData,
	toSearchableAudienceImportStatus,
	searchableAudienceImportStatus
  } from '../../helpers';

const { IMPORTING } = searchableAudienceImportStatus;

export default function SearchableAudienceImports(): any {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadSearchableAudienceImports());
		dispatch(getNextSearchableAudienceImportDetails());
	}, [dispatch]);

	const { 
		imports, 
		isFetching: isFetchingImports, 
		error: importsRetrievalError 
	} = useSelector(getSearchableAudienceImports);

	const { 
		result: nextImport, 
		isFetching: isFetchingNextImport, 
		error: nextImportRetrievalError 
	} = useSelector(state => state.nextSearchableAudienceImportDetails);

	/*const accountTypeId = useSelector(
		(state) => state.auth.user.user.accountTypeId
	  );*/

	const [sortColumn, setSortColumn] = useState('dateImported');
	const [sortDirection, setSortDirection] = useState('descending');
	const [filterValue, setFilterValue] = useState('');
	const [showImportMessageModal, setShowImportMessageModal] = useState(false);
	const [importedBy, setImportedBy] = useState('');

	const handleSort = clickedColumn => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			setSortDirection('ascending');
		} else {
			const nextDirection =
			sortDirection === 'ascending' ? 'descending' : 'ascending';
			setSortDirection(nextDirection);
		}
	};

	const handleFilterChange = e => {
		setFilterValue(e.currentTarget.value.toLowerCase());
	};

	const closeImportMessageModal = () => {
		setShowImportMessageModal(false);
	};

	const handleStartImport = async () => {
		const { importedBy } = await dispatch(startSearchableAudienceImport());
		setImportedBy(importedBy);
		setShowImportMessageModal(true);
	};

	if (isFetchingImports || isFetchingNextImport) {
		return <PageLoader />;
	}
	
	const error = importsRetrievalError || nextImportRetrievalError;
	if (error) {
	  return ErrorMessage({ message: error.msg });
	}

	const tableHeader = (
		<Table.Row>
			<Table.HeaderCell
				sorted={sortColumn === 'version' ? sortDirection : null}
				onClick={() => handleSort('version')}
			>
				Version
		  	</Table.HeaderCell>
			<Table.HeaderCell
				sorted={sortColumn === 'run' ? sortDirection : null}
				onClick={() => handleSort('run')}
			>
				Run
		  	</Table.HeaderCell>
			<Table.HeaderCell
				sorted={sortColumn === 'status' ? sortDirection : null}
				onClick={() => handleSort('importStatusId')}
			>
				Status
			</Table.HeaderCell>
			<Table.HeaderCell
				sorted={sortColumn === 'dateImported' ? sortDirection : null}
				onClick={() => handleSort('dateImported')}
			>
				Import Date
			</Table.HeaderCell>
			<Table.HeaderCell
				sorted={sortColumn === 'importedBy' ? sortDirection : null}
				onClick={() => handleSort('importedBy')}
			>
				Imported By
			</Table.HeaderCell>
			<Table.HeaderCell>Actions</Table.HeaderCell>
		</Table.Row>
	);

	const tableRows = imports
		.filter(details => {
			const version = `v${details.version}`;
			const importedBy = details.importedBy.toLowerCase();
			const status = toSearchableAudienceImportStatus(details.importStatusId).toLowerCase();
			return filterValue === '' || 
				version.includes(filterValue) || 
				importedBy.includes(filterValue) || 
				status.includes(filterValue);
		})
		.sort((a, b) => 
			NEW_sortData(a, b, { sortColumn, sortDirection })
		)
		.map(details => (
			<Table.Row key={details.id}>
				<Table.Cell>{`v${details.version}`}</Table.Cell>
				<Table.Cell>{details.run}</Table.Cell>
				<Table.Cell>
					{`${toSearchableAudienceImportStatus(details.importStatusId)}${details.isActive ? ' (Active)' : ''}`}
				</Table.Cell>

				<Table.Cell>
					{moment
						.utc(details.dateImported)
						.local()
						.format('YYYY-MM-DD HH:mm')
						.toString()
					}
				</Table.Cell>
				<Table.Cell>{details.importedBy}</Table.Cell>
				<Table.Cell>
					<Link 
						className="overflow-button" 
						to={`/workspace/searchable-audience-imports/${details.id}/details`}
					>
						View
					</Link>
				</Table.Cell>
			</Table.Row>
		));

	const alreadyImporting = imports.some(details => details.importStatusId === IMPORTING);
	const numOfDocs = nextImport.numOfFiles.toLocaleString('en-AU');

	const message = alreadyImporting
		? `Currently importing new data (${numOfDocs} documents)`
		: nextImport.available
			? `New data available for import (${numOfDocs} documents)`
			: 'No new data available';

	return (
		<Container>
			<Grid centered stackable>
				<Grid.Column>
					<div className="page-content">
						<Breadcrumb currentContext="Searchable Audience Imports" />
						<div className="table-container">
							<Message content={message} />
							<TableActions
								filterAction={handleFilterChange}
								filterPlaceholder="Filter Imports..."
								buttonText="Import"
								buttonAction={handleStartImport}
								disableButton={!nextImport.available || alreadyImporting}
							/>
							<PaginatedTable
								perPage={10}
								headerRow={tableHeader}
								rows={tableRows}
								filterValue={filterValue}
								sortable={true}
							/>
						</div>
					</div>
				</Grid.Column>
			</Grid>

			<Modal
				closeIcon
				open={showImportMessageModal}
				onClose={closeImportMessageModal}
			>
				<Modal.Header>Import Process Has Started</Modal.Header>
				<Modal.Content>
					<div>
					<p>
						Process to import {numOfDocs} documents into OpenSearch has begun.  It may take some time before the audiences
						are available to search in WorkSpace.
					</p>
					<p>
						A report, including any errors, will be emailed to {importedBy} upon completion.
					</p>
					</div>
				</Modal.Content>
			</Modal>
      	</Container>
	);
}
