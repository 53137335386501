// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import OnlineStoreItemFormFields from '../../components/OnlineStoreItem/OnlineStoreItemFormFields';
import {
  createOnlineStoreItem,
  // $FlowFixMe
  loadItemFormats,
  // $FlowFixMe
  loadItemTypes,
  // $FlowFixMe
  loadReportTypes,
  // $FlowFixMe
  loadNavigationTree,
} from '../../actions';
import {
  getOnlineStoreItemFormatsSortedAlphabetically,
  getOnlineStoreItemTypesSortedAlphabetically,
  getOnlineStoreReportTypesSortedAlphabetically,
  getNavigationTree,
} from '../../reducers/onlineStoreOptions';
import type {
  // State as AppState,
  // Dispatch,
  NewOnlineStoreItem as NewOnlineStoreItemType,
  // AccessRole,
  AuthTypes,
  // OnlineStoreItemFormat,
  // OnlineStoreItemType,
  // OnlineStoreReportType,
  // OnlineStoreNavigationTreeItem,
  OnlineStoreItemForm,
} from '../../types';
import Breadcrumb from '../../components/Breadcrumb';
import { managementFeatures } from '../../helpers';
import { getPermissionInFeature } from 'roy-morgan-auth';
//import FlashMessage from '../FlashMessage';
import Message from '../../components/Message';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';

type Props = {
  permission: ?AuthTypes,
  createOnlineStoreItem: (body: Object) => Promise<NewOnlineStoreItemType>,
  itemFormats: Array<any>,
  itemTypes: Array<any>,
  reportTypes: Array<any>,
  navigationTree: Array<any>,
  loadItemFormats: () => Promise<any>,
  loadItemTypes: () => Promise<any>,
  loadReportTypes: () => Promise<any>,
  loadNavigationTree: () => Promise<any>,
  history: RouterHistory,
};

type State = {
  formData?: OnlineStoreItemForm,
  errorMessage: any,
};

class NewOnlineStoreItem extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: undefined,
    };
  }
  componentDidMount() {
    this.props.loadItemFormats();
    this.props.loadItemTypes();
    this.props.loadReportTypes();
    this.props.loadNavigationTree();
  }

  formValidation = (data) => {
    let valid = true;
    let errorMessage;
    if (
      !data.itemFormatId ||
      !data.itemTypeId ||
      !data.reportTypeId ||
      !data.treeNavigationId
    ) {
      valid = false;
      errorMessage =
        'Item Format, Item Type, Report Type and Navigation Tree Location should be selected';
    }
    return { valid, errorMessage };
  };

  handleOnlineStoreItemCreate = (data) => {
    let { valid, errorMessage } = this.formValidation(data);
    if (!valid) {
      return this.setErrorMessage(errorMessage);
    } else {
      this.resetErrorMessage();
      this.props.createOnlineStoreItem(data).then(
        (result: any) => {
          if (result.id) {
            this.props.history.push(`/storeitems/${result.id}/details`);
          } else {
            return this.setErrorMessage(result.message);
          }
        },
        (failure) => {
          console.error(failure);
          Sentry.captureException(failure);
        }
      );
    }
  };

  setErrorMessage = (error) => {
    return this.setState(
      {
        ...this.state,
        errorMessage: error,
      },
      function () {}
    );
  };

  resetErrorMessage = () => {
    this.setState({
      ...this.state,
      errorMessage: undefined,
    });
  };

  render() {
    const onlineStoreItem = {
      name: '',
      shortDescription: '',
      longDescription: '',
      timePeriod: '',
      price: 0,
      turnAroundTime: 0,
      itemFormatId: null,
      itemTypeId: null,
      reportTypeId: null,
      treeNavigationId: null,
      tags: '',
    };

    const { itemFormats, itemTypes, reportTypes, navigationTree } = this.props;
    const { errorMessage } = this.state;

    return (
      <Container>
        <Grid>
          <Grid.Column width={12}>
            <Breadcrumb
              topLevelContext="All Store Items"
              currentContext={'Store Item'}
              backLink="/storeitems"
            />
            <div className="single-form">
              <Message
                onDismiss={this.resetErrorMessage}
                content={errorMessage}
                error
                show={errorMessage !== undefined}
              />
              <OnlineStoreItemFormFields
                permission={this.props.permission}
                onlineStoreItem={onlineStoreItem}
                itemFormats={itemFormats}
                itemTypes={itemTypes}
                reportTypes={reportTypes}
                navigationTree={navigationTree}
                handleFormSubmit={this.handleOnlineStoreItemCreate}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: any, props) => {
  return {
    itemFormats: getOnlineStoreItemFormatsSortedAlphabetically(state),
    itemTypes: getOnlineStoreItemTypesSortedAlphabetically(state),
    reportTypes: getOnlineStoreReportTypesSortedAlphabetically(state),
    navigationTree: getNavigationTree(state),
    permission: getPermissionInFeature(
      managementFeatures.ONLINE_STORE_MANAGEMENT,
      state.auth.authorisation
    ),
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      createOnlineStoreItem,
      loadItemFormats,
      loadItemTypes,
      loadReportTypes,
      loadNavigationTree,
    },
    dispatch
  );

export default (withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewOnlineStoreItem)
): any);
