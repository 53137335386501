// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USER_READ_REQUEST = 'ORGANISATION_USER_READ_REQUEST';
export const ORGANISATION_USER_READ_SUCCESS = 'ORGANISATION_USER_READ_SUCCESS';
export const ORGANISATION_USER_READ_FAILURE = 'ORGANISATION_USER_READ_FAILURE';

const readOrganisationUserRequest = (): Object => ({
  type: ORGANISATION_USER_READ_REQUEST,
});

const readOrganisationUserSuccess = (user): Object => ({
  type: ORGANISATION_USER_READ_SUCCESS,
  payload: user,
});

const readOrganisationUserFailure = (error): Object => ({
  type: ORGANISATION_USER_READ_FAILURE,
  error,
});

export const readOrganisationUser = (
  organisationId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(readOrganisationUserRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${userId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(readOrganisationUserSuccess(response));

    return true;
  } catch (error) {
    dispatch(readOrganisationUserFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
