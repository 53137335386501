// @flow
import type { OrganisationUserOrganisation } from '../types/organisationUserOrganisations';
import * as actions from '../actions/organisations/organisationUsers/organisationUserOrganisations';
import * as rootActions from '../actions';
import { createSelector } from 'reselect';
import type { OrganisationUserOrganisations } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: OrganisationUserOrganisations = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

export const organisationUserOrganisations = (
  state: OrganisationUserOrganisations = initialState,
  action: Object
):
  | any
  | OrganisationUserOrganisations
  | { byId: { ... }, error: void, isFetching: boolean, order: Array<any>, ... }
  | {
      byId: { [string]: OrganisationUserOrganisation },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationUserOrganisation },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationUserOrganisation },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    } => {
  switch (action.type) {
    case actions.ORGANISATION_USER_ORGANISATIONS_LOAD_REQUEST:
    case actions.ORGANISATION_USER_ORGANISATION_REMOVE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case actions.ORGANISATION_USER_ORGANISATIONS_LOAD_FAILURE:
    case actions.ORGANISATION_USER_ORGANISATION_REMOVE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.ORGANISATION_USER_ORGANISATIONS_LOAD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
        error: undefined,
      };
    /* eslint-disable no-case-declarations */
    case actions.ORGANISATION_USER_ORGANISATION_REMOVE_SUCCESS:
      let copy = state.byId;
      delete copy[action.organisationId];
      let orderCopy = state.order;
      orderCopy = orderCopy.filter((e) => e !== action.organisationId);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    /* eslint-enable no-case-declarations */
    case rootActions.ORGANISATION_CHANGE:
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const getOrganisationUserOrganisationsSortedAlphabetically: any = createSelector(
  (state) => state.organisationUserOrganisations.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        // return items[ a ].name - items[ b ].name;
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .filter((item) => {
        if (items[item].membershipTypeId === 2) {
          return true;
        }
        return false;
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
