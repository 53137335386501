//@flow
import React from 'react';
import type {
  // State as AppState,
  // Dispatch,
  OnlineStoreCouponItem,
  AuthTypes,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Confirm, Table, Modal } from 'semantic-ui-react';
import Message from '../../components/Message';
import PageLoader from '../../components/PageLoader';
import {
  loadOnlineStoreCouponItems,
  removeOnlineStoreCouponItem,
} from '../../actions/onlineStore/onlineStoreCoupons';
import { getOnlineStoreCouponItemsSortedAlphabetically } from '../../reducers/onlineStoreCouponItems';
import TableActions from '../../components/TableActions';
import OverflowMenu from '../../components/OverflowMenu';
import PaginatedTable from '../../components/PaginatedTable';
import AddCouponItem from '../../components/OnlineStoreCoupon/AddCouponItem';

import { managementFeatures } from '../../helpers';
import {
  getPermissionInFeature,
  restrictUserPermissions,
  permissions,
} from 'roy-morgan-auth';

type Props = {
  onlineStoreCouponId: number,
  loadOnlineStoreCouponItems: (couponId: number) => Promise<*>,
  items: Array<OnlineStoreCouponItem>,
  isFetching: boolean,
  errorResponse: ?string,
  permission: ?AuthTypes,
  removeOnlineStoreCouponItem: (item: OnlineStoreCouponItem) => Promise<*>,
};

type State = {
  filterValue: string,
  addItemOpen: boolean,
  removeItemOpen: boolean,
  removeItemTarget?: OnlineStoreCouponItem,
  showError: boolean,
};

class CouponItems extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      addItemOpen: false,
      removeItemOpen: false,
      removeItemTarget: undefined,
      showError: false,
    };
  }

  componentDidMount() {
    const { loadOnlineStoreCouponItems, onlineStoreCouponId } = this.props;
    loadOnlineStoreCouponItems(onlineStoreCouponId).then(() => {
      this.setErrorStatus();
    });
  }

  handleFilterChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  toggleModal = () => {
    this.setState({
      addItemOpen: !this.state.addItemOpen,
    });
  };

  setRemoveItemModalVisible = (item: OnlineStoreCouponItem) => {
    this.setState({
      ...this.state,
      removeItemOpen: true,
      removeItemTarget: item,
    });
  };

  handleRemoveItem = () => {
    let { removeItemTarget } = this.state;
    let { removeOnlineStoreCouponItem } = this.props;
    if (removeItemTarget) {
      removeOnlineStoreCouponItem(removeItemTarget);
    }
    this.setState({
      ...this.state,
      removeItemOpen: false,
      removeItemTarget: undefined,
    });
  };

  setErrorStatus = () => {
    return this.setState({
      ...this.state,
      showError: !!this.props.errorResponse,
    });
  };

  resetErrorStatus = () => {
    this.setState({
      ...this.state,
      showError: false,
    });
  };

  render() {
    const {
      onlineStoreCouponId,
      items,
      isFetching,
      permission,
      errorResponse,
    } = this.props;
    const { addItemOpen, showError } = this.state;
    const { INTERNAL_ADMIN, STANDARD_USER } = permissions;

    const filterValue = this.state.filterValue.toLowerCase();

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>SKU Number</Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    );

    let rows = items.filter((item: any) => {
      const name = item.itemName.toLowerCase();
      const skuNumber = item.skuNumber;
      return (
        name === filterValue ||
        name.includes(filterValue) ||
        skuNumber === filterValue ||
        skuNumber.includes(filterValue) ||
        filterValue === ''
      );
    });
    rows = rows.map((item: OnlineStoreCouponItem) => {
      return (
        <Table.Row key={item.id}>
          <Table.Cell>{item.skuNumber}</Table.Cell>
          <Table.Cell>{item.itemName}</Table.Cell>
          <Table.Cell>
            {restrictUserPermissions(
              [INTERNAL_ADMIN, STANDARD_USER],
              permission
            ) ? (
              <OverflowMenu>
                <li>
                  {/* // TODO: replace anchor with button // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onKeyPress={() => {
                      this.setRemoveItemModalVisible(item);
                    }}
                    onClick={() => {
                      this.setRemoveItemModalVisible(item);
                    }}
                  >
                    Remove
                  </a>
                </li>
              </OverflowMenu>
            ) : (
              <span>N/A</span>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <Message
          onDismiss={this.resetErrorStatus}
          // $FlowFixMe
          content={errorResponse}
          error
          show={showError}
        />
        <Confirm
          // remove user
          content={`Are you sure you want to remove ${
            this.state.removeItemTarget && this.state.removeItemTarget.itemName
              ? this.state.removeItemTarget.itemName
              : 'this item'
          } from the current coupon?`}
          open={this.state.removeItemOpen}
          onCancel={() =>
            this.setState({ ...this.state, removeItemOpen: false })
          }
          onConfirm={this.handleRemoveItem}
        />

        <Grid centered stackable>
          <Grid.Column>
            <TableActions
              title="Items"
              filterAction={this.handleFilterChange}
              filterPlaceholder="Filter Items..."
              buttonText="Add Item"
              buttonAction={this.toggleModal}
              // $FlowFixMe
              hideButton={errorResponse}
            />
            {isFetching ? (
              <PageLoader />
            ) : (
              <PaginatedTable headerRow={headerRow} rows={rows} />
            )}
          </Grid.Column>
        </Grid>
        <Modal
          open={addItemOpen}
          closeIcon={<button className="modal__close">Close</button>}
          onClose={this.toggleModal}
        >
          <Modal.Header>Add Item to Coupon</Modal.Header>
          <Modal.Content>
            <AddCouponItem couponId={onlineStoreCouponId} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  items: getOnlineStoreCouponItemsSortedAlphabetically(state),
  isFetching: state.onlineStoreCouponItems.isFetching,
  errorResponse: state.onlineStoreCouponItems.error,
  permission: getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    state.auth.authorisation
  ),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadOnlineStoreCouponItems,
      removeOnlineStoreCouponItem,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(CouponItems): any);
