// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadOnlineStoreOrders } from '../../actions/index';
import { Container, Grid, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PaginatedTable from '../../components/PaginatedTable';
import Breadcrumb from '../../components/Breadcrumb';
import TableActions from '../../components/TableActions';
// import type { OnlineStoreOrder, State as GlobalState } from '../../types/index';
import PageLoader from '../../components/PageLoader';
import { getOnlineStoreOrdersSortedByStatus } from '../../reducers/onlineStoreOrders';
// $FlowFixMe
import { sortChangeHandler, sortData } from '../../helpers';
import moment from 'moment';

type Props = {
  loadOnlineStoreOrders: () => Promise<boolean>,
  onlineStoreOrders: Array<Object>,
  isFetching: boolean,
};

// type State = {
//   filterValue: string
// };

export class OnlineStoreOrders extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
    };
  }

  componentDidMount() {
    const { loadOnlineStoreOrders } = this.props;
    loadOnlineStoreOrders();
  }

  handleFilterChange: (e: SyntheticInputEvent<HTMLInputElement>) => void = (
    e: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  handleSortChange: (clickedColumn: any) => () => any = (
    clickedColumn: any
  ) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort: (a: any, b: any) => any = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  getHeader: () => React.Node = () => {
    const { sortColumn, sortDirection } = this.state;
    return (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'id' ? sortDirection : null}
          onClick={this.handleSortChange('id')}
        >
          Order ID
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'orderDate' ? sortDirection : null}
          onClick={this.handleSortChange('orderDate')}
        >
          Purchase Date
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'deliveryDate' ? sortDirection : null}
          onClick={this.handleSortChange('deliveryDate')}
        >
          Delivery Date
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'buyerName' ? sortDirection : null}
          onClick={this.handleSortChange('buyerName')}
        >
          Buyer Name
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'paymentMethodId' ? sortDirection : null}
          onClick={this.handleSortChange('paymentMethodId')}
        >
          Payment Method
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'paymentStatusId' ? sortDirection : null}
          onClick={this.handleSortChange('paymentStatusId')}
        >
          Payment Status
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'orderStatusId' ? sortDirection : null}
          onClick={this.handleSortChange('orderStatusId')}
        >
          Order Status
        </Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    );
  };

  getRows: () => Array<any | React.Node> = () => {
    const { onlineStoreOrders } = this.props;

    const filterValue = this.state.filterValue.toLowerCase() || '';

    let data = onlineStoreOrders
      .map((onlineStoreOrder: any) => {
        return {
          ...onlineStoreOrder,
          orderDate: new Date(onlineStoreOrder.orderDate),
          deliveryDate: new Date(onlineStoreOrder.deliveryDate),
        };
      })
      .filter((onlineStoreOrder: any) => {
        const buyerName = onlineStoreOrder.buyerName
          ? onlineStoreOrder.buyerName.toLowerCase()
          : '';
        const orderId = onlineStoreOrder.id.toString();
        const orderStatus = onlineStoreOrder.orderStatus
          ? onlineStoreOrder.orderStatus.toLowerCase()
          : '';
        const paymentMethod = onlineStoreOrder.paymentMethod
          ? onlineStoreOrder.paymentMethod.toLowerCase()
          : '';
        const paymentStatus = onlineStoreOrder.paymentStatus
          ? onlineStoreOrder.paymentStatus.toLowerCase()
          : '';
        return (
          buyerName === filterValue ||
          buyerName.includes(filterValue) ||
          orderId === filterValue ||
          orderStatus === filterValue ||
          orderStatus.includes(filterValue) ||
          paymentMethod === filterValue ||
          paymentMethod.includes(filterValue) ||
          paymentStatus === filterValue ||
          paymentStatus.includes(filterValue) ||
          filterValue === ''
        );
      })
      .sort(this.sort);

    return data.map((onlineStoreOrder: any) => {
      return (
        <Table.Row key={onlineStoreOrder.id}>
          <Table.Cell>{onlineStoreOrder.id}</Table.Cell>
          <Table.Cell>
            {moment
              .utc(onlineStoreOrder.orderDate)
              .local()
              .format('YYYY-MM-DD LT')
              .toString()}
          </Table.Cell>
          <Table.Cell>
            {moment
              .utc(onlineStoreOrder.deliveryDate)
              .local()
              .format('YYYY-MM-DD LT')
              .toString()}
          </Table.Cell>
          <Table.Cell>{onlineStoreOrder.buyerName}</Table.Cell>
          <Table.Cell>{onlineStoreOrder.paymentMethod}</Table.Cell>
          <Table.Cell>{onlineStoreOrder.paymentStatus}</Table.Cell>
          <Table.Cell>{onlineStoreOrder.orderStatus}</Table.Cell>
          <Table.Cell>
            <Link to={`/storeorders/${onlineStoreOrder.id}/items`}>View</Link>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  render(): React.Node {
    const { isFetching } = this.props;

    return (
      <Container data-testid="organisations-container">
        <Grid centered stackable>
          <Grid.Column>
            <div className="page-content">
              <Breadcrumb currentContext="Store Orders" />
              <div className="table-container">
                <TableActions
                  filterAction={this.handleFilterChange}
                  filterPlaceholder="Filter Store Orders..."
                  hideButton={true}
                />
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    headerRow={this.getHeader()}
                    rows={this.getRows()}
                    sortable
                    stackEarly
                  />
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  onlineStoreOrders: getOnlineStoreOrdersSortedByStatus(state),
  isFetching: state.onlineStoreOrders.isFetching,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadOnlineStoreOrders,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreOrders): any);
