// @flow
import * as React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type {
  User,
  Organisation as OrgType,
  OrganisationTeam,
  OrganisationCollaborator,
  FeatureAccess,
  AuthTypes,
} from '../types';
import Breadcrumb from '../components/Breadcrumb';
import { readOrganisation, changeOrganisation } from '../actions';
import SideMenu from '../components/SideMenu';
import { Route, withRouter } from 'react-router-dom';
import {
  Details,
  Relationship,
  Users,
  Teams,
  Collaborators,
  Access,
  UsageLogs,
} from './OrganisationTabs';
import { UserDetails, UserAccess, UserOrganisations, UserTeams } from './UserTabs';
import {
  CollaboratorDetails,
  CollaboratorAccess,
  CollaboratorOrganisations,
  CollaboratorTeams
} from './CollaboratorTabs';
import {
  TeamDetails,
  TeamAccess,
  TeamUsers,
  TeamCollaborators,
} from './TeamTabs';
import { managementFeatures } from '../helpers';
import {
  getPermissionInFeature,
  restrictUserPermissions,
  permissions,
} from 'roy-morgan-auth';
import type { RouterHistory } from 'react-router-dom';
import PageLoader from './Organisations';
import UserUsageLogs from './UserTabs/UserUsageLogs';

type Props = {
  match: {
    params: {
      organisationId: number,
      tab?: string,
      userId: number,
      teamId?: string,
      collaboratorId?: string,
    },
    url: string,
  },
  history: RouterHistory,
  organisation: OrgType,
  user: User,
  collaborator: OrganisationCollaborator,
  team: OrganisationTeam,
  permission: ?AuthTypes,
  organisationAccess: FeatureAccess,
  readOrganisation: (oid: number) => Promise<boolean>,
  changeOrganisation: () => Promise<boolean>,
};

class Organisation extends React.Component<Props> {
  componentDidMount() {
    const { match, readOrganisation, changeOrganisation } = this.props;

    const currentOrgId = this.props.match.params.organisationId;
    changeOrganisation().then((success) => {
      readOrganisation(match.params.organisationId);
    });
    if (this.props.match.params.tab === undefined) {
      this.props.history.push(`/organisations/${currentOrgId}/details`);
    }
  }

  render() {
    const userId = this.props.match.params.userId;
    const { organisation, permission } = this.props;

    const {
      INTERNAL_ADMIN,
      BUSINESS_ADMIN,
      SUPPORT_ADMIN,
      ACCOUNT_MANAGER_ADMIN,
      STANDARD_USER,
    } = permissions;

    const currentOrgId = this.props.match.params.organisationId;
    const currentUserId = this.props.match.params.userId
      ? this.props.match.params.userId
      : '';
    const currentTeamId = this.props.match.params.teamId
      ? this.props.match.params.teamId
      : '';
    const currentCollaboratorId = this.props.match.params.collaboratorId
      ? this.props.match.params.collaboratorId
      : '';
    const currentUserMenuDisplay = !!this.props.match.params.userId;
    const currentTeamMenuDisplay = !!this.props.match.params.teamId;
    const currentUserSubTitle =
      this.props.user && this.props.user.name ? this.props.user.name : '';
    const currentTeamSubTitle =
      this.props.team && this.props.team.name ? this.props.team.name : '';

    const currentCollaboratorSubTitle =
      this.props.collaborator && this.props.collaborator.name
        ? this.props.collaborator.name
        : '';

    const currentUrl = this.props.match.url;

    const currentCollaboratorMenuDisplay = !!this.props.match.params.collaboratorId;
    const canHaveTeams = organisation && organisation.canHaveTeams;

    let userSubMenu = [
      {
        label: 'Details',
        container: () => (
          <UserDetails
            organisationId={currentOrgId}
            userId={currentUserId}
            organisation={organisation}
            permission={permission}
          />
        ),
        path: `/organisations/${currentOrgId}/users/${currentUserId}/details`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Products',
        container: () => (
          <UserAccess
            organisation={organisation}
            userId={currentUserId}
            permission={permission}
          />
        ),
        path: `/organisations/${currentOrgId}/users/${currentUserId}/products`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      }];
      if (canHaveTeams) {
        userSubMenu.push(
          {
            label: 'Teams',
            container: () => (
              <UserTeams
                organisation={organisation}
                userId={currentUserId}
                permission={permission}
              />
            ),
            path: `/organisations/${currentOrgId}/users/${currentUserId}/teams`,
            acceptedPermissions: [
              INTERNAL_ADMIN,
              BUSINESS_ADMIN,
              SUPPORT_ADMIN,
              ACCOUNT_MANAGER_ADMIN,
              STANDARD_USER
            ],
          }
        );
      }
    userSubMenu.push(
      {
        label: 'Usage Log',
        container: () => (
          <UserUsageLogs
            userId={userId}
            organisationId={currentOrgId}
            permission={permission}
            organisation={organisation}
          />
        ),
        path: `/organisations/${currentOrgId}/users/${currentUserId}/usage-log`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Collaborating',
        container: () => (
          <UserOrganisations
            organisation={organisation}
            userId={currentUserId}
          />
        ),
        path: `/organisations/${currentOrgId}/users/${currentUserId}/collaborating`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN
        ],
      }
    );
    let collaboratorSubMenu = [
      {
        label: 'Details',
        container: () => (
          <CollaboratorDetails
            organisationId={currentOrgId}
            collaboratorId={currentCollaboratorId}
            organisation={organisation}
            permission={permission}
          />
        ),
        path: `/organisations/${currentOrgId}/collaborators/${currentCollaboratorId}/details`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Products',
        container: () => (
          <CollaboratorAccess
            organisation={organisation}
            collaboratorId={currentCollaboratorId}
            permission={permission}
          />
        ),
        path: `/organisations/${currentOrgId}/collaborators/${currentCollaboratorId}/products`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      }];
    if (canHaveTeams){
      collaboratorSubMenu.push(
        {
          label: 'Teams',
          container: () => (
            <CollaboratorTeams
              organisation={organisation}
              collaboratorId={currentCollaboratorId}
              permission={permission}
            />
          ),
          path: `/organisations/${currentOrgId}/collaborators/${currentCollaboratorId}/teams`,
          acceptedPermissions: [
            INTERNAL_ADMIN,
            BUSINESS_ADMIN,
            SUPPORT_ADMIN,
            ACCOUNT_MANAGER_ADMIN,
            STANDARD_USER
          ],
        }
      );
    }
    collaboratorSubMenu.push(
      {
        label: 'Collaborating',
        container: () => (
          <CollaboratorOrganisations
            organisation={organisation}
            collaboratorId={currentCollaboratorId}
          />
        ),
        path: `/organisations/${currentOrgId}/collaborators/${currentCollaboratorId}/collaborating`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN
        ],
      }
    );

    const teamSubMenu = [
      {
        label: 'Details',
        container: () => (
          <TeamDetails
            organisationId={currentOrgId}
            teamId={currentTeamId}
            organisation={organisation}
            permission={permission}
          />
        ),
        path: `/organisations/${currentOrgId}/teams/${currentTeamId}/details`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Products',
        container: () => (
          <TeamAccess
            organisationId={currentOrgId}
            teamId={currentTeamId}
            organisation={organisation}
            permission={permission}
          />
        ),
        path: `/organisations/${currentOrgId}/teams/${currentTeamId}/products`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Users',
        container: () => (
          <TeamUsers
            organisationId={currentOrgId}
            teamId={currentTeamId}
            organisation={organisation}
            permission={permission}
          />
        ),
        path: `/organisations/${currentOrgId}/teams/${currentTeamId}/users`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Collaborators',
        container: () => (
          <TeamCollaborators
            organisationId={currentOrgId}
            teamId={currentTeamId}
            organisation={organisation}
            permission={permission}
          />
        ),
        path: `/organisations/${currentOrgId}/teams/${currentTeamId}/collaborators`,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
    ];
    const items = [
      {
        label: 'Details',
        path: `/organisations/${currentOrgId}/details`,
        container: () => (
          <Details
            organisation={organisation}
            organisationId={currentOrgId}
            permission={permission}
          />
        ),
        icon: 'details',
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Relationship',
        path: `/organisations/${currentOrgId}/relationship`,
        container: () => (
          <Relationship
            organisation={organisation}
            organisationId={currentOrgId}
            permission={permission}
          />
        ),
        icon: 'relationship',
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
        ],
      },
      {
        label: 'Products',
        path: `/organisations/${currentOrgId}/products`,
        container: () => (
          <Access organisation={organisation} permission={permission} />
        ),
        icon: 'product',
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN
        ],
      },
      {
        label: 'Teams',
        path: `/organisations/${currentOrgId}/teams`,
        container: () => (
          <Teams
            organisationId={currentOrgId}
            subItems={teamSubMenu}
            permission={permission}
          />
        ),
        noShow: !canHaveTeams,
        icon: 'team',
        subMenuDisplay: currentTeamMenuDisplay,
        subMenu: teamSubMenu,
        subMenuTitle: currentTeamSubTitle,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Users',
        path: `/organisations/${currentOrgId}/users`,
        container: () => (
          <Users
            organisationId={currentOrgId}
            subItems={userSubMenu}
            permission={permission}
            organisation={organisation}
          />
        ),
        icon: 'user',
        subMenuDisplay: currentUserMenuDisplay,
        subMenu: userSubMenu,
        subMenuTitle: currentUserSubTitle,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Collaborators',
        path: `/organisations/${currentOrgId}/collaborators`,
        container: () => (
          <Collaborators
            organisationId={currentOrgId}
            subItems={collaboratorSubMenu}
            permission={permission}
            organisation={organisation}
          />
        ),
        icon: 'collaborator',
        subMenuDisplay: currentCollaboratorMenuDisplay,
        subMenu: collaboratorSubMenu,
        subMenuTitle: currentCollaboratorSubTitle,
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
      {
        label: 'Usage Log',
        path: `/organisations/${currentOrgId}/usage-log`,
        container: () => (
          <UsageLogs
            organisationId={currentOrgId}
            permission={permission}
            organisation={organisation}
          />
        ),
        icon: 'usage',
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
    ];

    // TODO: Add in error message
    if (typeof organisation === 'undefined') {
      return <PageLoader />;
    }

    return (
      <Container>
        <Grid>
          <Grid.Column width={12}>
            <Breadcrumb
              topLevelContext="All Organisations"
              currentContext={organisation.name}
              backLink={
                restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN], permission)
                  ? '/organisations'
                  : undefined
              }
            />
          </Grid.Column>
        </Grid>
        <Grid className="side-menu-layout">
          <Grid.Column width={3} className="side-menu-column">
            <SideMenu
              currentPath={currentUrl}
              items={items}
              permission={permission}
            />
          </Grid.Column>
          <Grid.Column width={9}>
            <Grid>
              <Grid.Column>
                {items.map((item, index) => {
                  if (
                    item.acceptedPermissions &&
                    restrictUserPermissions(
                      item.acceptedPermissions,
                      permission
                    )
                  ) {
                    return (
                      <Route
                        key={index}
                        path={item.path}
                        render={item.container}
                      />
                    );
                  }
                  return '';
                })}
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = function (state: any, props) {
  return {
    isFetching: state.organisations.isFetching,
    organisation: state.organisations.byId[props.match.params.organisationId]
      ? state.organisations.byId[props.match.params.organisationId]
      : state.userOrganisations.byId[props.match.params.organisationId],
    user: state.organisationUsers.byId[props.match.params.userId],
    team: state.organisationTeams.byId[props.match.params.teamId],
    collaborator:
      state.organisationCollaborators.byId[props.match.params.collaboratorId],
    permission: getPermissionInFeature(
      managementFeatures.LIVE_MANAGEMENT,
      state.auth.authorisation
    )
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      readOrganisation,
      changeOrganisation,
    },
    dispatch
  );

export default (withRouter(
  // $FlowFixMe
  connect(mapStateToProps, mapDispatchToProps)(Organisation)
): any);
