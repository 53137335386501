// @flow
import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import TableActions from '../../components/TableActions';
import PaginatedTable from '../../components/PaginatedTable';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_sortData } from '../../helpers/sort';
import PageLoader from '../../components/PageLoader';
import { perDomain } from '../../actions/organisations/usageLogs/perDomain';
import InlineSvg from '../../components/InlineSvg';

type TableLog = {
  id: number,
  domain: string,
  last7Days: boolean,
  last4Weeks: boolean,
  last6Months: boolean,
  last12Months: boolean,
  lastDateAccessed: string,
};

type Props = {
  organisationId: number,
  userId: number,
};

export default function UserUsageTable({ organisationId, userId }: Props): any {
  const dispatch = useDispatch();
  const userUsagePerDomain = useSelector((state) => state.userUsagePerDomain);
  const isFetching = userUsagePerDomain.isFetching;
  const data = userUsagePerDomain.result;

  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    dispatch(perDomain(organisationId, userId));
  }, [dispatch, organisationId, userId]);

  const columnClick = (column: string) => {
    if (sortColumn !== column) {
      setSortColumn(column);

      if (['domain', 'last7Days', 'last4Weeks', 'last6Months', 'last12Months', 'lastDateAccessed'].includes(column)) {
        setSortDirection('descending');
      } else {
        setSortDirection('ascending');
      }

      return;
    }

    const newSort = sortDirection === 'ascending' ? 'descending' : 'ascending';
    setSortDirection(newSort);
  };

  const TableHeader = (
    <Table.Row>
      <Table.HeaderCell
        sorted={sortColumn === 'domain' ? sortDirection : null}
        onClick={() => columnClick('domain')}
      >
        Domain
      </Table.HeaderCell>

      <Table.HeaderCell
        sorted={sortColumn === 'last7Days' ? sortDirection : null}
        onClick={() => columnClick('lastDateAccessed')}
      >
        L7D
      </Table.HeaderCell>

      <Table.HeaderCell
        sorted={sortColumn === 'last4Weeks' ? sortDirection : null}
        onClick={() => columnClick('lastDateAccessed')}
      >
        L4W
      </Table.HeaderCell>

      <Table.HeaderCell
        sorted={sortColumn === 'last6Months' ? sortDirection : null}
        onClick={() => columnClick('lastDateAccessed')}
      >
        L6M
      </Table.HeaderCell>

      <Table.HeaderCell
        sorted={sortColumn === 'last12Months' ? sortDirection : null}
        onClick={() => columnClick('lastDateAccessed')}
      >
        L12M
      </Table.HeaderCell>

      <Table.HeaderCell
        sorted={sortColumn === 'lastDateAccessed' ? sortDirection : null}
        onClick={() => columnClick('lastDateAccessed')}
      >
        Last Date Accessed
      </Table.HeaderCell>
    </Table.Row>
  );

  let rowData = data.filter((log: TableLog) => {
    const domain = log.domain.toLowerCase();
    const filter = filterValue.toLowerCase();

    return domain === filter || domain.includes(filter) || filter === '';
  });

  // sorts domains alpabetically 
  if (sortDirection === 'ascending') {
    rowData = rowData.sort((a, b) => a.domain.localeCompare(b.domain));
  } else if (sortDirection === 'descending') {
    rowData = rowData.sort((a, b) => b.domain.localeCompare(a.domain));
  }

  const rows = rowData
    .sort((a, b) => NEW_sortData(a, b, { sortColumn, sortDirection }))
    .map((log: TableLog) => {
      return (
        <Table.Row key={log.id}>
          <Table.Cell>
            <span className="usage-table__domain">{log.domain}</span>
          </Table.Cell>

          <Table.Cell>
            <TableIcon value={log.last7Days} />
          </Table.Cell>

          <Table.Cell>
            <TableIcon value={log.last4Weeks} />
          </Table.Cell>

          <Table.Cell>
            <TableIcon value={log.last6Months} />
          </Table.Cell>

          <Table.Cell>
            <TableIcon value={log.last12Months} />
          </Table.Cell>

          <Table.Cell>
            <span className="user-usage-table__last-login-date">
              {log.lastDateAccessed}
            </span>
          </Table.Cell>
        </Table.Row>
      );
    });

  return (
    <div className="usage-table">
      <TableActions
        title="Engagement per Domain"
        filterAction={(e) => {
          setFilterValue(e.target.value);
        }}
        filterPlaceholder="Filter Usage Logs..."
      />
      {isFetching ? (<PageLoader />) : (
        <PaginatedTable
          perPage={40}
          headerRow={TableHeader}
          rows={rows}
          filterValue={filterValue}
          sortable={true}
        />
      )}
    </div>
  );
}

const TableIcon = ({ value }: { value: boolean }) => {
  if (value) {
    return <InlineSvg className="usage-table__table-icon" name="ok" />;
  }

  return (
    <InlineSvg
      className="usage-table__table-icon"
      name="not-ok"
      colour={'mischka'}
    />
  );
};
