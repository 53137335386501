// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_FEATURE_REMOVE_REQUEST =
  'ORGANISATION_FEATURE_REMOVE_REQUEST';
export const ORGANISATION_FEATURE_REMOVE_SUCCESS =
  'ORGANISATION_FEATURE_REMOVE_SUCCESS';
export const ORGANISATION_FEATURE_REMOVE_FAILURE =
  'ORGANISATION_FEATURE_REMOVE_FAILURE';

const removeOrganisationFeatureRequest = (featureId) => ({
  type: ORGANISATION_FEATURE_REMOVE_REQUEST,
  id: featureId,
});

const removeOrganisationFeatureSuccess = (featureId) => ({
  type: ORGANISATION_FEATURE_REMOVE_SUCCESS,
  id: featureId,
});

const removeOrganisationFeatureFailure = (error, featureId) => ({
  type: ORGANISATION_FEATURE_REMOVE_FAILURE,
  id: featureId,
  error,
});

export const removeOrganisationFeature = (
  organisationId: number,
  featureId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOrganisationFeatureRequest(featureId));

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/features/${featureId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(removeOrganisationFeatureSuccess(featureId));

    return featureId;
  } catch (error) {
    dispatch(removeOrganisationFeatureFailure(error.message, featureId));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
