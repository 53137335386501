// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ALL_USERS_USAGE_REQUEST = 'ALL_USERS_USAGE_REQUEST';
export const ALL_USERS_USAGE_SUCCESS = 'ALL_USERS_USAGE_SUCCESS';
export const ALL_USERS_USAGE_FAILURE = 'ALL_USERS_USAGE_FAILURE';

const getAllUsageByUserRequest = () => ({
  type: ALL_USERS_USAGE_REQUEST,
});

const getAllUsageByUserSuccess = (overallOrgsUsage) => ({
  type: ALL_USERS_USAGE_SUCCESS,
  payload: overallOrgsUsage,
});

const getAllUsageByUserFailure = (error) => ({
  type: ALL_USERS_USAGE_FAILURE,
  error,
});

export const resetUsageByUser = (): any => async (dispatch, getState) => {
  dispatch(getAllUsageByUserSuccess([]));
};

export const getAllUsageByUser = (
  domain: string,
  isGlobalAdmin?: boolean,
  filterRm?: boolean
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getAllUsageByUserRequest());

    // define query params
    let queryArr = [];
    // filter domains from results
    if (domain) queryArr.push(`domain=${domain}`);
    // modify query string to filter out RM email users from org total data
    if (filterRm) queryArr.push(`filterRm=true`);

    // build url endpoint
    const baseUrl = `${getApiBaseURL('v2', 'management')}`;
    let route = 'usage/active-overall/users';
    if (isGlobalAdmin) {
      route = 'usage/overall/users';
    }

    // append query params
    if (queryArr.length > 0) {
      route += `?${queryArr.join('&').trim()}`;
    }

    const response = await callAPI({
      method: 'GET',
      endpoint: `${baseUrl}/${route}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(getAllUsageByUserSuccess(response));
    return response;
  } catch (error) {
    dispatch(getAllUsageByUserFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
