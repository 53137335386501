// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import type { User } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ADMIN_UPDATE_USER_PASSWORD_REQUEST =
  'ADMIN_UPDATE_USER_PASSWORD_REQUEST';
export const ADMIN_UPDATE_USER_PASSWORD_SUCCESS =
  'ADMIN_UPDATE_USER_PASSWORD_SUCCESS';
export const ADMIN_UPDATE_USER_PASSWORD_FAILURE =
  'ADMIN_UPDATE_USER_PASSWORD_FAILURE';

function adminUpdateUserRequestPassword() {
  return {
    type: ADMIN_UPDATE_USER_PASSWORD_REQUEST,
  };
}

function adminUpdateUserSuccessPassword(user: User) {
  return {
    type: ADMIN_UPDATE_USER_PASSWORD_SUCCESS,
    payload: user,
  };
}

function adminUpdateUserFailurePassword(error: string) {
  return {
    type: ADMIN_UPDATE_USER_PASSWORD_FAILURE,
    error: error,
  };
}

export const adminUpdateUserPassword = (
  userId: number,
  password: string
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(adminUpdateUserRequestPassword());
    const today = new Date();

    const requestBody = {
      userId,
      password,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/account/password/admin-change`,
      body: requestBody,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(adminUpdateUserSuccessPassword(response));
    return true;
  } catch (error) {
    dispatch(adminUpdateUserFailurePassword(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return false;
  }
};
