// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_USER_REMOVE_REQUEST = 'API_USER_REMOVE_REQUEST';
export const API_USER_REMOVE_SUCCESS = 'API_USER_REMOVE_SUCCESS';
export const API_USER_REMOVE_FAILURE = 'API_USER_REMOVE_FAILURE';

const removeUserAccessRequest = (id) => ({
  type: API_USER_REMOVE_REQUEST,
  id
});

const removeUserAccessSuccess = (user) => ({
  type: API_USER_REMOVE_SUCCESS,
  payload: user
});

const removeUserAccessFailure = (error, id) => ({
  type: API_USER_REMOVE_FAILURE,
  error,
  id
});

export const removeUserAccess = (
  apiId: number,
  organisationId: number,
  user: any
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeUserAccessRequest(user.id));

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${user.id}/apis/${apiId}`
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(removeUserAccessSuccess(user));
  } catch (error) {
    dispatch(removeUserAccessFailure(error.message, user.id));
    Sentry.captureException(error);
    console.error(error);
  }
};