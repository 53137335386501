//@flow
import React from 'react';
import TabHeader from '../../components/TabHeader';
import type {
  State as AppState,
  Feature,
  OrganisationTeam,
  Organisation,
  AuthTypes,
  Dispatch,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadOrganisationTeamFeatures,
  updateOrganisationTeamFeature,
} from '../../actions/organisations/organisationTeams/organisationTeamFeatures';
import { getOrganisationTeamFeatures } from '../../reducers/organisationTeamFeatures';
import TableActions from '../../components/TableActions';
import PageLoader from '../../components/PageLoader';
import FeatureAcessTable from '../../components/FeatureAccessTable';
import { setFetchSyncReady } from '../../actions';

type Props = {
  organisation: Organisation,
  organisationId: number,
  team: ?OrganisationTeam,
  teamId: number,
  features: Array<Feature>,
  permission: ?AuthTypes,
  loadOrganisationTeamFeatures: (oid: number, tid: number) => Promise<null>,
  updateOrganisationTeamFeature: (
    oid: number,
    tid: number,
    pid: number,
    body:  { hasAccess: ?boolean }
  ) => Promise<null>,
  isFetching: boolean,
  isReady: boolean,
  setTeamAccessReady: () => void,
};

class TeamAccess extends React.Component<Props> {
  componentDidMount() {
    this.props.loadOrganisationTeamFeatures(
      this.props.organisation.id,
      this.props.teamId
    );
  }

  updateTeamFeatureAccess = (featureId, hasAccess) => {
    this.props.updateOrganisationTeamFeature(
      this.props.organisation.id,
      this.props.teamId,
      featureId,
      { hasAccess }
    );
  };

  render() {
    const {
      features,
      team,
      organisationId,
      isFetching,
    } = this.props;

    return (
      <div>
        <TabHeader
          tabHeading={`${team ? team.name + "'s " : ''}Access`}
          backLink={`/organisations/${organisationId}/teams`}
          backText="Back to Teams"
        />
        <TableActions title="Product Access" />
        {isFetching ? (
          <PageLoader />
        ) : (
          <FeatureAcessTable
            features={features}
            canUpdate={true}
            updateAccess={this.updateTeamFeatureAccess}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationTeamFeatures.isFetching,
  features: getOrganisationTeamFeatures(state),
  // $FlowFixMe
  team: state.organisationTeams.byId[props.teamId],
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationTeamFeatures,
      updateOrganisationTeamFeature,
      setTeamAccessReady: setFetchSyncReady('teamAccess'),
    },
    dispatch
  );

// $FlowFixMe
export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamAccess): any);
