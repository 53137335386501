// @flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logOut, getAuthToken } from 'roy-morgan-auth';
import NotAuthenticatedMessage from './NotAuthenticatedMessage';
import type { State as AppState } from '../types';
import { getIsReady } from '../reducers/fetchSync';

const PrivateRoute = ({
  component: Component,
  location,
  logOut,
  isUserDataReady,
  ...rest
}: any) => {
  const authToken = getAuthToken();
  if (!authToken || authToken === '0') {
    logOut();
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authToken && authToken !== '0' ? (
          isUserDataReady ? (
            <Component {...props} />
          ) : null
        ) : location.pathname === '/' ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ) : (
          <NotAuthenticatedMessage
            title="You must be logged in to view this page"
            buttonText="Go to Log In"
            loginUrl="login"
          />
        )
      }
    />
  );
};

const mapStateToProps = (state: AppState, props) => ({
  isUserDataReady: getIsReady(state, 'userData'),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      logOut,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute): any);
