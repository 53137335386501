// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadOrganisationFlags, loadOrganisations } from '../actions';
import type { Dispatch } from '../types';
import { Container, Grid, Table } from 'semantic-ui-react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { getOrganisationsSortedAlphabetically } from '../reducers/organisations';
import PaginatedTable from '../components/PaginatedTable';
import Breadcrumb from '../components/Breadcrumb';
import TableActions from '../components/TableActions';
import type {
  Organisation,
  State as GlobalState,
  OrganisationFlag as OrganisationFlagType,
} from '../types';
import PageLoader from '../components/PageLoader';
import {
  mapOrganisationAccessToPermission,
  restrictUserPermissions,
  permissions
} from 'roy-morgan-auth';
import moment from 'moment';
import { getOrganisationFlagsSortedById } from '../reducers/organisationFlags';
import {
  // $FlowFixMe
  sortChangeHandler,
  // $FlowFixMe
  sortData,
} from '../helpers';

type Props = {
  match: {
    params: Object,
    url: string,
  },
  loadOrganisations: () => Promise<boolean>,
  loadOrganisationFlags: () => Promise<OrganisationFlagType>,
  organisations: Array<Object>,
  organisationFlags: Array<OrganisationFlagType>,
  isFetching: boolean,
  permission: string,
  currentOrganisation: number,
};

type State = {
  filterValue: string,
  sortColumn: string,
  sortDirection: string,
};

export class Organisations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      sortColumn: '',
      sortDirection: '',
    };
  }

  componentDidMount() {
    const {
      loadOrganisations,
      permission,
      loadOrganisationFlags,
    } = this.props;
    const { INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN } = permissions;

    if (restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN], permission)) {
      loadOrganisations(); // company admin users do not have access to organisation listing page, so no need to load
      loadOrganisationFlags();
    }
  }

  getOrganisationFlagTextById: (id: any) => string = (id: any) => {
    const { organisationFlags } = this.props;
    let of = organisationFlags.find((f) => f.id === id);
    return of ? of.name : '';
  };
  handleFilterChange: (e: SyntheticInputEvent<HTMLInputElement>) => void = (
    e: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  handleSortChange: (clickedColumn: any) => () => any = (
    clickedColumn: any
  ) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort: (a: any, b: any) => any = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  render(): React.Node {
    const { sortColumn, sortDirection } = this.state;
    const {
      organisations,
      isFetching,
      permission,
      currentOrganisation,
      match,
    } = this.props;

    const { INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN } = permissions;

    if (!restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN], permission)) {
      if (!currentOrganisation) {
        return <PageLoader />;
      }
      const tab = match.params.tab || 'details';
      return <Redirect to={`/organisations/${currentOrganisation}/${tab}`} />;
    }

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'name' ? sortDirection : null}
          onClick={this.handleSortChange('name')}
        >
          Name
        </Table.HeaderCell>
        <Table.HeaderCell
          className="table__hide-on-mobile"
          sorted={sortColumn === 'dateCreated' ? sortDirection : null}
          onClick={this.handleSortChange('dateCreated')}
        >
          Created Date
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'nextReviewDate' ? sortDirection : null}
          onClick={this.handleSortChange('nextReviewDate')}
        >
          Review Date
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={
            sortColumn === 'organisationFlagName' ? sortDirection : null
          }
          onClick={this.handleSortChange('organisationFlagName')}
        >
          Flag
        </Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    );

    const filterValue = this.state.filterValue.toLowerCase() || '';

    let rows = organisations.filter((organisation: Organisation) => {
      const orgName = organisation.name.toLowerCase();
      return (
        orgName === filterValue ||
        orgName.includes(filterValue) ||
        filterValue === ''
      );
    });

    rows = rows.map((organisation: Organisation) => {
        return {
          ...organisation,
          organisationFlagName: this.getOrganisationFlagTextById(organisation.organisationFlagId)
        };
    });

    rows = rows.sort(this.sort).map((organisation: any) => {
      return (
        <Table.Row key={organisation.id}>
          <Table.Cell>{organisation.name}</Table.Cell>
          <Table.Cell className="table__hide-on-mobile">
            {moment
              .utc(organisation.dateCreated)
              .local()
              .format('YYYY-MM-DD')
              .toString()}
          </Table.Cell>
          <Table.Cell>
            {organisation.nextReviewDate
              ? moment
                  .utc(organisation.nextReviewDate)
                  .local()
                  .format('YYYY-MM-DD')
                  .toString()
              : ''}
          </Table.Cell>
          <Table.Cell>
            {this.getOrganisationFlagTextById(
              organisation.organisationFlagId
            )}
          </Table.Cell>
          <Table.Cell>
            <Link to={`/organisations/${organisation.id}`}>View</Link>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Container data-testid="organisations-container">
        <Grid centered stackable>
          <Grid.Column>
            <div className="page-content">
              <Breadcrumb currentContext="Organisations" />
              <div className="table-container">
                <TableActions
                  filterAction={this.handleFilterChange}
                  filterPlaceholder="Filter Organisations..."
                  buttonURL="/organisations/new"
                  buttonText="Create Organisation"
                  hideButton={
                    !restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN], permission)
                  }
                />
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    headerRow={TableHeader}
                    rows={rows}
                    perPage={100}
                    sortable={true}
                  />
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  organisations: getOrganisationsSortedAlphabetically(state),
  currentOrganisation: state.auth.organisations.currentOrganisation,
  organisationFlags: getOrganisationFlagsSortedById(state),
  isFetching: state.organisations.isFetching,
  permission: state.auth.user.user
    ? mapOrganisationAccessToPermission(true, state.auth.user.user.accountTypeId)
    : permissions.NO_ACCESS
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisations,
      loadOrganisationFlags,
    },
    dispatch
  );

export default (withRouter(
  // $FlowFixMe
  connect(mapStateToProps, mapDispatchToProps)(Organisations)
): any);
