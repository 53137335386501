//@flow
import * as React from 'react';
import { Grid, Form, Dropdown, Button, Modal } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react'; //ToDo: Current version produces warning.  Developer has stated that it will be fixed in next release.  https://github.com/arfedulov/semantic-ui-calendar-react/issues/56
import moment from 'moment';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import StaticField from '../StaticField';
import type {
  Organisation as OrgType,
  NewOrganisation as NewOrgType,
  OrganisationForm,
  AuthTypes,
  OrganisationFlag as OrganisationFlagType,
  AccountManager as AccountManagerType,
} from '../../types';
import SetAccountManagers from '../../containers/SetAccountManagers';

type Props = {
  permission: ?AuthTypes,
  organisationFlags: ?Array<OrganisationFlagType>,
  organisation: OrgType | NewOrgType,
  accountManagers: Array<AccountManagerType>,
  handleFormSubmit: Function,
  accountManagerAdditionAction: (
    oid: number,
    uid: number
  ) => Promise<AccountManagerType>,
  accountManagerRemovalAction: (oid: number, uid: number) => Promise<boolean>,
  children?: any,
};

type State = {
  setAccountManagerOpen?: boolean,
  formData: OrganisationForm,
  relationshipNotes?: string,
  liveSuiteContract?: string,
  workspaceContract?: string,
  personaEncodingAllowance?: string,
  organisationNextReviewDate?: string,
};

const dateFormat = 'DD/MM/YYYY';

const organisationFlagIdFromProps = (props: Props) => {
  return props.organisation && props.organisation.organisationFlagId
    ? props.organisation.organisationFlagId
    : null;
};

class OrganisationRelationshipFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formData: {},
    };

    if (
      restrictUserPermissions(
        [permissions.INTERNAL_ADMIN, permissions.BUSINESS_ADMIN],
        props.permission
      )
    ) {
      this.state = {
        formData: {
          ...this.state.formData,
          relationshipNotes: props.organisation.relationshipNotes,
          liveSuiteContractValue: props.organisation.liveSuiteContractValue,
          personaEncodingAllowance: props.organisation.personaEncodingAllowance,
          workspaceContractValue: props.organisation.workspaceContractValue,
          nextReviewDate: props.organisation.nextReviewDate,
          organisationFlagId: organisationFlagIdFromProps(props)
        },
        organisationName: props.organisation.name,
        organisationNotes: props.organisation.notes,
        organisationNextReviewDate: props.organisation.nextReviewDate,
        setAccountManagerOpen: false,
        setCanHaveTeamOpen: false
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      restrictUserPermissions(
        [permissions.INTERNAL_ADMIN, permissions.BUSINESS_ADMIN],
        nextProps.permission
      )
    ) {
      this.setState({
        formData: {
          ...this.state.formData,
          relationshipNotes: nextProps.organisation.relationshipNotes,
          liveSuiteContractValue: nextProps.organisation.liveSuiteContractValue,
          personaEncodingAllowance: nextProps.organisation.personaEncodingAllowance,
          workspaceContractValue: nextProps.organisation.workspaceContractValue,
          nextReviewDate: nextProps.organisation.nextReviewDate,
          organisationFlagId: organisationFlagIdFromProps(nextProps)
        },
      });
    }
  }

  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number, ... }
  ) => void = (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => {
    this.setState({
      formData: {
        ...this.state.formData,
        industryId: result.value,
      },
    });
  };

  handleDropdownChangeOrganisationFlag: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number, ... }
  ) => void = (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => {
    this.setState({
      formData: {
        ...this.state.formData,
        organisationFlagId: result.value,
      },
    });
  };

  handleDateChange: (event: any, { name: any, value: any, ... }) => void = (
    event: any,
    { name, value }: { name: any, value: any }
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[name] = value ? moment(value, dateFormat).toISOString() : null;
    this.setState({ formData });
  };

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleAccountManagerAddition: (userId: number) => void = (userId: number) => {
    this.props
      .accountManagerAdditionAction(this.props.organisation.id, userId)
      .then(
        (newManager) => {
          this.setState({ setAccountManagerOpen: false });
        },
        (failure) => {}
      );
  };

  handleAccountManagerRemoval: (userId: number) => void = (userId: number) => {
    this.props
      .accountManagerRemovalAction(this.props.organisation.id, userId)
      .then(
        () => {
          this.setState({ setAccountManagerOpen: false });
        },
        (failure) => {}
      );
  };

  toggleSetAccountManagerModal: (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => void = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.setState({
      setAccountManagerOpen: !this.state.setAccountManagerOpen,
    });
  };

  render(): React.Node {
    const { handleFormSubmit, permission, organisationFlags, accountManagers } =
      this.props;
    let organisationFlagId, relationshipNotesValue, nextReviewDate, liveSuiteContractValue, workspaceContractValue, personaEncodingAllowanceValue;
    const {
      setAccountManagerOpen,
      relationshipNotes,
      organisationNextReviewDate,
      liveSuiteContract,
      workspaceContract,
      personaEncodingAllowance
    } = this.state;

    const { INTERNAL_ADMIN, BUSINESS_ADMIN, STANDARD_USER } = permissions;

    let accountManagersDisplay = accountManagers.map((m) => m.name).join(', ');

    if (restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN], permission)) {
      liveSuiteContractValue = this.state.formData.liveSuiteContractValue;
      workspaceContractValue = this.state.formData.workspaceContractValue;
      personaEncodingAllowanceValue = this.state.formData.personaEncodingAllowance;
      relationshipNotesValue = this.state.formData.relationshipNotes;
      nextReviewDate = this.state.formData.nextReviewDate;
      organisationFlagId = this.state.formData.organisationFlagId;
    } else {
      relationshipNotesValue = relationshipNotes ? relationshipNotes : '';
      liveSuiteContractValue = liveSuiteContract ? liveSuiteContract : '';
      workspaceContractValue = workspaceContract ? workspaceContract : '';
      personaEncodingAllowanceValue = personaEncodingAllowance ? personaEncodingAllowance : '';
      nextReviewDate = organisationNextReviewDate
        ? organisationNextReviewDate
        : '';
      organisationFlagId = organisationFlagIdFromProps(this.props);
    }

    const nextReviewDateFormatted = nextReviewDate
      ? moment(nextReviewDate).format(dateFormat)
      : '';

    const organisationFlagOptions = organisationFlags
      ? organisationFlags.map((organisationFlag: OrganisationFlagType) => {
          return {
            text: organisationFlag.name,
            value: organisationFlag.id,
          };
        })
      : null;

    return (
      <Form
        className="organisation-relationship-form"
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column width={12}>
              <StaticField
                label="Account Managers"
                value={accountManagersDisplay}
              />
              <div>
                <Button onClick={this.toggleSetAccountManagerModal}>
                  Set Account Managers
                </Button>
                <Modal
                  open={setAccountManagerOpen}
                  closeIcon={<button className="modal__close">Close</button>}
                  onClose={this.toggleSetAccountManagerModal}
                >
                  <Modal.Header>Set Account Managers</Modal.Header>
                  <Modal.Content>
                    <SetAccountManagers
                      accountManagers={accountManagers}
                      addAccountManager={this.handleAccountManagerAddition}
                      removeAccountManager={this.handleAccountManagerRemoval}
                    />
                  </Modal.Content>
                </Modal>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <IndustryOrganisationFlagForm
                permission={permission}
                handleDropdownChange={this.handleDropdownChange}
                organisationFlagOptions={organisationFlagOptions}
                organisationFlagId={organisationFlagId}
                handleDropdownChangeOrganisationFlag={
                  this.handleDropdownChangeOrganisationFlag
                }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <DateInput
                label="End Date"
                name="nextReviewDate"
                placeholder="End date"
                value={nextReviewDateFormatted}
                initialDate={nextReviewDateFormatted}
                iconPosition="left"
                onChange={this.handleDateChange}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <Form.Input
                label="Contract Value pertaining to Live Suite"
                name="liveSuiteContractValue"
                value={liveSuiteContractValue}
                onChange={this.handleInputChange}
                placeholder="Contract Value pertaining to Live Suite (Dollar Value)"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <Form.Input
                label="Contract Value pertaining to Workspace"
                name="workspaceContractValue"
                value={workspaceContractValue}
                onChange={this.handleInputChange}
                placeholder="Contract Value pertaining to Workspace (Dollar Value)"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <Form.Input
                label="Persona Encoding Allowance"
                name="personaEncodingAllowance"
                value={personaEncodingAllowanceValue}
                onChange={this.handleInputChange}
                placeholder="Persona Encoding Allowance (Dollar Value)"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <Form.TextArea
                label="Notes"
                name="relationshipNotes"
                value={relationshipNotesValue}
                onChange={this.handleInputChange}
                placeholder="Enter your notes"
                rows={10}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className="organisation-form__actions">
                <Button
                  className="organisation-form__submit"
                  type="submit"
                  disabled={permission === STANDARD_USER}
                >
                  Submit
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const IndustryOrganisationFlagForm = (props: {
  organisationFlagOptions: ?(Object[]),
  organisationFlagId: ?number,
  permission: ?AuthTypes,
  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => void,
  handleDropdownChangeOrganisationFlag: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => void,
}) => {
  return (
    <div>
      {props.organisationFlagOptions ? (
        <Form.Field>
          <label htmlFor="organisationFlagId">Account Type</label>
          <Dropdown
            onChange={props.handleDropdownChangeOrganisationFlag}
            name="industryId"
            value={props.organisationFlagId}
            fluid
            selection
            placeholder={'Select Account Type'}
            options={props.organisationFlagOptions}
          />
          <br />
        </Form.Field>
      ) : (
        ''
      )}
    </div>
  );
};

export default OrganisationRelationshipFormFields;
