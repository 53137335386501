export const getItemsSortedByName = (items) => {
  let byId = items.byId;

  return byId === undefined
    ? []
    : Object.keys(byId)
        .sort((a, b) => {
          if (byId[a].name.toLowerCase() < byId[b].name.toLowerCase()) {
            return -1;
          } else if (byId[a].name.toLowerCase() > byId[b].name.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        })
        .map((sortedKey) => byId[sortedKey]);
};

export const sortData = (a, b, container) => {
  const { sortColumn, sortDirection } = container.state;
  const dir = sortDirection === 'ascending' ? 1 : -1;

  if (!sortColumn) {
    return 0;
  }

  if (a[sortColumn] === undefined) {
    return 1 * dir;
  }
  if (b[sortColumn] === undefined) {
    return -1 * dir;
  }

  if (
    (isNaN(a[sortColumn]) || a[sortColumn] === '') &&
    (isNaN(b[sortColumn]) || b[sortColumn] === '')
  ) {
    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) {
      return -1 * dir;
    } else if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) {
      return dir;
    } else {
      return 0;
    }
  }

  return dir * (a[sortColumn] - b[sortColumn]);
};

export const NEW_sortData = (a, b, values) => {
  const { sortColumn, sortDirection } = values;
  const dir = sortDirection === 'ascending' ? 1 : -1;

  if (!sortColumn) {
    return 0;
  }

  if (a[sortColumn] === undefined) {
    return 1 * dir;
  }
  if (b[sortColumn] === undefined) {
    return -1 * dir;
  }

  if (
    (isNaN(a[sortColumn]) || a[sortColumn] === '') &&
    (isNaN(b[sortColumn]) || b[sortColumn] === '')
  ) {
    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) {
      return -1 * dir;
    } else if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) {
      return dir;
    } else {
      return 0;
    }
  }

  return dir * (a[sortColumn] - b[sortColumn]);
};

export const sortChangeHandler = (clickedColumn, container) => {
  const { sortColumn, sortDirection } = container.state;

  if (sortColumn !== clickedColumn) {
    container.setState({
      sortColumn: clickedColumn,
      sortDirection: 'ascending',
    });

    return;
  }

  container.setState({
    sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending',
  });
};
