// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_REMOVE_REQUEST =
  'ORGANISATION_COLLABORATOR_REMOVE_REQUEST';
export const ORGANISATION_COLLABORATOR_REMOVE_SUCCESS =
  'ORGANISATION_COLLABORATOR_REMOVE_SUCCESS';
export const ORGANISATION_COLLABORATOR_REMOVE_FAILURE =
  'ORGANISATION_COLLABORATOR_REMOVE_FAILURE';

const removeOrganisationCollaboratorRequest = () => ({
  type: ORGANISATION_COLLABORATOR_REMOVE_REQUEST,
});

const removeOrganisationCollaboratorSuccess = (message, collaboratorId) => ({
  type: ORGANISATION_COLLABORATOR_REMOVE_SUCCESS,
  message: message,
  id: collaboratorId,
});

const removeOrganisationCollaboratorFailure = (error) => ({
  type: ORGANISATION_COLLABORATOR_REMOVE_FAILURE,
  error,
});

export const removeOrganisationCollaborator = (
  organisationId: number,
  collaboratorId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOrganisationCollaboratorRequest());

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/collaborators/${collaboratorId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(removeOrganisationCollaboratorSuccess(response, collaboratorId));

    return true;
  } catch (error) {
    dispatch(removeOrganisationCollaboratorFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
