// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { readOnlineStoreOrder, setFetchSyncReady } from '../../actions';
import type {
  // State as AppState,
  // Dispatch,
  OnlineStoreOrder as OnlineStoreOrderType,
  // AccessRole,
  AuthTypes,
} from '../../types';
import Breadcrumb from '../../components/Breadcrumb';
import SideMenu from '../../components/SideMenu';
import {
  OnlineStoreOrderDetails,
  OnlineStoreOrderItems,
} from '../OnlineStoreOrderTabs';
import { managementFeatures } from '../../helpers';
import { getPermissionInFeature } from 'roy-morgan-auth';
import { getIsReady } from '../../reducers/fetchSync';
type Props = {
  onlineStoreOrder: OnlineStoreOrderType,
  permission: ?AuthTypes,
  setSelectedOnlineStoreOrder: (uid: number) => number,
  match: {
    params: {
      tab?: string,
      onlineStoreOrderId: number,
    },
    url: string,
  },
  readOnlineStoreOrder: (onlineStoreOrderId: number) => Promise<null>,
  isReady: boolean,
  setOnlineStoreOrderReady: () => void,
};

class OnlineStoreOrder extends React.Component<Props, any> {
  componentDidMount() {
    const { match, readOnlineStoreOrder } = this.props;
    readOnlineStoreOrder(match.params.onlineStoreOrderId).finally(
      this.props.setOnlineStoreOrderReady
    );
  }

  render() {
    const { onlineStoreOrder, match, permission, isReady } = this.props;
    const currentUrl = match.url;
    const onlineStoreOrderId = match.params.onlineStoreOrderId;

    const items = [
      {
        label: 'Store Order Items',
        container: () => (
          <OnlineStoreOrderItems
            onlineStoreOrderId={onlineStoreOrderId}
            props={this.props}
          />
        ),
        path: `/storeorders/${onlineStoreOrderId}/items`,
      },
      {
        label: 'Details',
        container: () => (
          <OnlineStoreOrderDetails
            onlineStoreOrderId={onlineStoreOrderId}
            props={this.props}
          />
        ),
        path: `/storeorders/${onlineStoreOrderId}/details`,
      },
    ];

    if (!isReady) {
      return null;
    }

    if (typeof onlineStoreOrder === 'undefined') {
      return <Redirect to="/storeorders" />;
    } else {
      return (
        <Container>
          <Grid>
            <Grid.Column width={12}>
              <Breadcrumb
                titleClass={'breadcrumb__current-title--lower-case'}
                topLevelContext="All Store Orders"
                currentContext={'Order ID: ' + onlineStoreOrder.id}
                backLink="/storeorders"
              />
            </Grid.Column>
          </Grid>
          <Grid className="side-menu-layout">
            <Grid.Column width={3} className="side-menu-column">
              <SideMenu
                currentPath={currentUrl}
                items={items}
                permission={permission}
              />
            </Grid.Column>
            <Grid.Column width={9}>
              <Grid>
                <Grid.Column>
                  {items.map((item, index) => {
                    return (
                      <Route
                        key={index}
                        path={item.path}
                        render={item.container}
                      />
                    );
                  })}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state: any, props) => {
  return {
    onlineStoreOrder:
      state.onlineStoreOrders.byId[props.match.params.onlineStoreOrderId],
    permission: getPermissionInFeature(
      managementFeatures.ONLINE_STORE_MANAGEMENT,
      state.auth.authorisation
    ),
    isReady: getIsReady(state, 'onlineStoreOrder'),
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      readOnlineStoreOrder,
      setOnlineStoreOrderReady: setFetchSyncReady('onlineStoreOrder'),
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreOrder): any);
