//@flow
import React from 'react';
//import TabHeader from '../../components/TabHeader';
import type {
  // State as AppState,
  OnlineStoreCoupon,
  // Dispatch,
  AuthTypes,
  // OnlineStoreCouponTypes,
  // OnlineStoreCouponApplyTypes
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  readOnlineStoreCoupon,
  updateOnlineStoreCoupon,
  removeOnlineStoreCoupon,
  // $FlowFixMe
  loadCouponTypes,
  // $FlowFixMe
  loadCouponApplyTypes,
} from '../../actions';
import { Grid, Confirm } from 'semantic-ui-react';
import PageLoader from '../../components/PageLoader';
import OnlineStoreCouponFormFields from '../../components/OnlineStoreCoupon/OnlineStoreCouponFormFields';
import {
  getOnlineStoreCouponTypesSortedAlphabetically,
  getOnlineStoreCouponApplyTypesSortedAlphabetically,
} from '../../reducers/onlineStoreOptions';
import { managementFeatures } from '../../helpers';
import { getPermissionInFeature } from 'roy-morgan-auth';
//import { Confirm } from '../OrganisationTabs/Details';

type Props = {
  onlineStoreCouponId: number,
  onlineStoreCoupon: any,
  couponTypes: Array<any>,
  couponApplyTypes: Array<any>,
  isFetching: boolean,
  readOnlineStoreCoupon: (onlineStoreCouponId: number) => Promise<null>,
  loadCouponTypes: () => Promise<any>,
  loadCouponApplyTypes: () => Promise<any>,
  updateOnlineStoreCoupon: (
    onlineStoreCouponId: number,
    {}
  ) => Promise<OnlineStoreCoupon>,
  removeOnlineStoreCoupon: (onlineStoreCouponId: number) => Promise<null>,
  permission: ?AuthTypes,
};

// type State = {
//   deleteStoreItemOpen: boolean
// };

class OnlineStoreCouponDetails extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      deleteStoreItemOpen: false,
    };
  }

  componentDidMount() {
    this.props.readOnlineStoreCoupon(this.props.onlineStoreCouponId);
    this.props.loadCouponTypes();
    this.props.loadCouponApplyTypes();
  }

  handleOnlineStoreCouponUpdate = (formData) => {
    return this.props.updateOnlineStoreCoupon(
      this.props.onlineStoreCouponId,
      formData
    );
  };

  handleOnlineStoreCouponDelete = () => {
    this.setState({ ...this.state, deleteStoreCouponOpen: false });
    this.props.removeOnlineStoreCoupon(this.props.onlineStoreCouponId);
  };

  setOnlineStoreCouponDeleteModalVisible = () => {
    this.setState({ ...this.state, deleteStoreCouponOpen: true });
  };

  render() {
    const {
      onlineStoreCoupon,
      isFetching,
      couponTypes,
      couponApplyTypes,
    } = this.props;
    const dateUpdated = new Date(onlineStoreCoupon.dateUpdated);
    const dateCreated = new Date(onlineStoreCoupon.dateCreated);

    if (!onlineStoreCoupon) {
      return <div>Loading</div>;
    }
    return (
      <div>
        <h3 className="light-title">Basic Information</h3>
        <Confirm
          // This confirmation box handles the Archiving Store Coupon Action
          open={this.state.deleteStoreCouponOpen}
          onCancel={() =>
            this.setState({ ...this.state, deleteStoreCouponOpen: false })
          }
          content="Are you sure you want to delete the current Store Coupon? This will completely remove the Store Coupon."
          onConfirm={this.handleOnlineStoreCouponDelete}
        />
        {isFetching ? (
          <PageLoader />
        ) : (
          <div>
            <OnlineStoreCouponFormFields
              permission={this.props.permission}
              onlineStoreCoupon={this.props.onlineStoreCoupon}
              couponTypes={couponTypes}
              couponApplyTypes={couponApplyTypes}
              handleFormSubmit={this.handleOnlineStoreCouponUpdate}
            >
              <li>
                {/* // TODO: replace anchor with button // */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  tabIndex="0"
                  onClick={this.setOnlineStoreCouponDeleteModalVisible}
                  onKeyPress={this.setOnlineStoreCouponDeleteModalVisible}
                >
                  Delete Coupon
                </a>
              </li>
            </OnlineStoreCouponFormFields>
            <hr />
            <div className="additional-details">
              <Grid columns={3} stackable>
                <Grid.Row>
                  <Grid.Column>
                    <div className="additional-details__title">Created By</div>
                    {onlineStoreCoupon.createdBy}
                  </Grid.Column>
                  <Grid.Column>
                    <div className="additional-details__title">
                      Last Changed By
                    </div>
                    {onlineStoreCoupon.lastChangedBy}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <div className="additional-details__title">
                      Date Created
                    </div>
                    {dateCreated.toLocaleDateString('en-AU')}
                  </Grid.Column>
                  <Grid.Column>
                    <div className="additional-details__title">
                      Date Modified
                    </div>
                    {dateUpdated.toLocaleDateString('en-AU')}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any, props) => ({
  isFetching: state.onlineStoreCoupons.isFetching,
  onlineStoreCoupon: state.onlineStoreCoupons.byId[props.onlineStoreCouponId],
  couponTypes: getOnlineStoreCouponTypesSortedAlphabetically(state),
  couponApplyTypes: getOnlineStoreCouponApplyTypesSortedAlphabetically(state),
  permission: getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    state.auth.authorisation
  ),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      readOnlineStoreCoupon,
      updateOnlineStoreCoupon,
      removeOnlineStoreCoupon,
      loadCouponTypes,
      loadCouponApplyTypes,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreCouponDetails): any);
