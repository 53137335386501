// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import type { OrganisationCollaborator } from '../../../types/organisationCollaborators';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_UPDATE_REQUEST =
  'ORGANISATION_COLLABORATOR_UPDATE_REQUEST';
export const ORGANISATION_COLLABORATOR_UPDATE_SUCCESS =
  'ORGANISATION_COLLABORATOR_UPDATE_SUCCESS';
export const ORGANISATION_COLLABORATOR_UPDATE_FAILURE =
  'ORGANISATION_COLLABORATOR_UPDATE_FAILURE';

const updateOrganisationCollaboratorRequest = () => ({
  type: ORGANISATION_COLLABORATOR_UPDATE_REQUEST,
});

const updateOrganisationCollaboratorSuccess = (collaborator) => ({
  type: ORGANISATION_COLLABORATOR_UPDATE_SUCCESS,
  payload: collaborator,
});

const updateOrganisationCollaboratorFailure = (error) => ({
  type: ORGANISATION_COLLABORATOR_UPDATE_FAILURE,
  error,
});

export const updateOrganisationCollaborator = (
  organisationId: number,
  collaboratorId: number,
  formData: OrganisationCollaborator
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationCollaboratorRequest());
    const today = new Date();
    const body = {
      ...formData,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/collaborators/${collaboratorId}`,
      body,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(updateOrganisationCollaboratorSuccess(response));

    return true;
  } catch (error) {
    dispatch(updateOrganisationCollaboratorFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
