// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_READ_REQUEST =
  'ORGANISATION_COLLABORATOR_READ_REQUEST';
export const ORGANISATION_COLLABORATOR_READ_SUCCESS =
  'ORGANISATION_COLLABORATOR_READ_SUCCESS';
export const ORGANISATION_COLLABORATOR_READ_FAILURE =
  'ORGANISATION_COLLABORATOR_READ_FAILURE';

const readOrganisationCollaboratorRequest = () => ({
  type: ORGANISATION_COLLABORATOR_READ_REQUEST,
});

const readOrganisationCollaboratorSuccess = (collaborator) => ({
  type: ORGANISATION_COLLABORATOR_READ_SUCCESS,
  payload: collaborator,
});

const readOrganisationCollaboratorFailure = (error) => ({
  type: ORGANISATION_COLLABORATOR_READ_FAILURE,
  error,
});

export const readOrganisationCollaborator = (
  organisationId: number,
  collaboratorId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(readOrganisationCollaboratorRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/collaborators/${collaboratorId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(readOrganisationCollaboratorSuccess(response));

    return true;
  } catch (error) {
    dispatch(readOrganisationCollaboratorFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
