//@flow
import React from 'react';
import TabHeader from '../../components/TabHeader';
import UpdateGlobalUserFormFields from '../../components/GlobalUser/UpdateGlobalUserFormFields';
import AdminUpdateUserPassword from '../../components/AdminUpdateUserPassword';

import type {
  State as AppState,
  UpdateGlobalUserForm,
  Organisation,
  Dispatch,
  AuthTypes,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  readUser,
  updateUser,
  loadUserOrganisations,
  loadUserAccountTypes
} from '../../actions/globalUsers';
import { adminUpdateUserPassword } from '../../actions/user';
import {
  forceVerifyOrganisationUser,
  removeOrganisationUser,
  resendInvitationEmailOrganisationUser,
} from '../../actions/organisations/organisationUsers';

import { Grid, Confirm } from 'semantic-ui-react';
import StaticField from '../../components/StaticField';
import PageLoader from '../../components/PageLoader';
import moment from 'moment';
import { getPrimaryUserOrganisation } from '../../reducers/userOrganisataions';
import { getUserAccountTypes } from '../../reducers/userAccountTypes';
import {
  restrictUserPermissions,
  permissions,
  getPermissionInFeature,
} from 'roy-morgan-auth';
// $FlowFixMe
import {canUpdatePasswordForAnotherUser, managementFeatures} from '../../helpers';

type Props = {
  userId: number,
  user: any,
  accountTypes: Array<any>,
  forceVerifyOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  removeOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  isFetching: boolean,
  readUser: (userId: number) => Promise<null>,
  updateUser: (userId: number, {}) => Promise<null>,
  adminUpdateUserPassword: (userId: number, password: string) => boolean,
  loadUserOrganisations: (userId: number) => Promise<null>,
  loadUserAccountTypes: () => Promise<any>,
  organisation: Organisation,
  resendInvitationEmailOrganisationUser: (
    oid: number,
    uid: number
  ) => Promise<boolean>,
  permission: ?AuthTypes,
};

type State = {
  forceVerifyOpen: boolean,
  removeUserOpen: boolean,
  contextChange: boolean,
  resendInvitationEmailOpen: boolean,
};

class UserDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      forceVerifyOpen: false,
      removeUserOpen: false,
      contextChange: false,
      resendInvitationEmailOpen: false,
    };
  }

  componentDidMount() {
    this.props.loadUserAccountTypes();
    this.props.readUser(this.props.userId);
    this.props.loadUserOrganisations(this.props.userId);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (typeof nextProps.user === 'undefined') {
      this.setState({
        ...this.state,
        contextChange: true,
      });
    }
  }

  handleFormSubmit = (formData: UpdateGlobalUserForm) => {
    this.props.updateUser(this.props.userId, formData);
  };

  handleAdminUpdateUserPassword = async (password) => {
    const result = await this.props.adminUpdateUserPassword(
      this.props.userId,
      password
    );

    if (result) {
      await this.props.readUser(this.props.userId);
    }
    return result;
  };

  setRemoveUserModalVisible = () => {
    this.setState({ ...this.state, removeUserOpen: true });
  };

  setForceVerifyModalVisible = () => {
    this.setState({ ...this.state, forceVerifyOpen: true });
  };

  handleForceVerify = () => {
    this.props.forceVerifyOrganisationUser(
      this.props.organisation.id,
      this.props.user.id
    );
    this.setState({ ...this.state, forceVerifyOpen: false });
  };

  setResendInvitationEmailModalVisible = () => {
    this.setState({ ...this.state, resendInvitationEmailOpen: true });
  };

  handleResendInvitationEmail = () => {
    this.props.resendInvitationEmailOrganisationUser(
      this.props.organisation.id,
      this.props.user.id
    );
    this.setState({ ...this.state, resendInvitationEmailOpen: false });
  };

  handleRemoveUser = () => {
    this.props.removeOrganisationUser(
      this.props.organisation.id,
      this.props.user.id
    );
    this.setState({ ...this.state, removeUserOpen: false });
  };

  renderSwitchButton(user, permission) {
    const { INTERNAL_ADMIN, BUSINESS_ADMIN } = permissions;
    switch (user.status.toLowerCase()) {
      case 'active':
        return restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN], permission)
          ? (
            // TODO: replace anchor with button //
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              role="button"
              tabIndex="0"
              onClick={() =>
                // $FlowFixMe
                this.setForceVerifyModalVisible(user.id, user.name)
              }
              onKeyPress={() =>
                // $FlowFixMe
                this.setForceVerifyModalVisible(user.id, user.name)
              }
            >
              Force Email Verification
            </a>
          ) : null;
      case 'pending':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() =>
              // $FlowFixMe
              this.setResendInvitationEmailModalVisible(user.id, user.name)
            }
            onKeyPress={() =>
              // $FlowFixMe
              this.setResendInvitationEmailModalVisible(user.id, user.name)
            }
          >
            Resend Welcome
          </a>
        );
      case 'locked':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() =>
              // $FlowFixMe
              this.setForceVerifyModalVisible(user.id, user.name)
            }
            onKeyPress={() =>
              // $FlowFixMe
              this.setForceVerifyModalVisible(user.id, user.name)
            }
          >
            Resend Email Verification
          </a>
        );
      default:
        return null;
    }
  }

  render() {
    const { user, isFetching, organisation, permission, accountTypes } = this.props;

    if (!user) {
      return <div>Loading</div>;
    }

    const switchButton = this.renderSwitchButton(user, permission);

    return (
      <div>
        {user ? (
          <TabHeader
            tabHeading={`${user.name}'s Details`}
          />
        ) : (
          ''
        )}
        {isFetching ? (
          <PageLoader />
        ) : (
          <div>
            <Confirm
              // This confirmation box handles the Force Email Verification Action
              content="Are you sure you want to Force Email Verification for this user?"
              open={this.state.forceVerifyOpen}
              onCancel={() =>
                this.setState({ ...this.state, forceVerifyOpen: false })
              }
              onConfirm={this.handleForceVerify}
            />

            <Confirm
              // This confirmation box handles the re-send invitation email/welcome email action
              content="Are you sure you want to re-send welcome email for this user'?"
              open={this.state.resendInvitationEmailOpen}
              onCancel={() =>
                this.setState({
                  ...this.state,
                  resendInvitationEmailOpen: false,
                })
              }
              onConfirm={this.handleResendInvitationEmail}
            />

            <Confirm
              content={`Are you sure you want to remove ${
                user && user.email ? user.email : ''
              } from ${organisation && organisation.name}`}
              open={this.state.removeUserOpen}
              onCancel={() =>
                this.setState({ ...this.state, removeUserOpen: false })
              }
              onConfirm={this.handleRemoveUser}
            />
            <UpdateGlobalUserFormFields
              user={user}
              handleFormSubmit={this.handleFormSubmit}
              accountTypes={accountTypes}
              permission={permission}
            >
              {switchButton && (
                <li>{switchButton}</li>
              )}
              {canUpdatePasswordForAnotherUser(user, permission) && (
                <li>
                  <AdminUpdateUserPassword
                    handleAdminUpdateUserPassword={
                      // $FlowFixMe
                      this.handleAdminUpdateUserPassword
                    }
                    userStatus={user.status}
                    triggerAsButton={false}
                  />
                </li>
              )}
              <li>
                {/* // TODO: replace anchor with button // */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  tabIndex="-1"
                  onClick={this.setRemoveUserModalVisible}
                  onKeyPress={this.setRemoveUserModalVisible}
                >
                  Remove User
                </a>
              </li>
            </UpdateGlobalUserFormFields>
            <hr />
            <div className="additional-details">
              <Grid columns={3} stackable>
                <Grid.Row>
                  <Grid.Column>
                    <StaticField
                      label="Date Created"
                      value={moment
                        .utc(user.dateCreated)
                        .local()
                        .format('YYYY-MM-DD')
                        .toString()}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <StaticField
                      label="Last Login"
                      value={
                        user.lastLoginDateTime
                          ? moment
                              .utc(user.lastLoginDateTime)
                              .local()
                              .format('YYYY-MM-DD LT')
                              .toString()
                          : 'Never'
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <StaticField
                      label="Last Changed By"
                      value={user.lastChangedBy}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <StaticField
                      label="Archived?"
                      value={user.isArchived ? 'Yes' : 'No'}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <StaticField
                      label="Last Account Force Verify"
                      value={
                        user.lastForceVerifyAccountTime
                          ? moment
                              .utc(user.lastForceVerifyAccountTime)
                              .local()
                              .format('YYYY-MM-DD LT')
                              .toString()
                          : 'Never'
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.users.isFetching || state.userAccountTypes.isFetching,
  user: state.users.byId[props.userId],
  organisation: getPrimaryUserOrganisation(state),
  accountTypes: getUserAccountTypes(state),
  permission: getPermissionInFeature(
    managementFeatures.LIVE_MANAGEMENT,
    state.auth.authorisation
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      readUser,
      loadUserAccountTypes,
      updateUser,
      adminUpdateUserPassword,
      loadUserOrganisations,
      forceVerifyOrganisationUser,
      removeOrganisationUser,
      resendInvitationEmailOrganisationUser,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(UserDetails): any);
