// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type { OnlineStoreCoupon, OnlineStoreCouponForm } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_COUPON_UPDATE_REQUEST =
  'ONLINE_STORE_COUPON_UPDATE_REQUEST';
export const ONLINE_STORE_COUPON_UPDATE_SUCCESS =
  'ONLINE_STORE_COUPON_UPDATE_SUCCESS';
export const ONLINE_STORE_COUPON_UPDATE_FAILURE =
  'ONLINE_STORE_COUPON_UPDATE_FAILURE';

function updateOnlineStoreCouponRequest() {
  return {
    type: ONLINE_STORE_COUPON_UPDATE_REQUEST,
  };
}

function updateOnlineStoreCouponSuccess(onlineStoreCoupon: OnlineStoreCoupon) {
  return {
    type: ONLINE_STORE_COUPON_UPDATE_SUCCESS,
    payload: onlineStoreCoupon,
  };
}

function updateOnlineStoreCouponFailure(error: string) {
  return {
    type: ONLINE_STORE_COUPON_UPDATE_FAILURE,
    error: error,
  };
}

export const updateOnlineStoreCoupon = (
  onlineStoreCouponId: number,
  body: OnlineStoreCouponForm
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOnlineStoreCouponRequest());
    const today = new Date();

    const requestBody = {
      ...body,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/coupons/${onlineStoreCouponId}`,
      body: requestBody,
    });

    dispatch(updateOnlineStoreCouponSuccess(response));
    return response;
  } catch (error) {
    dispatch(updateOnlineStoreCouponFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
