// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../../helpers';
import { callAPI, refreshToken } from '../../../../middleware';
import type {
  ThunkAction,
  Action,
  OrganisationCollaboratorOrganisation,
  OrganisationCollaboratorOrganisationsResponse,
} from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_REQUEST =
  'ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_REQUEST';
export const ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_SUCCESS =
  'ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_SUCCESS';
export const ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_FAILURE =
  'ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_FAILURE';

const loadOrganisationCollaboratorOrganisationsRequest = (): Action => ({
  type: ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_REQUEST,
});

const loadOrganisationCollaboratorOrganisationsSuccess = (
  organisations: OrganisationCollaboratorOrganisationsResponse
): Action => ({
  type: ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_SUCCESS,
  payload: organisations,
});

const loadOrganisationCollaboratorOrganisationsFailure = (
  error: string
): Action => ({
  type: ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_FAILURE,
  error,
});

export const loadOrganisationCollaboratorOrganisations = (
  collaboratorId: number
): ThunkAction => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationCollaboratorOrganisationsRequest());

    const response:
      | OrganisationCollaboratorOrganisation[]
      | Error = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/users/${collaboratorId}/organisations?excludeArchived=true`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload: OrganisationCollaboratorOrganisationsResponse = normaliseArrayOfObjects(
      response
    );

    dispatch(
      loadOrganisationCollaboratorOrganisationsSuccess(normalisedPayload)
    );

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationCollaboratorOrganisationsFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
