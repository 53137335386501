// @flow
import type { OrganisationFeature } from '../types/organisationFeatures';
import { createSelector } from 'reselect';
import type { OrganisationFeatures } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';
import { toHierarchyFeatureObject } from '../helpers';

const initialState: OrganisationFeatures = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

export const organisationUserFeatures = (
  state: OrganisationFeatures = initialState,
  action: Object
):
  | any
  | OrganisationFeatures
  | { byId: { ... }, error: void, isFetching: boolean, order: Array<any>, ... }
  | {
      byId: { [number]: OrganisationFeature },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [number]: OrganisationFeature },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: number]: OrganisationFeature },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: number]: OrganisationFeature },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    } => {
  switch (action.type) {
    case 'ORGANISATION_USER_FEATURES_LOAD_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'ORGANISATION_USER_FEATURES_LOAD_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case 'ORGANISATION_USER_FEATURES_LOAD_SUCCESS':
      // TODO: Ask for update to API to return organisations full list of products, with a flag set for available vs unavailable products, rather than merging these values into the current product response.
      return {
        ...state,
        ...action.payload,
        error: undefined,
        isFetching: false,
      };
    case 'ORGANISATION_USER_FEATURE_UPDATE_REQUEST':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.featureId]: {
            ...state.byId[action.payload.featureId],
            isFetching: true,
          },
        },
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_USER_FEATURE_UPDATE_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload.byId
        },
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_USER_FEATURE_UPDATE_FAILURE':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            isFetching: false,
          },
        },
        isFetching: false,
        error: action.error,
      };
    case 'ORGANISATION_CHANGE':
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const getOrganisationUserFeatures: any = createSelector(
  (state) => state.organisationUserFeatures.byId,
  (state) => state.organisationUserFeatures.order,
  (items, order) => {
    if (items === undefined) {
      return [];
    }

    let features = order
      .map(function (sortedKey) {
        return items[sortedKey];
      });
    
    return toHierarchyFeatureObject(features, f => f.hasAccess);
  }
);