// @flow
import type { OverallTotalUsageByUser, Action } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */
const initialState: OverallTotalUsageByUser = {
  result: [],
  isFetching: false,
  error: undefined,
};

/* Reducer */
export const overallTotalUsageByUser = (
  state: OverallTotalUsageByUser = initialState,
  action: Action
): OverallTotalUsageByUser => {
  switch (action.type) {
    case 'ALL_USERS_USAGE_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'ALL_USERS_USAGE_SUCCESS':
      return {
        ...state,
        result: action.payload,
        isFetching: false,
        error: undefined,
      };
    case 'ALL_USERS_USAGE_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
