// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Message, Icon } from 'semantic-ui-react';
import type { Element } from 'React';

function FourOhFourMessage(): Element<'div'> {
  return (
    <div className="page--multifaceted-bg-grey">
      <div className="not-auth-message">
        <Message icon>
          <Icon name="exclamation circle large" />
          <div>
            <h2>Page Not Found</h2>
            <Link to="/">
              <Button>Return Home</Button>
            </Link>
          </div>
        </Message>
      </div>
    </div>
  );
}

export default FourOhFourMessage;
