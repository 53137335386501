// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware/index';
import type { OnlineStoreItem } from '../../../types/index';
import { getApiBaseURL } from 'roy-morgan-auth';

export const CLONE_STORE_ITEM_REQUEST = 'CLONE_STORE_ITEM_REQUEST';
export const CLONE_STORE_ITEM_SUCCESS = 'CLONE_STORE_ITEM_SUCCESS';
export const CLONE_STORE_ITEM_FAILURE = 'CLONE_STORE_ITEM_FAILURE';

function cloneStoreItemRequest() {
  return {
    type: CLONE_STORE_ITEM_REQUEST,
  };
}

function cloneStoreItemSuccess(onlineStoreItem: OnlineStoreItem) {
  return {
    type: CLONE_STORE_ITEM_SUCCESS,
    payload: onlineStoreItem,
  };
}

function cloneStoreItemFailure(error: string) {
  return {
    type: CLONE_STORE_ITEM_FAILURE,
    error: error,
  };
}

export const cloneStoreItem = (storeItemId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(cloneStoreItemRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/items/${storeItemId}/clone`,
    });

    dispatch(cloneStoreItemSuccess(response));
    return response;
  } catch (error) {
    dispatch(cloneStoreItemFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
