// @flow
import * as React from 'react';
import InlineSvg from '../components/InlineSvg';
import onClickOutside from 'react-onclickoutside';

type Props = {
  leftAlign?: boolean,
  children: any,
  dark?: boolean,
  className?: string,
};

type State = {
  menuOpen: boolean,
  actionsMouseOver: boolean,
};

class OverflowMenu extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      actionsMouseOver: false,
    };
  }

  handleClickOutside = (e: SyntheticEvent<*>) => {
    this.setState({
      menuOpen: false,
    });
  };

  toggleMenu = (e: SyntheticEvent<*>) => {
    e.stopPropagation();

    this.setState({
      actionsMouseOver: true,
      menuOpen: !this.state.menuOpen,
    });
  };

  toggleMenuKeyPress = (e: SyntheticKeyboardEvent<*>) => {
    e.stopPropagation();

    if (e.key === 'Enter') {
      this.toggleMenu(e);
    }
  };

  onActionsMouseOver = () => {
    this.setState({
      actionsMouseOver: true,
    });
  };

  onActionsMouseLeave = () => {
    this.setState({
      actionsMouseOver: false,
    });
    setTimeout(
      function () {
        if (this.state.actionsMouseOver === false) {
          this.setState({
            menuOpen: false,
          });
        }
      }.bind(this),
      300
    );
  };

  onMenuClick = (e) => {
    e.stopPropagation();
    this.setState({
      menuOpen: false,
    });
  };

  render() {
    return (
      <div
        className={`${this.props.dark ? 'overflow-menu-container--dark' : ''} ${
          this.props.className || ''
        } overflow-menu-container`}
      >
        <span
          className={`overflow-menu-container__trigger ${
            this.state.menuOpen
              ? 'overflow-menu-container__trigger--active'
              : ''
          }`}
          onClick={this.toggleMenu}
          onKeyPress={this.toggleMenuKeyPress}
          onMouseOver={this.onActionsMouseOver}
          onFocus={this.onActionsMouseOver}
          role="button"
          onKeyUp={this.toggleMenu}
          tabIndex={0}
        >
          <InlineSvg name="ellipsis" colour="heather" />
        </span>
        <ul
          className={`overflow-menu ${
            this.state.menuOpen ? 'overflow-menu--open' : ''
          } ${this.props.leftAlign ? 'menu--left' : ''}`}
          onMouseOver={this.onActionsMouseOver}
          onClick={this.onMenuClick}
          onFocus={this.onActionsMouseOver}
          onKeyUp={this.onMenuClick}
          role="presentation"
        >
          {this.props.children}
        </ul>
      </div>
    );
  }
}

export default (onClickOutside(OverflowMenu): any);
