// @flow
import React from 'react';
import { Button, Form, Modal, Popup, Icon } from 'semantic-ui-react';
import type { Node } from 'React';

type Props = {
  handleAdminUpdateUserPassword: (password: string) => boolean,
  triggerAsButton?: boolean,
  userStatus?: string,
};

type State = {
  isModalOpen: boolean,
  password: string,
  confirmPassword: string,
};

class AdminUpdateUserPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isModalOpen: false,
      password: '',
      confirmPassword: '',
    };
  }

  toggleModalOpenState: () => void = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleInputChange: (e: SyntheticInputEvent<HTMLInputElement>) => void = (
    e: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFormSubmit: () => Promise<void> = async () => {
    const result = await this.props.handleAdminUpdateUserPassword(
      this.state.password
    );
    if (result) {
      this.setState({
        isModalOpen: false,
        password: '',
        confirmPassword: '',
      });
    }
  };

  passwordHinter(
    newPassword: string,
    newPasswordConfirmation: string
  ): void | string {
    if (newPassword === '') {
      return 'Please enter a new password';
    } else if (newPasswordConfirmation === '') {
      return 'Please confirm password';
    } else if (newPassword !== newPasswordConfirmation) {
      return 'Passwords do not match';
    }
  }

  render(): Node {
    const { isModalOpen, password, confirmPassword } = this.state;
    const { triggerAsButton = false, userStatus } = this.props;

    const title =
      userStatus && userStatus === 'Pending'
        ? 'Set Password'
        : 'Change Password';

    return (
      <Modal
        className="update-password--modal"
        trigger={
          // TODO: replace anchor with button
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            type="button"
            role="button"
            className={triggerAsButton ? 'ui button' : ''}
            onClick={this.toggleModalOpenState}
            onKeyDown={this.toggleModalOpenState}
            tabIndex="0"
          >
            {title}
          </a>
        }
        open={isModalOpen}
        closeIcon={<button className="modal__close">Close</button>}
        onClose={this.toggleModalOpenState}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content className="update-password--modal-content">
          <Form onSubmit={this.handleFormSubmit}>
            <Form.Input
              label="Password"
              name="password"
              value={password}
              onChange={this.handleInputChange}
              type="password"
              autoComplete="new-password"
              required
            />
            <Form.Input
              label="Confirm Password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.handleInputChange}
              type="password"
              autoComplete="new-password"
              required
            />
            <Button
              className="form__submit-button"
              disabled={password.length < 8 || password !== confirmPassword}
              type="submit"
            >
              {title}
            </Button>
            <Popup
              trigger={
                <Icon className="password-help-icon" name="help circle" />
              }
              content="Password must contain at least one letter, number and special character. Password must be at least 8 characters in length"
            />
            {this.passwordHinter(password, confirmPassword)}
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default AdminUpdateUserPassword;
