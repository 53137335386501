// @flow

export const FETCH_SYNC_UPDATE_VALUE = 'FETCH_SYNC_UPDATE_VALUE';

const updateFetchSyncValue = (payload: { key: string, value: boolean }) => ({
  type: FETCH_SYNC_UPDATE_VALUE,
  payload,
});

export const setFetchSyncReady = (key: string): any => (): any => (dispatch) => {
  dispatch(
    updateFetchSyncValue({
      key,
      value: true,
    })
  );
};
