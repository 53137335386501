// @flow
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import * as React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './registerServiceWorker';
import store from './store/configureStore';
import 'roy-morgan-base-theme/dist/semantic.min.css';
import './assets/css/index.css';

// Get RM environment to pass through to Sentry
const env = process.env.REACT_APP_RM_ENV || '';

// Don't run in local development.
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://13798087fc7c4ee4ab9126df20ce36ff@o379043.ingest.sentry.io/5207443',
    environment: env,
  });
}

ReactDOM.render(
  <App store={store} />,
  // $FlowFixMe
  document.getElementById('root')
);

serviceWorker.unregister();
