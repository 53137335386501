//@flow
import React from 'react';
import TableActions from '../../components/TableActions';
import { Table, Confirm } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import PaginatedTable from '../../components/PaginatedTable';
import { getOrganisationCollaboratorsSortedAlphabetically } from '../../reducers/organisationCollaborators';
import type {
  State as AppState,
  Dispatch,
  OrganisationCollaborator,
  OrganisationCollaboratorForm,
  SubMenuItem,
  OrganisationCollaboratorFormWithTeams,
  AuthTypes, Organisation as OrgType
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadOrganisationCollaborators,
  addOrganisationCollaborator,
  removeOrganisationCollaborator,
} from '../../actions/organisations/organisationCollaborators';
import InviteUserFormFields from '../../components/OrganisationCollaborator/InviteCollaboratorFormFields';
import TabHeader from '../../components/TabHeader';
import OverflowMenu from '../../components/OverflowMenu';
import { Link, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import { loadAllOrganisationTeams } from '../../actions/organisations/organisationTeams';
import { getOrganisationTeamsSortedAlphabetically } from '../../reducers/organisationTeams';
import {
  addOrganisationCollaboratorToOrganisationTeam
} from '../../actions/organisations/organisationTeams/organisationTeamCollaborators';
import { formatDate } from '../../helpers'

type Props = {
  organisationTeams: [],
  organisation: OrgType,
  organisationCollaborators: Array<OrganisationCollaborator>,
  collaboratorFilterValue: string,
  organisationId: number,
  subItems: Array<SubMenuItem>,
  history: RouterHistory,
  loadOrganisationCollaborators: (oid: number) => Promise<boolean>,
  addOrganisationCollaborator: (
    oid: number,
    formData: OrganisationCollaboratorForm
  ) => Promise<OrganisationCollaborator>,
  addOrganisationCollaboratorToOrganisationTeam: (
    oid: number,
    tid: number,
    uid: number
  ) => Promise<*>,
  removeOrganisationCollaborator: (
    oid: number,
    uid: number
  ) => Promise<boolean>,
  isFetching: boolean,
  permission: ?AuthTypes,
};

type State = {
  collaboratorFilterValue: string,
  removeCollaboratorOpen: boolean,
  removeCollaboratorTarget?: {
    id: number,
    name: string,
  },
};

class Collaborators extends React.Component<Props, State> {
  csvLink: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      collaboratorFilterValue: '',
      removeCollaboratorOpen: false,
      removeCollaboratorTarget: undefined,
    };

    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.props.loadOrganisationCollaborators(this.props.organisationId);
  }

  handleCollaboratorSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      collaboratorFilterValue: e.target.value,
    });
  };

  handleFormSubmit = (formData: OrganisationCollaboratorFormWithTeams) => {
    let collaboratorInvitationForm = {
      email: formData.email,
      canAccessAllTeams: formData.teamsToInclude.length < 1
    }
      this.props.addOrganisationCollaborator(this.props.organisationId, collaboratorInvitationForm)
        .then(
          (collaboratorCreated: OrganisationCollaborator)=>{
            if (formData.teamsToInclude.length > 0){
              Promise.all(formData.teamsToInclude.map(tim => {
                this.props.addOrganisationCollaboratorToOrganisationTeam(this.props.organisationId,tim, collaboratorCreated.id);
                return true;
              })).then( ()=>{
                this.props.history.push(`/organisations/${this.props.organisationId}/collaborators/${collaboratorCreated.id}/details`);
              }, (failure) => {})
            } else {
              this.props.history.push(`/organisations/${this.props.organisationId}/collaborators/${collaboratorCreated.id}/details`);
            }
          },
          (failure) => {}
        )
  };

  setRemoveCollaboratorModalVisible = (collaboratorId, collaboratorName) => {
    this.setState({
      ...this.state,
      removeCollaboratorOpen: true,
      removeCollaboratorTarget: { id: collaboratorId, name: collaboratorName },
    });
  };

  handleRemoveCollaborator = () => {
    if (this.state.removeCollaboratorTarget) {
      this.props.removeOrganisationCollaborator(
        this.props.organisationId,
        this.state.removeCollaboratorTarget.id
      );
    }
    this.setState({
      ...this.state,
      removeCollaboratorOpen: false,
      removeCollaboratorTarget: undefined,
    });
  };


  exportCollaborators = () => {
    this.csvLink.current.link.click();
  };

  render() {
    const {
      organisationCollaborators,
      organisationId,
      subItems,
      isFetching,
    } = this.props;

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Last Login</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    );


    const csvData = organisationCollaborators.map((collaborator: OrganisationCollaborator) => {
      return {
        'Name': collaborator.name,
        'Email': collaborator.email,
        'Last Login': formatDate(collaborator.lastLoginDateTime),
        'Status': collaborator.status
      };
    });

    const filterValue = this.state.collaboratorFilterValue.toLowerCase();

    let rows = organisationCollaborators.filter(
      (collaborator: OrganisationCollaborator) => {
        const collaboratorName = collaborator.name.toLowerCase();
        return (
          collaboratorName === filterValue ||
          collaboratorName.includes(filterValue) ||
          filterValue === ''
        );
      }
    );

    rows = rows.map((collaborator: OrganisationCollaborator) => {
      return (
        <Table.Row key={collaborator.id}>
          <Table.Cell>{collaborator.name}</Table.Cell>
          <Table.Cell>{collaborator.email}</Table.Cell>
          <Table.Cell>{formatDate(collaborator.lastLoginDateTime)}</Table.Cell>
          <Table.Cell>{collaborator.status}</Table.Cell>
          <Table.Cell>
            <OverflowMenu>
              <li>
                <Link
                  to={`/organisations/${organisationId}/collaborators/${collaborator.id}/details`}
                >
                  View
                </Link>
              </li>
              <li>
                {/* // TODO: replace anchor with button // */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  tabIndex="0"
                  onClick={() =>
                    this.setRemoveCollaboratorModalVisible(
                      collaborator.id,
                      collaborator.name
                    )
                  }
                  onKeyPress={() =>
                    this.setRemoveCollaboratorModalVisible(
                      collaborator.id,
                      collaborator.name
                    )
                  }
                >
                  Revoke collaboration
                </a>
              </li>
            </OverflowMenu>
          </Table.Cell>
        </Table.Row>
      );
    });

    const newSubItems = [
      ...subItems,
      {
        path: `/organisations/${organisationId}/collaborators`,
        // break this out into own component possibly, and include in the same manner as done in /containers/Orgainsation.js
        container: () => (
          <React.Fragment>
            <Confirm
              // remove collaborator
              content={`Are you sure you want to remove ${
                this.state.removeCollaboratorTarget &&
                this.state.removeCollaboratorTarget.name
                  ? this.state.removeCollaboratorTarget.name
                  : 'this collaborator'
              } from the current organisation?`}
              open={this.state.removeCollaboratorOpen}
              onCancel={() =>
                this.setState({ ...this.state, removeCollaboratorOpen: false })
              }
              onConfirm={this.handleRemoveCollaborator}
            />
            <div>
              <TableActions
                title="Collaborators"
                filterAction={this.handleCollaboratorSearch}
                filterPlaceholder="Search Collaborators..."
                buttonText="Invite Collaborators"
                buttonURL={`/organisations/${organisationId}/collaborators/new`}
                downloadButtonAction={csvData.length === 0 ? null : this.exportCollaborators}
                showDownloadButton
              />
              <div className="page-content">
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    headerRow={TableHeader}
                    rows={rows}
                    stackEarly
                  />
                )}
              </div>
            </div>
          </React.Fragment>
        ),
      },
      {
        path: `/organisations/${organisationId}/collaborators/new`,
        container: () => (
          <div className="tabbed-content">
            <TabHeader
              tabHeading="Add New Collaborator to Organisation"
              backLink={`/organisations/${organisationId}/collaborators`}
            />
            <InviteUserFormFields
              handleFormSubmit={this.handleFormSubmit}
              organisationCanHaveTeams={this.props.organisation.canHaveTeams}
              organisationTeams = {this.props.organisationTeams}
            />
          </div>
        ),
      },
    ];

    return (
      <div className="tabbed-content">
        {newSubItems.map((item, index) => {
          return (
            <Route
              exact={true}
              key={index}
              path={item.path}
              render={item.container}
            />
          );
        })}
        <CSVLink
          data={csvData}
          filename={`organisation_${organisationId}_collaborators`}
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        >
        </CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationCollaborators.isFetching,
  organisationCollaborators: getOrganisationCollaboratorsSortedAlphabetically(state),
  organisationTeams: getOrganisationTeamsSortedAlphabetically(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationCollaborators,
      addOrganisationCollaborator,
      removeOrganisationCollaborator,
      loadAllOrganisationTeams,
      addOrganisationCollaboratorToOrganisationTeam,
    },
    dispatch
  );

export default (withRouter(
  // $FlowFixMe
  connect(mapStateToProps, mapDispatchToProps)(Collaborators)
): any);
