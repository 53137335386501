// @flow
import type { OrganisationCollaborator } from '../types/organisationCollaborators';
import * as actions from '../actions/organisations';
import * as rootActions from '../actions';
import { createSelector } from 'reselect';
import type { OrganisationCollaborators } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: OrganisationCollaborators = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

export const organisationCollaborators = (
  state: OrganisationCollaborators = initialState,
  action: Object
):
  | any
  | OrganisationCollaborators
  | { byId: { ... }, error: void, isFetching: boolean, order: Array<any>, ... }
  | {
      byId: { [string]: OrganisationCollaborator },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [string]: OrganisationCollaborator },
      error: void,
      isFetching: boolean,
      order: Array<any | number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationCollaborator },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationCollaborator },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    } => {
  switch (action.type) {
    case actions.ORGANISATION_COLLABORATORS_LOAD_REQUEST:
    case actions.ORGANISATION_COLLABORATOR_READ_REQUEST:
    case actions.ORGANISATION_COLLABORATOR_UPDATE_REQUEST:
    case actions.ORGANISATION_COLLABORATOR_ADD_REQUEST:
    case actions.ORGANISATION_COLLABORATOR_REMOVE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ORGANISATION_COLLABORATORS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.ORGANISATION_COLLABORATOR_ADD_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe TODO(DP): Fix this
          [action.payload.id]: action.payload,
        },
        // $FlowFixMe
        order: [...state.order, action.payload.id],
        isFetching: false,
        error: undefined,
      };
    case actions.ORGANISATION_COLLABORATOR_READ_SUCCESS:
    case actions.ORGANISATION_COLLABORATOR_UPDATE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          [action.payload.id]: action.payload,
        },
        // $FlowFixMe
        isFetching: false,
        error: undefined,
      };
    /* eslint-disable no-case-declarations */
    case actions.ORGANISATION_COLLABORATOR_REMOVE_SUCCESS:
      let copy = state.byId;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.id];
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.id);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    /* eslint-enable no-case-declarations */
    case actions.ORGANISATION_COLLABORATORS_LOAD_FAILURE:
    case actions.ORGANISATION_COLLABORATOR_READ_FAILURE:
    case actions.ORGANISATION_COLLABORATOR_UPDATE_FAILURE:
    case actions.ORGANISATION_COLLABORATOR_ADD_FAILURE:
    case actions.ORGANISATION_COLLABORATOR_REMOVE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case rootActions.ORGANISATION_CHANGE:
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const getOrganisationCollaboratorsSortedAlphabetically: any = createSelector(
  (state) => state.organisationCollaborators.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        // return items[ a ].name - items[ b ].name;
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
