// @flow
import type { User } from '../types/users';
import * as actions from '../actions/globalUsers';
import { createSelector } from 'reselect';
import type { Users } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: Users = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const users = (
  state: Users = initialState,
  action: Object
):
  | any
  | Users
  | {
      byId: { [string]: User },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [key: string]: User },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [key: string]: User },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [key: string]: User },
      error: ?string,
      isFetching: boolean,
      order: Array<number>,
      selectedId: any,
      ...
    } => {
  switch (action.type) {
    case actions.GLOBAL_USERS_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.GLOBAL_USERS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.GLOBAL_USERS_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.GLOBAL_USER_SET_SELECTED:
      return {
        ...state,
        selectedId: action.payload,
      };
    case actions.READ_GLOBAL_USER_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          [action.payload.id]: action.payload,
        },
        isFetching: false,
        error: undefined,
      };
    case actions.READ_GLOBAL_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */

export const getUsersSortedAlphabetically: any = createSelector(
  (state) => state.users.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
