// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const EMAIL_NOTIFICATIONS_LOAD_REQUEST = 'EMAIL_NOTIFICATIONS_LOAD_REQUEST';
export const EMAIL_NOTIFICATIONS_LOAD_SUCCESS = 'EMAIL_NOTIFICATIONS_LOAD_SUCCESS';
export const EMAIL_NOTIFICATIONS_LOAD_FAILURE = 'EMAIL_NOTIFICATIONS_LOAD_FAILURE';

const loadEmailNotificationsRequest = () => ({
  type: EMAIL_NOTIFICATIONS_LOAD_REQUEST,
});

const loadEmailNotificationsSuccess = (notifications) => ({
  type: EMAIL_NOTIFICATIONS_LOAD_SUCCESS,
  payload: notifications,
});

const loadEmailNotificationsFailure = (error) => ({
  type: EMAIL_NOTIFICATIONS_LOAD_FAILURE,
  error,
});

export const loadEmailNotifications = (): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadEmailNotificationsRequest());

    const response = await callAPI({
      method: 'GET',
       endpoint: `${getApiBaseURL(
         'v2',
         'management'
       )}/email-notifications`,
    });
    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadEmailNotificationsSuccess(normalisedPayload));
  } catch (error) {
    dispatch(loadEmailNotificationsFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};
