// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USER_REMOVE_REQUEST =
  'ORGANISATION_USER_REMOVE_REQUEST';
export const ORGANISATION_USER_REMOVE_SUCCESS =
  'ORGANISATION_USER_REMOVE_SUCCESS';
export const ORGANISATION_USER_REMOVE_FAILURE =
  'ORGANISATION_USER_REMOVE_FAILURE';

const removeOrganisationUserRequest = (): Object => ({
  type: ORGANISATION_USER_REMOVE_REQUEST,
});

const removeOrganisationUserSuccess = (message, userId): Object => ({
  type: ORGANISATION_USER_REMOVE_SUCCESS,
  message: message,
  id: userId,
});

const removeOrganisationUserFailure = (error): Object => ({
  type: ORGANISATION_USER_REMOVE_FAILURE,
  error,
});

export const removeOrganisationUser = (
  organisationId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOrganisationUserRequest());

    const today = new Date();
    const body = {
      isArchived: true,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${userId}`,
      body,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(removeOrganisationUserSuccess(response, userId));

    return true;
  } catch (error) {
    dispatch(removeOrganisationUserFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
