// @flow
import * as Sentry from '@sentry/browser';
import { callAPI } from '../../middleware';
import type { User } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const SELF_REGISTER_REQUEST = 'SELF_REGISTER_REQUEST';
export const SELF_REGISTER_SUCCESS = 'SELF_REGISTER_SUCCESS';
export const SELF_REGISTER_FAILURE = 'SELF_REGISTER_FAILURE';

function selfRegisterRequest() {
  return {
    type: SELF_REGISTER_REQUEST,
  };
}

function selfRegisterSuccess(user: User) {
  return {
    type: SELF_REGISTER_SUCCESS,
    payload: user,
  };
}

function selfRegisterFailure(error: string) {
  return {
    type: SELF_REGISTER_FAILURE,
    error: error,
  };
}

export const selfRegister = (body: Object): any => async (
  dispatch,
  getState
) => {
  try {
    dispatch(selfRegisterRequest());
    const today = new Date();

    const requestBody = {
      ...body,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL('v2', 'management')}/account/register/new`,
      body: requestBody,
    });

    dispatch(selfRegisterSuccess(response));
    return response;
  } catch (error) {
    dispatch(selfRegisterFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
