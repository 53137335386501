//@flow

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { readSearchableAudienceImport } from '../../actions';
import { Container, Grid } from 'semantic-ui-react';
import { Route, useParams } from 'react-router-dom';
import SideMenu from '../../components/SideMenu';
import Breadcrumb from '../../components/Breadcrumb';
import PageLoader from '../../components/PageLoader';
import ErrorMessage from '../../components/ErrorMessage';
import SearchableAudienceImportDetails from './SearchableAudienceImportTabs/SearchableAudienceImportDetails';

type Props = {
	location: any
};

export default function SearchableAudienceImport(props: Props): any {
	const dispatch = useDispatch();

	const { importId } = useParams();

	const { location } = props;

	useEffect(() => {
		dispatch(readSearchableAudienceImport(parseInt(importId)));
	}, [dispatch, importId]);

	const { items: imports, isFetching, error } = useSelector(
		state => state.searchableAudienceImports
	);
	const importDetails = imports[importId];

	if (error) {
		return ErrorMessage({ message: error.msg });
	}

	if (isFetching || !importDetails) {
		return <PageLoader />;
	}

	const menuItems = [
		{
			label: 'Details',
			path: `/workspace/searchable-audience-imports/${importId || ''}/details`,
			container: () => (<SearchableAudienceImportDetails details={importDetails}/>)
		}
	];

	const { version } = importDetails;

	return (
		<Container>
			<Grid>
				<Grid.Column width={12}>
					<Breadcrumb
						titleClass={'breadcrumb__current-title--lower-case'}
						topLevelContext="All Imports"
						currentContext={`V${version} (Run 5)`}
						backLink="/workspace/searchable-audience-imports"
					/>
				</Grid.Column>
			</Grid>
			<Grid className="side-menu-layout">
            <Grid.Column width={3} className="side-menu-column">
				<SideMenu
					currentPath={location.pathname}
					items={menuItems}
          permission={'standardUser'}
				/>
            </Grid.Column>
            <Grid.Column width={9}>
				<Grid>
					<Grid.Column>
						{menuItems.map((item, index) => {
							return (
								<Route
									key={index}
									path={item.path}
									render={item.container}
								/>
							);
						})}
					</Grid.Column>
				</Grid>
            </Grid.Column>
          </Grid>
	  	</Container>
	);
}
