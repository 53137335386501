// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import type { OrganisationUser } from '../../../types/organisationUsers';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USER_UPDATE_REQUEST =
  'ORGANISATION_USER_UPDATE_REQUEST';
export const ORGANISATION_USER_UPDATE_SUCCESS =
  'ORGANISATION_USER_UPDATE_SUCCESS';
export const ORGANISATION_USER_UPDATE_FAILURE =
  'ORGANISATION_USER_UPDATE_FAILURE';

const updateOrganisationUserRequest = (): Object => ({
  type: ORGANISATION_USER_UPDATE_REQUEST,
});

const updateOrganisationUserSuccess = (user): Object => ({
  type: ORGANISATION_USER_UPDATE_SUCCESS,
  payload: user,
});

const updateOrganisationUserFailure = (error): Object => ({
  type: ORGANISATION_USER_UPDATE_FAILURE,
  error,
});

export const updateOrganisationUser = (
  organisationId: number,
  userId: number,
  formData: OrganisationUser
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationUserRequest());
    const today = new Date();
    const body = {
      ...formData,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${userId}`,
      body,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(updateOrganisationUserSuccess(response));

    return true;
  } catch (error) {
    dispatch(updateOrganisationUserFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
