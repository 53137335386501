// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import type { User } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const GLOBAL_USER_UPDATE_REQUEST = 'GLOBAL_USER_UPDATE_REQUEST';
export const GLOBAL_USER_UPDATE_SUCCESS = 'GLOBAL_USER_UPDATE_SUCCESS';
export const GLOBAL_USER_UPDATE_FAILURE = 'GLOBAL_USER_UPDATE_FAILURE';

function updateUserRequest() {
  return {
    type: GLOBAL_USER_UPDATE_REQUEST,
  };
}

function updateUserSuccess(user: User) {
  return {
    type: GLOBAL_USER_UPDATE_SUCCESS,
    payload: user,
  };
}

function updateUserFailure(error: string) {
  return {
    type: GLOBAL_USER_UPDATE_FAILURE,
    error: error,
  };
}

export const updateUser = (userId: number, body: Object): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(updateUserRequest());
    const today = new Date();

    const requestBody = {
      ...body,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL('v2', 'management')}/users/${userId}`,
      body: requestBody,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(updateUserSuccess(response));
    return true;
  } catch (error) {
    dispatch(updateUserFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
