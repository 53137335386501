// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type { OnlineStoreCoupon } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_COUPON_READ_REQUEST =
  'ONLINE_STORE_COUPON_READ_REQUEST';
export const ONLINE_STORE_COUPON_READ_SUCCESS =
  'ONLINE_STORE_COUPON_READ_SUCCESS';
export const ONLINE_STORE_COUPON_READ_FAILURE =
  'ONLINE_STORE_COUPON_READ_FAILURE';

const readOnlineStoreCouponRequest = () => ({
  type: ONLINE_STORE_COUPON_READ_REQUEST,
});

const readOnlineStoreCouponSuccess = (
  onlineStoreCoupon: OnlineStoreCoupon
) => ({
  type: ONLINE_STORE_COUPON_READ_SUCCESS,
  payload: onlineStoreCoupon,
});

const readOnlineStoreCouponFailure = (error) => ({
  type: ONLINE_STORE_COUPON_READ_FAILURE,
  error,
});

export const readOnlineStoreCoupon = (
  onlineStoreCouponId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(readOnlineStoreCouponRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/coupons/${onlineStoreCouponId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(readOnlineStoreCouponSuccess(response));

    return true;
  } catch (error) {
    dispatch(readOnlineStoreCouponFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
