//@flow
import React from 'react';
import TabHeader from '../../components/TabHeader';
import UpdateUserFormFields from '../../components/OrganisationUser/UpdateUserFormFields';
import type {
  State as AppState,
  // OrganisationUser,
  UpdateOrganisationUserForm,
  Organisation,
  AuthTypes,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  readOrganisationUser,
  updateOrganisationUser,
  forceVerifyOrganisationUser,
  removeOrganisationUser,
  resendInvitationEmailOrganisationUser,
} from '../../actions/organisations/organisationUsers';
import { adminUpdateUserPassword } from '../../actions/user';
import { Grid, Confirm } from 'semantic-ui-react';
import AdminUpdateUserPassword from '../../components/AdminUpdateUserPassword';
import StaticField from '../../components/StaticField';
import { Redirect } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import moment from 'moment';
import { getIsReady } from '../../reducers/fetchSync';
import { setFetchSyncReady } from '../../actions';
// $FlowFixMe
import { canUpdatePasswordForAnotherUser } from '../../helpers';

type Props = {
  organisationId: number,
  userId: number,
  permission: ?AuthTypes,
  user: any,
  organisation: Organisation,
  readOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  updateOrganisationUser: (
    oid: number,
    uid: number,
    formData: UpdateOrganisationUserForm
  ) => Promise<boolean>,
  forceVerifyOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  removeOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  resendInvitationEmailOrganisationUser: (
    oid: number,
    uid: number
  ) => Promise<boolean>,
  isFetching: boolean,
  adminUpdateUserPassword: (userId: number, password: string) => Promise<null>,
  isSingleReady: boolean,
  setOrganisationUserSingleReady: () => void,
};

type State = {
  forceVerifyOpen: boolean,
  removeUserOpen: boolean,
  contextChange: boolean,
  resendInvitationEmailOpen: boolean,
};

class UserDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      forceVerifyOpen: false,
      removeUserOpen: false,
      contextChange: false,
      resendInvitationEmailOpen: false,
    };
  }

  componentDidMount() {
    this.props
      .readOrganisationUser(this.props.organisationId, this.props.userId)
      .finally(this.props.setOrganisationUserSingleReady);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (typeof nextProps.user === 'undefined') {
      this.setState({
        ...this.state,
        contextChange: true,
      });
    }
  }

  handleFormSubmit = (formData: UpdateOrganisationUserForm) => {
    this.props
      .updateOrganisationUser(
        this.props.organisationId,
        this.props.userId,
        formData
      )
      .then(
        (success) => {},
        (failure) => {}
      );
  };

  setForceVerifyModalVisible = () => {
    this.setState({ ...this.state, forceVerifyOpen: true });
  };

  handleForceVerify = () => {
    this.props.forceVerifyOrganisationUser(
      this.props.organisationId,
      this.props.userId
    );
    this.setState({ ...this.state, forceVerifyOpen: false });
  };

  setResendInvitationEmailModalVisible = () => {
    this.setState({ ...this.state, resendInvitationEmailOpen: true });
  };

  handleResendInvitationEmail = () => {
    this.props.resendInvitationEmailOrganisationUser(
      this.props.organisationId,
      this.props.userId
    );
    this.setState({ ...this.state, resendInvitationEmailOpen: false });
  };

  setRemoveUserModalVisible = () => {
    this.setState({ ...this.state, removeUserOpen: true });
  };

  handleRemoveUser = () => {
    this.props.removeOrganisationUser(
      this.props.organisationId,
      this.props.userId
    );
    this.setState({ ...this.state, removeUserOpen: false });
  };

  handleAdminUpdateUserPassword = (password) => {
    this.props
      .adminUpdateUserPassword(this.props.userId, password)
      .then((success) => {
        this.props.readOrganisationUser(
          this.props.organisationId,
          this.props.userId
        );
      });
  };

  renderSwitchButton(user, permission) {
    const { INTERNAL_ADMIN, BUSINESS_ADMIN, ACCOUNT_MANAGER_ADMIN } = permissions;
    switch (user.status.toLowerCase()) {
      case 'active':
        return restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, ACCOUNT_MANAGER_ADMIN], permission)
          ? (
            // TODO: replace anchor with button //
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              role="button"
              tabIndex="0"
              onClick={() =>
                // $FlowFixMe
                this.setForceVerifyModalVisible(user.id, user.name)
              }
              onKeyPress={() =>
                // $FlowFixMe
                this.setForceVerifyModalVisible(user.id, user.name)
              }
            >
              Force Email Verification
            </a>
          ) : null;
      case 'pending':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() =>
              // $FlowFixMe
              this.setResendInvitationEmailModalVisible(user.id, user.name)
            }
            onKeyPress={() =>
              // $FlowFixMe
              this.setResendInvitationEmailModalVisible(user.id, user.name)
            }
          >
            Resend Welcome
          </a>
        );
      case 'locked':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() =>
              // $FlowFixMe
              this.setForceVerifyModalVisible(user.id, user.name)
            }
            onKeyPress={() =>
              // $FlowFixMe
              this.setForceVerifyModalVisible(user.id, user.name)
            }
          >
            Resend Email Verification
          </a>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      organisationId,
      user,
      organisation,
      isFetching,
      permission,
      isSingleReady,
    } = this.props;
    const { INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN } = permissions;

    if (!isSingleReady) {
      return null;
    }

    if (user === undefined) {
      return <Redirect to={`/organisations/${organisationId}/users`} />;
    } else {
      const switchButton = this.renderSwitchButton(user, permission);

      return (
        <div>
          {user ? (
            <TabHeader
              tabHeading={`${user.name}'s Details`}
              backLink={`/organisations/${organisationId}/users`}
              backText="Back to Users"
            />
          ) : (
            ''
          )}
          <Confirm
            // This confirmation box handles the Force Email Verification Action
            content="Are you sure you want to Force Email Verification for this user?"
            open={this.state.forceVerifyOpen}
            onCancel={() =>
              this.setState({ ...this.state, forceVerifyOpen: false })
            }
            onConfirm={this.handleForceVerify}
          />

          <Confirm
            // This confirmation box handles the re-send invitation email/welcome email action
            content="Are you sure you want to re-send welcome email for this user'?"
            open={this.state.resendInvitationEmailOpen}
            onCancel={() =>
              this.setState({ ...this.state, resendInvitationEmailOpen: false })
            }
            onConfirm={this.handleResendInvitationEmail}
          />

          <Confirm
            content={`Are you sure you want to remove ${
              user && user.email ? user.email : ''
            } from ${organisation.name}?`}
            open={this.state.removeUserOpen}
            onCancel={() =>
              this.setState({ ...this.state, removeUserOpen: false })
            }
            onConfirm={this.handleRemoveUser}
          />
          {isFetching ? (
            <PageLoader />
          ) : (
            <div>
              <UpdateUserFormFields
                permission={permission}
                user={user}
                handleFormSubmit={this.handleFormSubmit}
                canHaveTeams={organisation.canHaveTeams}
              >
                {switchButton && (
                  <li>{switchButton}</li>
                )}
                {canUpdatePasswordForAnotherUser(user, permission) && (
                  <li>
                    <AdminUpdateUserPassword
                      handleAdminUpdateUserPassword={
                        // $FlowFixMe
                        this.handleAdminUpdateUserPassword
                      }
                      triggerAsButton={false}
                      userStatus={user.status}
                    />
                  </li>
                )}
                <li>
                  {/* // TODO: replace anchor with button // // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onClick={this.setRemoveUserModalVisible}
                    onKeyPress={this.setRemoveUserModalVisible}
                  >
                    Remove User
                  </a>
                </li>
              </UpdateUserFormFields>

              {restrictUserPermissions(
                [INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN],
                permission
              ) && (
                <div>
                  <hr />
                  <div className="additional-details">
                    <Grid columns={3} stackable>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticField
                            label="Date Created"
                            value={moment
                              .utc(user.dateCreated)
                              .local()
                              .format('YYYY-MM-DD')
                              .toString()}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <StaticField
                            label="Last Login"
                            value={
                              user.lastLoginDateTime
                                ? moment
                                    .utc(user.lastLoginDateTime)
                                    .local()
                                    .format('YYYY-MM-DD LT')
                                    .toString()
                                : 'Never'
                            }
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <StaticField
                            label="Last Changed By"
                            value={user.lastChangedBy}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticField
                            label="Archived?"
                            value={user.isArchived ? 'Yes' : 'No'}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <StaticField
                            label="Last Account Force Verify"
                            value={
                              user.lastForceVerifyAccountTime
                                ? moment
                                    .utc(user.lastForceVerifyAccountTime)
                                    .local()
                                    .format('YYYY-MM-DD LT')
                                    .toString()
                                : 'Never'
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = function (state: AppState, props) {
  return {
    isFetching: state.organisationUsers.isFetching,
    isSingleReady: getIsReady(state, 'organisationUserSingle'),
    user: state.organisationUsers.byId[props.userId]
      ? state.organisationUsers.byId[props.userId]
      : state.users.byId[props.userId],
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      readOrganisationUser,
      updateOrganisationUser,
      forceVerifyOrganisationUser,
      removeOrganisationUser,
      resendInvitationEmailOrganisationUser,
      adminUpdateUserPassword,
      setOrganisationUserSingleReady: setFetchSyncReady('organisationUserSingle'),
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(UserDetails): any);
