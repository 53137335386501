// @flow
import type { OrganisationTeam } from '../types/organisationTeams';
import { createSelector } from 'reselect';
import type { OrganisationTeams } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: OrganisationTeams = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

export const organisationCollaboratorTeams = (
  state: OrganisationTeams = initialState,
  action: Object
):
  | any
  | OrganisationTeams
  | { byId: { ... }, error: void, isFetching: boolean, order: Array<any>, ... }
  | {
      byId: { [string]: OrganisationTeam },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationTeam },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationTeam },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    } => {
  switch (action.type) {
    case 'ORGANISATION_COLLABORATOR_TEAMS_LOAD_REQUEST':
    case 'ORGANISATION_COLLABORATOR_TEAM_REMOVE_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case 'ORGANISATION_COLLABORATOR_TEAMS_LOAD_FAILURE':
    case 'ORGANISATION_COLLABORATOR_TEAM_REMOVE_FAILURE':
      return {
        ...state,
        isFetching: false,
        // $FlowFixMe
        error: action.error,
      };
    case 'ORGANISATION_COLLABORATOR_TEAMS_LOAD_SUCCESS':
      return {
        ...state,
        isFetching: false,
        ...action.payload,
        error: undefined,
      };
    /* eslint-disable no-case-declarations */
    case 'ORGANISATION_COLLABORATOR_TEAM_REMOVE_SUCCESS':
      let copy = state.byId;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.teamId];
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.teamId);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    /* eslint-enable no-case-declarations */
    case 'ORGANISATION_CHANGE':
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const getOrganisationCollaboratorTeamsSortedAlphabetically: any = createSelector(
  (state) => state.organisationCollaboratorTeams.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        // return items[ a ].name - items[ b ].name;
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
