// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import InlineSvg from './InlineSvg';
import type { MenuItem, AuthTypes } from '../types';
import { restrictUserPermissions } from 'roy-morgan-auth';

type Props = {|
  currentPath: string,
  permission: ?AuthTypes,
  items: Array<MenuItem>,
|};

type ItemProps = {
  item: MenuItem,
  currentPath: string,
  permission: ?AuthTypes,
};

const SideMenuItem = ({ item, currentPath, permission }: ItemProps) => (
  <div
    className={`side-menu__item ${
      currentPath.startsWith(item.path) ? 'side-menu__item--active' : ''
    }`}
  >
    <Link to={item.path} className="side-menu__link">
      {item.icon && (
        <InlineSvg name={item.icon} className="side-menu__item-icon" />
      )}
      <span className="side-menu__item-label">{item.label}</span>
    </Link>
    {item.subMenu && item.subMenuDisplay && (
      <div className="side-menu__sub-menu">
        {item.subMenuTitle && <h4>{item.subMenuTitle}</h4>}
        <div className="side-menu__sub-menu-items">
          {item.subMenu.map((subItem, index) => {
            if (
              restrictUserPermissions(subItem.acceptedPermissions, permission)
            ) {
              return (
                <Link
                  to={subItem.path}
                  className={`side-menu__sub-menu-link ${
                    currentPath.startsWith(subItem.path)
                      ? 'side-menu__sub-menu-link--active'
                      : ''
                  }`}
                  key={index}
                >
                  {subItem.label}
                </Link>
              );
            }
            return '';
          })}
        </div>
      </div>
    )}
  </div>
);

// Still need to add in icon somewhere

class SideMenu extends React.Component<Props> {
  render(): React.Element<'nav'> {
    const { items, permission } = this.props;

    return (
      <nav className="side-menu">
        {items.map((item, index) => {
          if (item.noShow) {
            return '';
          }
          if (item.acceptedPermissions) {
            if (restrictUserPermissions(item.acceptedPermissions, permission)) {
              return <SideMenuItem item={item} {...this.props} key={index} />;
            }
            return '';
          }
          return <SideMenuItem item={item} {...this.props} key={index} />;
        })}
      </nav>
    );
  }
}

export default SideMenu;
