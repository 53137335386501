//@flow
import * as React from 'react';
import {
  Grid,
  Form,
  Button,
  Modal,
} from 'semantic-ui-react';
import moment from 'moment';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import StaticField from '../StaticField';
import type {
  Organisation as OrgType,
  NewOrganisation as NewOrgType,
  OrganisationForm,
  AuthTypes
} from '../../types';
import  TransformOrganisationToHaveTeams from './TransformOrganisationToHaveTeams';


type Props = {
  permission: ?AuthTypes,
  organisation: OrgType | NewOrgType,
  handleFormSubmit: Function,
  refreshOrganisationTeams: Function,
  setCanHaveTeamsAction: (data: any) => Promise<any>,
  organisationTeams:?Array<any>,
  children?: any,
};

type State = {
  formData: OrganisationForm,
  setAccountManagerOpen?: boolean,
  organisationName: ?string,
  organisationNotes: ?string,
  organisationNextReviewDate: ?string,
  setCanHaveTeamOpen ?: boolean,
};

const dateFormat = 'DD/MM/YYYY';

const organisationFlagIdFromProps = (props: Props) => {
  return props.organisation && props.organisation.organisationFlagId
    ? props.organisation.organisationFlagId
    : null;
};

class OrganisationFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formData: {},
      organisationName: props.organisation.name,
      organisationNotes: props.organisation.notes,
      organisationNextReviewDate: props.organisation.nextReviewDate
    };
    if (
      restrictUserPermissions(
        [
          permissions.INTERNAL_ADMIN,
          permissions.BUSINESS_ADMIN,
          permissions.SUPPORT_ADMIN,
          permissions.ACCOUNT_MANAGER_ADMIN
        ],
        props.permission
      )
    ) {
      this.state = {
        formData: {
          ...this.state.formData,
          name: props.organisation.name,
          notes: props.organisation.notes,
          nextReviewDate: props.organisation.nextReviewDate,
          organisationFlagId: organisationFlagIdFromProps(props)
        },
        organisationName: props.organisation.name,
        organisationNotes: props.organisation.notes,
        organisationNextReviewDate: props.organisation.nextReviewDate,
        setAccountManagerOpen: false,
        setCanHaveTeamOpen: false
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      restrictUserPermissions(
        [
          permissions.INTERNAL_ADMIN,
          permissions.BUSINESS_ADMIN,
          permissions.SUPPORT_ADMIN,
          permissions.ACCOUNT_MANAGER_ADMIN
        ],
        nextProps.permission
      )
    ) {
      this.setState({
        formData: {
          ...this.state.formData,
          name: nextProps.organisation.name,
          notes: nextProps.organisation.notes,
          nextReviewDate: nextProps.organisation.nextReviewDate,
          organisationFlagId: organisationFlagIdFromProps(nextProps)
        }
      });
    }
  }

  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number, ... }
  ) => void = (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => {
    this.setState({
      formData: {
        ...this.state.formData,
      },
    });
  };

  handleDropdownChangeOrganisationFlag: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number, ... }
  ) => void = (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => {
    this.setState({
      formData: {
        ...this.state.formData,
        organisationFlagId: result.value,
      },
    });
  };

  handleDateChange: (event: any, { name: any, value: any, ... }) => void = (
    event: any,
    { name, value }: { name: any, value: any }
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[name] = value ? moment(value, dateFormat).toISOString() : null;
    this.setState({ formData });
  };

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleSetCanHaveTeams: (data: any) => void = (data: any) => {
    this.props.refreshOrganisationTeams();
  };

  toggleSetCanHaveTeamModal: (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => void = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.setState({
      setCanHaveTeamOpen: !this.state.setCanHaveTeamOpen,
    });
  };


  render(): React.Node {
    const {
      handleFormSubmit,
      permission
    } = this.props;
    let name, notes;
    const {
      organisationName,
      organisationNotes,
    } = this.state;

    const {
      INTERNAL_ADMIN,
      BUSINESS_ADMIN,
      SUPPORT_ADMIN,
      ACCOUNT_MANAGER_ADMIN
    } = permissions;

    if (
      restrictUserPermissions(
        [INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN],
        permission
      )
    ) {
      name = this.state.formData.name;
      notes = this.state.formData.notes;
    } else {
      name = organisationName ? organisationName : '';
      notes = organisationNotes ? organisationNotes : '';
    }


    const renderOrganisationHaveTeams = (organisation, organisationTeams) =>{
      if (organisation.canHaveTeams){
        const l = Array.isArray(organisationTeams) ? organisationTeams.length : 0;
        const text = 'This Organisation has ' + l + ' Teams'
          return (
            <Grid.Row>
              <Grid.Column width={12}>
                <StaticField
                  label="Teams"
                  value={text}
                />
              </Grid.Column>
            </Grid.Row>
          )
      }
       return (
        <Grid.Row>
          <Grid.Column width={12}>
            <StaticField
              label="Teams"
              value="Organisation doesn't allow to have teams"
            />
            <div>
              <Button onClick={this.toggleSetCanHaveTeamModal}>
                Arrange this organisation into teams
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      )
    }

    return (
      <Form
        className="organisation-form"
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >

        <Modal
          open={this.state.setCanHaveTeamOpen}
          closeIcon={<button className="modal__close">Close</button>}
          onClose={() =>this.setState({ ...this.state, setCanHaveTeamOpen: false })}
          style={  {modal : {
            marginTop: '0px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}}

        >
          <Modal.Header>Arrange this organisation into teams</Modal.Header>
          <Modal.Content>
            <TransformOrganisationToHaveTeams
              handleFormSubmit={this.handleSetCanHaveTeams}
              setCanHaveTeamsAction={this.props.setCanHaveTeamsAction}
              OrganisationId={this.props.organisation.id}
              >
            </TransformOrganisationToHaveTeams>
          </Modal.Content>
        </Modal>
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              {restrictUserPermissions(
                [INTERNAL_ADMIN, BUSINESS_ADMIN, ACCOUNT_MANAGER_ADMIN],
                permission
              ) ? (
                <Form.Input
                  label="Organisation Name"
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                  type="text"
                  required
                />
              ) : (
                <StaticField label="Organisation Name" value={name} />
              )}
            </Grid.Column>
          </Grid.Row>
          {restrictUserPermissions([
            INTERNAL_ADMIN,
            BUSINESS_ADMIN,
            SUPPORT_ADMIN,
            ACCOUNT_MANAGER_ADMIN
          ], permission) ? (
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.TextArea
                  label="Notes"
                  name="notes"
                  value={notes}
                  onChange={this.handleInputChange}
                  placeholder="Enter your notes"
                  rows={10}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            ''
          )}
          {restrictUserPermissions([
            INTERNAL_ADMIN,
            BUSINESS_ADMIN,
            ACCOUNT_MANAGER_ADMIN
          ], permission) ? (
            renderOrganisationHaveTeams(this.props.organisation, this.props.organisationTeams)
          ) : (
            ''
          )}
        </Grid>
      </Form>
    );
  }
}

export default OrganisationFormFields;
