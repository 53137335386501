// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_ACCOUNT_MANAGER_ADD_REQUEST =
  'ORGANISATION_ACCOUNT_MANAGER_ADD_REQUEST';
export const ORGANISATION_ACCOUNT_MANAGER_ADD_SUCCESS =
  'ORGANISATION_ACCOUNT_MANAGER_ADD_SUCCESS';
export const ORGANISATION_ACCOUNT_MANAGER_ADD_FAILURE =
  'ORGANISATION_ACCOUNT_MANAGER_ADD_FAILURE';

const addOrganisationAccountManagerRequest = () => ({
  type: ORGANISATION_ACCOUNT_MANAGER_ADD_REQUEST,
});

const addOrganisationAccountManagerSuccess = (accountManager) => ({
  type: ORGANISATION_ACCOUNT_MANAGER_ADD_SUCCESS,
  payload: accountManager,
});

const addOrganisationAccountManagerFailure = (error) => ({
  type: ORGANISATION_ACCOUNT_MANAGER_ADD_FAILURE,
  error,
});

export const addOrganisationAccountManager = (
  organisationId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(addOrganisationAccountManagerRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/account-managers`,
      body: {
        userId
      },
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(addOrganisationAccountManagerSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(addOrganisationAccountManagerFailure(error.message));
    throw error;
  }
};
