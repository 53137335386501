// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_ORGANISATION_UPDATE_REQUEST = 'API_ORGANISATION_UPDATE_REQUEST';
export const API_ORGANISATION_UPDATE_SUCCESS = 'API_ORGANISATION_UPDATE_SUCCESS';
export const API_ORGANISATION_UPDATE_FAILURE = 'API_ORGANISATION_UPDATE_FAILURE';

const updateOrganisationApiSubscriptionRequest = (id) => ({
  type: API_ORGANISATION_UPDATE_REQUEST,
  id
});

const updateOrganisationApiSubscriptionSuccess = (subscription) => ({
  type: API_ORGANISATION_UPDATE_SUCCESS,
  payload: subscription,
});

const updateOrganisationApiSubscriptionFailure = (error, id) => ({
  type: API_ORGANISATION_UPDATE_FAILURE,
  error,
  id
});

export const updateOrganisationApiSubscription = (
  apiId: number,
  isActive: boolean, 
  organisation: any
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationApiSubscriptionRequest(organisation.id));

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisation.id}/apis/${apiId}`,
      body: {
        isActive
      }
    });

    if (response instanceof Error) {
      throw response;
    }

    let subscription = toOrganisationSubscriptionObject(response, organisation);

    dispatch(updateOrganisationApiSubscriptionSuccess(subscription));
  } catch (error) {
    dispatch(updateOrganisationApiSubscriptionFailure(error.message, organisation.id));
    Sentry.captureException(error);
    console.error(error);
  }
};

function toOrganisationSubscriptionObject(apiSubscription, organisation) {
  return {
    id: organisation.id,
    name: organisation.name,
    apiId: apiSubscription.id,
    token: apiSubscription.token,
    isSubscriptionActive: apiSubscription.isActive
  };
}