// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_FEATURE_ADD_REQUEST =
  'ORGANISATION_FEATURE_ADD_REQUEST';
export const ORGANISATION_FEATURE_ADD_SUCCESS =
  'ORGANISATION_FEATURE_ADD_SUCCESS';
export const ORGANISATION_FEATURE_ADD_FAILURE =
  'ORGANISATION_FEATURE_ADD_FAILURE';

const addOrganisationFeatureRequest = (featureId) => ({
  type: ORGANISATION_FEATURE_ADD_REQUEST,
  id: featureId,
});

const addOrganisationFeatureSuccess = (feature) => ({
  type: ORGANISATION_FEATURE_ADD_SUCCESS,
  payload: feature,
});

const addOrganisationFeatureFailure = (error, featureId) => ({
  type: ORGANISATION_FEATURE_ADD_FAILURE,
  id: featureId,
  error,
});

export const addOrganisationFeature = (
  organisationId: number,
  featureId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(addOrganisationFeatureRequest(featureId));

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/features`,
      body: {
        featureId,
        defaultMemberAccess: false,
        defaultCollaboratorAccess: false,
      },
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(addOrganisationFeatureSuccess(response));

    return featureId;
  } catch (error) {
    dispatch(addOrganisationFeatureFailure(error.message, featureId));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
