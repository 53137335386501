// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type { OnlineStoreOrderItem } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_ORDER_ITEM_UPLOAD_REQUEST =
  'ONLINE_STORE_ORDER_ITEM_UPLOAD_REQUEST';
export const ONLINE_STORE_ORDER_ITEM_UPLOAD_SUCCESS =
  'ONLINE_STORE_ORDER_ITEM_UPLOAD_SUCCESS';
export const ONLINE_STORE_ORDER_ITEM_UPLOAD_FAILURE =
  'ONLINE_STORE_ORDER_ITEM_UPLOAD_FAILURE';

const ORDER_STATUS = {
  PENDING: 0,
  UPLOADED: 1,
  EMAILED: 2,
  DOWNLOADED: 3,
};

function uploadOnlineStoreOrderItemRequest() {
  return {
    type: ONLINE_STORE_ORDER_ITEM_UPLOAD_REQUEST,
  };
}

function uploadOnlineStoreOrderItemSuccess(
  onlineStoreOrderItem: OnlineStoreOrderItem
) {
  return {
    type: ONLINE_STORE_ORDER_ITEM_UPLOAD_SUCCESS,
    payload: onlineStoreOrderItem,
  };
}

function uploadOnlineStoreOrderItemFailure(error: string) {
  return {
    type: ONLINE_STORE_ORDER_ITEM_UPLOAD_FAILURE,
    error: error,
  };
}

async function uploadOrderItem(orderItemId: number, file: any) {
  const fileName = file.name;

  const uploadStoreItemResponse = await callAPI({
    method: 'GET',
    endpoint: `${getApiBaseURL(
      'v2',
      'store'
    )}/management/orderitems/${orderItemId}/reportuploadurl/${fileName}`,
  });

  const uploadUrl = uploadStoreItemResponse.url;

  const headers = new Headers({
    'Content-Type': 'application/octet-stream',
  });

  const uploadResponse = await fetch(uploadUrl, {
    method: 'PUT',
    headers,
    body: file,
  });

  if (!uploadResponse.ok) {
    throw new Error('Error uploading order item file.');
  }

  const orderItem = {
    id: orderItemId,
    refName: file.name,
    orderStatus: ORDER_STATUS.UPLOADED,
    dateUpdated: new Date().toISOString(),
  };

  return await callAPI({
    method: 'PUT',
    endpoint: `${getApiBaseURL(
      'v2',
      'store'
    )}/management/orderitems/${orderItemId}/upload`,
    body: orderItem,
  });
}

export const uploadOnlineStoreOrderItem = (
  orderItemId: number,
  orderItemFile: any
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(uploadOnlineStoreOrderItemRequest());

    const response = await uploadOrderItem(orderItemId, orderItemFile);

    dispatch(uploadOnlineStoreOrderItemSuccess(response));
    return response;
  } catch (error) {
    dispatch(uploadOnlineStoreOrderItemFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
