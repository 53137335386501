// @flow
import type { OnlineStoreItem } from '../types/onlineStoreItems';
import * as actions from '../actions/onlineStore';
import { createSelector } from 'reselect';
import type {
  // OnlineStoreItemsActions,
  OnlineStoreItems,
} from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: OnlineStoreItems = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const onlineStoreItems = (
  state: OnlineStoreItems = initialState,
  action: any
):
  | any
  | OnlineStoreItems
  | {
      byId: { [string | number]: OnlineStoreItem },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [string | number]: OnlineStoreItem },
      error: void,
      isFetching: boolean,
      order: Array<any | number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [key: string | number]: OnlineStoreItem },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [key: string | number]: OnlineStoreItem },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    } => {
  switch (action.type) {
    case actions.ONLINESTOREITEMS_LOAD_REQUEST:
    case actions.ONLINESTOREITEM_READ_REQUEST:
    case actions.ONLINESTOREITEM_UPDATE_REQUEST:
    case actions.ONLINESTOREITEM_CREATE_REQUEST:
    case actions.ONLINESTOREITEM_REMOVE_REQUEST:
    case actions.ONLINESTOREITEMSFORREPORTTYPE_UPDATE_REQUEST:
    case actions.CLONE_STORE_ITEM_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ONLINESTOREITEMS_LOAD_FAILURE:
    case actions.ONLINESTOREITEM_READ_FAILURE:
    case actions.ONLINESTOREITEM_UPDATE_FAILURE:
    case actions.ONLINESTOREITEM_CREATE_FAILURE:
    case actions.ONLINESTOREITEM_REMOVE_FAILURE:
    case actions.ONLINESTOREITEMSFORREPORTTYPE_UPDATE_FAILURE:
    case actions.CLONE_STORE_ITEM_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.ONLINESTOREITEMS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINESTOREITEM_READ_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          [action.payload.id]: action.payload,
        },
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINESTOREITEM_UPDATE_SUCCESS:
    case actions.ONLINESTOREITEM_CREATE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        isFetching: false,
        error: undefined,
      };
    /* eslint-disable no-case-declarations */
    case actions.ONLINESTOREITEM_REMOVE_SUCCESS:
      let copy = state.byId;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.id];
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.id);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    /* eslint-enable no-case-declarations */
    case actions.ONLINESTOREITEMSFORREPORTTYPE_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
      };
    case actions.CLONE_STORE_ITEM_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        order: [...state.order, action.payload.id],
        isFetching: false,
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOnlineStoreItemsSortedAlphabetically: any = createSelector(
  (state) => state.onlineStoreItems.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        let sKUNumberA = items[a].id;
        let sKUNumberB = items[b].id;
        if (sKUNumberA > sKUNumberB) return -1;
        if (sKUNumberA < sKUNumberB) return 1;
        return 0;
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
