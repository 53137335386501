// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const USER_USAGE_PER_USER_REQUEST = 'USER_USAGE_PER_USER_REQUEST';
export const USER_USAGE_PER_USER_SUCCESS = 'USER_USAGE_PER_USER_SUCCESS';
export const USER_USAGE_PER_USER_FAILURE = 'USER_USAGE_PER_USER_FAILURE';

const perDomainRequest = () => ({
  type: USER_USAGE_PER_USER_REQUEST,
});

const perDomainSuccess = (response) => ({
  type: USER_USAGE_PER_USER_SUCCESS,
  payload: response,
});

const perDomainFailure = (error) => ({
  type: USER_USAGE_PER_USER_FAILURE,
  error,
});

export const perDomain = (
  organisationId: number,
  userId: number,
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(perDomainRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${userId}/usage/per-domain`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(perDomainSuccess(response));
    return response;

  } catch (error) {
    dispatch(perDomainFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
