// @flow
import * as React from 'react';
import { NavMenu } from 'roy-morgan-header';
import { Menu } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import rmrLiveLogo from '../assets/images/logos/rmr_live_logo_0,25x.png';
import type { State, User, Products, Organisation, Team } from '../types';
import HeaderMenuItems from '../containers/HeaderMenuItems';
import { switchTo } from 'roy-morgan-auth';
import { teamChange, organisationChange } from '../actions';

/* TODO: Style Header correctly */

type Props = {|
  authUser: User,
  products: Products,
  logOut: () => Promise<*>,
  currentOrganisation: number,
  switchTo: (
    email: string,
    switchFromEmail: string,
    switchFromToken: string
  ) => Promise<void>,

  organisations: Organisation[],
  teams: Team[],
  currentTeam: number,
  organisationChange: (organisationId: number) => Promise<void>,
  teamChange: (teamId: number) => Promise<void>,
|};

class Header extends React.Component<Props> {
  handleOrganisationChange = (organisationId) => {
    this.props.organisationChange(organisationId).then((success) => {
      window.location.replace('/');
    });
  };

  handleTeamChange = (teamId) => {
    this.props.teamChange(teamId).then((success) => {
      window.location.replace('/');
    });
  };

  render() {
    let {
      authUser,
      products,
      currentOrganisation,
      switchTo,

      organisations,
      teams,
      currentTeam,
    } = this.props;

    let showLogin = true;
    // Don't show login button when login form is present.
    // This is present when there is no authUser.
    if (!authUser) {
      showLogin = false;
    }

    return (
      <NavMenu
        logoUrl={rmrLiveLogo}
        authUser={authUser}
        products={products}
        switchTo={switchTo}
        organisations={organisations}
        currentOrganisation={currentOrganisation}
        teams={teams}
        currentTeam={currentTeam}
        organisationChange={this.handleOrganisationChange}
        showLogin={showLogin}
        teamChange={this.handleTeamChange}
        mobileBreakpoint={850}
        userMenuBreakpoint={1300}
      >
        <Menu.Menu>
          {currentOrganisation && (
            <HeaderMenuItems
              organisationId={currentOrganisation}
              teamId={currentTeam}
              {...this.props}
            />
          )}
        </Menu.Menu>
      </NavMenu>
    );
  }
}

const mapStateToProps = (state: State) => ({
  authUser: state.auth.user.user,
  products: state.auth.products,
  currentOrganisation: state.auth.organisations.currentOrganisation,
  organisations: Object.keys(state.auth.organisations.byId).map(
    (i) => state.auth.organisations.byId[i]
  ),
  teams: Object.keys(state.auth.teams.byId).map(
    (i) => state.auth.teams.byId[i]
  ),
  currentTeam: state.auth.teams.currentTeam,
});

const mapDispatchToProps = (dispatch: any) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      organisationChange,
      teamChange,
      switchTo,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Header): any);
