// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type { OnlineStoreCouponItem } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_COUPON_ITEM_REMOVE_REQUEST =
  'ONLINE_STORE_COUPON_ITEM_REMOVE_REQUEST';
export const ONLINE_STORE_COUPON_ITEM_REMOVE_SUCCESS =
  'ONLINE_STORE_COUPON_ITEM_REMOVE_SUCCESS';
export const ONLINE_STORE_COUPON_ITEM_REMOVE_FAILURE =
  'ONLINE_STORE_COUPON_ITEM_REMOVE_FAILURE';

function removeOnlineStoreCouponItemRequest() {
  return {
    type: ONLINE_STORE_COUPON_ITEM_REMOVE_REQUEST,
  };
}

function removeOnlineStoreCouponItemSuccess(couponItemId: number) {
  return {
    type: ONLINE_STORE_COUPON_ITEM_REMOVE_SUCCESS,
    id: couponItemId,
  };
}

function removeOnlineStoreCouponItemFailure(error: string) {
  return {
    type: ONLINE_STORE_COUPON_ITEM_REMOVE_FAILURE,
    error: error,
  };
}

export const removeOnlineStoreCouponItem = (
  couponItem: OnlineStoreCouponItem
): any => async (dispatch, getState) => {
  try {
    const { id, couponId, itemId } = couponItem;

    refreshToken(dispatch);
    dispatch(removeOnlineStoreCouponItemRequest());

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/coupons/${couponId}/items/${itemId}`,
    });

    dispatch(removeOnlineStoreCouponItemSuccess(id));
    return response;
  } catch (error) {
    dispatch(removeOnlineStoreCouponItemFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
