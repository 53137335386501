//@flow
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import Breadcrumb from '../components/Breadcrumb';
import UserSettingsForm from '../components/UserSettingsForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { State as AppState, Dispatch } from '../types';
import { updateUser } from '../actions/user/updateUser';

type User = {
  name?: string,
  email?: string,
  phone?: string,
};

type Props = {
  user: User,
  updateUser: (formData: User) => Promise<null>,
};

class Settings extends React.Component<Props> {
  handleUpdate = (formData) => {
    this.props.updateUser(formData);
  };

  render() {
    return (
      <Container>
        <Grid>
          <Grid.Column width={12}>
            <Breadcrumb
              topLevelContext="Home"
              currentContext={'User Settings'}
              backLink="/organisations"
            />
            <div className="single-form">
              <h3 className="light-title">Basic Information</h3>
              <UserSettingsForm
                handleFormSubmit={this.handleUpdate}
                user={this.props.user}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  user: state.auth.user.user,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      updateUser,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Settings): any);
