// @flow
import type { Organisation } from '../types/organisations';
import * as actions from '../actions/globalUsers/loadUserOrganisations';
import { createSelector } from 'reselect';
import type { UserOrganisations } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';
import { userOrganisationMembershipType } from '../helpers';

/* Initial State */

const initialState: UserOrganisations = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const userOrganisations = (
  state: UserOrganisations = initialState,
  action: Object
):
  | any
  | UserOrganisations
  | {
      byId: { [key: string]: Organisation },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [key: string]: Organisation },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    } => {
  switch (action.type) {
    case actions.GLOBAL_USER_ORGANISATIONS_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.GLOBAL_USER_ORGANISATIONS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.GLOBAL_USER_ORGANISATIONS_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */

export const getUserOrganisationsSortedAlphabetically: any = createSelector(
  (state) => state.userOrganisations.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);

/**
 * Get the Users Primary Organisation
 *
 * The primary organisation is the organisations where membershipTypeId === 1
 */
export const getPrimaryUserOrganisation: any = createSelector(
  (state) => state.userOrganisations.byId,
  (items) => {
    if (items === undefined) {
      return null;
    }
    let organisation = null;
    Object.values(items).forEach((item) => {
      if (
        item &&
        item.membershipTypeId ===
          userOrganisationMembershipType.PRIMARY_ORGANISATION
      ) {
        organisation = item;
      }
    });

    return organisation;
  }
);
