// @flow
import * as actions from '../../actions';
import { createSelector } from 'reselect';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: any = {
  items: {},
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const searchableAudienceImports = (
  state: any = initialState,
  action: any
): any => {
  switch (action.type) {
    case actions.SEARCHABLE_AUDIENCE_IMPORTS_LOAD_REQUEST:
    case actions.SEARCHABLE_AUDIENCE_IMPORT_READ_REQUEST:
    case actions.START_SEARCHABLE_AUDIENCE_IMPORT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.SEARCHABLE_AUDIENCE_IMPORTS_LOAD_FAILURE:
    case actions.SEARCHABLE_AUDIENCE_IMPORT_READ_FAILURE:
    case actions.START_SEARCHABLE_AUDIENCE_IMPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.SEARCHABLE_AUDIENCE_IMPORTS_LOAD_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.SEARCHABLE_AUDIENCE_IMPORT_READ_SUCCESS:
    case actions.START_SEARCHABLE_AUDIENCE_IMPORT_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload
        },
        isFetching: false,
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getSearchableAudienceImports: any = createSelector(
  (state) => state.searchableAudienceImports,
  (imports) => {
    const { items, isFetching, error } = imports;

    if (items === undefined) {
      return [];
    }
    
    let itemArray = Object.keys(items).map(key => items[key]);

   return {
    imports: itemArray,
    isFetching,
    error
   };
  }
);
