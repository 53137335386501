// @flow

// This is a temporary duplication of the function that exists
// in roy-morgan-auth
// TODO: Update this project to work with the latest `roy-morgan-auth` project
export const getProductUrl = (
  productId: string,
  env: ?string
): string => {
  switch (productId) {
    case '1':
      // Live Management
      return '/';
    case '2':
      // Audiences
      switch (env) {
        case 'dev':
          return '//audiences.roymorgandev.com';
        case 'uat':
          return '//audiences.roymorganuat.com';
        default:
          return '//audiences.roymorgan.com';
      }
    case '3':
      // Search
      switch (env) {
        case 'dev':
          return '//search.roymorgandev.com';
        case 'uat':
          return '//search.roymorganuat.com';
        default:
          return '//search.roymorgan.com';
      }
    case '4':
      // Live Mapping
      switch (env) {
        case 'prod':
          return '//mapping.roymorgan.com';
        case 'uat':
          return '//livemapping.roymorganuat.com';
        case 'dev':
        default:
          return '//mapping.roymorgandev.com';
      }
    case '5':
      // Helix Encoding
      return '/';
    case '6':
      // Helix 2.0
      switch (env) {
        case 'prod':
          return '//helix.roymorgan.com';
        case 'uat':
          return '//helix.roymorganuat.com';
        default:
          return '//helix.roymorgandev.com';
      }
    case '9':
      // Online Store
      switch (env) {
        case 'prod':
          return '//store.roymorgan.com';
        case 'uat':
          return '//store.roymorganuat.com';
        default:
          return '//store.roymorgandev.com';
      }
    case '10':
      // Premium
      switch (env) {
        case 'prod':
          return '//premium.roymorgan.com';
        case 'uat':
          return '//premium.roymorganuat.com';
        default:
          return '//premium.roymorgandev.com';
      }
    case '-1':
      // Helix 2.0
      return '/no-access';
    default:
      return '/';
  }
};
