// @flow
import {
  getAuthToken,
  refreshToken as refresh,
  logOut,
  getCurrentOrganisationId,
  getCurrentTeamId,
} from 'roy-morgan-auth';
import type { Dispatch } from '../types';

// TODO: break this out into a reusable package - working across all RoyMorgan projects
type props = {
  method: string,
  endpoint: string,
  body?: Object,
};

type APIError = Error & {
  code?: string,
};

export const callAPI = async ({
  method,
  endpoint,
  body,
}: props): Promise<any> => {
  try {
    const targetUrl = `${endpoint}`;
    const token = getAuthToken();

    const headers = new Headers({
      token,
      'context-organisation': getCurrentOrganisationId(),
      'context-team': getCurrentTeamId(),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    });

    const response = await fetch(targetUrl, {
      method,
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      // TODO: Log error to sentry
      const errorResponse = await response.json();
      const error: APIError = Error(
        errorResponse.errors ? errorResponse.errors[0].message : 'API Error'
      );
      error.code = 'API';
      throw error;
    }
    const json = await response.json();

    return json;
  } catch (e) {
    // TODO: Log error to sentry
    if (e.code === 'API') {
      throw e;
    }
    throw new Error('API Error');
  }
};

/**
 * Handle refreshing the token.
 *
 * This should be called before each API request.
 * If ther request was unsucessful the user is logged out and redirected to the
 * root path.
 */
export const refreshToken = async (dispatch: Dispatch) => {
  if (process.env.NODE_ENV !== 'test') {
    const result = await dispatch(refresh());
    if (!result) {
      dispatch(logOut());
      window.location.href = '/';
    }
  }
};
