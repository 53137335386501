// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import type { ThunkAction } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORDER_PAYMENT_STATUS_LOAD_REQUEST =
  'ORDER_PAYMENT_STATUS_LOAD_REQUEST';
export const ORDER_PAYMENT_STATUS_LOAD_SUCCESS =
  'ORDER_PAYMENT_STATUS_LOAD_SUCCESS';
export const ORDER_PAYMENT_STATUS_LOAD_FAILURE =
  'ORDER_PAYMENT_STATUS_LOAD_FAILURE';

const loadOrderPaymentStatusRequest = (): any => ({
  type: ORDER_PAYMENT_STATUS_LOAD_REQUEST,
});

const loadOrderPaymentStatusSuccess = (formats: any): any => ({
  type: ORDER_PAYMENT_STATUS_LOAD_SUCCESS,
  payload: formats,
});

const loadOrderPaymentStatusFailure = (error: string): any => ({
  type: ORDER_PAYMENT_STATUS_LOAD_FAILURE,
  error,
});

export const loadOrderPaymentStatus = (): ThunkAction => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrderPaymentStatusRequest());

    const response: any[] | Error = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/options/paymentstatus`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload: any = normaliseArrayOfObjects(response);

    dispatch(loadOrderPaymentStatusSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrderPaymentStatusFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
