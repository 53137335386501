// @flow
import * as Sentry from '@sentry/browser';
import type {
  OrganisationTeamCollaborator,
  State as AppState,
  Dispatch,
} from '../../types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';
import PaginatedTable from '../PaginatedTable';
import PageLoader from '../PageLoader';
import {
  addOrganisationCollaboratorToOrganisationTeam,
  loadOrganisationTeamCollaborators,
} from '../../actions/organisations/organisationTeams/organisationTeamCollaborators';
import { loadOrganisationCollaborators } from '../../actions/organisations/organisationCollaborators';
import { getOrganisationCollaboratorsSortedAlphabetically } from '../../reducers/organisationCollaborators';

type Props = {
  organisationId: number,
  teamId: number,
  collaborators: Array<OrganisationTeamCollaborator>,
  loadOrganisationCollaborators: (oid: number) => Promise<*>,
  addOrganisationCollaboratorToOrganisationTeam: (
    oid: number,
    tid: number,
    uid: number
  ) => Promise<*>,
  loadOrganisationTeamCollaborators: (oid: number, tid: number) => Promise<*>,
};

type State = {
  collaboratorFilterValue: string,
  isAdding: boolean,
  isAddingId?: number,
  initialLoadComplete: boolean,
};

class AddTeamCollaborator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      collaboratorFilterValue: '',
      isAdding: false,
      isAddingId: undefined,
      initialLoadComplete: false,
    };
  }

  componentDidMount() {
    const { loadOrganisationCollaborators, organisationId } = this.props;

    loadOrganisationCollaborators(organisationId).then(
      (success) =>
        this.setState({
          initialLoadComplete: true,
        }),
      (error) =>
        this.setState({
          initialLoadComplete: true,
        })
    );
  }

  handleAddCollaborator = async (userId: number) => {
    try {
      const {
        organisationId,
        teamId,
        addOrganisationCollaboratorToOrganisationTeam,
        loadOrganisationCollaborators,
        loadOrganisationTeamCollaborators,
      } = this.props;

      this.setState({
        isAdding: true,
        isAddingId: userId,
      });

      const addResult = await addOrganisationCollaboratorToOrganisationTeam(
        organisationId,
        teamId,
        userId
      );

      if (addResult) {
        await loadOrganisationCollaborators(organisationId);
        loadOrganisationTeamCollaborators(organisationId, teamId);
      }

      this.setState({
        isAdding: false,
        isAddingId: undefined,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      this.setState({
        isAdding: false,
        isAddingId: undefined,
      });
    }
  };

  render() {
    const { collaborators } = this.props;
    const collaboratorFilterValue = this.state.collaboratorFilterValue.toLowerCase();
    const { initialLoadComplete } = this.state;

    let rows: Array<any> = [];

    if (collaborators) {
      rows = collaborators.filter(
        (collaborator: OrganisationTeamCollaborator) => {
          const collaboratorName = collaborator.name.toLowerCase();
          return (
            collaboratorName === collaboratorFilterValue ||
            collaboratorName.includes(collaboratorFilterValue) ||
            collaboratorFilterValue === ''
          );
        }
      );
      rows = rows.map((collaborator: OrganisationTeamCollaborator) => {
        return (
          <Table.Row key={collaborator.id}>
            <Table.Cell>{collaborator.name}</Table.Cell>
            <Table.Cell>
              <Button
                onClick={() => this.handleAddCollaborator(collaborator.id)}
                loading={this.state.isAddingId === collaborator.id}
                disabled={this.state.isAdding}
                floated="right"
              >
                Add
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell colSpan={2}>Name</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        {initialLoadComplete ? (
          <PaginatedTable headerRow={tableHeader} rows={rows} />
        ) : (
          <PageLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  collaborators: getOrganisationCollaboratorsSortedAlphabetically(state),
  isFetching: state.organisationCollaborators.isFetching,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationCollaborators,
      addOrganisationCollaboratorToOrganisationTeam,
      loadOrganisationTeamCollaborators,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeamCollaborator): any);
