// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_USERS_WITHOUT_ACCESS_LOAD_REQUEST = 'API_USERS_WITHOUT_ACCESS_LOAD_REQUEST';
export const API_USERS_WITHOUT_ACCESS_LOAD_SUCCESS = 'API_USERS_WITHOUT_ACCESS_LOAD_SUCCESS';
export const API_USERS_WITHOUT_ACCESS_LOAD_FAILURE = 'API_USERS_WITHOUT_ACCESS_LOAD_FAILURE';

const loadUsersWithoutApiAccessRequest = () => ({
  type: API_USERS_WITHOUT_ACCESS_LOAD_REQUEST,
});

const loadUsersWithoutApiAccessSuccess = (users) => ({
  type: API_USERS_WITHOUT_ACCESS_LOAD_SUCCESS,
  payload: users,
});

const loadUsersWithoutApiAccessFailure = (error) => ({
  type: API_USERS_WITHOUT_ACCESS_LOAD_FAILURE,
  error,
});

export const loadUsersWithoutApiAccess = (
  apiId: number, 
  organisationId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadUsersWithoutApiAccessRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/apis/${apiId}/organisations/${organisationId}/users-without-access`,
    });

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadUsersWithoutApiAccessSuccess(normalisedPayload));
  } catch (error) {
    dispatch(loadUsersWithoutApiAccessFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};
