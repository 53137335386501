// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadUsers } from '../../actions/globalUsers';
import type { Dispatch } from '../../types';
import { Container, Grid, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import TableActions from '../../components/TableActions';
import { getUsersSortedAlphabetically } from '../../reducers/users';
import PageLoader from '../../components/PageLoader';
import PaginatedTable from '../../components/PaginatedTable';
import Breadcrumb from '../../components/Breadcrumb';
import type { User, AuthTypes } from '../../types';
import {
  mapOrganisationAccessToPermission,
  restrictUserPermissions,
  permissions,
  switchTo
} from 'roy-morgan-auth';
import moment from 'moment';
import {
  // $FlowFixMe
  sortChangeHandler,
  // $FlowFixMe
  sortData,
} from '../../helpers';

type Props = {
  permission: ?AuthTypes,
  loadUsers: () => Promise<*>,
  switchTo: (
    email: string,
    switchFromEmail: string,
    switchFromToken?: string
  ) => Promise<void>,
  users: Array<Object>,
  isFetching: boolean,
  switchFromEmail: string,
};

type State = {
  filterValue: string,
  sortColumn: string,
  sortDirection: string,
};

class Users extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      sortColumn: '',
      sortDirection: '',
    };
  }

  componentDidMount() {
    const { loadUsers } = this.props;
    loadUsers();
  }

  handleFilterChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  switchToClick = (email: string, switchFromEmail: string) => {
    const { switchTo } = this.props;
    switchTo(email, switchFromEmail).then((success) =>
      window.location.replace('/')
    );
  };

  handleSortChange = (clickedColumn: any) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  render() {
    const { sortColumn, sortDirection } = this.state;
    const { users, isFetching, permission, switchFromEmail } = this.props;

    const isInternalUser = restrictUserPermissions([permissions.INTERNAL_ADMIN], permission);

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'userName' ? sortDirection : null}
          onClick={this.handleSortChange('userName')}
        >
          Name
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'email' ? sortDirection : null}
          onClick={this.handleSortChange('email')}
        >
          Email
        </Table.HeaderCell>
        <Table.HeaderCell
          className="table__hide-on-mobile"
          sorted={sortColumn === 'dateCreated' ? sortDirection : null}
          onClick={this.handleSortChange('dateCreated')}
        >
          Date Created
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'lastLoginDateTime' ? sortDirection : null}
          onClick={this.handleSortChange('lastLoginDateTime')}
        >
          Last Login
        </Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
        {isInternalUser && <Table.HeaderCell>Switch To</Table.HeaderCell>}
      </Table.Row>
    );

    const filterValue = this.state.filterValue.toLowerCase() || '';

    let rows = users.filter((user: User) => {
      const userName = user.name.toLowerCase();
      const email = user.email.toLowerCase();
      return (
        userName === filterValue ||
        userName.includes(filterValue) ||
        email === filterValue ||
        email.includes(filterValue) ||
        filterValue === ''
      );
    });

    rows = rows.sort(this.sort).map((user: User) => {
      return (
        <Table.Row key={user.id}>
          <Table.Cell>{user.name}</Table.Cell>
          <Table.Cell>{user.email}</Table.Cell>
          <Table.Cell className="table__hide-on-mobile">
            {moment
              .utc(user.dateCreated)
              .local()
              .format('YYYY-MM-DD')
              .toString()}
          </Table.Cell>
          <Table.Cell>
            {user.lastLoginDateTime
              ? moment
                  .utc(user.lastLoginDateTime)
                  .local()
                  .format('YYYY-MM-DD HH:mm')
                  .toString()
              : '-'}
          </Table.Cell>
          <Table.Cell>
            <Link to={`/users/${user.id}/details`}>View</Link>
          </Table.Cell>
          {isInternalUser && (
            <Table.Cell>
              <button
                className="switch-to"
                onClick={() => this.switchToClick(user.email, switchFromEmail)}
              >
                Switch To
              </button>
            </Table.Cell>
          )}
        </Table.Row>
      );
    });

    return (
      <Container>
        <Grid centered stackable>
          <Grid.Column>
            <div className="page-content">
              <Breadcrumb currentContext="Users" />
              <div className="table-container">
                <TableActions
                  filterAction={this.handleFilterChange}
                  filterPlaceholder="Filter Users..."
                />
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    perPage={100}
                    headerRow={TableHeader}
                    rows={rows}
                    filterValue={filterValue}
                    sortable={true}
                  />
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    users: getUsersSortedAlphabetically(state),
    isFetching: state.users.isFetching,
    permission: state.auth && state.auth.user && state.auth.user.user
      ? mapOrganisationAccessToPermission(true, state.auth.user.user.accountTypeId)
      : permissions.NO_ACCESS,
    switchFromEmail:
      state.auth &&
      state.auth.user &&
      state.auth.user.user &&
      state.auth.user.user.email,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadUsers,
      switchTo,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Users): any);
