// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type { UpdateOnlineStoreTreeCategoryForm } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINESTORETREECATEGORY_UPDATE_REQUEST =
  'ONLINESTORETREECATEGORY_UPDATE_REQUEST';
export const ONLINESTORETREECATEGORY_UPDATE_SUCCESS =
  'ONLINESTORETREECATEGORY_UPDATE_SUCCESS';
export const ONLINESTORETREECATEGORY_UPDATE_FAILURE =
  'ONLINESTORETREECATEGORY_UPDATE_FAILURE';

function updateOnlineStoreCategoryRequest() {
  return {
    type: ONLINESTORETREECATEGORY_UPDATE_REQUEST,
  };
}

function updateOnlineStoreCategorySuccess(category) {
  return {
    type: ONLINESTORETREECATEGORY_UPDATE_SUCCESS,
    payload: category,
  };
}

function updateOnlineStoreCategoryFailure(error: string) {
  return {
    type: ONLINESTORETREECATEGORY_UPDATE_FAILURE,
    error: error,
  };
}

export const updateOnlineStoreTreeCategory = (
  categoryId: number,
  body: UpdateOnlineStoreTreeCategoryForm
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOnlineStoreCategoryRequest());

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/category/${categoryId}`,
      body: body,
    });
    if (response instanceof Error) {
      throw response;
    }

    dispatch(updateOnlineStoreCategorySuccess(response));
    return response;
  } catch (error) {
    error.message = error.message || 'System Internal Error';
    error.errorMessage = true;
    dispatch(updateOnlineStoreCategoryFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
