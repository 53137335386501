// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type { OnlineStoreOrder, OnlineStoreOrderForm } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_ORDER_UPDATE_REQUEST =
  'ONLINE_STORE_ORDER_UPDATE_REQUEST';
export const ONLINE_STORE_ORDER_UPDATE_SUCCESS =
  'ONLINE_STORE_ORDER_UPDATE_SUCCESS';
export const ONLINE_STORE_ORDER_UPDATE_FAILURE =
  'ONLINE_STORE_ORDER_UPDATE_FAILURE';

function updateOnlineStoreOrderRequest() {
  return {
    type: ONLINE_STORE_ORDER_UPDATE_REQUEST,
  };
}

function updateOnlineStoreOrderSuccess(onlineStoreOrder: OnlineStoreOrder) {
  return {
    type: ONLINE_STORE_ORDER_UPDATE_SUCCESS,
    payload: onlineStoreOrder,
  };
}

function updateOnlineStoreOrderFailure(error: string) {
  return {
    type: ONLINE_STORE_ORDER_UPDATE_FAILURE,
    error: error,
  };
}

export const updateOnlineStoreOrder = (
  onlineStoreId: number,
  body: OnlineStoreOrderForm
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOnlineStoreOrderRequest());
    //const today = new Date();

    const requestBody = {
      ...body,
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/orders/${onlineStoreId}`,
      body: requestBody,
    });

    dispatch(updateOnlineStoreOrderSuccess(response));
    return response;
  } catch (error) {
    dispatch(updateOnlineStoreOrderFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
