// @flow
import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import type { Element } from 'React';

type Props = {
  message: string
};

function ErrorMessage({ message }: Props): Element<'div'> {
  return (
    <div className="">
      <div className="not-auth-message">
        <Message icon style={{ backgroundColor: 'white' }}>
          <Icon name="exclamation circle" size="large" />
          <div>
            <h2>Error</h2>
            <p>{message}</p>
          </div>
        </Message>
      </div>
    </div>
  );
}

export default ErrorMessage;
