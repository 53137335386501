//@flow
import React from 'react';
import TableActions from '../../components/TableActions';
import { Table, Loader, Button, Message } from 'semantic-ui-react';
import PaginatedTable from '../../components/PaginatedTable';
import FileButton from '../../components/FileButton';
import { uploadOnlineStoreOrderItem, sendPickupEmail } from '../../actions';
import moment from 'moment';

import type {
  // State as AppState,
  // Dispatch,
  OnlineStoreOrderItem,
  AuthTypes,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import { managementFeatures } from '../../helpers';
import {
  getPermissionInFeature,
  restrictUserPermissions,
  permissions,
} from 'roy-morgan-auth';
import { getOnlineStoreOrderItems } from '../../reducers/onlineStoreOrderItems';

type Props = {
  permission: ?AuthTypes,
  onlineStoreOrderId: number,
  onlineStoreOrderItems: Array<OnlineStoreOrderItem>,
  isFetching: boolean,
  uploadOnlineStoreOrderItem: (
    orderItemId: number,
    orderItemFile: any
  ) => Promise<OnlineStoreOrderItem>,
  sendPickupEmail: (onlineStoreOrderId: number) => Promise<boolean>,
};

// type State = {
//   uploadingItem: ?number,
//   sendPickupEmailSuccess: boolean
// };

class OnlineStoreOrderItems extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      uploadingItem: null,
      sendPickupEmailSuccess: false,
      sendPickupEmailButtonPressed: false,
    };
  }

  handleUpload = (orderItemId: number) => (file) => {
    this.setState({
      uploadingItem: orderItemId,
    });

    this.props.uploadOnlineStoreOrderItem(orderItemId, file).then((res) => {
      this.setState({
        uploadingItem: null,
      });
    });
  };

  isUploading = (orderItemId: ?number) => {
    const { uploadingItem } = this.state;
    return orderItemId ? uploadingItem === orderItemId : !!uploadingItem;
  };

  sendPickupEmail = (orderId: number) => {
    this.props.sendPickupEmail(orderId).then((res) => {
      this.setState({
        sendPickupEmailButtonPressed: true,
        sendPickupEmailSuccess: res,
      });
    });
  };

  render() {
    const { onlineStoreOrderItems, isFetching, permission } = this.props;
    const { INTERNAL_ADMIN, STANDARD_USER } = permissions;

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell>SKU ID</Table.HeaderCell>
        <Table.HeaderCell>Item Name</Table.HeaderCell>
        <Table.HeaderCell>Country</Table.HeaderCell>
        <Table.HeaderCell>Time Period</Table.HeaderCell>
        <Table.HeaderCell>Delivery Date</Table.HeaderCell>
        <Table.HeaderCell>Price</Table.HeaderCell>
        <Table.HeaderCell>Uploaded File</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        {!restrictUserPermissions(
          [INTERNAL_ADMIN, STANDARD_USER],
          permission
        ) ? (
          <Table.HeaderCell />
        ) : (
          <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
        )}
      </Table.Row>
    );

    let rows = onlineStoreOrderItems;

    rows = rows.map((orderItem: any) => {
      const deliveryDate = orderItem.deliveryDate
        ? moment.utc(orderItem.deliveryDate).local().format('YYYY-MM-DD LT')
        : '';

      if (
        !restrictUserPermissions(
          [INTERNAL_ADMIN, STANDARD_USER],
          permission
        )
      ) {
        return (
          <Table.Row key={orderItem.id}>
            <Table.Cell>{orderItem.skuNumber}</Table.Cell>
            <Table.Cell>{orderItem.name}</Table.Cell>
            <Table.Cell>{orderItem.country}</Table.Cell>
            <Table.Cell>
              {orderItem.timePeriod ? orderItem.timePeriod : ''}
            </Table.Cell>
            <Table.Cell>{deliveryDate.toString()}</Table.Cell>
            <Table.Cell>{orderItem.total}</Table.Cell>
            <Table.Cell>{orderItem.refName}</Table.Cell>
            <Table.Cell>{orderItem.orderStatus}</Table.Cell>
          </Table.Row>
        );
      }
      return (
        <Table.Row key={orderItem.id}>
          <Table.Cell>{orderItem.skuNumber}</Table.Cell>
          <Table.Cell>{orderItem.name}</Table.Cell>
          <Table.Cell>{orderItem.country}</Table.Cell>
          <Table.Cell>
            {orderItem.timePeriod ? orderItem.timePeriod : ''}
          </Table.Cell>
          <Table.Cell>
            {
              // $FlowFixMe
              deliveryDate.toLocaleString('en-AU')
            }
          </Table.Cell>
          <Table.Cell>{orderItem.total}</Table.Cell>
          <Table.Cell>
            {this.isUploading(orderItem.id) ? (
              <Loader active inline="centered" />
            ) : (
              orderItem.refName
            )}
          </Table.Cell>
          <Table.Cell>{orderItem.orderStatus}</Table.Cell>
          <Table.Cell>
            <FileButton
              disabled={this.isUploading(orderItem.id)}
              content="Upload"
              onSelect={this.handleUpload(orderItem.id)}
            />
          </Table.Cell>
        </Table.Row>
      );
    });

    if (typeof this.props.onlineStoreOrderId === 'undefined') {
      return <Redirect to={`/storeorders`} />;
    } else {
      return (
        <div className="tabbed-content">
          <TableActions title="Store Order Items" />
          <div className="page-content">
            {isFetching && !this.isUploading() ? (
              <PageLoader />
            ) : (
              <div>
                <PaginatedTable headerRow={TableHeader} rows={rows} />

                {!restrictUserPermissions(
                  [INTERNAL_ADMIN, STANDARD_USER],
                  permission
                ) ? (
                  ''
                ) : (
                  <div>
                    <Button
                      className="organisation-form__submit"
                      onClick={(e) =>
                        this.sendPickupEmail(this.props.onlineStoreOrderId)
                      }
                    >
                      Send Pickup Email
                    </Button>
                    {this.state.sendPickupEmailButtonPressed ? (
                      <Message
                        color={
                          this.state.sendPickupEmailSuccess ? 'green' : 'red'
                        }
                        content={
                          this.state.sendPickupEmailSuccess
                            ? 'Email sent'
                            : 'Email sent failed'
                        }
                      />
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: any, props) => {
  const orderItems =
    state.onlineStoreOrders.byId[props.onlineStoreOrderId].items;
  return {
    isFetching: state.onlineStoreOrders.isFetching,
    // $FlowFixMe
    onlineStoreOrderItems: orderItems
      ? getOnlineStoreOrderItems(orderItems)
      : [],
    permission: getPermissionInFeature(
      managementFeatures.ONLINE_STORE_MANAGEMENT,
      state.auth.authorisation
    ),
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      uploadOnlineStoreOrderItem,
      sendPickupEmail,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreOrderItems): any);
