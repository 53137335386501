// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_COUPON_ITEMS_LOAD_REQUEST =
  'ONLINE_STORE_COUPON_ITEMS_LOAD_REQUEST';
export const ONLINE_STORE_COUPON_ITEMS_LOAD_SUCCESS =
  'ONLINE_STORE_COUPON_ITEMS_LOAD_SUCCESS';
export const ONLINE_STORE_COUPON_ITEMS_LOAD_FAILURE =
  'ONLINE_STORE_COUPON_ITEMS_LOAD_FAILURE';

const loadOnlineStoreCouponItemsRequest = () => ({
  type: ONLINE_STORE_COUPON_ITEMS_LOAD_REQUEST,
});

const loadOnlineStoreCouponItemsSuccess = (onlineStoreCouponItems) => ({
  type: ONLINE_STORE_COUPON_ITEMS_LOAD_SUCCESS,
  payload: onlineStoreCouponItems,
});

const loadOnlineStoreCouponItemsFailure = (error) => ({
  type: ONLINE_STORE_COUPON_ITEMS_LOAD_FAILURE,
  error,
});

export const loadOnlineStoreCouponItems = (couponId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOnlineStoreCouponItemsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/coupons/${couponId}/items`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOnlineStoreCouponItemsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOnlineStoreCouponItemsFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
