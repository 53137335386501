// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadPendingIssues, updatePendingIssue } from '../../actions';
import type { AuthTypes } from '../../types';
import { Container, Grid, Table } from 'semantic-ui-react';
import OverflowMenu from '../../components/OverflowMenu';
import { Link } from 'react-router-dom';
import PaginatedTable from '../../components/PaginatedTable';
import Breadcrumb from '../../components/Breadcrumb';
import TableActions from '../../components/TableActions';
import PageLoader from '../../components/PageLoader';
import { managementFeatures } from '../../helpers';
import {
  getPermissionInFeature,
  restrictUserPermissions,
  permissions,
} from 'roy-morgan-auth';
// $FlowFixMe
import { sortChangeHandler, sortData } from '../../helpers';
import { getPendingIssuesSortedByProductName } from '../../reducers/pendingIssues';

type Props = {
  loadPendingIssues: () => Promise<boolean>,
  pendingIssues: Array<Object>,
  isFetching: boolean,
  permission: ?AuthTypes,
  error: any,
  updatePendingIssue: (id: number, body: Object) => Promise<any>,
};

type State = any;

class OnlineStoreManagementHome extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      sortColumn: '',
      sortDirection: '',
    };
  }

  componentDidMount() {
    const { loadPendingIssues } = this.props;
    loadPendingIssues();
  }

  handleFilterChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  handleSortChange = (clickedColumn: any) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  handleResolvePendingIssue = (id: number) => {
    let form = { isResolved: true };
    this.props.updatePendingIssue(id, form).then((success) => {
      this.props.loadPendingIssues();
    });
  };

  render() {
    const { pendingIssues, isFetching, permission } = this.props;
    const { sortColumn, sortDirection } = this.state;
    const { INTERNAL_ADMIN, STANDARD_USER } = permissions;

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'productName' ? sortDirection : null}
          onClick={this.handleSortChange('productName')}
        >
          Product
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'description' ? sortDirection : null}
          onClick={this.handleSortChange('description')}
        >
          Description
        </Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    );

    const filterValue = this.state.filterValue.toLowerCase() || '';

    let rows = pendingIssues
      .filter((pendingIssue: any) => {
        const pendingIssueProduct = pendingIssue.productName.toLowerCase();
        const pendingIssueDescription = pendingIssue.description.toLowerCase();
        return (
          pendingIssueProduct === filterValue ||
          pendingIssueProduct.includes(filterValue) ||
          pendingIssueDescription === filterValue ||
          pendingIssueDescription.includes(filterValue) ||
          filterValue === ''
        );
      })
      .sort(this.sort);

    rows = rows.map((pendingIssue: any) => {
      return (
        <Table.Row key={pendingIssue.id}>
          <Table.Cell>{pendingIssue.productName}</Table.Cell>
          <Table.Cell>{pendingIssue.description}</Table.Cell>
          <Table.Cell>
            <OverflowMenu>
              <li key="View">
                <Link
                  to={`/storeorders/${pendingIssue.details.orderId}/details`}
                  tabIndex="-1"
                >
                  View
                </Link>
              </li>
              <li key="Resolve">
                {/* // TODO: replace anchor with button // */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  tabIndex="-1"
                  onKeyPress={() => {
                    this.handleResolvePendingIssue(pendingIssue.id);
                  }}
                  onClick={() => {
                    this.handleResolvePendingIssue(pendingIssue.id);
                  }}
                >
                  Resolve
                </a>
              </li>
            </OverflowMenu>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        {restrictUserPermissions(
          [INTERNAL_ADMIN, STANDARD_USER],
          permission
        ) ? (
          <Container data-testid="organisations-container">
            <Grid centered stackable>
              <Grid.Column>
                <div className="page-content">
                  <Breadcrumb currentContext="Dashboard" />
                  <div className="table-container">
                    <TableActions
                      title="Pending Issues"
                      filterAction={this.handleFilterChange}
                      filterPlaceholder="Filter Pending Issues..."
                    />
                    {isFetching ? (
                      <PageLoader />
                    ) : (
                      <PaginatedTable
                        headerRow={TableHeader}
                        rows={rows}
                        perPage={100}
                      />
                    )}
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        ) : (
          <div className="test-helper">Dashboard</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  pendingIssues: getPendingIssuesSortedByProductName(state),
  isFetching: state.pendingIssues.isFetching,
  permission: getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    state.auth.authorisation
  ),
  error: state.pendingIssues.error,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadPendingIssues,
      updatePendingIssue,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreManagementHome): any);
