// @flow
import * as Sentry from '@sentry/browser';
import { getWorkspaceApiBaseURL } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';

export const GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_REQUEST = 'GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_REQUEST';
export const GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_SUCCESS = 'GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_SUCCESS';
export const GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_FAILURE = 'GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_FAILURE';

const getNextSearchableAudienceImportDetailsRequest = () => ({
  type: GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_REQUEST,
});

const getNextSearchableAudienceImportDetailsSuccess = (nextImportDetails) => ({
  type: GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_SUCCESS,
  payload: nextImportDetails,
});

const getNextSearchableAudienceImportDetailsFailure = (error) => ({
  type: GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_FAILURE,
  error,
});

export const getNextSearchableAudienceImportDetails = (): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(getNextSearchableAudienceImportDetailsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getWorkspaceApiBaseURL('v1')}/management/searchable-audiences/next-import`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(getNextSearchableAudienceImportDetailsSuccess(response));

    return true;
  } catch (error) {
    dispatch(getNextSearchableAudienceImportDetailsFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
