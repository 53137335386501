// @flow
import * as Sentry from '@sentry/browser';
// import type { Dispatch, Action } from '../../types';

export const ONLINESTOREITEM_CHANGE = 'ONLINESTOREITEM_CHANGE';

const changeOnlineStoreItemAction = () => ({
  type: ONLINESTOREITEM_CHANGE,
});

export const changeOnlineStoreItem = (): any => async (dispatch) => {
  try {
    dispatch(changeOnlineStoreItemAction());
    return true;
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return false;
  }
};
