// @flow
import * as actions from '../actions/globalUsers';
import { createSelector } from 'reselect';
import type { State, UserAccountTypes } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */
const initialState: any = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

/* Reducer */
export const userAccountTypes = (
  state: any = initialState,
  action: any
): UserAccountTypes => {
  switch (action.type) {
    case actions.USER_ACCOUNT_TYPES_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.USER_ACCOUNT_TYPES_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.USER_ACCOUNT_TYPES_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getUserAccountTypes: any = createSelector(
  (state: State) => state.userAccountTypes,
  (items) => {
    if (items.byId === undefined) {
      return [];
    }
    return items.order
      .map(function (key) {
        return items.byId[key.toString()];
      });
  }
);
