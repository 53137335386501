// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_READ_REQUEST = 'ORGANISATION_READ_REQUEST';
export const ORGANISATION_READ_SUCCESS = 'ORGANISATION_READ_SUCCESS';
export const ORGANISATION_READ_FAILURE = 'ORGANISATION_READ_FAILURE';

const readOrganisationRequest = (): Object => ({
  type: ORGANISATION_READ_REQUEST,
});

const readOrganisationSuccess = (organisation): Object => ({
  type: ORGANISATION_READ_SUCCESS,
  payload: organisation,
});

const readOrganisationFailure = (error): Object => ({
  type: ORGANISATION_READ_FAILURE,
  error,
});

export const readOrganisation = (organisationId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(readOrganisationRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(readOrganisationSuccess(response));

    return true;
  } catch (error) {
    dispatch(readOrganisationFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
