// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_COUPON_REMOVE_REQUEST =
  'ONLINE_STORE_COUPON_REMOVE_REQUEST';
export const ONLINE_STORE_COUPON_REMOVE_SUCCESS =
  'ONLINE_STORE_COUPON_REMOVE_SUCCESS';
export const ONLINE_STORE_COUPON_REMOVE_FAILURE =
  'ONLINE_STORE_COUPON_REMOVE_FAILURE';

function removeOnlineStoreCouponRequest() {
  return {
    type: ONLINE_STORE_COUPON_REMOVE_REQUEST,
  };
}

function removeOnlineStoreCouponSuccess(onlineStoreCouponId: number) {
  return {
    type: ONLINE_STORE_COUPON_REMOVE_SUCCESS,
    id: onlineStoreCouponId,
  };
}

function removeOnlineStoreCouponFailure(error: string) {
  return {
    type: ONLINE_STORE_COUPON_REMOVE_FAILURE,
    error: error,
  };
}

export const removeOnlineStoreCoupon = (
  onlineStoreCouponId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOnlineStoreCouponRequest());

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/coupons/${onlineStoreCouponId}`,
    });

    dispatch(removeOnlineStoreCouponSuccess(onlineStoreCouponId));
    return response;
  } catch (error) {
    dispatch(removeOnlineStoreCouponFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
