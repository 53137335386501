// @flow
import * as actions from '../actions/onlineStore';
import { createSelector } from 'reselect';
import type {} from // OnlineStoreCouponItemsActions,
// OnlineStoreCouponItems
'../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: any = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const onlineStoreCouponItems = (
  state: any = initialState,
  action: any
): any => {
  switch (action.type) {
    case actions.ONLINE_STORE_COUPON_ITEMS_LOAD_REQUEST:
    case actions.ONLINE_STORE_COUPON_ITEM_ADD_REQUEST:
    case actions.ONLINE_STORE_COUPON_ITEM_REMOVE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ONLINE_STORE_COUPON_ITEMS_LOAD_FAILURE:
      return {
        ...state,
        ...initialState,
        isFetching: false,
        error: action.error,
      };
    case actions.ONLINE_STORE_COUPON_ITEM_ADD_FAILURE:
    case actions.ONLINE_STORE_COUPON_ITEM_REMOVE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.ONLINE_STORE_COUPON_ITEMS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINE_STORE_COUPON_ITEM_ADD_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        isFetching: false,
        error: undefined,
      };
    /* eslint-disable no-case-declarations */
    case actions.ONLINE_STORE_COUPON_ITEM_REMOVE_SUCCESS:
      let copy = state.byId;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.id];
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.id);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    /* eslint-enable no-case-declarations */
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOnlineStoreCouponItemsSortedAlphabetically: any = createSelector(
  (state) => state.onlineStoreCouponItems.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        let skuNumberA = items[a].itemId;
        let skuNumberB = items[b].itemId;
        if (skuNumberA < skuNumberB) return -1;
        if (skuNumberA > skuNumberB) return 1;
        return 0;
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
