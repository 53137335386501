// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_REQUEST =
  'ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_REQUEST';
export const ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_SUCCESS =
  'ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_SUCCESS';
export const ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_FAILURE =
  'ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_FAILURE';

const addOrganisationUserToOrganisationTeamRequest = (): Object => ({
  type: ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_REQUEST,
});

const addOrganisationUserToOrganisationTeamSuccess = (users): Object => ({
  type: ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_SUCCESS,
  payload: users,
});

const addOrganisationUserToOrganisationTeamFailure = (error): Object => ({
  type: ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_FAILURE,
  error,
});

export const addOrganisationUserToOrganisationTeam = (
  organisationId: number,
  teamId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(addOrganisationUserToOrganisationTeamRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}/users`,
      body: {
        userId,
      },
    });

    dispatch(addOrganisationUserToOrganisationTeamSuccess(response));

    return true;
  } catch (error) {
    dispatch(addOrganisationUserToOrganisationTeamFailure(error.message));
    console.error(error);
    Sentry.captureException(error);

    return false;
  }
};
