// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_TOTAL_USAGE_REQUEST =
  'ORGANISATION_TOTAL_USAGE_REQUEST';
export const ORGANISATION_TOTAL_USAGE_SUCCESS =
  'ORGANISATION_TOTAL_USAGE_SUCCESS';
export const ORGANISATION_TOTAL_USAGE_FAILURE =
  'ORGANISATION_TOTAL_USAGE_FAILURE';

const totalUsageRequest = () => ({
  type: ORGANISATION_TOTAL_USAGE_REQUEST,
});

const totalUsageSuccess = (response) => ({
  type: ORGANISATION_TOTAL_USAGE_SUCCESS,
  payload: response,
});

const totalUsageFailure = (error) => ({
  type: ORGANISATION_TOTAL_USAGE_FAILURE,
  error,
});

export const totalUsage = (
  organisationId: number,
  domain: string,
  filterRm?: boolean
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(totalUsageRequest());

    // define query params
    let queryArr = [];
    // filter domains from results
    if (domain) queryArr.push(`domain=${domain}`);
    // modify query string to filter out RM email users from org total data
    if (filterRm) queryArr.push(`filterRm=true`);

    // append query params
    let queryString = '';
    if (queryArr.length > 0) {
      queryString += `?${queryArr.join('&').trim()}`;
    }

    const baseUrl = `${getApiBaseURL('v2', 'management')}`;
    const response = await callAPI({
      method: 'GET',
      endpoint: `${baseUrl}/organisations/${organisationId}/usage/total${queryString}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(totalUsageSuccess(response));
    return response;
  } catch (error) {
    dispatch(totalUsageFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
