// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { Dispatch } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USER_FORCE_VERIFY_REQUEST =
  'ORGANISATION_USER_FORCE_VERIFY_REQUEST';
export const ORGANISATION_USER_FORCE_VERIFY_SUCCESS =
  'ORGANISATION_USER_FORCE_VERIFY_SUCCESS';
export const ORGANISATION_USER_FORCE_VERIFY_FAILURE =
  'ORGANISATION_USER_FORCE_VERIFY_FAILURE';

function forceVerifyOrganisationUserRequest() {
  return {
    type: ORGANISATION_USER_FORCE_VERIFY_REQUEST,
  };
}

function forceVerifyOrganisationUserSuccess(user) {
  return {
    type: ORGANISATION_USER_FORCE_VERIFY_SUCCESS,
    payload: user,
  };
}

function forceVerifyOrganisationUserFailure(error) {
  return {
    type: ORGANISATION_USER_FORCE_VERIFY_FAILURE,
    error: error,
  };
}

export const forceVerifyOrganisationUser = (
  organisationId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(forceVerifyOrganisationUserRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${userId}/forceverify`,
    });

    dispatch(forceVerifyOrganisationUserSuccess(response));
    return response;
  } catch (error) {
    dispatch(forceVerifyOrganisationUserFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
      Sentry.captureException(error);
    }

    return error;
  }
};
