// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../../helpers';
import { callAPI, refreshToken } from '../../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_TEAMS_LOAD_REQUEST =
  'ORGANISATION_COLLABORATOR_TEAMS_LOAD_REQUEST';
export const ORGANISATION_COLLABORATOR_TEAMS_LOAD_SUCCESS =
  'ORGANISATION_COLLABORATOR_TEAMS_LOAD_SUCCESS';
export const ORGANISATION_COLLABORATOR_TEAMS_LOAD_FAILURE =
  'ORGANISATION_COLLABORATOR_TEAMS_LOAD_FAILURE';

const loadOrganisationCollaboratorTeamsRequest = () => ({
  type: ORGANISATION_COLLABORATOR_TEAMS_LOAD_REQUEST,
});

const loadOrganisationCollaboratorTeamsSuccess = (teams) => ({
  type: ORGANISATION_COLLABORATOR_TEAMS_LOAD_SUCCESS,
  payload: teams,
});

const loadOrganisationCollaboratorTeamsFailure = (error) => ({
  type: ORGANISATION_COLLABORATOR_TEAMS_LOAD_FAILURE,
  error,
});

export const loadOrganisationCollaboratorTeams = (
  organisationId: number,
  collaboratorId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationCollaboratorTeamsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/users/${collaboratorId}/organisations/${organisationId}/teams?excludeArchived=true`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOrganisationCollaboratorTeamsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationCollaboratorTeamsFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
