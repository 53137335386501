//@flow
import React from 'react';
import type { Element } from 'React';
import type { Feature } from '../types';

import { Dropdown, Table } from 'semantic-ui-react';
import HierarchyTable from './HierarchyTable';
import StaticField from './StaticField';
import { featureAccess } from '../helpers';

type Props = {
  features: Array<Feature>,
  canUpdate: boolean,
  updateAccess: (featureId: number, hasAccess: ?boolean) => void
};

export default function FeatureAccessTable(props: Props) : Element<any> {
  const {
    features,
    canUpdate,
    updateAccess
  } = props;

  const { GRANT, DENY, INHERIT } = featureAccess;

  const resolvedAccessOption = (feature: Feature): string => {
    return feature.hasAccess ? GRANT : DENY;
  };

  const selectedAccessOption = (feature: Feature): string => {
    if(feature.isInheritedFromDefault) {
      return INHERIT;
    }

    return resolvedAccessOption(feature);
  };

  const handleAccessChange = (feature, object) => {
    let hasAccess = object.value === 0 
      ? null 
      : object.value;
    updateAccess(feature.id, hasAccess);
  };

  const TableHeader = (
    <Table.Row>
      <Table.HeaderCell />
      <Table.HeaderCell width={2}>
        Access Level
      </Table.HeaderCell>
      <Table.HeaderCell width={3}>
        Resolved Level
      </Table.HeaderCell>
    </Table.Row>
  );

  const FeatureName = (feature) => (
    <div className="product-row__title">{feature.name}</div>
  );

  const AccessLevel = (feature) => (
    <div>
      {canUpdate ? (
        <Dropdown
          id={feature.id}
          loading={feature.isFetching}
          onChange={(e, object) => {
            handleAccessChange(feature, object);
          }}
          className="product-row__dropdown"
          value={
            feature.isInheritedFromDefault
              ? 0
              : feature.hasAccess
          }
          selection
          options={[
            { text: GRANT, value: true },
            { text: DENY, value: false },
            { text: INHERIT, value: 0 },
          ]}
        />
      ) : (
        <StaticField
          label=""
          value={selectedAccessOption(feature)}
        />
      )}
    </div>
  );

  const ResolvedLevel = (feature) => (
    <div>
      <StaticField
        label=""
        value={resolvedAccessOption(feature)}
      />
    </div>
  );

  const columns = [FeatureName, AccessLevel, ResolvedLevel];

  return (
    <HierarchyTable
      perPage={1000}
      headerRow={TableHeader}
      columns={columns}
      items={features}
      rowClassName="product-row"
    />
  );
}

