// @flow

import moment from "moment";

export const formatDate = (date: ?string): string => {
  return date
    ? moment
      .utc(date)
      .local()
      .format('YYYY-MM-DD HH:mm')
      .toString()
    : '-';
};
