// @flow
import type { OrganisationTeamUser } from '../types/organisationTeamUsers';
import * as rootActions from '../actions';
import { createSelector } from 'reselect';
import type { OrganisationTeamUsers } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: OrganisationTeamUsers = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const organisationTeamUsers = (
  state: OrganisationTeamUsers = initialState,
  action: Object
):
  | any
  | OrganisationTeamUsers
  | { byId: { ... }, error: void, isFetching: boolean, order: Array<any>, ... }
  | {
      byId: { [string]: OrganisationTeamUser },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationTeamUser },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      ...
    }
  | {
      byId: { [key: string]: OrganisationTeamUser },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      ...
    } => {
  switch (action.type) {
    case 'ORGANISATION_TEAM_USERS_LOAD_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'ORGANISATION_TEAM_USERS_LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_TEAM_USERS_LOAD_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    /* eslint-disable no-case-declarations */
    case 'ORGANISATION_USER_TEAM_REMOVE_SUCCESS':
      let copy = state.byId;
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.userId];
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.userId);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    /* eslint-enable no-case-declarations */
    case rootActions.ORGANISATION_CHANGE:
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */

export const getUsersSortedAlphabetically: any = createSelector(
  (state) => state.organisationTeamUsers.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
