//@flow
import * as React from 'react';
import TableActions from '../../components/TableActions';
import { Table, Confirm } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import PaginatedTable from '../../components/PaginatedTable';
import { getOrganisationUsersSortedAlphabetically } from '../../reducers/organisationUsers';
import { getOrganisationTeamsSortedAlphabetically } from '../../reducers/organisationTeams';
import type {
  State as AppState,
  Dispatch,
  OrganisationUser,
  OrganisationUserForm,
  SubMenuItem,
  AuthTypes, Organisation as OrgType, OrganisationUserFormWithTeams
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadOrganisationUsers,
  inviteOrganisationUser,
  removeOrganisationUser,
  forceVerifyOrganisationUser,
  resendInvitationEmailOrganisationUser,
} from '../../actions/organisations/organisationUsers';
import { loadAllOrganisationTeams } from '../../actions/organisations/organisationTeams';
import { addOrganisationUserToOrganisationTeam } from '../../actions/organisations/organisationTeams/organisationTeamUsers';
import InviteUserFormFields from '../../components/OrganisationUser/InviteUserFormFields';
import TabHeader from '../../components/TabHeader';
import OverflowMenu from '../../components/OverflowMenu';
import { Link, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import {
  restrictUserPermissions,
  permissions,
  switchTo,
} from 'roy-morgan-auth';
import {
  // $FlowFixMe
  sortChangeHandler,
  // $FlowFixMe
  sortData,
  formatDate
} from '../../helpers';

type Props = {
  organisationTeams: [],
  organisationUsers: Array<OrganisationUser>,
  userFilterValue: string,
  organisation: OrgType,
  organisationId: number,
  subItems: Array<SubMenuItem>,
  history: RouterHistory,
  permission: ?AuthTypes,
  loadOrganisationUsers: (oid: number) => Promise<boolean>,
  addOrganisationUserToOrganisationTeam: (oid: number, tid: number, uid: number)=> Promise<boolean>,
  inviteOrganisationUser: (
    oid: number,
    formData: OrganisationUserForm
  ) => Promise<OrganisationUser>,
  removeOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  forceVerifyOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  loadAllOrganisationTeams: (
    oid: number,
    teamAccess: ?boolean
  ) => Promise<boolean>,
  resendInvitationEmailOrganisationUser: (
    oid: number,
    uid: number
  ) => Promise<boolean>,
  isFetching: boolean,
  switchFromEmail: string,
  switchTo: (
    email: string,
    switchFromEmail: string,
    switchFromToken?: string
  ) => Promise<void>,
};

type State = {
  userFilterValue: string,
  removeUserOpen: boolean,
  forceVerifyOpen: boolean,
  resendInvitationEmailOpen: boolean,
  forceVerifyTarget?: {
    id: number,
    name: string,
  },
  removeUserTarget?: {
    id: number,
    name: string,
  },
  resendInvitationEmailTarget?: {
    id: number,
    name: string,
  },
  sortColumn: string,
  sortDirection: string,
};

class Users extends React.Component<Props, State> {
  csvLink: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      userFilterValue: '',
      removeUserOpen: false,
      forceVerifyOpen: false,
      resendInvitationEmailOpen: false,
      forceVerifyTarget: undefined,
      removeUserTarget: undefined,
      resendInvitationEmailTarget: undefined,
      sortColumn: '',
      sortDirection: '',
    };

    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.props.loadOrganisationUsers(this.props.organisationId);
    this.props.loadAllOrganisationTeams(this.props.organisationId);
  }

  handleUserSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      userFilterValue: e.target.value,
    });
  };

  handleFormSubmit =  (formData: OrganisationUserFormWithTeams) => {
    let userInvitationForm = {
      name: formData.name,
      email: formData.email,
      canAccessAllTeams: formData.teamsToInclude.length < 1
    }
    this.props.inviteOrganisationUser(this.props.organisationId, userInvitationForm)
      .then(
        (userCreated: OrganisationUser)=>{
          if (formData.teamsToInclude.length > 0) {
            Promise.all(formData.teamsToInclude.map(tim => {
              this.props.addOrganisationUserToOrganisationTeam(this.props.organisationId,tim, userCreated.id);
              return true;
            })).then( ()=>{
              this.props.history.push(`/organisations/${this.props.organisationId}/users/${userCreated.id}/details`)
            }, (failure) => {})
          } else {
            this.props.history.push(`/organisations/${this.props.organisationId}/users/${userCreated.id}/details`);
          }
        },
      (failure) => {}
      )
    };

  setForceVerifyModalVisible = (userId, userName) => {
    this.setState({
      forceVerifyOpen: true,
      forceVerifyTarget: { id: userId, name: userName },
    });
  };

  setRemoveUserModalVisible = (userId, userName) => {
    this.setState({
      removeUserOpen: true,
      removeUserTarget: { id: userId, name: userName },
    });
  };

  switchToClick = (email: ?string, switchFromEmail: string) => {
    const { switchTo } = this.props;
    if (email) {
      switchTo(email, switchFromEmail).then((success) =>
        window.location.replace('/')
      );
    }
  };

  setResendInvitationEmailModalVisible = (userId, userName) => {
    this.setState({
      resendInvitationEmailOpen: true,
      resendInvitationEmailTarget: { id: userId, name: userName },
    });
  };

  handleForceVerify = () => {
    if (this.state.forceVerifyTarget) {
      this.props.forceVerifyOrganisationUser(
        this.props.organisationId,
        this.state.forceVerifyTarget.id
      );
    }
    this.setState({
      forceVerifyOpen: false,
      forceVerifyTarget: undefined,
    });
  };

  handleResendInvitationEmail = () => {
    if (this.state.resendInvitationEmailTarget) {
      this.props.resendInvitationEmailOrganisationUser(
        this.props.organisationId,
        this.state.resendInvitationEmailTarget.id
      );
    }
    this.setState({
      resendInvitationEmailOpen: false,
      resendInvitationEmailTarget: undefined,
    });
  };

  handleRemoveUser = () => {
    if (this.state.removeUserTarget) {
      this.props.removeOrganisationUser(
        this.props.organisationId,
        this.state.removeUserTarget.id
      );
    }
    this.setState({
      removeUserOpen: false,
      removeUserTarget: undefined,
    });
  };

  renderSwitchButton(user) {
    switch (user.status.toLowerCase()) {
      case 'active':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() => this.setForceVerifyModalVisible(user.id, user.name)}
            onKeyPress={() =>
              this.setForceVerifyModalVisible(user.id, user.name)
            }
          >
            Force Email Verification
          </a>
        );
      case 'pending':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() =>
              this.setResendInvitationEmailModalVisible(user.id, user.name)
            }
            onKeyPress={() =>
              this.setResendInvitationEmailModalVisible(user.id, user.name)
            }
          >
            Resend Welcome
          </a>
        );
      case 'locked':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() => this.setForceVerifyModalVisible(user.id, user.name)}
            onKeyPress={() =>
              this.setForceVerifyModalVisible(user.id, user.name)
            }
          >
            Resend Email Verification
          </a>
        );
      default:
        return '';
    }
  }

  handleSortChange = (clickedColumn: any) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  exportUsers = () => {
    this.csvLink.current.link.click();
  };

  render() {
    const { sortColumn, sortDirection } = this.state;
    const {
      organisationUsers,
      organisationId,
      subItems,
      isFetching,
      permission,
      switchFromEmail,
    } = this.props;
    const {
      INTERNAL_ADMIN,
      BUSINESS_ADMIN,
      SUPPORT_ADMIN,
      ACCOUNT_MANAGER_ADMIN,
      STANDARD_USER
    } = permissions;

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'userName' ? sortDirection : null}
          onClick={this.handleSortChange('userName')}
        >
          Name
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'email' ? sortDirection : null}
          onClick={this.handleSortChange('email')}
        >
          Email
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'lastLoginDateTime' ? sortDirection : null}
          onClick={this.handleSortChange('lastLoginDateTime')}
        >
          Last Login
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'status' ? sortDirection : null}
          onClick={this.handleSortChange('status')}
        >
          Status
        </Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    );

    const csvData = organisationUsers.map((user: OrganisationUser) => {
      return {
        'Name': user.name,
        'Email': user.email,
        'Last Login': formatDate(user.lastLoginDateTime),
        'Status': user.status
      };
    });

    const filterValue = this.state.userFilterValue.toLowerCase();

    let rows = organisationUsers.filter((user: any) => {
      const userName = user.name.toLowerCase();
      const email = user.email.toLowerCase();
      return (
        userName === filterValue ||
        userName.includes(filterValue) ||
        email === filterValue ||
        email.includes(filterValue) ||
        filterValue === ''
      );
    });

    rows = rows.sort(this.sort).map((user: OrganisationUser) => {
      const userEmail = user.email;
      return (
        <Table.Row key={user.id}>
          <Table.Cell>{user.name}</Table.Cell>
          <Table.Cell>{user.email}</Table.Cell>
          <Table.Cell>{formatDate(user.lastLoginDateTime)}</Table.Cell>
          <Table.Cell>{user.status}</Table.Cell>
          <Table.Cell>
            <OverflowMenu>
              <li>
                <Link
                  to={`/organisations/${organisationId}/users/${user.id}/details`}
                >
                  View
                </Link>
              </li>
              {restrictUserPermissions(
                [INTERNAL_ADMIN, BUSINESS_ADMIN, ACCOUNT_MANAGER_ADMIN],
                permission
              ) && <li>{this.renderSwitchButton(user)}</li>}
              {restrictUserPermissions([INTERNAL_ADMIN], permission) && (
                <li>
                  {/* // TODO: replace anchor with button // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      this.switchToClick(userEmail, switchFromEmail)
                    }
                    onKeyPress={() =>
                      this.switchToClick(userEmail, switchFromEmail)
                    }
                  >
                    Switch To
                  </a>
                </li>
              )}
              <li>
                {/* // TODO: replace anchor with button // */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  tabIndex="0"
                  onClick={() =>
                    this.setRemoveUserModalVisible(user.id, user.name)
                  }
                  onKeyPress={() =>
                    this.setRemoveUserModalVisible(user.id, user.name)
                  }
                >
                  Remove User
                </a>
              </li>
            </OverflowMenu>
          </Table.Cell>
        </Table.Row>
      );
    });

    const newSubItems = [
      ...subItems,
      {
        path: `/organisations/${organisationId}/users`,
        // break this out into own component possibly, and include in the same manner as done in /containers/Orgainsation.js
        container: () => (
          <div className="tabbed-content">
            <Confirm
              // remove user
              content={`Are you sure you want to remove ${
                this.state.removeUserTarget && this.state.removeUserTarget.name
                  ? this.state.removeUserTarget.name
                  : 'this user'
              } from the current organisation?`}
              open={this.state.removeUserOpen}
              onCancel={() =>
                this.setState({ ...this.state, removeUserOpen: false })
              }
              onConfirm={this.handleRemoveUser}
            />

            <Confirm
              // force verify or re-send email verification
              content={`Are you sure you want to force verify email or re-send email verification for ${
                this.state.forceVerifyTarget &&
                this.state.forceVerifyTarget.name
                  ? this.state.forceVerifyTarget.name
                  : 'this user'
              }?`}
              open={this.state.forceVerifyOpen}
              onCancel={() =>
                this.setState({ ...this.state, forceVerifyOpen: false })
              }
              onConfirm={this.handleForceVerify}
            />

            <Confirm
              // re-send welcome
              content={`Are you sure you want to re-send welcome email for ${
                this.state.resendInvitationEmailTarget &&
                this.state.resendInvitationEmailTarget.name
                  ? this.state.resendInvitationEmailTarget.name
                  : 'this user'
              }?`}
              open={this.state.resendInvitationEmailOpen}
              onCancel={() =>
                this.setState({
                  ...this.state,
                  resendInvitationEmailOpen: false,
                })
              }
              onConfirm={this.handleResendInvitationEmail}
            />

            <div>
              <TableActions
                title="Users"
                filterAction={this.handleUserSearch}
                filterPlaceholder="Search Users..."
                buttonText="Invite Users"
                buttonURL={`/organisations/${organisationId}/users/new`}
                downloadButtonAction={csvData.length === 0 ? null : this.exportUsers}
                showDownloadButton
              />
              <div className="page-content">
                {/* $FlowFixMe: TODO(DP): Fix this */}
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    perPage={100}
                    headerRow={TableHeader}
                    rows={rows}
                    sortable={true}
                    stackEarly
                  />
                )}
              </div>
            </div>
          </div>
        ),
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER
        ],
      },
      {
        path: `/organisations/${organisationId}/users/new`,
        container: () => (
          <div className="tabbed-content">
            <TabHeader
              tabHeading="Add New User to Organisation"
              // backAction={this.handleShowInviteUserForm}
              backLink={`/organisations/${organisationId}/users`}
              backText="Back to Users"
            />
            <InviteUserFormFields
              handleFormSubmit={this.handleFormSubmit}
              organisationCanHaveTeams={this.props.organisation.canHaveTeams}
              organisationTeams = {this.props.organisationTeams}
            />
          </div>
        ),
        acceptedPermissions: [
          INTERNAL_ADMIN,
          BUSINESS_ADMIN,
          SUPPORT_ADMIN,
          ACCOUNT_MANAGER_ADMIN,
          STANDARD_USER,
        ],
      },
    ];

    return (
      <div className="tabbed-content">
        {newSubItems.map((item, index) => {
          if (
            item.acceptedPermissions &&
            restrictUserPermissions(item.acceptedPermissions, permission)
          ) {
            return (
              <Route
                exact={true}
                key={index}
                path={item.path}
                render={item.container}
              />
            );
          }
          return '';
        })}
        <CSVLink
          data={csvData}
          filename={`organisation_${organisationId}_users`}
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        >
        </CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  isFetching: state.organisationUsers.isFetching,
  organisationUsers: getOrganisationUsersSortedAlphabetically(state),
  // isFetching: state.organisationTeams.isFetching,
  organisationTeams: getOrganisationTeamsSortedAlphabetically(state),
  switchFromEmail:
    state.auth &&
    state.auth.user &&
    state.auth.user.user &&
    state.auth.user.user.email,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationUsers,
      inviteOrganisationUser,
      removeOrganisationUser,
      forceVerifyOrganisationUser,
      resendInvitationEmailOrganisationUser,
      loadAllOrganisationTeams,
      addOrganisationUserToOrganisationTeam,
      switchTo,
    },
    dispatch
  );

// $FlowFixMe
export default (withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Users)
): any);
