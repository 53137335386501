// @flow
// $FlowFixMe
import * as actions from '../../actions/onlineStore/index';
import { createSelector } from 'reselect';
// import type { OnlineStoreItemFormatActions, OnlineStoreItemFormats } from '../../types/index';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: any = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const onlineStoreItemFormats = (
  state: any = initialState,
  action: any
): any => {
  switch (action.type) {
    // $FlowFixMe
    case actions.ITEM_FORMATS_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    // $FlowFixMe
    case actions.ITEM_FORMATS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    // $FlowFixMe
    case actions.ITEM_FORMATS_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOnlineStoreItemFormatsSortedAlphabetically: any = createSelector(
  (state) => state.onlineStoreItemFormats.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }
    return Object.keys(items)
      .sort(function (a, b) {
        if (items[a] === '' || items[a] === null) {
          return 1;
        } else if (items[b] === '' || items[b] === null) {
          return -1;
        } else if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
