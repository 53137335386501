// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_USER_ADD_REQUEST = 'API_USER_ADD_REQUEST';
export const API_USER_ADD_SUCCESS = 'API_USER_ADD_SUCCESS';
export const API_USER_ADD_FAILURE = 'API_USER_ADD_FAILURE';

const addUserAccessRequest = () => ({
  type: API_USER_ADD_REQUEST,
});

const addUserAccessSuccess = (user) => ({
  type: API_USER_ADD_SUCCESS,
  payload: user,
});

const addUserAccessFailure = (error) => ({
  type: API_USER_ADD_FAILURE,
  error,
});

export const addUserAccess = (
  apiId: number, 
  organisationId: number,
  user: any
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(addUserAccessRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${user.id}/apis`,
      body: {
        apiId
      }
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(addUserAccessSuccess(user));
  } catch (error) {
    dispatch(addUserAccessFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};