//@flow
import React from 'react';
import TableActions from '../../components/TableActions';
import { Table, Confirm } from 'semantic-ui-react';
import PaginatedTable from '../../components/PaginatedTable';
import { getUserOrganisationsSortedAlphabetically } from '../../reducers/userOrganisataions';
import type {
  State as AppState,
  Dispatch,
  Organisation,
  AuthTypes,
} from '../../types';
import { loadUserOrganisations } from '../../actions/globalUsers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import {
  forceVerifyOrganisationUser,
  removeOrganisationUser,
  resendInvitationEmailOrganisationUser,
} from '../../actions/organisations/organisationUsers';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import OverflowMenu from '../../components/OverflowMenu';

type Props = {
  userId: number,
  organisationId: number,
  permission: ?AuthTypes,
  user: any,
  organisations: Array<Organisation>,
  isFetching: boolean,
  loadUserOrganisations: (userId: number) => Promise<null>,
  forceVerifyOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  removeOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  resendInvitationEmailOrganisationUser: (
    oid: number,
    uid: number
  ) => Promise<boolean>,
};

type State = {
  organisationFilterValue: string,
  contextChange: boolean,
  forceVerifyOpen: boolean,
  removeUserOpen: boolean,
  resendInvitationEmailOpen: boolean,
  organisationId: number,
};

class UserOrganisations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      organisationFilterValue: '',
      contextChange: false,
      forceVerifyOpen: false,
      removeUserOpen: false,
      resendInvitationEmailOpen: false,
      organisationId: 0,
    };
  }

  componentDidMount() {
    this.props.loadUserOrganisations(this.props.userId);
  }

  handleOrganisationsSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      organisationFilterValue: e.target.value,
    });
  };

  setForceVerifyModalVisible = (organisationId) => {
    this.setState({
      ...this.state,
      forceVerifyOpen: true,
      organisationId: organisationId,
    });
  };

  handleForceVerify = () => {
    this.props.forceVerifyOrganisationUser(
      this.state.organisationId,
      this.props.userId
    );
    this.setState({ ...this.state, forceVerifyOpen: false });
  };

  setResendInvitationEmailModalVisible = (organisationId) => {
    this.setState({
      ...this.state,
      resendInvitationEmailOpen: true,
      organisationId: organisationId,
    });
  };

  handleResendInvitationEmail = () => {
    this.props.resendInvitationEmailOrganisationUser(
      this.state.organisationId,
      this.props.userId
    );
    this.setState({ ...this.state, resendInvitationEmailOpen: false });
  };

  setRemoveUserModalVisible = (organisationId) => {
    this.setState({
      ...this.state,
      removeUserOpen: true,
      organisationId: organisationId,
    });
  };

  handleRemoveUser = () => {
    this.props.removeOrganisationUser(
      this.state.organisationId,
      this.props.userId
    );
    this.setState({ ...this.state, removeUserOpen: false });
  };

  renderSwitchButton = (organisationId) => {
    switch (this.props.user.status.toLowerCase()) {
      case 'active':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() =>
              // $FlowFixMe
              this.setForceVerifyModalVisible(organisationId)
            }
            onKeyPress={() =>
              // $FlowFixMe
              this.setForceVerifyModalVisible(organisationId)
            }
          >
            Force Email Verification
          </a>
        );
      case 'pending':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() =>
              // $FlowFixMe
              this.setResendInvitationEmailModalVisible(organisationId)
            }
            onKeyPress={() =>
              // $FlowFixMe
              this.setResendInvitationEmailModalVisible(organisationId)
            }
          >
            Resend Welcome
          </a>
        );
      case 'locked':
        return (
          // TODO: replace anchor with button //
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="button"
            tabIndex="0"
            onClick={() =>
              // $FlowFixMe
              this.setForceVerifyModalVisible(organisationId)
            }
            onKeyPress={() =>
              // $FlowFixMe
              this.setForceVerifyModalVisible(organisationId)
            }
          >
            Resend Email Verification
          </a>
        );
      default:
        return '';
    }
  };

  RenderDeleteUserButton = (organisationId) => {
    return (
      // TODO: replace anchor with button //
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        tabIndex="-1"
        onClick={() => {
          this.setRemoveUserModalVisible(organisationId);
        }}
        onKeyPress={() => {
          this.setRemoveUserModalVisible(organisationId);
        }}
      >
        Remove User
      </a>
    );
  };

  render() {
    const { organisations, isFetching, userId, user, permission } = this.props;
    const organisationFilterValue = this.state.organisationFilterValue.toLowerCase();

    const { INTERNAL_ADMIN, BUSINESS_ADMIN } = permissions;
    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell>Organisations Name</Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    );

    let rows = organisations.filter((organisation: Organisation) => {
      const organisationName = organisation.name.toLowerCase();
      return (
        organisationName === organisationFilterValue ||
        organisationName.includes(organisationFilterValue) ||
        organisationFilterValue === ''
      );
    });
    rows = rows.map((organisation: Organisation) => {
      return (
        <Table.Row key={organisation.id}>
          <Table.Cell>{organisation.name}</Table.Cell>
          <Table.Cell>
            <OverflowMenu dark={true} leftAlign={true}>
              <Link
                to={`/organisations/${organisation.id}/users/${userId}/details`}
              >
                View
              </Link>
              {restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN], permission) && (
                <li>{this.renderSwitchButton(organisation.id)}</li>
              )}
              <li>{this.RenderDeleteUserButton(organisation.id)}</li>
            </OverflowMenu>
          </Table.Cell>
        </Table.Row>
      );
    });

    if (this.state.contextChange || typeof this.props.userId === 'undefined') {
      return <Redirect to={`/users`} />;
    } else {
      return (
        <div>
          <TableActions
            title="Collaborating Organisations"
            filterAction={this.handleOrganisationsSearch}
            filterPlaceholder="Search Organisations..."
          />
          {isFetching ? (
            <PageLoader />
          ) : (
            <PaginatedTable headerRow={TableHeader} rows={rows} />
          )}

          <Confirm
            // This confirmation box handles the Force Email Verification Action
            content="Are you sure you want to Force Email Verification for this user?"
            open={this.state.forceVerifyOpen}
            onCancel={() =>
              this.setState({ ...this.state, forceVerifyOpen: false })
            }
            onConfirm={this.handleForceVerify}
          />

          <Confirm
            // This confirmation box handles the re-send invitation email/welcome email action
            content="Are you sure you want to re-send welcome email for this user'?"
            open={this.state.resendInvitationEmailOpen}
            onCancel={() =>
              this.setState({ ...this.state, resendInvitationEmailOpen: false })
            }
            onConfirm={this.handleResendInvitationEmail}
          />

          <Confirm
            content={`Are you sure you want to remove ${
              user && user.email ? user.email : ''
            } `}
            open={this.state.removeUserOpen}
            onCancel={() =>
              this.setState({ ...this.state, removeUserOpen: false })
            }
            onConfirm={this.handleRemoveUser}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationUsers.isFetching,
  // $FlowFixMe
  organisations: getUserOrganisationsSortedAlphabetically(state),
  user: state.organisationUsers.byId[props.userId]
    ? state.organisationUsers.byId[props.userId]
    : state.users.byId[props.userId],
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadUserOrganisations,
      forceVerifyOrganisationUser,
      removeOrganisationUser,
      resendInvitationEmailOrganisationUser,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(UserOrganisations): any);
