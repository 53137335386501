// @flow
import * as actions from '../actions/user/selfRegister';
import type { Action, SelfRegistration } from '../types';

const intialState = {
  isFetching: false,
};

export const selfRegistration = (
  state: SelfRegistration = intialState,
  action: Action
): SelfRegistration => {
  switch (action.type) {
    case actions.SELF_REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.SELF_REGISTER_SUCCESS:
      return {
        ...state,
        user: {
          ...action.payload,
        },
        isFetching: false,
      };
    case actions.SELF_REGISTER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
