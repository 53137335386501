//@flow
import React from 'react';
import type { Organisation } from '../../types';
import OrganisationRelationshipFormFields from '../../components/Organisation/OrganisationRelationshipFormFields';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  updateOrganisation,
  loadOrganisationFlags,
  loadOrganisationAccountManagers,
  addOrganisationAccountManager,
  removeOrganisationAccountManager,
} from '../../actions';
import { getOrganisationFlagsSortedById } from '../../reducers/organisationFlags';
import { getOrganisationAccountManagersSortedAlphabetically } from '../../reducers/organisationAccountManagers';
import type {
  Dispatch,
  Organisation as OrgType,
  AuthTypes,
  OrganisationFlag as OrganisationFlagType,
  AccountManager as AccountManagerType,
  AccountManagers as AccountManagersType
} from '../../types';
import type { RouterHistory } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';


type Props = {
  organisation: OrgType,
  organisationId: number,
  accountManagers: Array<AccountManagerType>,
  organisationFlags: Array<OrganisationFlagType>,
  loadOrganisationFlags: () => Promise<OrganisationFlagType>,
  history: RouterHistory,
  updateOrganisation: (oid: number, body: Object) => Promise<Organisation>,
  loadOrganisationAccountManagers: (oid: number) => Promise<AccountManagersType>,
  addOrganisationAccountManager: (oid: number, uid: number) => Promise<AccountManagerType>,
  removeOrganisationAccountManager: (oid: number, uid: number) => Promise<boolean>,
  isFetching: boolean,
  permission: ?AuthTypes,
};

type State = {
  forceVerifyOpen: boolean,
  archiveOrganisationOpen: boolean,
};

class Relationship extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      forceVerifyOpen: false,
      archiveOrganisationOpen: false,
    };
  }

  componentDidMount() {
    const { permission, organisationId } = this.props;
    const { INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN,  ACCOUNT_MANAGER_ADMIN } = permissions;

    if (restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN], permission)) {
      this.props.loadOrganisationFlags();
    }

    if (organisationId &&
      restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN], permission)
    ) {
      this.props.loadOrganisationAccountManagers(organisationId);
    }
  }

  handleOrganisationUpdate = (data) => {
    this.props.updateOrganisation(this.props.organisationId, data);
  };

  render() {
    const { isFetching } = this.props;

    return (
      <div className="tabbed-content">
        <h3 className="light-title">Relationship</h3>
        {isFetching ? (
          <PageLoader />
        ) : (
          <div>
            <OrganisationRelationshipFormFields
              handleFormSubmit={this.handleOrganisationUpdate}
              organisation={this.props.organisation}
              permission={this.props.permission}
              accountManagerAdditionAction={this.props.addOrganisationAccountManager}
              accountManagerRemovalAction={this.props.removeOrganisationAccountManager}
              industries={null}
              organisationFlags={this.props.organisationFlags}
              accountManagers={this.props.accountManagers}
            >
            </OrganisationRelationshipFormFields>
          </div>
        )}
      </div>
    );
  }
}



const mapStateToProps = (state, props) => ({
  isFetching: state.organisations.isFetching || state.organisationAccountManagers.isFetching,
  accountManagers: getOrganisationAccountManagersSortedAlphabetically(state),
  organisationFlags: getOrganisationFlagsSortedById(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateOrganisation,
      loadOrganisationFlags,
      loadOrganisationAccountManagers,
      addOrganisationAccountManager,
      removeOrganisationAccountManager,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Relationship): any);
