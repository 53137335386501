// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { readUser } from '../../actions/globalUsers/readUser';
import type {
  Dispatch,
  User as UserState,
  State as AppState,
} from '../../types';
import Breadcrumb from '../../components/Breadcrumb';
import SideMenu from '../../components/SideMenu';
import { mapOrganisationAccessToPermission } from 'roy-morgan-auth';
import { UserDetails, UserOrganisations } from '../GlobalUserTabs';
import type { AuthTypes } from '../../types';
import { managementFeatures } from '../../helpers';
import { setFetchSyncReady } from '../../actions';
import { getIsReady } from '../../reducers/fetchSync';

type Props = {
  user: UserState,
  permission: ?AuthTypes,
  setSelectedUser: (uid: number) => number,
  match: {
    params: {
      tab?: string,
      userId: number,
      teamId?: string,
      collaboratorId?: string,
    },
    url: string,
  },
  readUser: (userId: number) => Promise<null>,
  isReady: boolean,
  setReadUserReady: () => void,
};

type State = {
  contextChange: boolean,
};

class User extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      contextChange: false,
    };
  }
  componentDidMount() {
    const { match, readUser } = this.props;
    readUser(match.params.userId).finally(this.props.setReadUserReady);
  }

  render() {
    const { user, match, permission, isReady } = this.props;
    const currentUrl = match.url;
    const userId = match.params.userId;
    const items = [
      {
        label: 'Details',
        path: `/users/${userId}/details`,
        container: () => <UserDetails userId={userId} />,
      },
      {
        label: 'Organisations',
        path: `/users/${userId}/organisations`,
        container: () => (
          <UserOrganisations
            userId={userId}
            props={this.props}
            permission={permission}
          />
        ),
      },
    ];

    if (!isReady) {
      return null;
    }

    if (this.state.contextChange || typeof user === 'undefined') {
      return <Redirect to="/users" />;
    } else {
      return (
        <Container>
          <Grid>
            <Grid.Column width={12}>
              <Breadcrumb
                titleClass={'breadcrumb__current-title--lower-case'}
                topLevelContext="All Users"
                currentContext={user.email}
                backLink="/users"
              />
            </Grid.Column>
          </Grid>
          <Grid className="side-menu-layout">
            <Grid.Column width={3} className="side-menu-column">
              <SideMenu
                currentPath={currentUrl}
                items={items}
                permission={permission}
              />
            </Grid.Column>
            <Grid.Column width={9}>
              <Grid>
                <Grid.Column>
                  {items.map((item, index) => {
                    return (
                      <Route
                        key={index}
                        path={item.path}
                        render={item.container}
                      />
                    );
                  })}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state: AppState, props) => {
  const { LIVE_MANAGEMENT } = managementFeatures;

  let hasAccess = state.auth.authorisation[`${LIVE_MANAGEMENT}`]
    ? state.auth.authorisation[`${LIVE_MANAGEMENT}`].hasAccess
    : false;

  return {
    user: state.users.byId[props.match.params.userId],
    permission: mapOrganisationAccessToPermission(
      hasAccess,
      state.auth.user.user.accountTypeId
    ),
    isReady: getIsReady(state, 'readUser'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      readUser,
      setReadUserReady: setFetchSyncReady('readUser'),
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(User): any);
