// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../../helpers';
import { callAPI, refreshToken } from '../../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USER_ORGANISATIONS_LOAD_REQUEST =
  'ORGANISATION_USER_ORGANISATIONS_LOAD_REQUEST';
export const ORGANISATION_USER_ORGANISATIONS_LOAD_SUCCESS =
  'ORGANISATION_USER_ORGANISATIONS_LOAD_SUCCESS';
export const ORGANISATION_USER_ORGANISATIONS_LOAD_FAILURE =
  'ORGANISATION_USER_ORGANISATIONS_LOAD_FAILURE';

const loadOrganisationUserOrganisationsRequest = (): Object => ({
  type: ORGANISATION_USER_ORGANISATIONS_LOAD_REQUEST,
});

const loadOrganisationUserOrganisationsSuccess = (teams): Object => ({
  type: ORGANISATION_USER_ORGANISATIONS_LOAD_SUCCESS,
  payload: teams,
});

const loadOrganisationUserOrganisationsFailure = (error): Object => ({
  type: ORGANISATION_USER_ORGANISATIONS_LOAD_FAILURE,
  error,
});

export const loadOrganisationUserOrganisations = (
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationUserOrganisationsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/users/${userId}/organisations?excludeArchived=true`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOrganisationUserOrganisationsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationUserOrganisationsFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
