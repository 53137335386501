// @flow
import React from 'react';
import { Grid, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import OverflowMenu from '../components/OverflowMenu';
import type { Node } from 'React';

type Props = {
  title?: string,
  filterPlaceholder?: string,
  filterAction?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  hideButton?: boolean,
  buttonArray: Array<Object>,
};

class TableActions extends React.Component<Props> {
  render(): Node {
    const { title, filterAction, filterPlaceholder, buttonArray } = this.props;
    return (
      <Grid className="table-actions" stackable columns={2}>
        <Grid.Column
          className={
            title
              ? 'table-actions__title-container'
              : 'table-actions__title-container--hidden'
          }
        >
          <h3 className="table-actions__title" data-testid="title">
            {title}
          </h3>
        </Grid.Column>
        <Grid.Column className="table-actions__right-column">
          {filterAction ? (
            <Input
              className="table-actions__input"
              icon="search"
              iconPosition="left"
              onChange={filterAction}
              placeholder={filterPlaceholder}
              data-testid="filter-input"
            />
          ) : (
            ''
          )}

          {buttonArray.length > 0 ? (
            <OverflowMenu>
              {buttonArray.map(function (b) {
                if (!b.hideButton && b.buttonURL) {
                  return (
                    <li key={b.buttonText}>
                      <Link to={b.buttonURL} tabIndex="-1">
                        {b.buttonText}
                      </Link>
                    </li>
                  );
                }
                if (!b.hideButton && b.buttonAction) {
                  return (
                    <li key={b.buttonText}>
                      {/* // TODO: replace anchor with button // */}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        role="button"
                        tabIndex="-1"
                        onKeyPress={b.buttonAction}
                        onClick={b.buttonAction}
                      >
                        {b.buttonText}
                      </a>
                    </li>
                  );
                }
                return '';
              })}
            </OverflowMenu>
          ) : (
            ''
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

export default TableActions;
