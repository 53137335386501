// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../../helpers';
import { callAPI, refreshToken } from '../../../../middleware';
import type {
  ThunkAction,
  OrganisationCollaboratorFeaturesResponse,
  Action,
  Feature,
} from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_FEATURES_LOAD_REQUEST =
  'ORGANISATION_COLLABORATOR_FEATURES_LOAD_REQUEST';
export const ORGANISATION_COLLABORATOR_FEATURES_LOAD_SUCCESS =
  'ORGANISATION_COLLABORATOR_FEATURES_LOAD_SUCCESS';
export const ORGANISATION_COLLABORATOR_FEATURES_LOAD_FAILURE =
  'ORGANISATION_COLLABORATOR_FEATURES_LOAD_FAILURE';

const loadOrganisationCollaboratorFeaturesRequest = (): Action => ({
  type: ORGANISATION_COLLABORATOR_FEATURES_LOAD_REQUEST,
});

const loadOrganisationCollaboratorFeaturesSuccess = (
  features: OrganisationCollaboratorFeaturesResponse
): Action => ({
  type: ORGANISATION_COLLABORATOR_FEATURES_LOAD_SUCCESS,
  payload: features,
});

const loadOrganisationCollaboratorFeaturesFailure = (
  error: string
): Action => ({
  type: ORGANISATION_COLLABORATOR_FEATURES_LOAD_FAILURE,
  error,
});

export const loadOrganisationCollaboratorFeatures = (
  organisationId: number,
  collaboratorId: number
): ThunkAction => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationCollaboratorFeaturesRequest());

    const response: Feature[] | Error = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/collaborators/${collaboratorId}/features`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload: OrganisationCollaboratorFeaturesResponse = normaliseArrayOfObjects(
      response
    );

    dispatch(loadOrganisationCollaboratorFeaturesSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationCollaboratorFeaturesFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
