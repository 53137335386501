//@flow
import React from 'react';
import type {
  State as AppState,
  Feature,
  Organisation,
  AuthTypes,
  Dispatch,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadOrganisationUserFeatures,
  updateOrganisationUserFeature,
} from '../../actions/organisations/organisationUsers/organisationUserFeatures';
import { getOrganisationUserFeatures } from '../../reducers/organisationUserFeatures';
import TableActions from '../../components/TableActions';
import PageLoader from '../../components/PageLoader';
import FeatureAcessTable from '../../components/FeatureAccessTable';
import {
  // $FlowFixMe
  sortChangeHandler,
  // $FlowFixMe
  sortData
} from '../../helpers';

type Props = {
  organisation: Organisation,
  userId: number,
  features: Array<Feature>,
  permission: ?AuthTypes,
  loadOrganisationUserFeatures: (oid: number, uid: number) => Promise<null>,
  updateOrganisationUserFeature: (
    oid: number,
    uid: number,
    pid: number,
    body: { hasAccess: ?boolean }
  ) => Promise<null>,
  isFetching: boolean,
};

class UserAccess extends React.Component<Props> {
  componentDidMount() {
    this.props.loadOrganisationUserFeatures(
      this.props.organisation.id,
      this.props.userId
    );
  }

  updateUserFeatureAccess = (featureId: number, hasAccess: ?boolean) => {
    this.props.updateOrganisationUserFeature(
      this.props.organisation.id,
      this.props.userId,
      featureId,
      { hasAccess }
    );
  };

  handleSortChange = (clickedColumn: any) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  render() {
    const { features, isFetching } = this.props;

    return (
      <div>
        <TableActions title="Product Access" />
        {isFetching ? (
          <PageLoader />
        ) : (
          <FeatureAcessTable
            features={features}
            canUpdate={true}
            updateAccess={this.updateUserFeatureAccess}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  isFetching: state.organisationUserFeatures.isFetching,
  features: getOrganisationUserFeatures(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationUserFeatures,
      updateOrganisationUserFeature,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccess): any);
