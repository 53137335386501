//@flow
import React, { useState } from 'react';
import { Table, Modal, Button, Grid } from 'semantic-ui-react';

import PageLoader from './PageLoader';
import PaginatedTable from './PaginatedTable';
import TableActions from './TableActions';

import type { Element } from 'React';

type Props = {
  title: string,
  items: Array<any>,
  isFetchingItems: boolean,
  isOpen: boolean,
  onSubmit: (item: any) => Promise<any>,
  onClose: () => any,
};

export default function AddItemModal(props: Props) : Element<any> {
  const {
    onSubmit,
    onClose,
    isOpen,
    items,
    isFetchingItems,
    title
  } = props;

  const [filterValue, setFilterValue] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [itemToAdd, setitemToAdd] = useState(null);

  const handleFilterChange = e => {
    setFilterValue(e.currentTarget.value.toLowerCase());
  };

  const handleAddItem = async (item: any) => {
    setIsAdding(true);
    setitemToAdd(item);

    await onSubmit(item);

    setIsAdding(false);
    setitemToAdd(null);
    setFilterValue('');

    onClose();
  };

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell width={6}>
        Name
      </Table.HeaderCell>
      <Table.HeaderCell width={6} />
    </Table.Row>
  );

  const tableRows = items
    .filter(item => {
      const name = item.name.toLowerCase();
      return filterValue === '' || name.includes(filterValue);
    })
    .map(item => (
      <Table.Row key={item.id}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>
          <Button
            onClick={() => handleAddItem(item)}
            loading={itemToAdd && itemToAdd.id === item.id}
            disabled={isAdding}
            floated="right"
          >
            Add
          </Button>
        </Table.Cell>
      </Table.Row>
    ));

  return (
    <Modal
      open={isOpen}
      closeIcon={<button className="modal__close">Close</button>}
      onClose={onClose}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <TableActions
          filterAction={handleFilterChange}
          filterPlaceholder="Filter..."
        />

        <Grid padded centered>
          <Grid.Row centered>
            <Grid.Column>
              {isFetchingItems ? (
                <PageLoader />
              ) : (
                <PaginatedTable
                  perPage={3}
                  headerRow={tableHeader}
                  rows={tableRows}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Modal.Content>
    </Modal>
  );
}
