// @flow
import type { OnlineStoreTreeCategory } from '../types/onlineStoreTreeCategories';
import * as actions from '../actions/onlineStore';
import type {
  OnlineStoreTreeCategoriesActions,
  OnlineStoreTreeCategories,
} from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';
import { createSelector } from 'reselect';

/* Initial State */

const initialState: OnlineStoreTreeCategories = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const onlineTreeCategories = (
  state: OnlineStoreTreeCategories = initialState,
  action: OnlineStoreTreeCategoriesActions
):
  | any
  | OnlineStoreTreeCategories
  | {
      byId: { [string | number]: OnlineStoreTreeCategory },
      error: void,
      isFetching: boolean,
      order: Array<any | number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [string | number]: OnlineStoreTreeCategory },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [key: string | number]: OnlineStoreTreeCategory },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [key: string | number]: OnlineStoreTreeCategory },
      error: string,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    } => {
  switch (action.type) {
    case actions.ONLINESTORETREECATEGORY_CREATE_REQUEST:
    case actions.ONLINESTORETREECATEGORIES_LOAD_REQUEST:
    case actions.ONLINESTORETREECATEGORY_REMOVE_REQUEST:
    case actions.ONLINESTORETREECATEGORY_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ONLINESTORETREECATEGORY_CREATE_FAILURE:
    case actions.ONLINESTORETREECATEGORIES_LOAD_FAILURE:
    case actions.ONLINESTORETREECATEGORY_REMOVE_FAILURE:
    case actions.ONLINESTORETREECATEGORY_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.ONLINESTORETREECATEGORIES_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINESTORETREECATEGORY_CREATE_SUCCESS:
    case actions.ONLINESTORETREECATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe TODO(DP): Fix this
          [action.payload.id]: action.payload,
        },
        // $FlowFixMe
        order: [...state.order, action.payload.id],
        isFetching: true,
        error: undefined,
      };
    case actions.ONLINESTORETREECATEGORY_REMOVE_SUCCESS: {
      let copy = state.byId;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.id];
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.id);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    }
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOnlineStoreTreeCategories: any = createSelector(
  (state) => state.onlineTreeCategories.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    let ggg = Object.keys(items)
      .sort(function (a, b) {
        return 0;
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
    return ggg;
  }
);
