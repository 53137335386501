// @flow
import React from 'react';
import { Grid, Form, Button, Popup, Icon } from 'semantic-ui-react';
import type { Node } from 'React';

type PasswordResetRequest = {
  oldPassword: string,
  newPassword: string,
  newPasswordConfirmation: string,
};

type Props = {
  handleFormSubmit: (formData: {
    newPassword: string,
    oldPassword: string,
  }) => void,
};

type State = {
  formData: PasswordResetRequest,
};

class UserPasswordSettingsForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formData: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      },
    };
  }

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  passwordHinter(
    newPassword: string,
    newPasswordConfirmation: string
  ): void | string {
    if (newPassword === '') {
      return 'Please enter your new password';
    } else if (newPasswordConfirmation === '') {
      return 'Please confirm your password';
    } else if (newPassword !== newPasswordConfirmation) {
      return 'Passwords do not match';
    }
  }

  render(): Node {
    const {
      oldPassword,
      newPassword,
      newPasswordConfirmation,
    } = this.state.formData;
    const { handleFormSubmit } = this.props;
    return (
      <Form
        onSubmit={() => {
          handleFormSubmit({
            newPassword: this.state.formData.newPassword,
            oldPassword: this.state.formData.oldPassword,
          });
        }}
      >
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Old Password"
                name="oldPassword"
                value={oldPassword}
                onChange={this.handleInputChange}
                type="password"
                required
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="New Password"
                name="newPassword"
                value={newPassword}
                onChange={this.handleInputChange}
                type="password"
                required
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Confirm New Password"
                name="newPasswordConfirmation"
                value={newPasswordConfirmation}
                onChange={this.handleInputChange}
                type="password"
                required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Button
                className="form__submit-button"
                disabled={
                  newPassword !== newPasswordConfirmation ||
                  newPassword === '' ||
                  newPasswordConfirmation === ''
                    ? true
                    : false
                }
                type="submit"
              >
                Update Password
              </Button>
              <Popup
                trigger={
                  <Icon className="password-help-icon" name="help circle" />
                }
                content="Password must contain at least one letter, number and special character. Password must be at least 8 characters in length"
              />
              {this.passwordHinter(newPassword, newPasswordConfirmation)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default UserPasswordSettingsForm;
