//@flow
import * as React from 'react';
import { Grid, Form, Dropdown, Button } from 'semantic-ui-react';

type Props = {
  reportTypes: Array<any>,
  handleFormSubmit: Function,
};

type State = {
  formData: any,
};

class OnlineStoreUpdateTimePeriodForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formData: {
        reportTypeId: undefined,
        timePeriod: '',
      },
    };
  }

  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: any
  ) => void = (event: SyntheticInputEvent<HTMLSelectElement>, result: any) => {
    let formData = Object.assign({}, this.state.formData);
    formData[result.name] = result.value;
    this.setState({ formData });
  };

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  render(): React.Node {
    const { handleFormSubmit, reportTypes } = this.props;

    const { formData } = this.state;

    const { timePeriod, reportTypeId } = formData;

    const reportTypeOptions = reportTypes.map((format: any) => {
      return {
        text: format.name,
        value: format.id,
      };
    });

    return (
      <Form
        className="organisation-form"
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              <Form.Field required>
                <label htmlFor="reportTypeId">Report Type</label>
                <Dropdown
                  onChange={this.handleDropdownChange}
                  name="reportTypeId"
                  value={reportTypeId}
                  fluid
                  selection
                  placeholder={'Select Report Type'}
                  options={reportTypeOptions}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Time Period"
                name="timePeriod"
                value={timePeriod}
                type="text"
                onChange={this.handleInputChange}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <div className="organisation-form__actions">
                <Button className="organisation-form__submit" type="submit">
                  Update Time Period
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default OnlineStoreUpdateTimePeriodForm;
