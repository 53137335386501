// @flow
import { createSelector } from 'reselect';
import type { OrganisationFlags, Action, State } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */
const initialState: OrganisationFlags = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

/* Reducer */
export const organisationFlags = (
  state: OrganisationFlags = initialState,
  action: Action
): OrganisationFlags => {
  switch (action.type) {
    case 'ORGANISATION_FLAGS_LOAD_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'ORGANISATION_FLAGS_LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_FLAGS_LOAD_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOrganisationFlagsSortedById: any = createSelector(
  (state: State) => state.organisationFlags.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }
    return Object.keys(items)
      .sort(function (a, b) {
        if (items[a].id < items[b].id) {
          return -1;
        } else if (items[a].id > items[b].id) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
