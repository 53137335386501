// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware/index';
// import type { ThunkAction, Dispatch, Action } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINESTOREITEM_READ_REQUEST = 'ONLINESTOREITEM_READ_REQUEST';
export const ONLINESTOREITEM_READ_SUCCESS = 'ONLINESTOREITEM_READ_SUCCESS';
export const ONLINESTOREITEM_READ_FAILURE = 'ONLINESTOREITEM_READ_FAILURE';

const readOnlineStoreItemRequest = () => ({
  type: ONLINESTOREITEM_READ_REQUEST,
});

const readOnlineStoreItemSuccess = (onlineStoreItem) => ({
  type: ONLINESTOREITEM_READ_SUCCESS,
  payload: onlineStoreItem,
});

const readOnlineStoreItemFailure = (error) => ({
  type: ONLINESTOREITEM_READ_FAILURE,
  error,
});

export const readOnlineStoreItem = (onlineStoreItemId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(readOnlineStoreItemRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/items/${onlineStoreItemId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(readOnlineStoreItemSuccess(response));

    return true;
  } catch (error) {
    dispatch(readOnlineStoreItemFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
