// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_TEAM_REMOVE_REQUEST =
  'ORGANISATION_COLLABORATOR_TEAM_REMOVE_REQUEST';
export const ORGANISATION_COLLABORATOR_TEAM_REMOVE_SUCCESS =
  'ORGANISATION_COLLABORATOR_TEAM_REMOVE_SUCCESS';
export const ORGANISATION_COLLABORATOR_TEAM_REMOVE_FAILURE =
  'ORGANISATION_COLLABORATOR_TEAM_REMOVE_FAILURE';

const removeOrganisationCollaboratorTeamRequest = () => ({
  type: ORGANISATION_COLLABORATOR_TEAM_REMOVE_REQUEST,
});

const removeOrganisationCollaboratorTeamSuccess = (teamId, collaboratorId) => ({
  type: ORGANISATION_COLLABORATOR_TEAM_REMOVE_SUCCESS,
  teamId,
  collaboratorId,
});

const removeOrganisationCollaboratorTeamFailure = (error) => ({
  type: ORGANISATION_COLLABORATOR_TEAM_REMOVE_FAILURE,
  error,
});

export const removeOrganisationCollaboratorTeam = (
  organisationId: number,
  teamId: number,
  collaboratorId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOrganisationCollaboratorTeamRequest());

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}/collaborators/${collaboratorId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(removeOrganisationCollaboratorTeamSuccess(teamId, collaboratorId));

    return true;
  } catch (error) {
    dispatch(removeOrganisationCollaboratorTeamFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
