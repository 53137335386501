// @flow
import { createSelector } from 'reselect';
import type { UsersApiAccess } from '../types';
import * as actions from '../actions/apiSubscriptions';
// $FlowFixMe
import { getItemsSortedByName } from '../helpers';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: UsersApiAccess = {
  order: [],
  byId: {},
  isFetching: false,
  error: undefined
};

/* Reducer */

export const usersWithoutApiAccess = (
  state: UsersApiAccess = initialState,
  action: Object
): UsersApiAccess => {
  switch (action.type) {
    case actions.API_USERS_WITHOUT_ACCESS_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.API_USERS_WITHOUT_ACCESS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.API_USER_ADD_SUCCESS:
      delete state.byId[action.payload.id];
      state.order = state.order.filter(e => e !== action.payload.id);
      return {
        ...state,
        byId: {
          ...state.byId
        },
        order: state.order,
        isFetching: false,
        error: undefined
      };
    case actions.API_USER_REMOVE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload
          }
        },
        isFetching: false,
        error: undefined
      };
    case actions.API_USERS_WITHOUT_ACCESS_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getUsersWithoutApiAccess: any = createSelector(
  (state) => state.usersWithoutApiAccess,
  (items) => {
      let users = getItemsSortedByName(items);

      return {
        items: users, 
        isFetching: items.isFetching, 
        error: items.error
      }
  }
);
