// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_ORGANISATION_UPDATE_KEY_REQUEST = 'API_ORGANISATION_UPDATE_KEY_REQUEST';
export const API_ORGANISATION_UPDATE_KEY_SUCCESS = 'API_ORGANISATION_UPDATE_KEY_SUCCESS';
export const API_ORGANISATION_UPDATE_KEY_FAILURE = 'API_ORGANISATION_UPDATE_KEY_FAILURE';

const regenerateOrganisationApiKeyRequest = (id) => ({
  type: API_ORGANISATION_UPDATE_KEY_REQUEST,
  id
});

const regenerateOrganisationApiKeySuccess = (subscription) => ({
  type: API_ORGANISATION_UPDATE_KEY_SUCCESS,
  payload: subscription,
});

const regenerateOrganisationApiKeyFailure = (error, id) => ({
  type: API_ORGANISATION_UPDATE_KEY_FAILURE,
  error,
  id
});

export const regenerateOrganisationApiKey = (
  apiId: number,
  organisation: any
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(regenerateOrganisationApiKeyRequest(organisation.id));

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisation.id}/apis/${apiId}/regenerate-token`
    });

    if (response instanceof Error) {
      throw response;
    }

    let subscription = toOrganisationSubscriptionObject(response, organisation);

    dispatch(regenerateOrganisationApiKeySuccess(subscription));
  } catch (error) {
    dispatch(regenerateOrganisationApiKeyFailure(error.message, organisation.id));
    Sentry.captureException(error);
    console.error(error);
  }
};

function toOrganisationSubscriptionObject(apiSubscription, organisation) {
  return {
    id: organisation.id,
    name: organisation.name,
    apiId: apiSubscription.id,
    token: apiSubscription.token,
    isSubscriptionActive: apiSubscription.isActive
  };
}