//@flow
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserUsageBarChart from '../UsageLogs/UserUsageBarChart';
import UserUsageTable from '../UsageLogs/UserUsageTable';
import { Dropdown } from 'semantic-ui-react';
import { loadUsageDomains } from '../../actions';
import type { AuthTypes } from '../../types';

type Props = {
  organisationId: number,
  userId: number,
  permission: ?AuthTypes,
  organisation: Object,
};

let defaultDomainOption = {
  value: '',
  text: 'All Domains',
};

export default function UserUsageLogs({
  userId,
  organisationId,
}: Props): any {
  const dispatch = useDispatch();
  const [domain, setDomain] = useState(defaultDomainOption);
  const usageDomains = useSelector((state) => state.usageDomains.result);

  const formattedDomains = useMemo(() => {
    return usageDomains.map((domain) => ({
      value: domain,
      text: domain,
    }));
  }, [usageDomains]);

  const unsortedDomainOptions = [defaultDomainOption, ...formattedDomains];
  // Gets unsorted domain options array of objects and sorts alphabetically based on the "text" value
  const domainOptions = unsortedDomainOptions.sort((a, b) =>
    a.text > b.text ? 1 : -1
  );

  useEffect(() => {
    dispatch(loadUsageDomains());
  }, []);

  const handleDropdown = (e, value) => {
    const selectedOption = domainOptions.find(
      (option) => option.value === value.value
    );

    if (selectedOption) {
      setDomain(selectedOption);
    } else {
      setDomain(defaultDomainOption);
    }
  };

  return (
    <div>
      <h3 className="table-actions__title">Usage Log</h3>
      <div className="usage-logs__field-and-button-wrapper">
        <div className="usage-logs__field-wrapper">
          <label htmlFor="domainFilter" className="usage-logs__label">
            Filter Domain
          </label>
          <Dropdown
            onChange={(e, value) => handleDropdown(e, value)}
            className="usage-logs__dropdown"
            name="domainFilter"
            placeholder="Filter by domain"
            selection
            options={domainOptions}
          />
        </div>
      </div>
      <UserUsageBarChart domain={domain} organisationId={organisationId} userId={userId} />
      <UserUsageTable organisationId={organisationId} userId={userId} />
    </div>
  );
}
