// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_ORGANISATION_REMOVE_REQUEST = 'API_ORGANISATION_REMOVE_REQUEST';
export const API_ORGANISATION_REMOVE_SUCCESS = 'API_ORGANISATION_REMOVE_SUCCESS';
export const API_ORGANISATION_REMOVE_FAILURE = 'API_ORGANISATION_REMOVE_FAILURE';

const removeOrganisationApiSubscriptionRequest = (id) => ({
  type: API_ORGANISATION_REMOVE_REQUEST,
  id
});

const removeOrganisationApiSubscriptionSuccess = (organisation) => ({
  type: API_ORGANISATION_REMOVE_SUCCESS,
  payload: organisation
});

const removeOrganisationApiSubscriptionFailure = (error, id) => ({
  type: API_ORGANISATION_REMOVE_FAILURE,
  error,
  id
});

export const removeOrganisationApiSubscription = (
  apiId: number,
  organisation: any
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOrganisationApiSubscriptionRequest(organisation.id));

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisation.id}/apis/${apiId}`
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(removeOrganisationApiSubscriptionSuccess(organisation));
  } catch (error) {
    dispatch(removeOrganisationApiSubscriptionFailure(error.message, organisation.id));
    Sentry.captureException(error);
    console.error(error);
  }
};