// @flow
import * as Sentry from '@sentry/browser';
import { callAPI } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const LOAD_ORGANISATION_USERS_ACTIVITY_FAILURE = 'LOAD_ORGANISATION_USERS_ACTIVITY_FAILURE';

const loadOrganisationUsersActivityFailure = (error): Object => ({
    type: LOAD_ORGANISATION_USERS_ACTIVITY_FAILURE,
    error,
});

export const loadOrganisationUsersActivity = (
    organisationId: number,
    includeRMRUsers: boolean,
    includeArchivedUsers: boolean,
    includeBackgroundApiUsage: boolean
): any => async (dispatch, getState) => {
    const queryBase = `organisations/${organisationId}/usage/organisation-activity`;
    const query = `?includeRMRUsers=${String(includeRMRUsers)}&includeArchivedUsers=${String(includeArchivedUsers)}&includeBackgroundApiUsage=${String(includeBackgroundApiUsage)}`;

    try {
    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'management')}/${queryBase}${query}`,
    });

    return response;
    } catch (error) {
        dispatch(loadOrganisationUsersActivityFailure(error.message));
        console.error(error);
        Sentry.captureException(error);

        return error;
    }
};
