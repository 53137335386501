// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import type { ThunkAction } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const COUPON_APPLY_TYPES_LOAD_REQUEST =
  'COUPON_APPLY_TYPES_LOAD_REQUEST';
export const COUPON_APPLY_TYPES_LOAD_SUCCESS =
  'COUPON_APPLY_TYPES_LOAD_SUCCESS';
export const COUPON_APPLY_TYPES_LOAD_FAILURE =
  'COUPON_APPLY_TYPES_LOAD_FAILURE';

const loadCouponApplyTypesRequest = (): any => ({
  type: COUPON_APPLY_TYPES_LOAD_REQUEST,
});

const loadCouponApplyTypesSuccess = (options: any): any => ({
  type: COUPON_APPLY_TYPES_LOAD_SUCCESS,
  payload: options,
});

const loadCouponApplyTypesFailure = (error: string): any => ({
  type: COUPON_APPLY_TYPES_LOAD_FAILURE,
  error,
});

export const loadCouponApplyTypes = (): ThunkAction => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadCouponApplyTypesRequest());

    const response: any | Error = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/options/coupon-apply-types`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload: any = normaliseArrayOfObjects(response);

    dispatch(loadCouponApplyTypesSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadCouponApplyTypesFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
