import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Popup, Container, Grid, Table } from 'semantic-ui-react';
import { loadApiSubscriptions } from '../../actions';

import PaginatedTable from '../../components/PaginatedTable';
import TableActions from '../../components/TableActions';
import PageLoader from '../../components/PageLoader';
import ErrorMessage from '../../components/ErrorMessage';
import Breadcrumb from '../../components/Breadcrumb';

export default function ApiSubscriptions() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadApiSubscriptions());
  }, [dispatch]);

  const { items: apiSubscriptions, isFetching, error } = useSelector(
    state => state.apiSubscriptions
  );

  const [filterValue, setFilterValue] = useState('');

  const handleFilterChange = e => {
    setFilterValue(e.currentTarget.value.toLowerCase());
  };

  if (error) {
    return <ErrorMessage message={error.toString()} />;
  }

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell width={5}>
        API
      </Table.HeaderCell>
      <Table.HeaderCell width={4}>
        Type
      </Table.HeaderCell>
      <Table.HeaderCell width={2}>
        Subscriptions
      </Table.HeaderCell>
      <Table.HeaderCell width={1}>
        Actions
      </Table.HeaderCell>
    </Table.Row>
  );

  const tableRows = apiSubscriptions
    .filter(api => {
      const title = api.name.toLowerCase();
      return filterValue === '' || title.includes(filterValue);
    })
    .map(api => (
      <Table.Row key={api.id}>
        <Table.Cell>
          <Popup
            content={api.description}
            trigger={<div>{api.name}</div>}
          />
        </Table.Cell>
        <Table.Cell>{api.apiType}</Table.Cell>
        <Table.Cell>{api.numberOfActiveSubscriptions}</Table.Cell>
        <Table.Cell>
          <Link to={`/api-subscriptions/${api.id}/details`}>View</Link>
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <Container>
        <Grid centered stackable>
          <Grid.Column>
            <div className="page-content">
              <Breadcrumb currentContext="API Subscriptions" />
              <div className="table-container">
                <TableActions
                  filterAction={handleFilterChange}
                  filterPlaceholder="Filter..."
                />
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    perPage={20}
                    headerRow={tableHeader}
                    rows={tableRows}
                    filterValue={filterValue}
                  />
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
}
