// @flow
import * as React from 'react';
import { Message as SemanticMessage } from 'semantic-ui-react';
import CSSTransition from 'react-transition-group/CSSTransition';

type Props = {
  error?: boolean,
  warning?: boolean,
  success?: boolean,
  content?: string,
  children?: any,
  show?: boolean,
  showDelay?: boolean,
  onDismiss?: () => void,
};

class Message extends React.Component<Props> {
  render(): React.Node {
    const {
      show = true,
      showDelay = false,
      error,
      warning,
      success,
      content,
      onDismiss,
    } = this.props;

    const classNames = 'flash-message-trans';

    return (
      <CSSTransition
        key={content}
        in={show}
        appear={show}
        classNames={classNames}
        timeout={{
          appear: 750,
          enter: 1000,
          exit: 500,
        }}
      >
        {showDelay ? (
          <SemanticMessage
            className="flash-message"
            error={error}
            warning={warning}
            success={success}
            content={content}
            onDismiss={onDismiss}
          >
            {this.props.children}
          </SemanticMessage>
        ) : (
          <div />
        )}
      </CSSTransition>
    );
  }
}

export default Message;
