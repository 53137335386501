// @flow
import * as actions from '../../actions';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: any = {
  result: {
    available: false,
    numOfFiles: 0
  },
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const nextSearchableAudienceImportDetails = (
  state: any = initialState,
  action: any
): any => {
  switch (action.type) {
    case actions.GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_SUCCESS:
      return {
        ...state,
        result: action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.GET_NEXT_SEARCHABLE_AUDIENCE_IMPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
