// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { managementFeatures } from '../helpers';
import {
  getPermissionInFeature,
  restrictUserPermissions,
  permissions,
  hasProductAccess,
} from 'roy-morgan-auth';
import OnlineStorePendingIssues from './OnlineStore/OnlineStorePendingIssues';
import Organisations from './Organisations';
import PageLoader from '../components/PageLoader';

type Props = {
  hasAccess: boolean,
  featureAccess: Object,
};

class Home extends React.Component<Props> {
  render() {
    if (!this.props.hasAccess) {
      return <PageLoader />;
    }
    switch (getUserStartingFeature(this.props)) {
      case managementFeatures.LIVE_MANAGEMENT:
        return <Organisations />;
      case managementFeatures.ONLINE_STORE_MANAGEMENT:
        return <OnlineStorePendingIssues />;
      default:
        return '';
    }
  }
}

const getUserStartingFeature = (props) => {
  let { featureAccess } = props;
  const { INTERNAL_ADMIN, STANDARD_USER } = permissions;

  const onlineStoreManagementPermission = getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    featureAccess
  );

  return restrictUserPermissions(
    [INTERNAL_ADMIN, STANDARD_USER],
    onlineStoreManagementPermission
  )
    ? managementFeatures.ONLINE_STORE_MANAGEMENT
    : managementFeatures.LIVE_MANAGEMENT;
};

const mapStateToProps = function (state: any, props) {
  return {
    featureAccess: state.auth.authorisation,
    hasAccess:
      state.auth.products && state.auth.products.length > 0
        ? hasProductAccess(state.auth.products, 'Live Management')
        : undefined,
  };
};

export default (connect(mapStateToProps)(Home): any);
