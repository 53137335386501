// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_REQUEST =
  'ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_REQUEST';
export const ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_SUCCESS =
  'ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_SUCCESS';
export const ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_FAILURE =
  'ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_FAILURE';

const addOrganisationCollaboratorToOrganisationTeamRequest = () => ({
  type: ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_REQUEST,
});

const addOrganisationCollaboratorToOrganisationTeamSuccess = (users) => ({
  type: ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_SUCCESS,
  payload: users,
});

const addOrganisationCollaboratorToOrganisationTeamFailure = (error) => ({
  type: ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_FAILURE,
  error,
});

export const addOrganisationCollaboratorToOrganisationTeam = (
  organisationId: number,
  teamId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(addOrganisationCollaboratorToOrganisationTeamRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}/collaborators`,
      body: {
        userId,
      },
    });

    dispatch(addOrganisationCollaboratorToOrganisationTeamSuccess(response));

    return true;
  } catch (error) {
    dispatch(
      addOrganisationCollaboratorToOrganisationTeamFailure(error.message)
    );
    console.error(error);
    Sentry.captureException(error);

    return false;
  }
};
