// @flow
import React from 'react';
import { Grid, Form, Dropdown, Button } from 'semantic-ui-react';
import type { OrganisationUserFormWithTeams } from '../../types';
import type { Node } from 'React';

type Props = {
  backAction?: Function,
  backURL?: Function,
  handleFormSubmit: (formData: OrganisationUserFormWithTeams) => void,
  organisationCanHaveTeams ?: boolean,
  organisationTeams: []
};

type State = {
  formData: OrganisationUserFormWithTeams,
};

class InviteUserFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formData: {
        name: '',
        email: '',
        teamsToInclude: [],
      },
    };
  }

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number[], ... }
  ) => void = (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number[] }
  ) => {
    this.setState({
      formData: {
        ...this.state.formData,
        teamsToInclude: result.value
      },
    });
  };

  render(): Node {
    const { name, email } = this.state.formData;
    const { handleFormSubmit, organisationCanHaveTeams, organisationTeams } = this.props;
    let teams = [];
    if (organisationCanHaveTeams && organisationTeams.length > 0){
      teams = organisationTeams.map((t)=>( {key: t.id, text: t.name, value: t.id}));
    }
    return (
      <Form
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Name"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                type="text"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Email"
                name="email"
                value={email}
                onChange={this.handleInputChange}
                type="email"
                required
              />
            </Grid.Column>
          </Grid.Row>
            { organisationCanHaveTeams ? (
              <Grid.Row>
              <Grid.Column width={8}>
              <div className="field">
                <label htmlFor="teamsSelection">Teams</label>
                <Dropdown
                  onChange={this.handleDropdownChange}
                  name="teamsSelection"
                  fluid multiple
                  selection
                  options={teams}
                />
              </div>
              </Grid.Column>
              </Grid.Row>
              ) :
              ('')
              }
          { organisationCanHaveTeams ? (
              <Grid.Row>
                <Grid.Column width={8}>
                  <div>
                    If no teams selected, the user will have access to all teams, including teams created in the future.
                  </div>
                </Grid.Column>
              </Grid.Row>
            ) :
            ('')
          }
            <Grid.Row>
            <Grid.Column>
              <Button className="organisation-form__submit" type="submit">
                Send Invite
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default InviteUserFormFields;
