// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../../helpers';
import { callAPI, refreshToken } from '../../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_TEAM_FEATURES_LOAD_REQUEST =
  'ORGANISATION_TEAM_FEATURES_LOAD_REQUEST';
export const ORGANISATION_TEAM_FEATURES_LOAD_SUCCESS =
  'ORGANISATION_TEAM_FEATURES_LOAD_SUCCESS';
export const ORGANISATION_TEAM_FEATURES_LOAD_FAILURE =
  'ORGANISATION_TEAM_FEATURES_LOAD_FAILURE';

const loadOrganisationTeamFeaturesRequest = () => ({
  type: ORGANISATION_TEAM_FEATURES_LOAD_REQUEST,
});

const loadOrganisationTeamFeaturesSuccess = (features) => ({
  type: ORGANISATION_TEAM_FEATURES_LOAD_SUCCESS,
  payload: features,
});

const loadOrganisationTeamFeaturesFailure = (error) => ({
  type: ORGANISATION_TEAM_FEATURES_LOAD_FAILURE,
  error,
});

export const loadOrganisationTeamFeatures = (
  organisationId: number,
  teamId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationTeamFeaturesRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}/features`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOrganisationTeamFeaturesSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationTeamFeaturesFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
