//@flow
import * as React from 'react';
import { Grid, Form, Dropdown, Button, Modal } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react'; //ToDo: Current version produces warning.  Developer has stated that it will be fixed in next release.  https://github.com/arfedulov/semantic-ui-calendar-react/issues/56
import moment from 'moment';
import OverflowMenu from '../OverflowMenu';
import StaticField from '../StaticField';
import type {
  Organisation as OrgType,
  NewOrganisation as NewOrgType,
  Industry as IndustryType,
  OrganisationForm,
  AuthTypes,
  OrganisationFlag as OrganisationFlagType,
  AccountManager as AccountManagerType
} from '../../types';
import SetAccountManagers from '../../containers/SetAccountManagers';

type Props = {
  permission: ?AuthTypes,
  industries: Array<IndustryType>,
  organisationFlags: Array<OrganisationFlagType>,
  organisation: OrgType | NewOrgType,
  handleFormSubmit: Function,
  children?: any,
};

type State = {
  formData: OrganisationForm,
  accountManagers: Array<AccountManagerType>,
  setAccountManagerOpen?: boolean
};

const dateFormat = 'DD/MM/YYYY';

const organisationFlagsIdFromProps = (props: Props) => {
  return props.organisation && props.organisation.organisationFlagId
    ? props.organisation.organisationFlagId
    : 1;
};

class NewOrganisationFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formData: {
        name: props.organisation.name,
        notes: props.organisation.notes,
        nextReviewDate: props.organisation.nextReviewDate,
        organisationFlagId: organisationFlagsIdFromProps(props)
      },
      accountManagers: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.permission !== this.props.permission) {
      this.setState({
        formData: {
          ...this.state.formData,
          name: nextProps.organisation.name,
          notes: nextProps.organisation.notes,
          nextReviewDate: nextProps.organisation.nextReviewDate,
          organisationFlagId: organisationFlagsIdFromProps(nextProps),
        },
          accountManagers: []
      });
    }
  }

  handleDropdownChangeOrganisationFlag: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number, ... }
  ) => void = (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => {
    this.setState({
      formData: {
        ...this.state.formData,
        organisationFlagId: result.value,
      },
    });
  };

  handleDateChange: (event: any, { name: any, value: any, ... }) => void = (
    event: any,
    { name, value }: { name: any, value: any }
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[name] = value ? moment(value, dateFormat).toISOString() : null;
    this.setState({ formData });
  };

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleAccountManagerAddition: (id: number, name: string) => void = (
    id: number, 
    name: string
  ) => {
    this.setState({
      accountManagers: [
        ...this.state.accountManagers, 
        { id, name }
      ],
      setAccountManagerOpen: false,
    });
  };

  handleAccountManagerRemoval: (id: number) => void = (
    id: number,
  ) => {
    this.setState({
      accountManagers: this
        .state
        .accountManagers
        .filter(m => m.id !== id),
      setAccountManagerOpen: false,
    });
  };

  toggleSetAccountManagerModal: (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => void = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.setState({
      setAccountManagerOpen: !this.state.setAccountManagerOpen,
    });
  };

  render(): React.Node {
    const {
      handleFormSubmit,
      organisationFlags,
    } = this.props;

    const {
      setAccountManagerOpen,
      accountManagers,
    } = this.state;

    let accountManagersDisplay = accountManagers
      .map(m => m.name)
      .join(', ');

    let name = this.state.formData.name;
    let notes = this.state.formData.notes;
    let nextReviewDate = this.state.formData.nextReviewDate;
    let organisationFlagId = this.state.formData.organisationFlagId;

    const nextReviewDateFormatted = nextReviewDate
      ? moment(nextReviewDate).format(dateFormat)
      : '';

    const organisationFlagOptions = organisationFlags.map(
      (organisationFlag: OrganisationFlagType) => {
        return {
          text: organisationFlag.name,
          value: organisationFlag.id,
        };
      }
    );

    return (
      <Form
        className="organisation-form"
        onSubmit={() => {
          handleFormSubmit(this.state.formData, accountManagers);
        }}
      >
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
                <Form.Input
                  label="Organisation Name"
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                  type="text"
                  required
                />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <Form.TextArea
                label="Notes"
                name="notes"
                value={notes}
                onChange={this.handleInputChange}
                placeholder="Enter your notes"
                rows={10}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <DateInput
                label="Next Review Date"
                name="nextReviewDate"
                placeholder="next review date"
                value={nextReviewDateFormatted}
                initialDate={nextReviewDateFormatted}
                iconPosition="left"
                onChange={this.handleDateChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <StaticField
                label="Account Managers"
                value={accountManagersDisplay}
              />
              <div>
                <Button onClick={this.toggleSetAccountManagerModal}>
                  Set Account Managers
                </Button>
                <Modal
                  open={setAccountManagerOpen}
                  closeIcon={<button className="modal__close">Close</button>}
                  onClose={this.toggleSetAccountManagerModal}
                >
                  <Modal.Header>Set Account Managers</Modal.Header>
                  <Modal.Content>
                    <SetAccountManagers
                      accountManagers={accountManagers}
                      addAccountManager={this.handleAccountManagerAddition}
                      removeAccountManager={this.handleAccountManagerRemoval}
                    />
                  </Modal.Content>
                </Modal>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <Form.Field>
                <label htmlFor="organisationFlagId">Organisation Flag</label>
                <Dropdown
                  onChange={this.handleDropdownChangeOrganisationFlag}
                  name="organisationFlagId"
                  value={organisationFlagId}
                  fluid
                  selection
                  placeholder={'Select Organisation Flag'}
                  options={organisationFlagOptions}
                />
              </Form.Field>
              <div className="organisation-form__actions">
                <Button className="organisation-form__submit" type="submit">
                  Submit
                </Button>
                {this.props.children ? (
                  <OverflowMenu dark={true} leftAlign={true}>
                    {this.props.children}
                  </OverflowMenu>
                ) : (
                  ''
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default NewOrganisationFormFields;
