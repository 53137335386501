//@flow

import React from 'react';
import { Grid } from 'semantic-ui-react';
import StaticField from '../../../components/StaticField';
import moment from 'moment';
import { 
	toSearchableAudienceImportStatus,
	searchableAudienceImportStatus
} from '../../../helpers';

const { IMPORTED, IMPORT_ERROR } = searchableAudienceImportStatus;

type Props = {
	details: any
};
  
export default function SearchableAudienceImportDetails(props: Props): any {
	const { details } = props;
	const { 
		version, 
		run, 
		importStatusId, 
		dateImported, 
		importedBy, 
		numOfFiles, 
		errorMessages, 
		isActive 
	} = details;

	return (
		<Grid columns={2} stackable>
			<Grid.Row>
				<Grid.Column width={6}>
					<StaticField
						label="Version"
						value={version}
					/>
				</Grid.Column>
				<Grid.Column width={6}>
					<StaticField
						label="Run"
						value={run}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width={6}>
					<StaticField
						label="Status"
						value={toSearchableAudienceImportStatus(importStatusId)}
					/>
				</Grid.Column>
				<Grid.Column width={6}>
					<StaticField
						label="Number of Documents"
						value={numOfFiles ? numOfFiles.toLocaleString('en-AU') : '0'}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width={6}>
					<StaticField
						label="Import Date"
						value={moment
							.utc(dateImported)
							.local()
							.format('YYYY-MM-DD HH:mm')
							.toString()
						}
					/>
				</Grid.Column>
				<Grid.Column width={6}>
					<StaticField
						label="Imported By"
						value={importedBy}
					/>
				</Grid.Column>
			</Grid.Row>
			{importStatusId === IMPORTED && (
				<Grid.Row>
					<Grid.Column width={6}>
						<StaticField
							label="Active?"
							value={isActive ? 'Yes' : 'No'}
						/>
					</Grid.Column>
				</Grid.Row>
			)}
			{importStatusId === IMPORT_ERROR && (
				<Grid.Row>
					<Grid.Column width={12}>
						<StaticField
							label="Errors"
							value={
								errorMessages
									? errorMessages.map((e,idx) => (
										<div key={idx}>{e.message}</div>
									)) 
									: ''
							}
						/>
					</Grid.Column>
				</Grid.Row>
			)}
		</Grid>
	);
}
