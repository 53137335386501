// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadOnlineStoreOrderItems,
  uploadOnlineStoreOrderItem,
} from '../../actions/index';
import type { AuthTypes } from '../../types/index';
import { Container, Grid, Table, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PaginatedTable from '../../components/PaginatedTable';
import Breadcrumb from '../../components/Breadcrumb';
import TableActions from '../../components/TableActions';
import type { OnlineStoreOrderItem } from '../../types/index';
import PageLoader from '../../components/PageLoader';
import { managementFeatures } from '../../helpers/index';
import {
  getPermissionInFeature,
  restrictUserPermissions,
  permissions,
} from 'roy-morgan-auth';
import { getOnlineStoreOrderItemsSortedByStatus } from '../../reducers/onlineStoreOrderItems';
// $FlowFixMe
import { sortChangeHandler, sortData } from '../../helpers';
import FileButton from '../../components/FileButton';
import moment from 'moment';

type Props = {
  loadOnlineStoreOrderItems: () => Promise<boolean>,
  uploadOnlineStoreOrderItem: (
    orderItemId: number,
    orderItemFile: any
  ) => Promise<OnlineStoreOrderItem>,
  onlineStoreOrderItems: Array<Object>,
  isFetching: boolean,
  permission: ?AuthTypes,
};

// type State = {
//   filterValue: string
// };

export class OnlineStoreOrderItems extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
    };
  }

  componentDidMount() {
    const { loadOnlineStoreOrderItems } = this.props;
    loadOnlineStoreOrderItems();
  }

  handleFilterChange: (e: SyntheticInputEvent<HTMLInputElement>) => void = (
    e: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  handleSortChange: (clickedColumn: any) => () => any = (
    clickedColumn: any
  ) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort: (a: any, b: any) => any = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  handleUpload: (orderItemId: number) => (file: any) => void = (
    orderItemId: number
  ) => (file: any) => {
    this.setState({
      uploadingItem: orderItemId,
    });

    this.props.uploadOnlineStoreOrderItem(orderItemId, file).then((res) => {
      this.setState({
        uploadingItem: null,
      });
    });
  };

  isUploading: (orderItemId: ?number) => any | boolean = (
    orderItemId: ?number
  ) => {
    const { uploadingItem } = this.state;
    return orderItemId ? uploadingItem === orderItemId : !!uploadingItem;
  };

  render(): React.Node {
    const { onlineStoreOrderItems, isFetching, permission } = this.props;
    const { sortColumn, sortDirection } = this.state;
    const { INTERNAL_ADMIN, STANDARD_USER } = permissions;

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'orderId' ? sortDirection : null}
          onClick={this.handleSortChange('orderId')}
        >
          Order ID
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'skuNumber' ? sortDirection : null}
          onClick={this.handleSortChange('skuNumber')}
        >
          SKU ID
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'name' ? sortDirection : null}
          onClick={this.handleSortChange('name')}
        >
          Title
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'country' ? sortDirection : null}
          onClick={this.handleSortChange('country')}
        >
          Country
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'refName' ? sortDirection : null}
          onClick={this.handleSortChange('refName')}
        >
          Uploaded File
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'orderDate' ? sortDirection : null}
          onClick={this.handleSortChange('orderDate')}
        >
          Purchase Date
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'deliveryDate' ? sortDirection : null}
          onClick={this.handleSortChange('deliveryDate')}
        >
          Delivery Date
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'price' ? sortDirection : null}
          onClick={this.handleSortChange('price')}
        >
          Price
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'paymentStatusId' ? sortDirection : null}
          onClick={this.handleSortChange('paymentStatusId')}
        >
          Payment Status
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'orderStatusId' ? sortDirection : null}
          onClick={this.handleSortChange('orderStatusId')}
        >
          Order Status
        </Table.HeaderCell>
        {!restrictUserPermissions(
          [INTERNAL_ADMIN, STANDARD_USER],
          permission
        ) ? (
          <Table.HeaderCell />
        ) : (
          <Table.HeaderCell>Actions</Table.HeaderCell>
        )}
      </Table.Row>
    );

    const filterValue = this.state.filterValue.toLowerCase() || '';
    // RD-1890
    // RD-1881
    let rows = onlineStoreOrderItems
      .map((onlineStoreOrderItem: any) => {
        return {
          ...onlineStoreOrderItem,
          orderDate: new Date(onlineStoreOrderItem.orderDate),
          deliveryDate: new Date(onlineStoreOrderItem.deliveryDate),
        };
      })
      .filter((onlineStoreOrderItem: any) => {
        const name = onlineStoreOrderItem.name.toLowerCase();
        const orderId = onlineStoreOrderItem.orderId.toString();
        const skuId = onlineStoreOrderItem.skuNumber.toString();
        const country = onlineStoreOrderItem.country;
        return (
          name === filterValue ||
          name.includes(filterValue) ||
          orderId === filterValue ||
          orderId.includes(filterValue) ||
          skuId === filterValue ||
          skuId.includes(filterValue) ||
          country === filterValue ||
          country.includes(filterValue) ||
          filterValue === ''
        );
      })
      .sort(this.sort);

    rows = rows.map((onlineStoreOrderItem: any) => {
      if (
        !restrictUserPermissions(
          [INTERNAL_ADMIN, STANDARD_USER],
          permission
        )
      ) {
        return (
          <Table.Row key={onlineStoreOrderItem.id}>
            <Table.Cell>
              <Link to={`/storeorders/${onlineStoreOrderItem.orderId}/items`}>
                {onlineStoreOrderItem.orderId}
              </Link>
            </Table.Cell>
            <Table.Cell>{onlineStoreOrderItem.skuNumber}</Table.Cell>
            <Table.Cell>{onlineStoreOrderItem.name}</Table.Cell>
            <Table.Cell>{onlineStoreOrderItem.country}</Table.Cell>
            <Table.Cell>
              {this.isUploading(onlineStoreOrderItem.id) ? (
                <Loader active inline="centered" />
              ) : (
                onlineStoreOrderItem.refName
              )}
            </Table.Cell>
            <Table.Cell>
              {moment
                .utc(onlineStoreOrderItem.orderDate)
                .local()
                .format('YYYY-MM-DD LT')
                .toString()}
            </Table.Cell>
            <Table.Cell>
              {moment
                .utc(onlineStoreOrderItem.deliveryDate)
                .local()
                .format('YYYY-MM-DD LT')
                .toString()}
            </Table.Cell>
            <Table.Cell>{onlineStoreOrderItem.total}</Table.Cell>
            <Table.Cell>{onlineStoreOrderItem.paymentStatus}</Table.Cell>
            <Table.Cell>{onlineStoreOrderItem.orderStatus}</Table.Cell>
            <Table.Cell />
          </Table.Row>
        );
      }
      return (
        <Table.Row key={onlineStoreOrderItem.id}>
          <Table.Cell>
            <Link to={`/storeorders/${onlineStoreOrderItem.orderId}/items`}>
              {onlineStoreOrderItem.orderId}
            </Link>
          </Table.Cell>
          <Table.Cell>{onlineStoreOrderItem.skuNumber}</Table.Cell>
          <Table.Cell>{onlineStoreOrderItem.name}</Table.Cell>
          <Table.Cell>{onlineStoreOrderItem.country}</Table.Cell>
          <Table.Cell>
            {this.isUploading(onlineStoreOrderItem.id) ? (
              <Loader active inline="centered" />
            ) : (
              onlineStoreOrderItem.refName
            )}
          </Table.Cell>
          <Table.Cell>
            {moment
              .utc(onlineStoreOrderItem.orderDate)
              .local()
              .format('YYYY-MM-DD LT')
              .toString()}
          </Table.Cell>
          <Table.Cell>
            {moment
              .utc(onlineStoreOrderItem.deliveryDate)
              .local()
              .format('YYYY-MM-DD LT')
              .toString()}
          </Table.Cell>
          <Table.Cell>{onlineStoreOrderItem.total}</Table.Cell>
          <Table.Cell>{onlineStoreOrderItem.paymentStatus}</Table.Cell>
          <Table.Cell>{onlineStoreOrderItem.orderStatus}</Table.Cell>
          <Table.Cell>
            <FileButton
              disabled={this.isUploading(onlineStoreOrderItem.id)}
              content="Upload"
              onSelect={this.handleUpload(onlineStoreOrderItem.id)}
            />
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Container data-testid="organisations-container">
        <Grid centered stackable>
          <Grid.Column>
            <div className="page-content">
              <Breadcrumb currentContext="Store Order Items" />
              <div className="table-container">
                <TableActions
                  filterAction={this.handleFilterChange}
                  filterPlaceholder="Filter Store Items..."
                  // buttonURL="/storeitems/new"
                  // buttonText="Create Store Item"
                  hideButton={true}
                />
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    headerRow={TableHeader}
                    rows={rows}
                    sortable
                    stackEarly
                  />
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  onlineStoreOrderItems: getOnlineStoreOrderItemsSortedByStatus(
    state.onlineStoreOrderItems
  ),
  isFetching: state.onlineStoreOrderItems.isFetching,
  permission: getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    state.auth.authorisation
  ),
});

const mapDispatchToProps = (dispatch: any) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      uploadOnlineStoreOrderItem,
      loadOnlineStoreOrderItems,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreOrderItems): any);
