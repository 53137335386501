//@flow
import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import type { Element } from 'React';
import DownloadActivityModal from './DownloadActivityModal';

type Props = {
  organisationId: number,
  organisationName: string,
};

function DownloadActivity({organisationId, organisationName}: Props): Element<'div'> {
  const [
    addDownloadActivityModalOpen,
    setaddDownloadActivityModalOpen,
  ] = useState(false);

  const toggleAddDownloadActivityModal = () => {
    setaddDownloadActivityModalOpen(!addDownloadActivityModalOpen);
  };
  return (
    <div>
      <Button
        className="download-activity-button"
        onClick={toggleAddDownloadActivityModal}
      >
        Download Detailed Activity
      </Button>

      <DownloadActivityModal
        isOpen={addDownloadActivityModalOpen}
        onClose={toggleAddDownloadActivityModal}
        organisationId={organisationId}
        organisationName={organisationName}
      />
    </div>
  );
}

export default DownloadActivity;
