// @flow
import type { Feature } from '../types';

export const LIVE_MANAGEMENT_PRODUCT = 1;

export const managementFeatures = {
  LIVE_MANAGEMENT: 1,
  ONLINE_STORE_MANAGEMENT: 13,
  WORKSPACE_MANAGEMENT: 57
};

export const featureAccess = {
  GRANT: 'Grant',
  DENY: 'Deny',
  INHERIT: 'Inherit'
};


export function toHierarchyFeatureObject(
  features: Array<Feature>, 
  hasAccess: (feature: Feature) => boolean
): Array<Feature> {
    return getFeatureHierarchy(null, features, hasAccess);
}

function getFeatureHierarchy(
  root: ?Feature, 
  allFeatures: Array<Feature>, 
  hasAccess: (feature: Feature) => boolean) {
    let parentId = root ? root.id : undefined;
    let level = root ? root.level + 1 : 0;
    let children = allFeatures.filter(f => f.parentFeatureId === parentId);
    
    children.forEach(c => { 
        c.level = level;
        c.children = getFeatureHierarchy(c, allFeatures, hasAccess);
        if(!c.children.length) {
            delete c.children;
        }
        c.hasChildren = hasAccess(c) && !!c.children;
    });

    return children;
}