// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../../helpers';
import { callAPI, refreshToken } from '../../../../middleware';
import type { 
  ThunkAction, 
  Action, 
  OrganisationCollaboratorFeaturesResponse,
  Feature
} from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_FEATURE_UPDATE_REQUEST =
  'ORGANISATION_COLLABORATOR_FEATURE_UPDATE_REQUEST';
export const ORGANISATION_COLLABORATOR_FEATURE_UPDATE_SUCCESS =
  'ORGANISATION_COLLABORATOR_FEATURE_UPDATE_SUCCESS';
export const ORGANISATION_COLLABORATOR_FEATURE_UPDATE_FAILURE =
  'ORGANISATION_COLLABORATOR_FEATURE_UPDATE_FAILURE';

const updateOrganisationCollaboratorFeatureRequest = (featureId): Action => ({
  type: ORGANISATION_COLLABORATOR_FEATURE_UPDATE_REQUEST,
  payload: {
    featureId,
  },
});

const updateOrganisationCollaboratorFeatureSuccess = (
  features: OrganisationCollaboratorFeaturesResponse
): Action => ({
  type: ORGANISATION_COLLABORATOR_FEATURE_UPDATE_SUCCESS,
  payload: features,
});

const updateOrganisationCollaboratorFeatureFailure = (
  error: string,
  featureId: number
  // $FlowFixMe(DP) - Fix error.
): Action => ({
  type: ORGANISATION_COLLABORATOR_FEATURE_UPDATE_FAILURE,
  error,
  id: featureId,
});

export const updateOrganisationCollaboratorFeature = (
  organisationId: number,
  collaboratorId: number,
  featureId: number,
  body: {}
): ThunkAction => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationCollaboratorFeatureRequest(featureId));

    const response: Array<Feature> | Error = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/collaborators/${collaboratorId}/features/${featureId}`,
      body,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);
    dispatch(updateOrganisationCollaboratorFeatureSuccess(normalisedPayload));

    return response;
  } catch (error) {
    dispatch(
      updateOrganisationCollaboratorFeatureFailure(error.message, featureId)
    );
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
