// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import type { ThunkAction } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const USER_ACCOUNT_TYPES_LOAD_REQUEST =
  'USER_ACCOUNT_TYPES_LOAD_REQUEST';
export const USER_ACCOUNT_TYPES_LOAD_SUCCESS =
  'USER_ACCOUNT_TYPES_LOAD_SUCCESS';
export const USER_ACCOUNT_TYPES_LOAD_FAILURE =
  'USER_ACCOUNT_TYPES_LOAD_FAILURE';

const loadUserAccountTypesRequest = (): any => ({
  type: USER_ACCOUNT_TYPES_LOAD_REQUEST,
});

const loadUserAccountTypesSuccess = (options: any): any => ({
  type: USER_ACCOUNT_TYPES_LOAD_SUCCESS,
  payload: options,
});

const loadUserAccountTypesFailure = (error: string): any => ({
  type: USER_ACCOUNT_TYPES_LOAD_FAILURE,
  error,
});

export const loadUserAccountTypes = (): ThunkAction => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadUserAccountTypesRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'management')}/user-account-types`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadUserAccountTypesSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadUserAccountTypesFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
