//@flow
import React from 'react';
import TabHeader from '../../components/TabHeader';
import type {
  State as AppState,
  OrganisationTeam,
  Dispatch,
  OrganisationTeamCollaborator,
  AuthTypes,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Confirm, Table, Modal } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import PageLoader from '../../components/PageLoader';
import { loadOrganisationTeamCollaborators } from '../../actions/organisations/organisationTeams/organisationTeamCollaborators/';
import { removeOrganisationCollaboratorTeam } from '../../actions/organisations/organisationCollaborators/organisationCollaboratorTeams';

import { Link } from 'react-router-dom';
import { getCollaboratorsSortedAlphabetically } from '../../reducers/organisationTeamCollaborators';
import TableActions from '../../components/TableActions';
import AddTeamCollaborator from '../../components/OrganisationTeam/AddTeamCollaborator';
import OverflowMenu from '../../components/OverflowMenu';
import PaginatedTable from '../../components/PaginatedTable';

type Props = {
  organisationId: number,
  teamId: number,
  team: ?OrganisationTeam,
  loadOrganisationTeamCollaborators: (oid: number, tid: number) => Promise<*>,
  collaborators: Array<OrganisationTeamCollaborator>,
  isFetching: boolean,
  permission: ?AuthTypes,
  removeOrganisationCollaboratorTeam: (
    organisationId: number,
    teamId: number,
    collaboratorId: number
  ) => Promise<*>,
  isReady: boolean,
  setTeamCollaboratorsReady: () => void,
};

type State = {
  filterValue: string,
  addCollaboratorOpen: boolean,
  removeCollaboratorOpen: boolean,
  removeCollaboratorTarget?: {
    id: number,
    name: string,
  },
};

class TeamCollaborators extends React.Component<Props, State> {
  csvLink: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      addCollaboratorOpen: false,
      removeCollaboratorOpen: false,
      removeCollaboratorTarget: undefined,
    };

    this.csvLink = React.createRef();
  }

  componentDidMount() {
    const {
      loadOrganisationTeamCollaborators,
      organisationId,
      teamId,
    } = this.props;
    loadOrganisationTeamCollaborators(organisationId, teamId);
  }

  handleFilterChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  toggleModal = () => {
    this.setState({
      addCollaboratorOpen: !this.state.addCollaboratorOpen,
    });
  };

  setRemoveCollaboratorModalVisible = (userId: number, userName: string) => {
    this.setState({
      removeCollaboratorOpen: true,
      removeCollaboratorTarget: { id: userId, name: userName },
    });
  };

  handleRemoveCollaborator = () => {
    if (this.state.removeCollaboratorTarget) {
      this.props.removeOrganisationCollaboratorTeam(
        this.props.organisationId,
        this.props.teamId,
        this.state.removeCollaboratorTarget.id
      );
    }
    this.setState({
      ...this.state,
      removeCollaboratorOpen: false,
      removeCollaboratorTarget: undefined,
    });
  };

  exportCollaborators = () => {
    this.csvLink.current.link.click();
  };

  render() {
    const {
      organisationId,
      teamId,
      team,
      collaborators,
      isFetching
    } = this.props;
    const { addCollaboratorOpen } = this.state;


    const csvData = collaborators.map((collaborator: any) => {
      return {
        'Name': collaborator.name,
        'Email': collaborator.email,
        'Status': collaborator.status
      };
    });

    const filterValue = this.state.filterValue.toLowerCase();

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    );

    let rows = collaborators.filter((collaborator: any) => {
      const collaboratorName = collaborator.name.toLowerCase();
      const email = collaborator.email.toLowerCase();
      return (
        collaboratorName === filterValue ||
        collaboratorName.includes(filterValue) ||
        email === filterValue ||
        email.includes(filterValue) ||
        filterValue === ''
      );
    });
    rows = rows.map((collaborator: OrganisationTeamCollaborator) => {
      return (
        <Table.Row key={collaborator.id}>
          <Table.Cell>
            <Link
              to={`/organisations/${organisationId}/collaborators/${collaborator.id}/details`}
            >
              {collaborator.name}
            </Link>
          </Table.Cell>
          <Table.Cell>
            <Link
              to={`/organisations/${organisationId}/collaborators/${collaborator.id}/details`}
            >
              {collaborator.email}
            </Link>
          </Table.Cell>
          <Table.Cell>{collaborator.status}</Table.Cell>
          <Table.Cell>
            {!collaborator.canAccessAllTeams ? (
              <OverflowMenu>
                <li>
                  {/* // TODO: replace anchor with button // // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onKeyPress={() => {
                      this.setRemoveCollaboratorModalVisible(
                        collaborator.id,
                        collaborator.name
                      );
                    }}
                    onClick={() => {
                      this.setRemoveCollaboratorModalVisible(
                        collaborator.id,
                        collaborator.name
                      );
                    }}
                  >
                    Remove
                  </a>
                </li>
              </OverflowMenu>
            ) : (
              <span>N/A</span>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <TabHeader
          tabHeading={`${team ? team.name + "'s " : ''}Collaborators`}
          backLink={`/organisations/${organisationId}/teams`}
          backText="Back to Teams"
        />
        <Confirm
          // remove user
          content={`Are you sure you want to remove ${
            this.state.removeCollaboratorTarget &&
            this.state.removeCollaboratorTarget.name
              ? this.state.removeCollaboratorTarget.name
              : 'this user'
          } from the current team?`}
          open={this.state.removeCollaboratorOpen}
          onCancel={() =>
            this.setState({ ...this.state, removeCollaboratorOpen: false })
          }
          onConfirm={this.handleRemoveCollaborator}
        />

        <Grid centered stackable>
          <Grid.Column>
            <TableActions
              filterAction={this.handleFilterChange}
              filterPlaceholder="Filter Collaborators..."
              buttonText="Add Collaborator"
              buttonAction={this.toggleModal}
              downloadButtonAction={csvData.length === 0 ? null : this.exportCollaborators}
              showDownloadButton
            />
            {isFetching ? (
              <PageLoader />
            ) : (
              <PaginatedTable headerRow={headerRow} rows={rows} stackEarly />
            )}
          </Grid.Column>
        </Grid>
        <Modal
          open={addCollaboratorOpen}
          closeIcon={<button className="modal__close">Close</button>}
          onClose={this.toggleModal}
        >
          <Modal.Header>Add Collaborator to Team</Modal.Header>
          <Modal.Content>
            <AddTeamCollaborator
              organisationId={organisationId}
              teamId={teamId}
            />
          </Modal.Content>
        </Modal>
        <CSVLink
          data={csvData}
          filename={`organisation_${organisationId}_team_${teamId}_collaborators`}
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        >
        </CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  team: state.organisationTeams.byId[props.teamId.toString()],
  collaborators: getCollaboratorsSortedAlphabetically(state),
  isFetching: state.organisationTeamCollaborators.isFetching,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationTeamCollaborators,
      removeOrganisationCollaboratorTeam,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamCollaborators): any);
