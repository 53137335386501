// @flow
import * as React from 'react';
import { Form, Button } from 'semantic-ui-react';

type FormData = {
  newPassword: string,
  newPasswordConfirmation: string,
};

type Props = {
  handleSubmit: Function,
  isFetching?: ?boolean,
  error?: ?string,
};

type State = {
  formData: FormData,
};

class TempResetPasswordForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formData: {
        newPassword: '',
        newPasswordConfirmation: '',
      },
    };
  }

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  render(): React.Node {
    const { handleSubmit, isFetching } = this.props;
    const { newPassword, newPasswordConfirmation } = this.state.formData;

    const passwordTipStyle = {
      fontSize: '11px',
    };

    return (
      <Form
        onSubmit={() => {
          handleSubmit(newPassword);
        }}
        className="login-form__form"
      >
        <Form.Input
          label="New Password"
          name="newPassword"
          type="password"
          onChange={this.handleInputChange}
          disabled={isFetching}
        />

        <p style={passwordTipStyle}>
          Passwords must be at least eight characters in length, and must
          include one uppercase letter, one lowercase letter, and one numeric
          character
        </p>

        <Form.Input
          label="Confirm New Password"
          name="newPasswordConfirmation"
          type="password"
          onChange={this.handleInputChange}
          disabled={isFetching}
        />

        <div>
          <Button
            disabled={
              newPassword !== newPasswordConfirmation || newPassword === ''
                ? true
                : false
            }
            type="submit"
            loading={isFetching}
          >
            Update Password
          </Button>
        </div>
      </Form>
    );
  }
}

export default TempResetPasswordForm;
