// @flow
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { headerReducer } from 'roy-morgan-header';
import { users } from './users';
import { userOrganisations } from './userOrganisataions';
import { userAccountTypes } from './userAccountTypes';
import { accountManagers } from './accountManagers';
import { organisations } from './organisations';
import { organisationFlags } from './organisationFlags';
import { organisationUsers } from './organisationUsers';
import { organisationTeams } from './organisationTeams';
import { organisationTeamFeatures } from './organisationTeamFeatures';
import { organisationTeamUsers } from './organisationTeamUsers';
import { organisationTeamUsersWithoutAccess } from './organisationTeamUsersWithoutAccess';
import { organisationTeamCollaborators } from './organisationTeamCollaborators';
import { organisationCollaborators } from './organisationCollaborators';
import { organisationFeatures } from './organisationFeatures';
import { organisationUserFeatures } from './organisationUserFeatures';
import { organisationUserTeams } from './organisationUserTeams';
import { organisationUserOrganisations } from './organisationUserOrganisations';
import { organisationCollaboratorTeams } from './organisationCollaboratorTeams';
import { organisationCollaboratorFeatures } from './organisationCollaboratorFeatures';
import { organisationCollaboratorOrganisations } from './organisationCollaboratorOrganisations';
import { organisationAccountManagers } from './organisationAccountManagers';
import { apiSubscriptions } from './apiSubscriptions';
import { organisationsWithApiSubscription } from './organisationsWithApiSubscription';
import { organisationsWithoutApiSubscription } from './organisationsWithoutApiSubscription';
import { usersWithApiAccess } from './usersWithApiAccess';
import { usersWithoutApiAccess } from './usersWithoutApiAccess';
import { onlineStoreItems } from './onlineStoreItems';
import { emailNotifications } from './emailNotifications';
import { organisationTotalUsage } from './organisationTotalUsage';
import { userTotalUsage } from './userTotalUsage';
import { organisationPerUserUsage } from './organisationPerUserUsage';
import { userUsagePerDomain } from './userUsagePerDomain';
import { overallTotalUsageByOrg } from './overallTotalUsageByOrg';
import { overallTotalUsageByUser } from './overallTotalUsageByUser';
import {
  onlineStoreItemFormats,
  onlineStoreItemTypes,
  onlineStoreReportTypes,
  onlineStoreNavigationTree,
  onlineStoreOrderPaymentStatus,
  onlineStoreCouponTypes,
  onlineStoreCouponApplyTypes,
} from './onlineStoreOptions';
import { onlineTreeCategories } from './onlineStoreTreeCategories';
import { onlineStoreOrders } from './onlineStoreOrders';
import { onlineStoreOrderItems } from './onlineStoreOrderItems';
import { onlineStoreCoupons } from './onlineStoreCoupons';
import { onlineStoreCouponItems } from './onlineStoreCouponItems';
import { onlineStoreItemsWithoutCouponAccess } from './onlineStoreItemsWithoutCouponAccess';
import { messages } from './messages';
import { industries } from './industries';
import { pendingIssues } from './pendingIssues';
import { selfRegistration } from './selfRegistration';
import { usageDomains } from './usageDomains';
import { 
  searchableAudienceImports,
  nextSearchableAudienceImportDetails 
} from './workSpace';
import { reducer as authReducer } from 'roy-morgan-auth';
import { fetchSync } from './fetchSync';

const rootReducer: any = combineReducers({
  auth: authReducer,
  fetchSync,
  header: headerReducer,
  users,
  emailNotifications,
  userOrganisations,
  userAccountTypes,
  accountManagers,
  organisations,
  organisationFlags,
  organisationUsers,
  organisationUserFeatures,
  organisationUserTeams,
  organisationUserOrganisations,
  organisationTeams,
  organisationTeamFeatures,
  organisationTeamUsers,
  organisationTeamUsersWithoutAccess,
  organisationTeamCollaborators,
  organisationCollaborators,
  organisationCollaboratorFeatures,
  organisationCollaboratorTeams,
  organisationCollaboratorOrganisations,
  organisationAccountManagers,
  organisationFeatures,
  organisationTotalUsage,
  userTotalUsage,
  organisationPerUserUsage,
  userUsagePerDomain,
  apiSubscriptions,
  organisationsWithApiSubscription,
  organisationsWithoutApiSubscription,
  usersWithApiAccess,
  usersWithoutApiAccess,
  onlineStoreItems,
  onlineStoreItemFormats,
  onlineStoreItemTypes,
  onlineStoreReportTypes,
  onlineStoreNavigationTree,
  onlineStoreOrderPaymentStatus,
  onlineTreeCategories,
  onlineStoreOrders,
  onlineStoreOrderItems,
  onlineStoreCoupons,
  onlineStoreCouponTypes,
  onlineStoreCouponApplyTypes,
  onlineStoreCouponItems,
  onlineStoreItemsWithoutCouponAccess,
  industries,
  messages,
  pendingIssues,
  selfRegistration,
  usageDomains,
  overallTotalUsageByOrg,
  overallTotalUsageByUser,
  searchableAudienceImports,
  nextSearchableAudienceImportDetails,
  form: formReducer,
});

export default rootReducer;
