// @flow
import URLSearchParams from 'url-search-params';
import type { Location } from 'react-router-dom';

export function redirectHook(location: Location): string {
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get('redirect');
  let finalRedirect = '/';
  if (redirectTo) {
    const decoded = decodeURIComponent(redirectTo);
    if (
      extractHostname(decoded) === extractHostname(document.location.origin)
    ) {
      finalRedirect = new URL(decoded).pathname;
    }
  }
  return finalRedirect;
}

function extractHostname(url: any) {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}
