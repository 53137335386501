// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USER_TEAM_REMOVE_REQUEST =
  'ORGANISATION_USER_TEAM_REMOVE_REQUEST';
export const ORGANISATION_USER_TEAM_REMOVE_SUCCESS =
  'ORGANISATION_USER_TEAM_REMOVE_SUCCESS';
export const ORGANISATION_USER_TEAM_REMOVE_FAILURE =
  'ORGANISATION_USER_TEAM_REMOVE_FAILURE';

const removeOrganisationUserTeamRequest = (): Object => ({
  type: ORGANISATION_USER_TEAM_REMOVE_REQUEST,
});

const removeOrganisationUserTeamSuccess = (teamId, userId): Object => ({
  type: ORGANISATION_USER_TEAM_REMOVE_SUCCESS,
  teamId,
  userId,
});

const removeOrganisationUserTeamFailure = (error): Object => ({
  type: ORGANISATION_USER_TEAM_REMOVE_FAILURE,
  error,
});

export const removeOrganisationUserTeam = (
  organisationId: number,
  teamId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOrganisationUserTeamRequest());

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}/users/${userId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(removeOrganisationUserTeamSuccess(teamId, userId));

    return true;
  } catch (error) {
    dispatch(removeOrganisationUserTeamFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
