// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_ORDER_ITEMS_LOAD_REQUEST =
  'ONLINE_STORE_ORDER_ITEMS_LOAD_REQUEST';
export const ONLINE_STORE_ORDER_ITEMS_LOAD_SUCCESS =
  'ONLINE_STORE_ORDER_ITEMS_LOAD_SUCCESS';
export const ONLINE_STORE_ORDER_ITEMS_LOAD_FAILURE =
  'ONLINE_STORE_ORDER_ITEMS_LOAD_FAILURE';

const loadOnlineStoreOrderItemsRequest = () => ({
  type: ONLINE_STORE_ORDER_ITEMS_LOAD_REQUEST,
});

const loadOnlineStoreOrderItemsSuccess = (onlineStorePurchasedItems) => ({
  type: ONLINE_STORE_ORDER_ITEMS_LOAD_SUCCESS,
  payload: onlineStorePurchasedItems,
});

const loadOnlineStoreOrderItemsFailure = (error) => ({
  type: ONLINE_STORE_ORDER_ITEMS_LOAD_FAILURE,
  error,
});

export const loadOnlineStoreOrderItems = (): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOnlineStoreOrderItemsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/management/orderitems`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOnlineStoreOrderItemsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOnlineStoreOrderItemsFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
