//@flow
import React from 'react';
import type {
  // State as AppState,
  AuthTypes,
  // Dispatch,
  OnlineStoreOrder,
  // OnlineStoreOrderPaymentStatus,
  OnlineStoreOrderForm,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  updateOnlineStoreOrder,
  // $FlowFixMe
  loadOrderPaymentStatus,
  sendReceiptEmail,
} from '../../actions';
import { Redirect } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import TabHeader from '../../components/TabHeader';
import OnlineStoreOrderFormFields from '../../components/OnlineStoreOrder/OnlineStoreOrderFormFields';
import { getOnlineStoreOrderPaymentStatusSortedAlphabetically } from '../../reducers/onlineStoreOptions';
import { managementFeatures } from '../../helpers';
import { getPermissionInFeature } from 'roy-morgan-auth';

type Props = {
  onlineStoreOrderId: number,
  onlineStoreOrder: OnlineStoreOrder,
  paymentStatus: Array<any>,
  updateOnlineStoreOrder: (
    id: number,
    body: Object
  ) => Promise<OnlineStoreOrder>,
  loadOrderPaymentStatus: () => Promise<any>,
  sendReceiptEmail: (onlineStoreOrderId: number) => Promise<any>,
  isFetching: boolean,
  permission: ?AuthTypes,
};

class OnlineStoreOrderDetails extends React.Component<Props, any> {
  componentDidMount() {
    this.props.loadOrderPaymentStatus();
  }

  handleFormSubmit = (formData: OnlineStoreOrderForm) => {
    this.props.updateOnlineStoreOrder(this.props.onlineStoreOrderId, formData);
  };

  sendReceiptEmail = (orderId: number) => {
    this.props.sendReceiptEmail(orderId);
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (typeof nextProps.onlineStoreOrder === 'undefined') {
      this.setState({
        ...this.state,
      });
    }
  }

  render() {
    const {
      onlineStoreOrder,
      isFetching,
      permission,
      paymentStatus,
    } = this.props;

    if (onlineStoreOrder === undefined) {
      return <Redirect to={`/storeorders`} />;
    } else {
      return (
        <div>
          {onlineStoreOrder ? <TabHeader tabHeading={`Order Details`} /> : ''}
          {isFetching ? (
            <PageLoader />
          ) : (
            <div>
              <OnlineStoreOrderFormFields
                onlineStoreOrder={onlineStoreOrder}
                permission={permission}
                paymentStatus={paymentStatus}
                handleFormSubmit={this.handleFormSubmit}
                sendReceiptEmail={this.sendReceiptEmail}
              />
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state: any, props) => ({
  isFetching: state.onlineStoreOrders.isFetching,
  onlineStoreOrder: state.onlineStoreOrders.byId[props.onlineStoreOrderId],
  paymentStatus: getOnlineStoreOrderPaymentStatusSortedAlphabetically(state),
  permission: getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    state.auth.authorisation
  ),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      updateOnlineStoreOrder,
      loadOrderPaymentStatus,
      sendReceiptEmail,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreOrderDetails): any);
