// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL, loadUserOrganisations } from 'roy-morgan-auth';
import { addOrganisationAccountManager } from './organisationAccountManagers/addOrganisationAccountManager';

import type {
  // ThunkAction,
  OrganisationForm,
  AccountManager
} from '../../types';

export const ORGANISATION_CREATE_REQUEST = 'ORGANISATION_CREATE_REQUEST';
export const ORGANISATION_CREATE_SUCCESS = 'ORGANISATION_CREATE_SUCCESS';
export const ORGANISATION_CREATE_FAILURE = 'ORGANISATION_CREATE_FAILURE';

const createOrganisationRequest = () => ({
  type: ORGANISATION_CREATE_REQUEST,
});

const createOrganisationSuccess = (organisations) => ({
  type: ORGANISATION_CREATE_SUCCESS,
  payload: organisations,
});

const createOrganisationFailure = (error) => ({
  type: ORGANISATION_CREATE_FAILURE,
  error,
});

export const createOrganisation = (body: OrganisationForm, accountManagers: Array<AccountManager>): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(createOrganisationRequest());

    const requestBody = {
      ...body,
      adminUserId: getState().auth.user.id,
    };

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL('v2', 'management')}/organisations`,
      body: requestBody,
    });

    if (response instanceof Error) {
      throw response;
    }

    for(let manager of accountManagers) {
      await dispatch(addOrganisationAccountManager(response.id, manager.id));
    }

    await dispatch(loadUserOrganisations()); //add new organisation to internal user's organisation list
    dispatch(createOrganisationSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(createOrganisationFailure(error.message));
    throw error;
  }
};
