// @flow
import type { EmailNotifications, Action } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';
import { createSelector } from 'reselect';

/* Initial State */
const initialState: EmailNotifications = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

/* Reducer */
export const emailNotifications = (
  state: EmailNotifications = initialState,
  action: Action
): EmailNotifications => {
  switch (action.type) {
    case 'READ_EMAIL_NOTIFICATION_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          [action.payload.id]: action.payload,
        },
        isFetching: false,
        error: undefined,
      };
    // Make request
    case 'READ_EMAIL_NOTIFICATION_REQUEST':
    case 'EMAIL_NOTIFICATIONS_LOAD_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'EMAIL_NOTIFICATIONS_LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    // Handle failure
    case 'READ_EMAIL_NOTIFICATION_FAILURE':
    case 'EMAIL_NOTIFICATIONS_LOAD_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */

export const getSortedAlphabetically: any = createSelector(
  (state) => state.emailNotifications.byId,
  (state) =>  state.emailNotifications.order,
  (items, order) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
          if (items[a].timestemp > items[b].timestemp) {
            return -1;
          } else if (items[a].timestemp < items[b].timestemp) {
            return 1;
          } else {
            return 0;
          }
        })
        .map(function (sortedKey) {
          return items[sortedKey];
        });
  }
);


