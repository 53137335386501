// @flow
import * as Sentry from '@sentry/browser';
import type {
  OrganisationTeamUserWithoutAccess,
  State as AppState,
  Dispatch,
} from '../../types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';
import PaginatedTable from '../PaginatedTable';
import PageLoader from '../PageLoader';
import {
  loadOrganisationTeamUsersWithoutAccess,
  addOrganisationUserToOrganisationTeam,
  loadOrganisationTeamUsers,
} from '../../actions/organisations/organisationTeams/organisationTeamUsers';
import { getUsersSortedAlphabetically } from '../../reducers/organisationTeamUsersWithoutAccess';

type Props = {
  organisationId: number,
  teamId: number,
  usersWithoutAccess: Array<OrganisationTeamUserWithoutAccess>,
  loadOrganisationTeamUsersWithoutAccess: (
    oid: number,
    tid: number
  ) => Promise<*>,
  addOrganisationUserToOrganisationTeam: (
    oid: number,
    tid: number,
    uid: number
  ) => Promise<*>,
  loadOrganisationTeamUsers: (oid: number, tid: number) => Promise<*>,
};

type State = {
  userFilterValue: string,
  isAdding: boolean,
  isAddingId?: number,
  initialLoadComplete: boolean,
};

class AddTeamUser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userFilterValue: '',
      isAdding: false,
      isAddingId: undefined,
      initialLoadComplete: false,
    };
  }

  componentDidMount() {
    const {
      loadOrganisationTeamUsersWithoutAccess,
      organisationId,
      teamId,
    } = this.props;

    loadOrganisationTeamUsersWithoutAccess(organisationId, teamId).then(
      (success) =>
        this.setState({
          initialLoadComplete: true,
        }),
      (error) =>
        this.setState({
          initialLoadComplete: true,
        })
    );
  }

  handleAddUser = async (userId: number) => {
    try {
      const {
        organisationId,
        teamId,
        addOrganisationUserToOrganisationTeam,
        loadOrganisationTeamUsersWithoutAccess,
        loadOrganisationTeamUsers,
      } = this.props;

      this.setState({
        isAdding: true,
        isAddingId: userId,
      });

      const addResult = await addOrganisationUserToOrganisationTeam(
        organisationId,
        teamId,
        userId
      );

      if (addResult) {
        await loadOrganisationTeamUsersWithoutAccess(organisationId, teamId);
        loadOrganisationTeamUsers(organisationId, teamId);
      }

      this.setState({
        isAdding: false,
        isAddingId: undefined,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      this.setState({
        isAdding: false,
        isAddingId: undefined,
      });
    }
  };

  render() {
    const users = this.props.usersWithoutAccess;
    const userFilterValue = this.state.userFilterValue.toLowerCase();
    const { initialLoadComplete } = this.state;

    let rows: Array<any> = [];

    if (users) {
      rows = users.filter((user: OrganisationTeamUserWithoutAccess) => {
        const userName = user.name.toLowerCase();
        return (
          userName === userFilterValue ||
          userName.includes(userFilterValue) ||
          userFilterValue === ''
        );
      });
      rows = rows.map((user: OrganisationTeamUserWithoutAccess) => {
        return (
          <Table.Row key={user.id}>
            <Table.Cell>{user.name}</Table.Cell>
            <Table.Cell>
              <Button
                onClick={() => this.handleAddUser(user.id)}
                loading={this.state.isAddingId === user.id}
                disabled={this.state.isAdding}
                floated="right"
              >
                Add
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell colSpan={2}>Name</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        {initialLoadComplete ? (
          <PaginatedTable headerRow={tableHeader} rows={rows} />
        ) : (
          <PageLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  usersWithoutAccess: getUsersSortedAlphabetically(state),
  isFetching: state.organisationTeamUsersWithoutAccess.isFetching,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationTeamUsersWithoutAccess,
      addOrganisationUserToOrganisationTeam,
      loadOrganisationTeamUsers,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(AddTeamUser): any);
