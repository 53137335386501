// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_FORCE_VERIFY_REQUEST =
  'ORGANISATION_FORCE_VERIFY_REQUEST';
export const ORGANISATION_FORCE_VERIFY_SUCCESS =
  'ORGANISATION_FORCE_VERIFY_SUCCESS';
export const ORGANISATION_FORCE_VERIFY_FAILURE =
  'ORGANISATION_FORCE_VERIFY_FAILURE';

function forceVerifyOrganisationRequest() {
  return {
    type: ORGANISATION_FORCE_VERIFY_REQUEST,
  };
}

function forceVerifyOrganisationSuccess() {
  return {
    type: ORGANISATION_FORCE_VERIFY_SUCCESS,
  };
}

function forceVerifyOrganisationFailure(error) {
  return {
    type: ORGANISATION_FORCE_VERIFY_FAILURE,
    error: error,
  };
}

export const forceVerifyOrganisation = (organisationId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(forceVerifyOrganisationRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/forceverify`,
    });

    dispatch(forceVerifyOrganisationSuccess());
    return response;
  } catch (error) {
    dispatch(forceVerifyOrganisationFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
