// @flow
import React from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

type User = {
  name?: string,
  email?: string,
  phone?: string,
  password?: string,
};

type Props = {
  user: User,
  isFetching: boolean,
  handleFormSubmit: (formData: User) => void,
};

type State = {
  formData: {
    ...User,
    confirmPassword?: string,
  },
};

class SelfRegisterFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formData: {
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
      },
    };
  }

  handleInputChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  render() {
    const { isFetching } = this.props;
    const {
      name,
      email,
      phone,
      password,
      confirmPassword,
    } = this.state.formData;
    const { handleFormSubmit } = this.props;

    const passwordTipStyle = {
      fontSize: '11px',
    };
    return (
      <Form
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Name"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                type="text"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Email"
                name="email"
                value={email}
                onChange={this.handleInputChange}
                type="email"
                required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Phone"
                name="phone"
                value={phone}
                onChange={this.handleInputChange}
                type="phone"
                required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                type="password"
                required
              />
              <p style={passwordTipStyle}>
                Passwords must be at least eight characters in length, and must
                include one uppercase letter, one lowercase letter, and one
                numeric character
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={this.handleInputChange}
                type="password"
                required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Button
                className="organisation-form__submit"
                type="submit"
                loading={isFetching}
              >
                Register
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}
const mapStateToProps = (state, props) => ({
  isFetching: state.selfRegistration.isFetching,
});

export default (connect(mapStateToProps)(SelfRegisterFormFields): any);
