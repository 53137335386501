// @flow
import { createSelector } from 'reselect';
import type { OrganisationTeams } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: OrganisationTeams = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

export const organisationTeams = (
  state: OrganisationTeams = initialState,
  action: Object
): OrganisationTeams => {
  switch (action.type) {
    case 'ORGANISATION_TEAM_CREATE_REQUEST':
    case 'ORGANISATION_TEAMS_LOAD_REQUEST':
    case 'ORGANISATION_TEAM_UPDATE_REQUEST':
    case 'ORGANISATION_TEAM_READ_REQUEST':
    case 'ORGANISATION_TEAM_ARCHIVE_REQUEST':
    case 'ORGANISATION_TEAM_FORCE_VERIFY_REQUEST':
    case 'ORGANISATION_TEAMS_LOAD_ALL_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case 'ORGANISATION_TEAMS_LOAD_FAILURE':
    case 'ORGANISATION_TEAM_CREATE_FAILURE':
    case 'ORGANISATION_TEAM_UPDATE_FAILURE':
    case 'ORGANISATION_TEAM_READ_FAILURE':
    case 'ORGANISATION_TEAM_ARCHIVE_FAILURE':
    case 'ORGANISATION_TEAM_FORCE_VERIFY_FAILURE':
    case 'ORGANISATION_TEAMS_LOAD_ALL_FAILURE':
      return {
        ...state,
        isFetching: false,
        // $FlowFixMe
        error: action.error,
      };
    case 'ORGANISATION_TEAMS_LOAD_SUCCESS':
    case 'ORGANISATION_TEAMS_LOAD_ALL_SUCCESS':
      return {
        ...state,
        // $FlowFixMe
        byId: {
          ...state.byId,
          ...action.payload.byId,
        },
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_TEAM_CREATE_SUCCESS':
    case 'ORGANISATION_TEAM_UPDATE_SUCCESS':
    case 'ORGANISATION_TEAM_READ_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe TODO(DP): Fix this
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
          },
        },
        // $FlowFixMe
        order: [...state.order, action.payload.id],
        isFetching: false,
        error: undefined,
      };
    /* eslint-disable no-case-declarations */
    case 'ORGANISATION_TEAM_ARCHIVE_SUCCESS':
      let copy = state.byId;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.payload.id];
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.payload.id);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    /* eslint-enable no-case-declarations */
    case 'ORGANISATION_TEAM_FORCE_VERIFY_SUCCESS':
      return {
        ...state,
        isFetching: false,
        error: undefined,
      };
    case 'ORGANISATION_CHANGE':
      return {
        ...state,
        byId: {},
        order: [],
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const getOrganisationTeamsSortedAlphabetically: any = createSelector(
  (state) => state.organisationTeams.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        // return items[ a ].name - items[ b ].name;
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
