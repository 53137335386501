// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import type { ThunkAction } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ITEM_FORMATS_LOAD_REQUEST = 'ITEM_FORMATS_LOAD_REQUEST';
export const ITEM_FORMATS_LOAD_SUCCESS = 'ITEM_FORMATS_LOAD_SUCCESS';
export const ITEM_FORMATS_LOAD_FAILURE = 'ITEM_FORMATS_LOAD_FAILURE';

const loadItemFormatsRequest = (): any => ({
  type: ITEM_FORMATS_LOAD_REQUEST,
});

const loadItemFormatsSuccess = (formats: any): any => ({
  type: ITEM_FORMATS_LOAD_SUCCESS,
  payload: formats,
});

const loadItemFormatsFailure = (error: string): any => ({
  type: ITEM_FORMATS_LOAD_FAILURE,
  error,
});

export const loadItemFormats = (): ThunkAction => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadItemFormatsRequest());

    const response: any[] | Error = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/management/options/itemformat`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload: any = normaliseArrayOfObjects(response);

    dispatch(loadItemFormatsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadItemFormatsFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
