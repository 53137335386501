// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';
// import type { ThunkAction, Dispatch, Action } from '../../types';

export const GLOBAL_USER_ORGANISATIONS_LOAD_REQUEST =
  'GLOBAL_USER_ORGANISATIONS_LOAD_REQUEST';
export const GLOBAL_USER_ORGANISATIONS_LOAD_SUCCESS =
  'GLOBAL_USER_ORGANISATIONS_LOAD_SUCCESS';
export const GLOBAL_USER_ORGANISATIONS_LOAD_FAILURE =
  'GLOBAL_USER_ORGANISATIONS_LOAD_FAILURE';

const loadUserOrganisationsRequest = () => ({
  type: GLOBAL_USER_ORGANISATIONS_LOAD_REQUEST,
});

const loadUserOrganisationsSuccess = (organisations) => ({
  type: GLOBAL_USER_ORGANISATIONS_LOAD_SUCCESS,
  payload: organisations,
});

const loadUserOrganisationsFailure = (error) => ({
  type: GLOBAL_USER_ORGANISATIONS_LOAD_FAILURE,
  error,
});

export const loadUserOrganisations = (userId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadUserOrganisationsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/users/${userId}/organisations?excludeArchived=true`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadUserOrganisationsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadUserOrganisationsFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
