// @flow
import React from 'react';
import { Grid, Form, Dropdown, Button } from 'semantic-ui-react';
import type { OnlineStoreOrderForm, AuthTypes } from '../../types';
import StaticField from '../StaticField';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import moment from 'moment';
import type { Node } from 'React';

type Props = {
  permission: ?AuthTypes,
  onlineStoreOrder: any,
  handleFormSubmit: (formData: OnlineStoreOrderForm) => void,
  sendReceiptEmail: (onlineStoreOrderId: number) => void,
  paymentStatus: Array<any>,
  children?: any,
};

type State = any;

class OnlineStoreOrderFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const onlineStoreOrder = this.props.onlineStoreOrder;

    this.state = {
      formData: {
        paymentStatusId: onlineStoreOrder.paymentStatusId,
      },
      buyerName: onlineStoreOrder.buyerName,
      email: onlineStoreOrder.email,
      billingAddress: onlineStoreOrder.billingAddress,
      contactNumber: onlineStoreOrder.phone,
      paymentMethodId: onlineStoreOrder.paymentMethodId,
      paymentMethod: onlineStoreOrder.paymentMethod,
      paymentStatusText: onlineStoreOrder.paymentStatus,
      orderDate: onlineStoreOrder.orderDate,
      orderStatusId: onlineStoreOrder.orderStatusId,
      orderStatus: onlineStoreOrder.orderStatus,
      orgName: onlineStoreOrder.orgName,
      total: onlineStoreOrder.total,
      tax: onlineStoreOrder.gst,
      discount: onlineStoreOrder.discount,
    };
  }

  handleChangePaymentStatus: (object: any) => void = (object: any) => {
    let form = { paymentStatusId: object.value };
    this.props.handleFormSubmit(form);
  };

  render(): Node {
    const { formData } = this.state;
    const {
      buyerName,
      email,
      billingAddress,
      contactNumber,
      paymentMethod,
      orderDate,
      orderStatus,
      paymentStatusText,
      orgName,
      total,
      tax,
      discount,
    } = this.state;
    const { paymentStatus, permission, sendReceiptEmail } = this.props;
    const { paymentStatusId } = formData;

    const paymentStatusOptions = paymentStatus.map((o: any) => {
      return {
        text: o.name,
        value: o.id,
      };
    });

    if (
      !restrictUserPermissions(
        [permissions.INTERNAL_ADMIN, permissions.STANDARD_USER],
        permission
      )
    ) {
      return <StaticForm {...this.props} formData={this.state} />;
    }

    return (
      <Form>
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column>
              <StaticField label="Buyer's Name" value={buyerName} />
            </Grid.Column>
            <Grid.Column>
              <StaticField label="Buyer's Email" value={email} />
            </Grid.Column>
            <Grid.Column>
              <StaticField
                label="Contact Number"
                value={contactNumber ? contactNumber : ''}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <StaticField
                label="Organisation Name"
                value={orgName ? orgName : ''}
              />
            </Grid.Column>
            <Grid.Column>
              <StaticField
                label="Billing Address"
                value={billingAddress ? billingAddress : ''}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <StaticField label="Payment Method" value={paymentMethod} />
            </Grid.Column>
            <Grid.Column>
              {paymentMethod === 'EFT' ? (
                <Form.Field>
                  <label htmlFor="paymentStatusId">Payment Status</label>
                  <Dropdown
                    onChange={(e, object) => {
                      this.handleChangePaymentStatus(object);
                    }}
                    name="paymentStatusId"
                    value={paymentStatusId}
                    fluid
                    selection
                    placeholder={'Select Payment Status'}
                    options={paymentStatusOptions}
                  />
                </Form.Field>
              ) : (
                <StaticField label="Payment Status" value={paymentStatusText} />
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <StaticField label="Total Price (incl. GST)" value={total} />
            </Grid.Column>
            <Grid.Column>
              <StaticField label="GST" value={tax} />
            </Grid.Column>
            <Grid.Column>
              <StaticField label="Discount" value={discount} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <StaticField
                label="Order Date"
                value={moment
                  .utc(orderDate)
                  .local()
                  .format('YYYY-MM-DD LT')
                  .toString()}
              />
            </Grid.Column>
            <Grid.Column>
              <StaticField label="Order Status" value={orderStatus} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button
                className="organisation-form__submit"
                onClick={(e) =>
                  sendReceiptEmail(this.props.onlineStoreOrder.id)
                }
              >
                Send Receipt Email
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const StaticForm = (props: { formData: Object }) => {
  const { formData } = props;
  const {
    buyerName,
    email,
    billingAddress,
    contactNumber,
    paymentMethod,
    paymentStatus,
    orderDate,
    orderStatus,
    orgName,
    amount,
    tax,
  } = formData;

  return (
    <Form>
      <Grid columns={3} stackable>
        <Grid.Row>
          <Grid.Column>
            <StaticField label="Buyer's Name" value={buyerName} />
          </Grid.Column>
          <Grid.Column>
            <StaticField label="Buyer's Email" value={email} />
          </Grid.Column>
          <Grid.Column>
            <StaticField
              label="Contact Number"
              value={contactNumber ? contactNumber : ''}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField
              label="Organisation Name"
              value={orgName ? orgName : ''}
            />
          </Grid.Column>
          <Grid.Column>
            <StaticField
              label="Billing Address"
              value={billingAddress ? billingAddress : ''}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField label="Payment Method" value={paymentMethod} />
          </Grid.Column>
          <Grid.Column>
            <StaticField label="Payment Status" value={paymentStatus} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField label="Total Price (incl. GST)" value={amount} />
          </Grid.Column>
          <Grid.Column>
            <StaticField label="GST" value={tax} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField label="Order Date" value={orderDate} />
          </Grid.Column>
          <Grid.Column>
            <StaticField label="Order Status" value={orderStatus} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default OnlineStoreOrderFormFields;
