//@flow
import React, { useState } from 'react';
import type { Element, Node } from 'React';

import { Table, Icon } from 'semantic-ui-react';
import PaginatedTable from './PaginatedTable';

type Props = {
  perPage?: number,
  headerRow: Node,
  columns: Array<any>,
  items: Array<any>,
  rowClassName: string
};

export default function HierarchyTable(props: Props) : Element<any> {
  const {
    perPage,
    headerRow,
    columns,
    items,
    rowClassName
  } = props;

  const [expandedRows, setExpandedRows] = useState([]);

  const isRowExpanded = (id: number) => {
    return expandedRows.includes(id);
  };

  const handleExpand = (id: number) : void => {
    setExpandedRows([
      ...expandedRows,
      id
    ]);
  };

  const handleCollapse = (id: number) : void => {
    let updatedList = expandedRows.filter(r => r !== id);
    setExpandedRows(updatedList);
  };

  const getRow = (item: any): Node => {
    return (
      <Table.Row className={rowClassName} key={item.id}>
        {
          columns.map((col,idx) => {
            if(idx === 0) {
              return (
                <Table.Cell key={`${item.id}-${idx}`} className={`hierarchy-row level-${item.level}`}>
                  {item.hasChildren
                    ? isRowExpanded(item.id)
                      ? (<Icon className="angle down" link onClick={() => handleCollapse(item.id)} />) 
                      : (<Icon className="angle right" link onClick={() => handleExpand(item.id)} />)
                    : 
                  ''}
                  {col(item)}
                </Table.Cell>
              )
            }

            return (<Table.Cell key={`${item.id}-${idx}`}>{col(item)}</Table.Cell>);
          })
        }
      </Table.Row>
    );
  }

  const getRows = (items: Array<any>): Array<Node> => {
    let rows=[];
	  
    items.forEach(item => {
      rows.push(getRow(item));
      if(item.hasChildren && isRowExpanded(item.id)) {
        let decendantRows = getRows(item.children);
			  rows.push(...decendantRows);
      }
    });
		
	  return rows;
  }

  let rows = getRows(items)

  return (
    <PaginatedTable
      headerRow={headerRow}
      rows={rows}
      perPage={perPage}
    />
  );
}

