// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import type { Organisation } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_ALLOW_TO_HAVE_TEAMS_REQUEST = 'ORGANISATION_ALLOW_TO_HAVE_TEAMS_REQUEST';
export const ORGANISATION_ALLOW_TO_HAVE_TEAMS_SUCCESS = 'ORGANISATION_ALLOW_TO_HAVE_TEAMS_SUCCESS';
export const ORGANISATION_ALLOW_TO_HAVE_TEAMS_FAILURE = 'ORGANISATION_ALLOW_TO_HAVE_TEAMS_FAILURE';

function allowToHaveTeamsOrganisationRequest() {
  return {
    type: ORGANISATION_ALLOW_TO_HAVE_TEAMS_REQUEST,
  };
}

function allowToHaveTeamsOrganisationSuccess(organisation: Organisation) {
  return {
    type: ORGANISATION_ALLOW_TO_HAVE_TEAMS_SUCCESS,
    payload: organisation,
  };
}

function allowToHaveTeamsOrganisationFailure(error: string) {
  return {
    type: ORGANISATION_ALLOW_TO_HAVE_TEAMS_FAILURE,
    error: error,
  };
}

export const allowToHaveTeamsOrganisation = (
  organisationId: number, teamNames: string
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(allowToHaveTeamsOrganisationRequest());
    const requestBody = {
      teams: teamNames
    };

    const response = await callAPI({
      method: 'PUT',
      body: requestBody,
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/allow-to-have-teams`
    });

    dispatch(allowToHaveTeamsOrganisationSuccess(response));
    return response;
  } catch (error) {
    dispatch(allowToHaveTeamsOrganisationFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
      Sentry.captureException(error);
    }

    return error;
  }
};
