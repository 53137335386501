/* eslint-disable react/display-name */
//@flow
import React, { useEffect }  from 'react';
import type { Element } from 'React';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Route, Switch } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';

import { managementFeatures } from '../../helpers';
import {
  getPermissionInFeature,
  restrictUserPermissions,
  permissions
} from 'roy-morgan-auth';

import SideMenu from '../../components/SideMenu';
import Breadcrumb from '../../components/Breadcrumb';
import ErrorMessage from '../../components/ErrorMessage';
import PageLoader from '../../components/PageLoader';

import ApiSubscriptionDetails from './tabs/ApiSubscriptionDetails';
import ApiSubscriptionUsers from './tabs/subTabs/ApiSubscriptionUsers';

import { loadApiSubscriptions } from '../../actions';

type Props = {
  location: any
};

export default function ApiSubscripton(props: Props) : Element<any> {
  const dispatch = useDispatch();
  const { apiId, organisationId } = useParams();
  const { location } = props;

  useEffect(() => {
    dispatch(loadApiSubscriptions());
  }, [dispatch]);

  const { items: apiSubscriptions, isFetching, error } = useSelector(
    state => state.apiSubscriptions
  );
  const apiSubscription = isFetching
    ? null
    : apiSubscriptions.find(a => a.id === parseInt(apiId));

  const { authorisation } = useSelector(state => state.auth);
  const permission = getPermissionInFeature(
    managementFeatures.LIVE_MANAGEMENT,
    authorisation
  );

  const organisation = useSelector(
    state => state.organisationsWithApiSubscription.byId[organisationId]
  );
  const subMenuDisplay = organisationId ? true : false;
  const subMenuTitle = organisation && organisation.name
    ? organisation.name
    : '';

  const currentOrganisationId = parseInt(organisationId) || 0;

  const subMenu = [
    {
      label: 'Users With Access',
      container: () => (
        <ApiSubscriptionUsers
          organisationId={currentOrganisationId}
          api={apiSubscription}
        />
      ),
      path: `/api-subscriptions/${apiId || ''}/details/${organisationId || ''}/users`,
      acceptedPermissions: [permissions.INTERNAL_ADMIN]
    }
  ];

  const menuItems = [
    {
      label: 'Details',
      icon: 'details',
      path: `/api-subscriptions/${apiId || ''}/details`,
      container: () => (
        <ApiSubscriptionDetails
          subItems={subMenu}
          api={apiSubscription}
        />
      ),
      acceptedPermissions: [permissions.INTERNAL_ADMIN],
      subMenuDisplay,
      subMenu,
      subMenuTitle,
    }
  ];

  if (error) {
    return <ErrorMessage message={error.toString()} />;
  }

  if(isFetching || !apiSubscription) {
    return (
      <Grid.Row>
        <PageLoader />
      </Grid.Row>);
  }

  return (
    <Container>
      <Grid>
        <Grid.Column width={12}>
          <Breadcrumb
            topLevelContext="Back to API Listing"
            currentContext={apiSubscription.name}
            backLink={'/api-subscriptions'}
          />
        </Grid.Column>
      </Grid>

      <Grid stackable className="side-menu-layout">
        <Grid.Column width={3} className="side-menu-column">
          <SideMenu
            currentPath={location.pathname}
            items={menuItems}
            permission={permission}
          />
        </Grid.Column>

        <Grid.Column  width={9}>
            {isFetching || !apiSubscription ? (
              <Grid.Row>
                <PageLoader />
              </Grid.Row>
            ) : (
              <Switch>
                {
                  menuItems.map((item, index) => {
                    let hasPermission = restrictUserPermissions(item.acceptedPermissions, permission);
                    if (hasPermission) {
                      return (
                        <Route
                          key={index}
                          path={item.path}
                          render={item.container}
                        />
                      );
                    }
                    return '';
                  })
                }
              </Switch>
          )}
        </Grid.Column>

      </Grid>
    </Container>
  );
}
