// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware/index';
import type { OnlineStoreItem } from '../../../types/index';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINESTOREITEM_CREATE_REQUEST = 'ONLINESTOREITEM_CREATE_REQUEST';
export const ONLINESTOREITEM_CREATE_SUCCESS = 'ONLINESTOREITEM_CREATE_SUCCESS';
export const ONLINESTOREITEM_CREATE_FAILURE = 'ONLINESTOREITEM_CREATE_FAILURE';

function createOnlineStoreItemRequest() {
  return {
    type: ONLINESTOREITEM_CREATE_REQUEST,
  };
}

function createOnlineStoreItemSuccess(onlineStoreItem: OnlineStoreItem) {
  return {
    type: ONLINESTOREITEM_CREATE_SUCCESS,
    payload: onlineStoreItem,
  };
}

function createOnlineStoreItemFailure(error: string) {
  return {
    type: ONLINESTOREITEM_CREATE_FAILURE,
    error: error,
  };
}

export const createOnlineStoreItem = (body: any): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(createOnlineStoreItemRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL('v2', 'store')}/management/items`,
      body: body,
    });

    let updateBody = {};

    if (body.storeItemFile && body.storeItemFile !== '') {
      const fileName = body.storeItemFile.split('\\').pop();

      const uploadStoreItemResponse = await callAPI({
        method: 'GET',
        endpoint: `${getApiBaseURL('v2', 'store')}/management/items/${
          response.id
        }/reportuploadurl/${fileName}`,
      });

      const uploadUrl = uploadStoreItemResponse.url;

      const headers = new Headers({
        'Content-Type': 'application/octet-stream',
      });

      const uploadResponse = await fetch(uploadUrl, {
        method: 'PUT',
        headers,
        body: body.storeItemFile,
      });

      updateBody.reportRef = uploadResponse.ok
        ? fileName
        : 'error when uploading store item file';
    }

    if (body.storeItemSampleFile && body.storeItemSampleFile !== '') {
      const sampleFileName = body.storeItemSampleFile.split('\\').pop();

      // console.log(body.reportTypeId);

      const uploadStoreItemSampleResponse = await callAPI({
        method: 'GET',
        endpoint: `${getApiBaseURL('v1')}/store/items/${
          response.id
        }/sampleuploadurl/${sampleFileName}`,
      });

      const uploadSampleUrl = uploadStoreItemSampleResponse.url;

      const headers = new Headers({
        'Content-Type': 'application/octet-stream',
      });

      const uploadSampleResponse = await fetch(uploadSampleUrl, {
        method: 'PUT',
        headers,
        body: body.storeItemSampleFile,
      });

      updateBody.sampleRef = uploadSampleResponse.ok
        ? sampleFileName
        : 'error when uploading sample file';
    }

    if (
      updateBody.reportRef !== undefined ||
      updateBody.sampleRef !== undefined
    ) {
      updateBody.dateUpdated = response.dateCreated;

      await callAPI({
        method: 'PUT',
        endpoint: `${getApiBaseURL('v2', 'store')}/management/items/${
          response.id
        }`,
        body: updateBody,
      });
    }

    dispatch(createOnlineStoreItemSuccess(response));
    return response;
  } catch (error) {
    dispatch(createOnlineStoreItemFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
