// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getWorkspaceApiBaseURL } from '../../../helpers';

export const START_SEARCHABLE_AUDIENCE_IMPORT_REQUEST = 'START_SEARCHABLE_AUDIENCE_IMPORT_REQUEST';
export const START_SEARCHABLE_AUDIENCE_IMPORT_SUCCESS = 'START_SEARCHABLE_AUDIENCE_IMPORT_SUCCESS';
export const START_SEARCHABLE_AUDIENCE_IMPORT_FAILURE = 'START_SEARCHABLE_AUDIENCE_IMPORT_FAILURE';

function startSearchableAudienceImportRequest() {
  return {
    type: START_SEARCHABLE_AUDIENCE_IMPORT_REQUEST,
  };
}

function startSearchableAudienceImportSuccess(importDetails) {
  return {
    type: START_SEARCHABLE_AUDIENCE_IMPORT_SUCCESS,
    payload: importDetails,
  };
}

function startSearchableAudienceImportFailure(error) {
  return {
    type: START_SEARCHABLE_AUDIENCE_IMPORT_FAILURE,
    error
  };
}

export const startSearchableAudienceImport = (): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(startSearchableAudienceImportRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getWorkspaceApiBaseURL('v1')}/management/searchable-audiences/imports`,
    });

    dispatch(startSearchableAudienceImportSuccess(response));
    return response;
  } catch (error) {
    dispatch(startSearchableAudienceImportFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
