// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadOnlineStoreCoupons } from '../../actions';
import type { AuthTypes } from '../../types';
import { Container, Grid, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PaginatedTable from '../../components/PaginatedTable';
import Breadcrumb from '../../components/Breadcrumb';
import TableActions from '../../components/TableActions';
import PageLoader from '../../components/PageLoader';
import { getOnlineStoreCouponsSortedCreatedBy } from '../../reducers/onlineStoreCoupons';
import {
  managementFeatures,
  // $FlowFixMe
  sortChangeHandler,
  // $FlowFixMe
  sortData,
} from '../../helpers';
import {
  getPermissionInFeature,
  restrictUserPermissions,
  permissions,
} from 'roy-morgan-auth';
import moment from 'moment';

const COUPON_TYPE_PERCENTAGE = 1;

type Props = {
  loadOnlineStoreCoupons: () => Promise<boolean>,
  onlineStoreCoupons: Array<Object>,
  isFetching: boolean,
  permission: ?AuthTypes,
};

type State = {
  filterValue: string,
  sortColumn: ?string,
  sortDirection: ?string,
};

export class OnlineStoreCoupons extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      sortColumn: '',
      sortDirection: '',
    };
  }

  componentDidMount() {
    const { loadOnlineStoreCoupons } = this.props;
    loadOnlineStoreCoupons();
  }

  handleFilterChange: (e: SyntheticInputEvent<HTMLInputElement>) => void = (
    e: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  handleSortChange: (clickedColumn: any) => () => any = (
    clickedColumn: any
  ) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort: (a: any, b: any) => any = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  render(): React.Node {
    const { onlineStoreCoupons, isFetching, permission } = this.props;
    const { sortColumn, sortDirection } = this.state;
    const { INTERNAL_ADMIN, STANDARD_USER } = permissions;

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'name' ? sortDirection : null}
          onClick={this.handleSortChange('name')}
        >
          Coupon Code
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'couponApplyType' ? sortDirection : null}
          onClick={this.handleSortChange('couponApplyType')}
        >
          Applied Field
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'couponType' ? sortDirection : null}
          onClick={this.handleSortChange('couponType')}
        >
          Type
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'validFromUnix' ? sortDirection : null}
          onClick={this.handleSortChange('validFromUnix')}
        >
          Valid From
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'validToUnix' ? sortDirection : null}
          onClick={this.handleSortChange('validToUnix')}
        >
          Valid To
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'amount' ? sortDirection : null}
          onClick={this.handleSortChange('amount')}
        >
          Discount
        </Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    );

    const filterValue = this.state.filterValue.toLowerCase() || '';

    let rows = onlineStoreCoupons
      .map((onlineStoreCoupon: any) => {
        return {
          ...onlineStoreCoupon,
          validTo: onlineStoreCoupon.validTo
            ? moment
                .utc(onlineStoreCoupon.validTo)
                .local()
                .format('YYYY-MM-DD LT')
                .toString()
            : '',
          validFrom: onlineStoreCoupon.validFrom
            ? moment
                .utc(onlineStoreCoupon.validFrom)
                .local()
                .format('YYYY-MM-DD LT')
                .toString()
            : '',
          validToUnix: onlineStoreCoupon.validTo
            ? moment.utc(onlineStoreCoupon.validTo).unix()
            : 0,
          validFromUnix: onlineStoreCoupon.validFrom
            ? moment.utc(onlineStoreCoupon.validFrom).unix()
            : 0,
        };
      })
      .filter((onlineStoreCoupon: any) => {
        const validTo = onlineStoreCoupon.validTo.toLowerCase();
        const validFrom = onlineStoreCoupon.validFrom.toLowerCase();
        const amount =
          onlineStoreCoupon.couponTypeId === COUPON_TYPE_PERCENTAGE
            ? `${onlineStoreCoupon.amount}%`.toLowerCase()
            : `$${onlineStoreCoupon.amount}`.toLowerCase();
        const couponName = onlineStoreCoupon.name.toLowerCase();
        const couponType = onlineStoreCoupon.couponType.toLowerCase();
        const couponApplyType = onlineStoreCoupon.couponApplyType.toLowerCase();
        return (
          couponName === filterValue ||
          couponName.includes(filterValue) ||
          couponType === filterValue ||
          couponType.includes(filterValue) ||
          couponApplyType === filterValue ||
          couponApplyType.includes(filterValue) ||
          validTo === filterValue ||
          validTo.includes(filterValue) ||
          validFrom === filterValue ||
          validFrom.includes(filterValue) ||
          amount === filterValue ||
          amount.includes(filterValue) ||
          filterValue === ''
        );
      })
      .sort(this.sort);

    rows = rows.map((onlineStoreCoupon: any) => {
      const amount =
        onlineStoreCoupon.couponTypeId === COUPON_TYPE_PERCENTAGE
          ? `${onlineStoreCoupon.amount}%`
          : `$${onlineStoreCoupon.amount}`;
      return (
        <Table.Row key={onlineStoreCoupon.id}>
          <Table.Cell>{onlineStoreCoupon.name}</Table.Cell>
          <Table.Cell>{onlineStoreCoupon.couponApplyType}</Table.Cell>
          <Table.Cell>{onlineStoreCoupon.couponType}</Table.Cell>
          <Table.Cell>{onlineStoreCoupon.validFrom}</Table.Cell>
          <Table.Cell>{onlineStoreCoupon.validTo}</Table.Cell>
          <Table.Cell>{amount}</Table.Cell>
          <Table.Cell>
            <Link to={`/storecoupons/${onlineStoreCoupon.id}/details`}>
              View
            </Link>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Container data-testid="organisations-container">
        <Grid centered stackable>
          <Grid.Column>
            <div className="page-content">
              <Breadcrumb currentContext="Store Coupons" />
              <div className="table-container">
                <TableActions
                  filterAction={this.handleFilterChange}
                  filterPlaceholder="Filter Coupons..."
                  buttonURL="/storecoupons/new"
                  buttonText="Create Coupon"
                  hideButton={
                    !restrictUserPermissions(
                      [INTERNAL_ADMIN, STANDARD_USER],
                      permission
                    )
                  }
                />
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    headerRow={TableHeader}
                    rows={rows}
                    sortable
                  />
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  onlineStoreCoupons: getOnlineStoreCouponsSortedCreatedBy(state),
  isFetching: state.onlineStoreCoupons.isFetching,
  permission: getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    state.auth.authorisation
  ),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadOnlineStoreCoupons,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreCoupons): any);
