// @flow
import { createSelector } from 'reselect';
import type { Industries, Action, State } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */
const initialState: Industries = {
  byId: {},
  order: [],
  isFetching: false,
  error: undefined,
};

/* Reducer */
export const industries = (
  state: Industries = initialState,
  action: Action
): Industries => {
  switch (action.type) {
    case 'INDUSTRIES_LOAD_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'INDUSTRIES_LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case 'INDUSTRIES_LOAD_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getIndustriesSortedAlphabetically: any = createSelector(
  (state: State) => state.industries.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }
    return Object.keys(items)
      .sort(function (a, b) {
        if (items[a] === '' || items[a] === null) {
          return 1;
        } else if (items[b] === '' || items[b] === null) {
          return -1;
        } else if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
