// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetMessages } from '../actions';
import Message from '../components/Message';
import type { State as GlobalState, Action } from '../types';

type Props = {
  content?: string,
  type?: 'error' | 'warning' | 'success',
  messageTimer?: any,
  resetMessages: () => Action,
};

type State = {
  show: boolean,
  showDelay: boolean,
};

class FlashMessage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      showDelay: true,
    };
  }

  messageTimer = null;
  componentDidMount() {
    this.setTimer();
  }

  componentDidUpdate(nextProps) {
    if (this.props.type && this.props !== nextProps) {
      this.setTimer();
      this.setState({
        show: true,
        showDelay: true,
      });
    }
  }

  setTimer() {
    if (this.props.type !== 'error') {
      // clear any existing timer
      if (this.messageTimer != null) {
        clearTimeout(this.messageTimer);
      }
      // hide after `delay` milliseconds
      this.messageTimer = setTimeout(
        function () {
          this.setState({ show: false });

          //remove the element from dom after transition finished
          setTimeout(() => {
            this.setState({ showDelay: false });
            this.props.resetMessages();
          }, 800);
          this.messageTimer = null;
        }.bind(this),
        4000
      );
    }
  }

  componentWillUnmount() {
    if (this.messageTimer) {
      clearTimeout(this.messageTimer);
    }
  }

  onCloseMessage = () => {
    this.setState({
      show: false,
    });
    this.props.resetMessages();
  };

  render() {
    const { content, type } = this.props;
    const { show, showDelay } = this.state;

    if (content) {
      switch (type) {
        case 'error':
          return (
            <Message
              content={content}
              show={show}
              showDelay={show}
              onDismiss={this.onCloseMessage}
              error
            />
          );

        case 'warning':
          return (
            <Message
              content={content}
              show={show}
              showDelay={showDelay}
              onDismiss={this.onCloseMessage}
              warning
            />
          );

        case 'success':
          return (
            <Message
              content={content}
              show={show}
              showDelay={showDelay}
              onDismiss={this.onCloseMessage}
              success
            />
          );

        default:
          return (
            <Message
              content={content}
              show={show}
              showDelay={showDelay}
              onDismiss={this.onCloseMessage}
            />
          );
      }
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state: GlobalState) => ({
  content: state.messages.content,
  type: state.messages.type,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetMessages,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(FlashMessage): any);
