// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import type { OrganisationTeam } from '../../../types/organisationTeams';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_TEAM_UPDATE_REQUEST =
  'ORGANISATION_TEAM_UPDATE_REQUEST';
export const ORGANISATION_TEAM_UPDATE_SUCCESS =
  'ORGANISATION_TEAM_UPDATE_SUCCESS';
export const ORGANISATION_TEAM_UPDATE_FAILURE =
  'ORGANISATION_TEAM_UPDATE_FAILURE';

const updateOrganisationTeamRequest = (): Object => ({
  type: ORGANISATION_TEAM_UPDATE_REQUEST,
});

const updateOrganisationTeamSuccess = (team: OrganisationTeam): Object => ({
  type: ORGANISATION_TEAM_UPDATE_SUCCESS,
  payload: team,
});

const updateOrganisationTeamFailure = (error): Object => ({
  type: ORGANISATION_TEAM_UPDATE_FAILURE,
  error,
});

export const updateOrganisationTeam = (
  organisationId: number,
  teamId: number,
  formData: OrganisationTeam
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationTeamRequest());
    const today = new Date();
    const body = {
      ...formData,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}`,
      body,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(updateOrganisationTeamSuccess(response));

    return true;
  } catch (error) {
    dispatch(updateOrganisationTeamFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
