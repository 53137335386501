// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_ACCOUNT_MANAGER_REMOVE_REQUEST =
  'ORGANISATION_ACCOUNT_MANAGER_REMOVE_REQUEST';
export const ORGANISATION_ACCOUNT_MANAGER_REMOVE_SUCCESS =
  'ORGANISATION_ACCOUNT_MANAGER_REMOVE_SUCCESS';
export const ORGANISATION_ACCOUNT_MANAGER_REMOVE_FAILURE =
  'ORGANISATION_ACCOUNT_MANAGER_REMOVE_FAILURE';

const removeOrganisationAccountManagerRequest = () => ({
  type: ORGANISATION_ACCOUNT_MANAGER_REMOVE_REQUEST,
});

const removeOrganisationAccountManagerSuccess = (message, userId) => ({
  type: ORGANISATION_ACCOUNT_MANAGER_REMOVE_SUCCESS,
  message: message,
  id: userId,
});

const removeOrganisationAccountManagerFailure = (error) => ({
  type: ORGANISATION_ACCOUNT_MANAGER_REMOVE_FAILURE,
  error,
});

export const removeOrganisationAccountManager = (
  organisationId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOrganisationAccountManagerRequest());

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/account-managers/${userId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(removeOrganisationAccountManagerSuccess(response, userId));

    return true;
  } catch (error) {
    dispatch(removeOrganisationAccountManagerFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
