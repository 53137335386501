// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type { NewOnlineStoreTreeCategory } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINESTORETREECATEGORY_CREATE_REQUEST =
  'ONLINESTORETREECATEGORY_CREATE_REQUEST';
export const ONLINESTORETREECATEGORY_CREATE_SUCCESS =
  'ONLINESTORETREECATEGORY_CREATE_SUCCESS';
export const ONLINESTORETREECATEGORY_CREATE_FAILURE =
  'ONLINESTORETREECATEGORY_CREATE_FAILURE';

function createOnlineStoreCategoryRequest() {
  return {
    type: ONLINESTORETREECATEGORY_CREATE_REQUEST,
  };
}

function createOnlineStoreCategorySuccess(category) {
  return {
    type: ONLINESTORETREECATEGORY_CREATE_SUCCESS,
    payload: category,
  };
}

function createOnlineStoreCategoryFailure(error: string) {
  return {
    type: ONLINESTORETREECATEGORY_CREATE_FAILURE,
    error: error,
  };
}

export const createOnlineStoreTreeCategory = (
  body: NewOnlineStoreTreeCategory
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(createOnlineStoreCategoryRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL('v2', 'store')}/management/category`,
      body: body,
    });
    if (response instanceof Error) {
      throw response;
    }

    dispatch(createOnlineStoreCategorySuccess(response));
    return response;
  } catch (error) {
    error.message = error.message || 'System Internal Error';
    error.errorMessage = true;
    dispatch(createOnlineStoreCategoryFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
