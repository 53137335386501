// @flow
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import OnlineStoreCouponFormFields from '../../components/OnlineStoreCoupon/OnlineStoreCouponFormFields';
import {
  createOnlineStoreCoupon,
  // $FlowFixMe
  loadCouponTypes,
  // $FlowFixMe
  loadCouponApplyTypes,
} from '../../actions';
import {
  getOnlineStoreCouponTypesSortedAlphabetically,
  getOnlineStoreCouponApplyTypesSortedAlphabetically,
} from '../../reducers/onlineStoreOptions';
import type {
  State as AppState,
  Dispatch,
  OnlineStoreCouponForm,
  AuthTypes,
  // OnlineStoreCouponTypes,
  // OnlineStoreCouponApplyTypes
} from '../../types';
import Breadcrumb from '../../components/Breadcrumb';
import { managementFeatures } from '../../helpers';
import { getPermissionInFeature } from 'roy-morgan-auth';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';

type Props = {
  permission: ?AuthTypes,
  createOnlineStoreCoupon: (body: Object) => Promise<OnlineStoreCouponForm>,
  couponTypes: Array<any>,
  couponApplyTypes: Array<any>,
  loadCouponTypes: () => Promise<any>,
  loadCouponApplyTypes: () => Promise<any>,
  history: RouterHistory,
};

type State = {
  formData: OnlineStoreCouponForm,
};

class NewOnlineStoreCoupon extends React.Component<Props, State> {
  componentDidMount() {
    this.props.loadCouponTypes();
    this.props.loadCouponApplyTypes();
  }

  handleOnlineStoreCouponCreate = (data) => {
    return this.props.createOnlineStoreCoupon(data).then(
      (result) => {
        if (result.id) {
          this.props.history.push(`/storecoupons/${result.id}/details`);
        }
        return result;
      },
      (failure) => {
        console.error(failure);
        Sentry.captureException(failure);
      }
    );
  };

  render() {
    const onlineStoreCoupon = {
      name: '',
      amount: 0,
      isOneOff: false,
      couponTypeId: null,
      couponApplyTypeId: null,
      validFrom: null,
      validTo: null,
    };

    const { couponTypes, couponApplyTypes } = this.props;

    return (
      <Container>
        <Grid>
          <Grid.Column width={12}>
            <Breadcrumb
              topLevelContext="All Store Coupons"
              currentContext={'Store Coupon'}
              backLink="/storecoupons"
            />
            <div className="single-form">
              <OnlineStoreCouponFormFields
                permission={this.props.permission}
                // $FlowFixMe
                onlineStoreCoupon={onlineStoreCoupon}
                couponTypes={couponTypes}
                couponApplyTypes={couponApplyTypes}
                handleFormSubmit={this.handleOnlineStoreCouponCreate}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState, props) => {
  return {
    couponTypes: getOnlineStoreCouponTypesSortedAlphabetically(state),
    couponApplyTypes: getOnlineStoreCouponApplyTypesSortedAlphabetically(state),
    permission: getPermissionInFeature(
      managementFeatures.ONLINE_STORE_MANAGEMENT,
      // $FlowFixMe
      state.auth.authorisation
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      createOnlineStoreCoupon,
      loadCouponTypes,
      loadCouponApplyTypes,
    },
    dispatch
  );

export default (withRouter(
  // $FlowFixMe
  connect(mapStateToProps, mapDispatchToProps)(NewOnlineStoreCoupon)
): any);
