//@flow
import React from 'react';
import OverflowMenu from '../../components/OverflowMenu';
import TableActions from '../../components/TableActions';
import { Table, Confirm } from 'semantic-ui-react';
import PaginatedTable from '../../components/PaginatedTable';
import { getOrganisationUserTeamsSortedAlphabetically } from '../../reducers/organisationUserTeams';
import type {
  State as AppState,
  Dispatch,
  OrganisationUserTeam,
  Organisation,
  AuthTypes,
} from '../../types';
import {
  loadOrganisationUserTeams,
  removeOrganisationUserTeam,
} from '../../actions/organisations/organisationUsers/organisationUserTeams';
import {
  readOrganisationUser
} from '../../actions/organisations/organisationUsers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageLoader from '../../components/PageLoader';
import { getIsReady } from '../../reducers/fetchSync';
import { setFetchSyncReady } from '../../actions';

type Props = {
  organisation: Organisation,
  userId: number,
  teams: Array<OrganisationUserTeam>,
  readOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  loadOrganisationUserTeams: (oid: number, uid: number) => Promise<boolean>,
  removeOrganisationUserTeam: (
    oid: number,
    tid: number,
    uid: number
  ) => Promise<null>,
  isFetching: boolean,
  permission: ?AuthTypes,
  isSingleReady: boolean,
  setOrganisationUserSingleReady: () => void,
  user: any
};

type State = {
  teamFilterValue: string,
  removeTeamOpen: boolean,
  removeTeamTarget?: { id: number, name: string },
};

class UserTeams extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      teamFilterValue: '',
      removeTeamOpen: false,
      removeTeamTarget: undefined,
    };
  }

  componentDidMount() {
    this.props.loadOrganisationUserTeams(
      this.props.organisation.id,
      this.props.userId
    );
    this.props
      .readOrganisationUser(this.props.organisation.id, this.props.userId)
      .finally(this.props.setOrganisationUserSingleReady);
  }

  handleTeamSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      teamFilterValue: e.target.value,
    });
  };

  handleRemoveTeam = () => {
    if (this.state.removeTeamTarget) {
      this.props.removeOrganisationUserTeam(
        this.props.organisation.id,
        this.state.removeTeamTarget.id,
        this.props.userId
      );
    }
    this.setState({ ...this.state, removeTeamOpen: false });
  };

  setRemoveTeamModalVisible = (teamId, teamName) => {
    this.setState({
      ...this.state,
      removeTeamOpen: true,
      removeTeamTarget: { id: teamId, name: teamName },
    });
  };

  render() {
    const { teams, isFetching, isSingleReady, user } = this.props;

    if (!isSingleReady) {
      return null;
    }
    const { canAccessAllTeams } = user;
    const teamFilterValue = this.state.teamFilterValue.toLowerCase();
    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell>Team Name</Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    );

    let rows = teams.filter((team: OrganisationUserTeam) => {
      const teamName = team.name.toLowerCase();
      return (
        teamName === teamFilterValue ||
        teamName.includes(teamFilterValue) ||
        teamFilterValue === ''
      );
    });
    rows = rows.map((team: OrganisationUserTeam) => {
      return (
        <Table.Row key={team.id}>
          <Table.Cell>{team.name}</Table.Cell>
          <Table.Cell>
            {!canAccessAllTeams ? (
              <OverflowMenu>
                <li>
                  {/* // TODO: replace anchor with button // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onClick={() => {
                      this.setRemoveTeamModalVisible(team.id, team.name);
                    }}
                    onKeyPress={() => {
                      this.setRemoveTeamModalVisible(team.id, team.name);
                    }}
                  >
                    Remove From Team
                  </a>
                </li>
              </OverflowMenu>
            ) : (
              'N/A'
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <Confirm
          // This confirmation box handles the Archive Organisation Action
          open={this.state.removeTeamOpen}
          onCancel={() =>
            this.setState({ ...this.state, removeTeamOpen: false })
          }
          content={`Do you want to remove team access to ${
            this.state.removeTeamTarget && this.state.removeTeamTarget.name
              ? this.state.removeTeamTarget.name
              : ''
          }?`}
          onConfirm={this.handleRemoveTeam}
        />
        <TableActions
          title="Teams"
          filterAction={this.handleTeamSearch}
          filterPlaceholder="Search Teams..."
        />
        {isFetching ? (
          <PageLoader />
        ) : (
          <div>
            {canAccessAllTeams ? ('User has access to all teams.') : ('')}
            <PaginatedTable headerRow={TableHeader} rows={rows} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationUserTeams.isFetching,
  teams: getOrganisationUserTeamsSortedAlphabetically(state),
  isSingleReady: getIsReady(state, 'organisationUserSingle'),
  user: state.organisationUsers.byId[props.userId]
    ? state.organisationUsers.byId[props.userId]
    : state.users.byId[props.userId],
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      readOrganisationUser,
      loadOrganisationUserTeams,
      removeOrganisationUserTeam,
      setOrganisationUserSingleReady: setFetchSyncReady('organisationUserSingle'),
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(UserTeams): any);
