// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_REQUEST =
  'ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_REQUEST';
export const ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_SUCCESS =
  'ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_SUCCESS';
export const ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_FAILURE =
  'ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_FAILURE';

const loadOnlineStoreItemsWithoutCouponAccessRequest = () => ({
  type: ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_REQUEST,
});

const loadOnlineStoreItemsWithoutCouponAccessSuccess = (
  onlineStoreCouponsItems
) => ({
  type: ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_SUCCESS,
  payload: onlineStoreCouponsItems,
});

const loadOnlineStoreItemsWithoutCouponAccessFailure = (error) => ({
  type: ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_FAILURE,
  error,
});

export const loadOnlineStoreItemsWithoutCouponAccess = (
  couponId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOnlineStoreItemsWithoutCouponAccessRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/coupons/${couponId}/items-without-access`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOnlineStoreItemsWithoutCouponAccessSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOnlineStoreItemsWithoutCouponAccessFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
