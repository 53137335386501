// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { readOnlineStoreCoupon, setFetchSyncReady } from '../../actions';
import type {
  // State as AppState,
  // Dispatch,
  OnlineStoreCoupon as OnlineStoreCouponType,
  AuthTypes,
} from '../../types';
import Breadcrumb from '../../components/Breadcrumb';
import SideMenu from '../../components/SideMenu';
import {
  OnlineStoreCouponDetails,
  OnlineStoreCouponItems,
} from '../OnlineStoreCouponTabs';
import { getIsReady } from '../../reducers/fetchSync';

type Props = {
  onlineStoreCoupon: OnlineStoreCouponType,
  permission: ?AuthTypes,
  setSelectedOnlineStoreCoupon: (uid: number) => number,
  match: {
    params: {
      tab?: string,
      onlineStoreCouponId: number,
    },
    url: string,
  },
  readOnlineStoreCoupon: (onlineStoreCouponId: number) => Promise<null>,
  isReady: boolean,
  setOnlineStoreCouponReady: () => void,
};

const COUPON_APPLY_TYPE_ITEM = 1;

class OnlineStoreCoupon extends React.Component<Props, any> {
  componentDidMount() {
    const { match, readOnlineStoreCoupon } = this.props;
    readOnlineStoreCoupon(match.params.onlineStoreCouponId).finally(
      this.props.setOnlineStoreCouponReady
    );
  }

  render() {
    const { onlineStoreCoupon, match, permission, isReady } = this.props;
    const currentUrl = match.url;
    const onlineStoreCouponId = match.params.onlineStoreCouponId;

    const items = [
      {
        label: 'Details',
        container: () => (
          <OnlineStoreCouponDetails onlineStoreCouponId={onlineStoreCouponId} />
        ),
        path: `/storecoupons/${onlineStoreCouponId}/details`,
      },
    ];

    if (!isReady) {
      return null;
    }

    if (typeof onlineStoreCoupon === 'undefined') {
      return <Redirect to="/storecoupons" />;
    }

    if (onlineStoreCoupon.couponApplyTypeId === COUPON_APPLY_TYPE_ITEM) {
      items.push({
        label: 'Items',
        container: () => (
          <OnlineStoreCouponItems onlineStoreCouponId={onlineStoreCouponId} />
        ),
        path: `/storecoupons/${onlineStoreCouponId}/items`,
      });
    }

    return (
      <Container>
        <Grid>
          <Grid.Column width={12}>
            <Breadcrumb
              titleClass={'breadcrumb__current-title--lower-case'}
              topLevelContext="All Store Coupons"
              // $FlowFixMe
              currentContext={onlineStoreCoupon.name}
              backLink="/storecoupons"
            />
          </Grid.Column>
        </Grid>
        <Grid className="side-menu-layout">
          <Grid.Column width={3} className="side-menu-column">
            <SideMenu
              currentPath={currentUrl}
              items={items}
              permission={permission}
            />
          </Grid.Column>
          <Grid.Column width={9}>
            <Grid>
              <Grid.Column>
                {items.map((item, index) => {
                  return (
                    <Route
                      key={index}
                      path={item.path}
                      render={item.container}
                    />
                  );
                })}
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: any, props) => {
  return {
    onlineStoreCoupon:
      state.onlineStoreCoupons.byId[props.match.params.onlineStoreCouponId],
    isReady: getIsReady(state, 'onlineStoreCoupon'),
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      readOnlineStoreCoupon,
      setOnlineStoreCouponReady: setFetchSyncReady('onlineStoreCoupon'),
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreCoupon): any);
