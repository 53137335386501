// @flow
import * as React from 'react';
import { verifyUser } from 'roy-morgan-auth';
import { getProductUrl } from '../helpers';
import URLSearchParams from 'url-search-params';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageLoader from '../components/PageLoader';
import { Container, Grid } from 'semantic-ui-react';

type Props = {
  verifyUser: (token: string) => Promise<?boolean>,
  location: Object,
};

type State = {
  verificationStatus: boolean,
  isFetching: boolean,
};

class VerifyUser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isFetching: true,
      verificationStatus: false,
    };
  }

  componentDidMount() {
    const { verifyUser, location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const newToken = searchParams.get('token') || '';
    const productId = searchParams.get('productId') || '';
    const env = process.env.REACT_APP_RM_ENV
      ? process.env.REACT_APP_RM_ENV
      : process.env.REACT_APP_ENV;
    const redirectUrl = getProductUrl(productId, env);

    verifyUser(newToken).then(
      (success) => {
        if (success === true) {
          if (redirectUrl !== '/') {
            window.location.href = redirectUrl;
          } else {
            this.setState({
              verificationStatus: true,
              isFetching: false,
            });
          }
        }
      },
      (failure) =>
        this.setState({
          verificationStatus: false,
          isFetching: false,
        })
    );
  }

  render() {
    const { verificationStatus, isFetching } = this.state;
    if (isFetching) {
      return <PageLoader />;
    } else {
      return (
        <div className="page--multifaceted-bg-grey">
          <Container className="page-container">
            <div className="login-form">
              <Grid stackable centered>
                <Grid.Column width={6}>
                  <div className="callout">
                    <h2>
                      {verificationStatus
                        ? 'Verification Success'
                        : 'Verification Failure'}
                    </h2>
                    <Grid stackable centered>
                      <Grid.Column width={10}>
                        <div>
                          <p>
                            {verificationStatus
                              ? 'Email address successfully verified.'
                              : 'Email failed to verify. Please contact your Organisation Admin for further assistance.'}
                          </p>
                          <p>
                            <a href="/verification">Continue</a>
                          </p>
                        </div>
                      </Grid.Column>
                    </Grid>
                  </div>
                </Grid.Column>
              </Grid>
            </div>
          </Container>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      verifyUser,
    },
    dispatch
  );

export default (withRouter(connect(null, mapDispatchToProps)(VerifyUser)): any);
