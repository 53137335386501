// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const USER_TOTAL_USAGE_REQUEST =
  'USER_TOTAL_USAGE_REQUEST';
export const USER_TOTAL_USAGE_SUCCESS =
  'USER_TOTAL_USAGE_SUCCESS';
export const USER_TOTAL_USAGE_FAILURE =
  'USER_TOTAL_USAGE_FAILURE';

const totalUserUsageRequest = () => ({
  type: USER_TOTAL_USAGE_REQUEST,
});

const totalUserUsageSuccess = (response) => ({
  type: USER_TOTAL_USAGE_SUCCESS,
  payload: response,
});

const totalUserUsageFailure = (error) => ({
  type: USER_TOTAL_USAGE_FAILURE,
  error,
});

export const totalUserUsage = (
  organisationId: number,
  userId: number,
  domain: string
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(totalUserUsageRequest());

    const queryString = domain ? `?domain=${domain}` : ``;

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${userId}/usage/total${queryString}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(totalUserUsageSuccess(response));
    return response;
  } catch (error) {
    dispatch(totalUserUsageFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
