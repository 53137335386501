// @flow
import type { UserUsagePerDomain, Action } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */
const initialState: UserUsagePerDomain = {
  result: [],
  isFetching: false,
  error: undefined,
};

/* Reducer */
export const userUsagePerDomain = (
  state: UserUsagePerDomain = initialState,
  action: Action
): UserUsagePerDomain => {
  switch (action.type) {
    case 'USER_USAGE_PER_USER_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'USER_USAGE_PER_USER_SUCCESS':
      return {
        ...state,
        result: action.payload,
        isFetching: false,
        error: undefined,
      };
    case 'USER_USAGE_PER_USER_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
