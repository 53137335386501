// @flow
import * as actions from '../actions/pendingIssues';
import { createSelector } from 'reselect';
import type { PendingIssueActions, PendingIssues } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: PendingIssues = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: null,
};

/* Reducer */

export const pendingIssues = (
  state: PendingIssues = initialState,
  action: PendingIssueActions
): PendingIssues => {
  switch (action.type) {
    case actions.PENDING_ISSUES_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case actions.PENDING_ISSUES_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: null,
      };
    case actions.PENDING_ISSUES_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.PENDING_ISSUE_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case actions.PENDING_ISSUE_UPDATE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        isFetching: false,
        error: null,
      };
    case actions.PENDING_ISSUE_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */

export const getPendingIssuesSortedByProductName: any = createSelector(
  (state) => state.pendingIssues.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        // return items[ a ].name - items[ b ].name;
        if (
          items[a].productName.toLowerCase() <
          items[b].productName.toLowerCase()
        ) {
          return -1;
        } else if (
          items[a].productName.toLowerCase() >
          items[b].productName.toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
