// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import type { PendingIssue, PendingIssueForm } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const PENDING_ISSUE_UPDATE_REQUEST = 'PENDING_ISSUE_UPDATE_REQUEST';
export const PENDING_ISSUE_UPDATE_SUCCESS = 'PENDING_ISSUE_UPDATE_SUCCESS';
export const PENDING_ISSUE_UPDATE_FAILURE = 'PENDING_ISSUE_UPDATE_FAILURE';

function updatePendingIssueRequest() {
  return {
    type: PENDING_ISSUE_UPDATE_REQUEST,
  };
}

function updatePendingIssueSuccess(issue: PendingIssue) {
  return {
    type: PENDING_ISSUE_UPDATE_SUCCESS,
    payload: issue,
  };
}

function updatePendingIssueFailure(error: string) {
  return {
    type: PENDING_ISSUE_UPDATE_FAILURE,
    error: error,
  };
}

export const updatePendingIssue = (
  pendingIssueId: number,
  body: PendingIssueForm
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updatePendingIssueRequest());

    const requestBody = {
      ...body,
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/pending-issues/${pendingIssueId}`,
      body: requestBody,
    });

    dispatch(updatePendingIssueSuccess(response));
    return response;
  } catch (error) {
    dispatch(updatePendingIssueFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
      Sentry.captureException(error);
    }

    return error;
  }
};
