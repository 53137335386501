// @flow
import * as Sentry from '@sentry/browser';
import { arrayToObject, getWorkspaceApiBaseURL } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';

export const SEARCHABLE_AUDIENCE_IMPORTS_LOAD_REQUEST = 'SEARCHABLE_AUDIENCE_IMPORTS_LOAD_REQUEST';
export const SEARCHABLE_AUDIENCE_IMPORTS_LOAD_SUCCESS = 'SEARCHABLE_AUDIENCE_IMPORTS_LOAD_SUCCESS';
export const SEARCHABLE_AUDIENCE_IMPORTS_LOAD_FAILURE = 'SEARCHABLE_AUDIENCE_IMPORTS_LOAD_FAILURE';

const loadSearchableAudienceImportsRequest = () => ({
  type: SEARCHABLE_AUDIENCE_IMPORTS_LOAD_REQUEST,
});

const loadSearchableAudienceImportsSuccess = (imports) => ({
  type: SEARCHABLE_AUDIENCE_IMPORTS_LOAD_SUCCESS,
  payload: imports,
});

const loadSearchableAudienceImportsFailure = (error) => ({
  type: SEARCHABLE_AUDIENCE_IMPORTS_LOAD_FAILURE,
  error,
});

export const loadSearchableAudienceImports = (): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadSearchableAudienceImportsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getWorkspaceApiBaseURL('v1')}/management/searchable-audiences/imports`,
    });

    if (response instanceof Error) {
      return response;
    }

    const imports = arrayToObject(response);

    dispatch(loadSearchableAudienceImportsSuccess(imports));

    return imports;
  } catch (error) {
    dispatch(loadSearchableAudienceImportsFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
