// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type { OnlineStoreCouponItem } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_COUPON_ITEM_ADD_REQUEST =
  'ONLINE_STORE_COUPON_ITEM_ADD_REQUEST';
export const ONLINE_STORE_COUPON_ITEM_ADD_SUCCESS =
  'ONLINE_STORE_COUPON_ITEM_ADD_SUCCESS';
export const ONLINE_STORE_COUPON_ITEM_ADD_FAILURE =
  'ONLINE_STORE_COUPON_ITEM_ADD_FAILURE';

function createOnlineStoreCouponItemRequest() {
  return {
    type: ONLINE_STORE_COUPON_ITEM_ADD_REQUEST,
  };
}

function createOnlineStoreCouponItemSuccess(couponItem: OnlineStoreCouponItem) {
  return {
    type: ONLINE_STORE_COUPON_ITEM_ADD_SUCCESS,
    payload: couponItem,
  };
}

function createOnlineStoreCouponItemFailure(error: string) {
  return {
    type: ONLINE_STORE_COUPON_ITEM_ADD_FAILURE,
    error: error,
  };
}

export const addOnlineStoreItemToCoupon = (
  couponId: number,
  itemId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(createOnlineStoreCouponItemRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/coupons/${couponId}/items`,
      body: {
        itemId,
      },
    });

    dispatch(createOnlineStoreCouponItemSuccess(response));
    return response;
  } catch (error) {
    dispatch(createOnlineStoreCouponItemFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
