// @flow

import { changeOrganisationContext } from 'roy-morgan-auth';
import * as Sentry from '@sentry/browser';

import type { Dispatch, State } from '../types';

import { LIVE_MANAGEMENT_PRODUCT } from '../helpers';

export const organisationChange = (organisationId: number): any => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  try {
    //ToDo: temporary - move into auth package
    await dispatch(
      changeOrganisationContext(organisationId, LIVE_MANAGEMENT_PRODUCT)
    );
  } catch (error) {
    Sentry.captureException(error);
    // TODO: Handle error
    return false;
  }
};
