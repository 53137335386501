//@flow

import React, { useEffect, useState } from 'react';
import type { Element } from 'React';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Table, Confirm, Loader } from 'semantic-ui-react';
import OverflowMenu from '../../../../components/OverflowMenu';

import {
  loadUsersWithApiAccess,
  loadUsersWithoutApiAccess,
  addUserAccess,
  removeUserAccess,
} from '../../../../actions';

import {
  getUsersWithApiAccess,
} from '../../../../reducers/usersWithApiAccess';

 import {
  getUsersWithoutApiAccess,
} from '../../../../reducers/usersWithoutApiAccess';

import TabHeader from '../../../../components/TabHeader';
import PaginatedTable from '../../../../components/PaginatedTable';
import TableActions from '../../../../components/TableActions';
import PageLoader from '../../../../components/PageLoader';
import ErrorMessage from '../../../../components/ErrorMessage';
import AddItemModal from '../../../../components/AddItemModal';

type Props = {
  api: any,
  organisationId: number
};

export default function ApiSubscriptionUsers(props: Props) : Element<'div'> {
  const dispatch = useDispatch();
  const { api, organisationId } = props;
  
  useEffect(() => {
    dispatch(loadUsersWithApiAccess(api.id, organisationId));
    dispatch(loadUsersWithoutApiAccess(api.id, organisationId));
  }, [dispatch, api.id, organisationId]);

  const {
    items: usersWithAccess,
    isFetching: isFetchingUsersWithAccess,
    error: usersWithAccessError,
  } = useSelector(getUsersWithApiAccess);

  const {
    items: usersWithoutAccess,
    isFetching: isFetchingUsersWithoutAccess,
    error: usersWithoutAccessError,
  } = useSelector(getUsersWithoutApiAccess);

  const [filterValue, setFilterValue] = useState('');
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [removeUserConfirmOpen, setRemoveUserConfirmOpen] = useState(false);
  const [removeUserTarget, setRemoveUserTarget] = useState(null);

  const handleFilterChange = e => {
    setFilterValue(e.currentTarget.value.toLowerCase());
  };

  const toggleAddUserModal = () => {
    setAddUserModalOpen(!addUserModalOpen);
  };

  const handleAddUserAccess = async (user) => {
    return dispatch(addUserAccess(api.id, organisationId, user));
  }; 

  const handleRemoveUser = () => {
    if (removeUserTarget) {
      dispatch(removeUserAccess(api.id, organisationId, removeUserTarget));
    }
    setRemoveUserTarget(null);
    setRemoveUserConfirmOpen(false);
  };

  const handleRemoveUserClick = (user) => {
    setRemoveUserTarget(user);
    setRemoveUserConfirmOpen(true);
  };

 const getRemoveUserConfirmationMessage = () => {
    let userName = removeUserTarget && removeUserTarget.name
      ? removeUserTarget.name
      : 'this user';

    return `Are you sure you want to remove ${userName}'s access?`;
  };

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell width={5}>
        Name
      </Table.HeaderCell>
      <Table.HeaderCell width={5}>
        Email
      </Table.HeaderCell>
      <Table.HeaderCell width={2}>
        Actions
      </Table.HeaderCell>
    </Table.Row>
  );

  const tableRows = usersWithAccess
    .filter(user => {
      const name = user.name.toLowerCase();
      const email = user.email.toLowerCase();
      return filterValue === '' 
        || name.includes(filterValue) 
        || email.includes(filterValue);
    })
    .map(user => (
      <Table.Row key={user.id}>
        <Table.Cell>{user.name}</Table.Cell>
        <Table.Cell>{user.email}</Table.Cell>
        <Table.Cell>
          {user.isFetching ? (
            <Loader
              className="overflow--loader"
              active
              inline
              size="small"
            />
          ) : (
            <OverflowMenu>
              <li>
                <button
                  className="overflow-button"
                  onClick={() => handleRemoveUserClick(user)}
                >
                  Remove Access
                </button>
              </li>
            </OverflowMenu>
          )}
        </Table.Cell>
      </Table.Row>
    ));

  let err = usersWithAccessError || usersWithoutAccessError;

  if (err) {
    return ErrorMessage({ message: err.msg });
  }

  return (
    <div className="tabbed-content">
      <TabHeader
        backLink={`/api-subscriptions/${api.id}/details`}
        backText="Back to API Details"
      />

      <TableActions
        title="Users With Access"
        filterAction={handleFilterChange}
        filterPlaceholder="Filter..."
        buttonText="Add Access"
        buttonAction={toggleAddUserModal}
      />

      <Grid padded centered>
        <Grid.Row centered>
          <Grid.Column>
            {isFetchingUsersWithAccess ? (
              <PageLoader />
            ) : (
              <PaginatedTable
                perPage={10}
                headerRow={tableHeader}
                rows={tableRows}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <AddItemModal
        title="Add User Access"
        items={usersWithoutAccess}
        isFetchingItems={isFetchingUsersWithoutAccess}
        isOpen={addUserModalOpen}
        onClose={toggleAddUserModal}
        onSubmit={handleAddUserAccess}
      />
      
      <Confirm
        content={getRemoveUserConfirmationMessage()}
        open={removeUserConfirmOpen}
        onCancel={() => setRemoveUserConfirmOpen(false)}
        onConfirm={handleRemoveUser}
      /> 
    </div>
  );
}
