// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import type { ThunkAction } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const NAVIGATION_TREE_LOAD_REQUEST = 'NAVIGATION_TREE_LOAD_REQUEST';
export const NAVIGATION_TREE_LOAD_SUCCESS = 'NAVIGATION_TREE_LOAD_SUCCESS';
export const NAVIGATION_TREE_LOAD_FAILURE = 'NAVIGATION_TREE_LOAD_FAILURE';

const loadNavigationTreeRequest = (): any => ({
  type: NAVIGATION_TREE_LOAD_REQUEST,
});

const loadNavigationTreeSuccess = (options: any): any => ({
  type: NAVIGATION_TREE_LOAD_SUCCESS,
  payload: options,
});

const loadNavigationTreeFailure = (error: string): any => ({
  type: NAVIGATION_TREE_LOAD_FAILURE,
  error,
});

export const loadNavigationTree = (): ThunkAction => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadNavigationTreeRequest());

    const response: any[] | Error = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/management/options/tree`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload: any = normaliseArrayOfObjects(response);

    dispatch(loadNavigationTreeSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadNavigationTreeFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
