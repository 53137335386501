// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_ORGANISATIONS_UNSUBSCRIBED_LOAD_REQUEST = 'API_ORGANISATIONS_UNSUBSCRIBED_LOAD_REQUEST';
export const API_ORGANISATIONS_UNSUBSCRIBED_LOAD_SUCCESS = 'API_ORGANISATIONS_UNSUBSCRIBED_LOAD_SUCCESS';
export const API_ORGANISATIONS_UNSUBSCRIBED_LOAD_FAILURE = 'API_ORGANISATIONS_UNSUBSCRIBED_LOAD_FAILURE';

const loadOrganisationsWithoutApiSubscriptionRequest = () => ({
  type: API_ORGANISATIONS_UNSUBSCRIBED_LOAD_REQUEST,
});

const loadOrganisationsWithoutApiSubscriptionSuccess = (organisations) => ({
  type: API_ORGANISATIONS_UNSUBSCRIBED_LOAD_SUCCESS,
  payload: organisations,
});

const loadOrganisationsWithoutApiSubscriptionFailure = (error) => ({
  type: API_ORGANISATIONS_UNSUBSCRIBED_LOAD_FAILURE,
  error,
});

export const loadOrganisationsWithoutApiSubscription = (apiId: number): any => 
  async (dispatch, getState) => {
    try {
      refreshToken(dispatch);
      dispatch(loadOrganisationsWithoutApiSubscriptionRequest());

      const response = await callAPI({
        method: 'GET',
        endpoint: `${getApiBaseURL(
          'v2',
          'management'
        )}/apis/${apiId}/organisations-unsubscribed`,
      });

      const normalisedPayload = normaliseArrayOfObjects(response);

      dispatch(loadOrganisationsWithoutApiSubscriptionSuccess(normalisedPayload));
    } catch (error) {
      dispatch(loadOrganisationsWithoutApiSubscriptionFailure(error.message));
      Sentry.captureException(error);
      console.error(error);
    }
};
