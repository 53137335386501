// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import type { Organisation, OrganisationForm } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_UPDATE_REQUEST = 'ORGANISATION_UPDATE_REQUEST';
export const ORGANISATION_UPDATE_SUCCESS = 'ORGANISATION_UPDATE_SUCCESS';
export const ORGANISATION_UPDATE_FAILURE = 'ORGANISATION_UPDATE_FAILURE';

function updateOrganisationRequest() {
  return {
    type: ORGANISATION_UPDATE_REQUEST,
  };
}

function updateOrganisationSuccess(organisation: Organisation) {
  return {
    type: ORGANISATION_UPDATE_SUCCESS,
    payload: organisation,
  };
}

function updateOrganisationFailure(error: string) {
  return {
    type: ORGANISATION_UPDATE_FAILURE,
    error: error,
  };
}

export const updateOrganisation = (
  organisationId: number,
  body: OrganisationForm
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationRequest());
    const today = new Date();

    const requestBody = {
      ...body,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}`,
      body: requestBody,
    });

    dispatch(updateOrganisationSuccess(response));
    return response;
  } catch (error) {
    dispatch(updateOrganisationFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
      Sentry.captureException(error);
    }

    return error;
  }
};
