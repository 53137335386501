// @flow
import * as actions from '../actions/onlineStore';
import { createSelector } from 'reselect';
import type { OnlineStoreCouponsActions, OnlineStoreCoupons } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: OnlineStoreCoupons = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const onlineStoreCoupons = (
  state: OnlineStoreCoupons = initialState,
  action: OnlineStoreCouponsActions
): OnlineStoreCoupons => {
  switch (action.type) {
    case actions.ONLINE_STORE_COUPONS_LOAD_REQUEST:
    case actions.ONLINE_STORE_COUPON_READ_REQUEST:
    case actions.ONLINE_STORE_COUPON_UPDATE_REQUEST:
    case actions.ONLINE_STORE_COUPON_CREATE_REQUEST:
    case actions.ONLINE_STORE_COUPON_REMOVE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ONLINE_STORE_COUPONS_LOAD_FAILURE:
    case actions.ONLINE_STORE_COUPON_READ_FAILURE:
    case actions.ONLINE_STORE_COUPON_UPDATE_FAILURE:
    case actions.ONLINE_STORE_COUPON_CREATE_FAILURE:
    case actions.ONLINE_STORE_COUPON_REMOVE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.ONLINE_STORE_COUPONS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINE_STORE_COUPON_READ_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          [action.payload.id]: action.payload,
        },
        isFetching: false,
        error: undefined,
      };
    case actions.ONLINE_STORE_COUPON_UPDATE_SUCCESS:
    case actions.ONLINE_STORE_COUPON_CREATE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        isFetching: false,
        error: undefined,
      };
    /* eslint-disable no-case-declarations */
    case actions.ONLINE_STORE_COUPON_REMOVE_SUCCESS:
      let copy = state.byId;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.id];
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.id);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    /* eslint-enable no-case-declarations */
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOnlineStoreCouponsSortedAlphabetically: any = createSelector(
  (state) => state.onlineStoreCoupons.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        let nameA = items[a].name.toLowerCase();
        let nameB = items[b].name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);

export const getOnlineStoreCouponsSortedCreatedBy: any = createSelector(
  (state) => state.onlineStoreCoupons.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        let aDate = new Date(items[a].dateCreated);
        let bDate = new Date(items[b].dateCreated);
        return bDate - aDate;
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
