// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type {
  // ThunkAction,
  OrganisationCollaboratorForm,
} from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_ADD_REQUEST =
  'ORGANISATION_COLLABORATOR_ADD_REQUEST';
export const ORGANISATION_COLLABORATOR_ADD_SUCCESS =
  'ORGANISATION_COLLABORATOR_ADD_SUCCESS';
export const ORGANISATION_COLLABORATOR_ADD_FAILURE =
  'ORGANISATION_COLLABORATOR_ADD_FAILURE';

const addOrganisationCollaboratorRequest = () => ({
  type: ORGANISATION_COLLABORATOR_ADD_REQUEST,
});

const addOrganisationCollaboratorSuccess = (collaborator) => ({
  type: ORGANISATION_COLLABORATOR_ADD_SUCCESS,
  payload: collaborator,
});

const addOrganisationCollaboratorFailure = (error) => ({
  type: ORGANISATION_COLLABORATOR_ADD_FAILURE,
  error,
});

export const addOrganisationCollaborator = (
  organisationId: number,
  body: OrganisationCollaboratorForm
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(addOrganisationCollaboratorRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/collaborators`,
      body,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(addOrganisationCollaboratorSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(addOrganisationCollaboratorFailure(error.message));
    throw error;
  }
};
