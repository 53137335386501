//@flow
import React from 'react';
import { Table, Dropdown, Confirm, Loader } from 'semantic-ui-react';
import TableActions from '../../components/TableActions';
import type {
  Organisation,
  State as AppState,
  OrganisationFeature,
  AuthTypes,
  Dispatch,
} from '../../types';
import ActionLink from '../../components/ActionLink';
import HierarchyTable from '../../components/HierarchyTable';
import InlineSvg from '../../components/InlineSvg';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadOrganisationFeatures,
  updateOrganisationFeature,
  removeOrganisationFeature,
  addOrganisationFeature,
} from '../../actions/organisations/organisationFeatures';
import { getOrganisationFeatureHierarchy } from '../../reducers/organisationFeatures';
import PageLoader from '../../components/PageLoader';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import StaticField from '../../components/StaticField';
import {
  // $FlowFixMe
  sortChangeHandler,
  // $FlowFixMe
  sortData,
  featureAccess
} from '../../helpers';

type Props = {
  organisation: Organisation,
  features: Array<OrganisationFeature>,
  permission: ?AuthTypes,
  loadOrganisationFeatures: (oid: number) => Promise<null>,
  updateOrganisationFeature: (
    oid: number,
    pid: number,
    body: Object,
    updating: string
  ) => Promise<null>,
  removeOrganisationFeature: (oid: number, pid: number) => Promise<null>,
  addOrganisationFeature: (oid: number, pid: number) => Promise<null>,
  isFetching: boolean,
};

type State = {
  removeOrgOpen: boolean,
  sortColumn: ?string,
  sortDirection: ?string,
  archiveFeatureTarget?: {
    id: number,
    name: string,
  },
};

class Access extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      removeOrgOpen: false,
      sortColumn: '',
      sortDirection: '',
    };
  }

  componentDidMount() {
    this.props.loadOrganisationFeatures(this.props.organisation.id);
  }

  setRemoveFeatureModalVisible = (id, name) => {
    this.setState({
      ...this.state,
      removeOrgOpen: true,
      archiveFeatureTarget: {
        id,
        name
      },
    });
  };

  handleMemberChange = (feature, object) => {
    if (feature.defaultMemberAccess === object.value) return;
    const body = {
      defaultMemberAccess: object.value,
      defaultCollaboratorAccess: feature.defaultCollaboratorAccess,
    };
    this.props.updateOrganisationFeature(
      this.props.organisation.id,
      object.id,
      body,
      'Member'
    );
  };

  handleCollaboratorChange = (feature, object) => {
    if (feature.defaultCollaboratorAccess === object.value) return;
    const body = {
      defaultMemberAccess: feature.defaultMemberAccess,
      defaultCollaboratorAccess: object.value,
    };
    this.props.updateOrganisationFeature(
      this.props.organisation.id,
      object.id,
      body,
      'Collaborator'
    );
  };

  handleRemoveFeature = () => {
    if (this.state.archiveFeatureTarget) {
      this.props.removeOrganisationFeature(
        this.props.organisation.id,
        this.state.archiveFeatureTarget.id
      );
    }
    this.setState({
      ...this.state,
      removeOrgOpen: false,
      archiveFeatureTarget: undefined,
    });
  };

  handleAddFeature = (featureId) => {
    this.props.addOrganisationFeature(this.props.organisation.id, featureId);
  };

  handleSortChange = (clickedColumn: any) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  canManageFeature = (feature: OrganisationFeature, permission: ?AuthTypes) => {
    const { INTERNAL_ADMIN, BUSINESS_ADMIN } = permissions;
    return feature.isSpecial
      ? restrictUserPermissions([INTERNAL_ADMIN], permission)
      : restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN], permission);
  };

  render() {
    const { sortColumn, sortDirection } = this.state;
    const { features, isFetching, permission } = this.props;
    const { GRANT, DENY } = featureAccess;

    const FeatureName = (feature) => (
      <div
        className={feature.isSubscribed 
          ? "product-row__title" 
          : "product-row__title--locked"
        }
      >
        {feature.name}
      </div>
    );

    const FeatureDefaultMemberAccess = (feature) => {
      if(!feature.isSubscribed) {
        return (
          <div className="product-row__icon">
            <InlineSvg name="lock" colour="paleSky" />
          </div>
        );
      }

      return (
        <div>
          {this.canManageFeature(feature, permission) ? (
            <Dropdown
              id={feature.id}
              loading={feature.isFetchingMember}
              onChange={(e, object) => {
                this.handleMemberChange(feature, object);
              }}
              className="product-row__dropdown"
              value={feature.defaultMemberAccess}
              selection
              options={[
                { text: GRANT, value: true },
                { text: DENY, value: false },
              ]}
            />
          ) : (
            <StaticField
              label=""
              value={feature.defaultMemberAccess ? GRANT : DENY}
            />
          )}
        </div>
      );
    };
    
    const FeatureDefaultCollaboratorAccess = (feature) => {
      if(!feature.isSubscribed) {
        return (
          <div className="product-row__icon">
            <InlineSvg name="lock" colour="paleSky" />
          </div>
        );
      }

      return (
        <div>
          {this.canManageFeature(feature, permission) ? (
            <Dropdown
              id={feature.id}
              loading={feature.isFetchingCollaborator}
              onChange={(e, object) => {
                this.handleCollaboratorChange(feature, object);
              }}
              className="product-row__dropdown"
              value={feature.defaultCollaboratorAccess}
              selection
              options={[
                { text: GRANT, value: true },
                { text: DENY, value: false },
              ]}
            />
          ) : (
            <StaticField
              label=""
              value={feature.defaultCollaboratorAccess ? GRANT : DENY}
            />
          )}
        </div>
      );
    };
    
    const Actions = (feature) => (
      <div>
        {this.canManageFeature(feature, permission) ? (
          feature.isFetching ? (
            <Loader
              className="overflow--loader"
              active
              inline
              size="small"
            />
          ) : (
            <ActionLink 
              name={feature.isSubscribed ? 'Remove' : 'Add'}
              action={() => {
                feature.isSubscribed
                  ? this.setRemoveFeatureModalVisible(feature.id, feature.name)
                  : this.handleAddFeature(feature.id);
              }}
            />
          )
        ) : (
          <span>N/A</span>
        )}
      </div>
    );

    const columns = [
      FeatureName,
      FeatureDefaultMemberAccess,
      FeatureDefaultCollaboratorAccess,
      Actions
    ];

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'name' ? sortDirection : null}
          onClick={this.handleSortChange('name')}
        />
        <Table.HeaderCell
          width={2}
          sorted={sortColumn === 'defaultMemberAccess' ? sortDirection : null}
          onClick={this.handleSortChange('defaultMemberAccess')}
        >
          User
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          sorted={
            sortColumn === 'defaultCollaboratorAccess' ? sortDirection : null
          }
          onClick={this.handleSortChange('defaultCollaboratorAccess')}
        >
          Collaborator
        </Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        <Confirm
          // This confirmation box handles the Archive Organisation Action
          open={this.state.removeOrgOpen}
          onCancel={() =>
            this.setState({ ...this.state, removeOrgOpen: false })
          }
          content={`Are you sure you want to remove organisation access to ${
            this.state.archiveFeatureTarget &&
            this.state.archiveFeatureTarget.name
              ? this.state.archiveFeatureTarget.name
              : 'this resource'
          }?`}
          onConfirm={this.handleRemoveFeature}
        />
        <TableActions title="Product Access" />
        <div className="page-content">
          {isFetching ? (
            <PageLoader />
          ) : (
            <HierarchyTable
              perPage={1000}
              headerRow={TableHeader}
              columns={columns}
              items={features}
              rowClassName="product-row"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  isFetching: state.organisationFeatures.isFetching,
  features: getOrganisationFeatureHierarchy(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationFeatures,
      updateOrganisationFeature,
      removeOrganisationFeature,
      addOrganisationFeature,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Access): any);
