// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import type {
  ThunkAction,
  Action,
  OrganisationFlags,
  OrganisationFlagsResponse,
} from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_FLAGS_LOAD_REQUEST =
  'ORGANISATION_FLAGS_LOAD_REQUEST';
export const ORGANISATION_FLAGS_LOAD_SUCCESS =
  'ORGANISATION_FLAGS_LOAD_SUCCESS';
export const ORGANISATION_FLAGS_LOAD_FAILURE =
  'ORGANISATION_FLAGS_LOAD_FAILURE';

const loadOrganisationFlagsRequest = (): Action => ({
  type: ORGANISATION_FLAGS_LOAD_REQUEST,
});

const loadOrganisationFlagsSuccess = (
  organisationFlags: OrganisationFlagsResponse
): Action => ({
  type: ORGANISATION_FLAGS_LOAD_SUCCESS,
  payload: organisationFlags,
});

const loadOrganisationFlagsFailure = (error: string): Action => ({
  type: ORGANISATION_FLAGS_LOAD_FAILURE,
  error,
});

export const loadOrganisationFlags = (): ThunkAction => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationFlagsRequest());

    const response: OrganisationFlags[] | Error = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'management')}/organisation-flags`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload: OrganisationFlagsResponse = normaliseArrayOfObjects(
      response
    );

    dispatch(loadOrganisationFlagsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationFlagsFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
