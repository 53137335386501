// @flow
import * as actions from '../actions/organisations';
import { createSelector } from 'reselect';
import type { OrganisationActions, Organisations } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: Organisations = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
  currentOrganisation: '',
};

/* Reducer */

export const organisations = (
  state: Organisations = initialState,
  action: OrganisationActions
): Organisations => {
  switch (action.type) {
    case actions.ORGANISATIONS_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ORGANISATIONS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.ORGANISATIONS_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case actions.ORGANISATION_READ_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ORGANISATION_READ_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          [action.payload.id]: action.payload,
        },
        isFetching: false,
        error: undefined,
      };
    case actions.ORGANISATION_READ_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.ORGANISATION_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ORGANISATION_UPDATE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        isFetching: false,
        error: undefined,
      };
    case actions.ORGANISATION_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.ORGANISATION_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ORGANISATION_CREATE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            id: action.payload.id,
            name: action.payload.name,
            dateCreated: action.payload.dateCreated,
          },
        },
        order: [...state.order, action.payload.id],
        isFetching: false,
        error: undefined,
      };
    case actions.ORGANISATION_CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case actions.ORGANISATION_ARCHIVE_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.ORGANISATION_ARCHIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    /* eslint-disable no-case-declarations */
    case actions.ORGANISATION_ARCHIVE_SUCCESS:
      let copy = state.byId;
      // $FlowFixMe TODO(DP): Fix this
      delete copy[action.payload.id];
      let orderCopy = state.order;
      // $FlowFixMe TODO(DP): Fix this
      orderCopy = orderCopy.filter((e) => e !== action.payload.id);
      return {
        ...state,
        byId: {
          ...copy,
        },
        order: orderCopy,
        isFetching: false,
        error: undefined,
      };
    /* eslint-enable no-case-declarations */
    case actions.ORGANISATION_FORCE_VERIFY_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.ORGANISATION_FORCE_VERIFY_SUCCESS:
      return {
        ...state,
        error: undefined,
        isFetching: false,
      };
    case actions.ORGANISATION_FORCE_VERIFY_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };

    case actions.ORGANISATION_ALLOW_TO_HAVE_TEAMS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ORGANISATION_ALLOW_TO_HAVE_TEAMS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        isFetching: false,
        error: undefined,
      };
    case actions.ORGANISATION_ALLOW_TO_HAVE_TEAMS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOrganisationsSortedByDate: any = createSelector(
  (state) => state.organisations.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        let aDate = new Date(items[a].dateCreated);
        let bDate = new Date(items[b].dateCreated);
        return bDate - aDate;
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);

export const getOrganisationsSortedAlphabetically: any = createSelector(
  (state) => state.organisations.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        // return items[ a ].name - items[ b ].name;
        if (items[a].name.toLowerCase() < items[b].name.toLowerCase()) {
          return -1;
        } else if (items[a].name.toLowerCase() > items[b].name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
