//@flow
import * as React from 'react';
import { Grid, Form, Dropdown, Button } from 'semantic-ui-react';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import OverflowMenu from '../OverflowMenu';
import StaticField from '../StaticField';
import type {
  OnlineStoreItem as OnlineStoreItemType,
  AuthTypes,
} from '../../types';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from "draft-js-export-html";

type Props = {
  permission: ?AuthTypes,
  itemFormats: Array<any>,
  itemTypes: Array<OnlineStoreItemType>,
  reportTypes: Array<any>,
  navigationTree: Array<any>,
  onlineStoreItem: any,
  handleFormSubmit: Function,
  children?: any,
};

type State = any;

const selectedOption = (
  options: ?({ text: string, value: number }[]),
  optionId: ?number
): ?string => {
  const selectedOption =
    options && optionId ? options.find((i) => i.value === optionId) : null;
  return selectedOption ? selectedOption.text : '';
};

class OnlineStoreItemFormFields extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    const fulldesc1Html = props.onlineStoreItem.fulldesc1 ? props.onlineStoreItem.fulldesc1.replaceAll("</h3><li>", "</h3><ul><li>").replaceAll("</li><h3>", "</li></ul><h3>") : "";
    const fulldesc2Html = props.onlineStoreItem.fulldesc2 ? props.onlineStoreItem.fulldesc2.replaceAll("</h3><li>", "</h3><ul><li>").replaceAll("</li><h3>", "</li></ul><h3>") : "";
    const fulldesc3Html = props.onlineStoreItem.fulldesc3 ? props.onlineStoreItem.fulldesc3.replaceAll("</h3><li>", "</h3><ul><li>").replaceAll("</li><h3>", "</li></ul><h3>") : "";
    const fulldesc4Html = props.onlineStoreItem.fulldesc4 ? props.onlineStoreItem.fulldesc4.replaceAll("</h3><li>", "</h3><ul><li>").replaceAll("</li><h3>", "</li></ul><h3>") : "";

    const fulldesc1ContentState = stateFromHTML(fulldesc1Html);
    const fulldesc2ContentState = stateFromHTML(fulldesc2Html);
    const fulldesc3ContentState = stateFromHTML(fulldesc3Html);
    const fulldesc4ContentState = stateFromHTML(fulldesc4Html);

    var fullDesc1State = null;
    var fullDesc2State = null;
    var fullDesc3State = null;
    var fullDesc4State = null;

    if (fulldesc1ContentState) {
      fullDesc1State = EditorState.createWithContent(fulldesc1ContentState);
    }

    if (fulldesc2ContentState) {
      fullDesc2State = EditorState.createWithContent(fulldesc2ContentState);
    }

    if (fulldesc3ContentState) {
      fullDesc3State = EditorState.createWithContent(fulldesc3ContentState);
    }

    if (fulldesc4ContentState) {
      fullDesc4State = EditorState.createWithContent(fulldesc4ContentState);
    }

    this.state = {
      formData: {
        id: props.onlineStoreItem.id,
        skuNumber: props.onlineStoreItem.skuNumber,
        name: props.onlineStoreItem.name,
        shortDescription: props.onlineStoreItem.shortDescription,
        fulldesc1: props.onlineStoreItem.fulldesc1,
        fulldesc2: props.onlineStoreItem.fulldesc2,
        fulldesc3: props.onlineStoreItem.fulldesc3,
        fulldesc4: props.onlineStoreItem.fulldesc4,
        timePeriod: props.onlineStoreItem.timePeriod,
        price: props.onlineStoreItem.price,
        turnAroundTime: props.onlineStoreItem.turnAroundTime,
        itemFormatId: props.onlineStoreItem.itemFormatId,
        itemTypeId: props.onlineStoreItem.itemTypeId,
        reportTypeId: props.onlineStoreItem.reportTypeId,
        treeNavigationId: props.onlineStoreItem.treeNavigationId,
        tags: props.onlineStoreItem.tags,
        sampleSize: props.onlineStoreItem.sampleSize,
      },
      characterLimit: 160 - props.onlineStoreItem.shortDescription.length,
      storeItemFile: null,
      storeItemSampleFile: null,
      fullDesc1State: fullDesc1State ? fullDesc1State : null,
      fullDesc2State: fullDesc2State ? fullDesc2State : null,
      fullDesc3State: fullDesc3State ? fullDesc3State : null,
      fullDesc4State: fullDesc4State ? fullDesc4State : null,
    };

  }
  config: any;

  onFullDesc1StateChange: Function = (fullDesc1State) => {
    let formData = Object.assign({}, this.state.formData);
    formData["fulldesc1"] = stateToHTML(fullDesc1State.getCurrentContent());
    this.setState({formData,fullDesc1State});
  };

  onFullDesc2StateChange: Function = (fullDesc2State) => {
    let formData = Object.assign({}, this.state.formData);
    formData["fulldesc2"] = stateToHTML(fullDesc2State.getCurrentContent());
    this.setState({formData,fullDesc2State});
  };

  onFullDesc3StateChange: Function = (fullDesc3State) => {
    let formData = Object.assign({}, this.state.formData);
    formData["fulldesc3"] = stateToHTML(fullDesc3State.getCurrentContent());
    this.setState({formData,fullDesc3State});
  };

  onFullDesc4StateChange: Function = (fullDesc4State) => {
    let formData = Object.assign({}, this.state.formData);
    formData["fulldesc4"] = stateToHTML(fullDesc4State.getCurrentContent());
    this.setState({formData,fullDesc4State});
  };

  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: any
  ) => void = (event: SyntheticInputEvent<HTMLSelectElement>, result: any) => {
    let formData = Object.assign({}, this.state.formData);
    formData[result.name] = result.value;
    this.setState({ formData });
  };

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    if (event.target.name === 'shortDescription') {
      let characterLimit = 160 - event.target.value.length;
      this.setState({
        characterLimit,
      });
    }

    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleSelectedItemFile: (event: any) => void = (event: any) => {
    this.setState({ storeItemFile: event.target.files[0] });
  };

  handleSelectedItemSampleFile: (event: any) => void = (event: any) => {
    this.setState({ storeItemSampleFile: event.target.files[0] });
  };

  handleModelChange: Function = (desc, model) => {
    let formData = Object.assign({}, this.state.formData);
    formData[desc] = model;
    this.setState({ formData });
  };

  handleFullDescChange: (input: any) => (model: any) => void = (input: any) => {
    return (model: any) => {
      this.handleModelChange(input, model);
    };
  };

  render(): React.Node {
    const {
      handleFormSubmit,
      itemFormats,
      itemTypes,
      reportTypes,
      navigationTree,
      permission,
    } = this.props;

    const { formData } = this.state;

    const {
      skuNumber,
      name,
      shortDescription,
      fulldesc1,
      fulldesc2,
      fulldesc3,
      fulldesc4,
      timePeriod,
      price,
      turnAroundTime,
      itemFormatId,
      itemTypeId,
      reportTypeId,
      tags,
      treeNavigationId,
      sampleSize,
    } = formData;
    
    const { fullDesc1State,fullDesc2State,fullDesc3State,fullDesc4State } = this.state;
    
    const { INTERNAL_ADMIN, STANDARD_USER } = permissions;

    const itemFormatOptions = itemFormats.map((format: any) => {
      return {
        text: format.name,
        value: format.id,
      };
    });

    const itemTypeOptions = itemTypes.map((format: OnlineStoreItemType) => {
      return {
        text: format.name,
        value: format.id,
      };
    });

    const reportTypeOptions = reportTypes.map((format: any) => {
      return {
        text: format.name,
        value: format.id,
      };
    });

    const navigationTreeOptions = navigationTree.map((tree: any) => {
      return {
        text: tree.path,
        value: tree.id,
      };
    });

    if (
      !restrictUserPermissions([INTERNAL_ADMIN, STANDARD_USER], permission)
    ) {
      return (
        <StaticForm
          {...this.props}
          formData={this.state.formData}
          itemFormatOptions={itemFormatOptions}
          itemTypeOptions={itemTypeOptions}
          reportTypeOptions={reportTypeOptions}
          navigationTreeOptions={navigationTreeOptions}
        />
      );
    }

    const toolbar = {
      inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline'],
      },
      fontSize: { className: "draftjs-option-hidden" },
      fontFamily: { className: "draftjs-option-hidden" },
      colorPicker: { className: "draftjs-option-hidden" },
      image: { className: "draftjs-option-hidden" },
      emoji: { className: "draftjs-option-hidden" },
      embedded: { className: "draftjs-option-hidden" },
      remove: { className: "draftjs-option-hidden" },
      history: { className: "draftjs-option-hidden" },
    };
    return (
      <Form
        data-testid="online-store-item-form-fields"
        className="organisation-form"
        onSubmit={() => {
          handleFormSubmit(
            this.state.formData,
            this.state.storeItemFile,
            this.state.storeItemSampleFile
          );
        }}
      >
        <Grid columns={3} stackable>
          <Grid.Row>
            {this.props.onlineStoreItem.id && (
              <Grid.Column>
                <Form.Input
                  label="SKU ID"
                  name="skuId"
                  value={skuNumber}
                  type="text"
                  readOnly
                />
              </Grid.Column>
            )}
            <Grid.Column width={8}>
              <Form.Input
                label="Name"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                type="text"
                required
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <Form.Input
                label={
                  'Meta Description (' +
                  this.state.characterLimit +
                  ' character remaining)'
                }
                name="shortDescription"
                value={shortDescription}
                onChange={this.handleInputChange}
                type="text"
                maxLength="160"
                //required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <Form.TextArea
                label="Full Description 1"
                name="fulldesc1"
                value={fulldesc1}
                hidden // remove this hidden properties to debug
              />
              <Editor
                editorState={fullDesc1State}
                onEditorStateChange={this.onFullDesc1StateChange}
                toolbar={toolbar}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <Form.TextArea
                label="Full Description 2"
                name="fulldesc2"
                value={fulldesc2}
                hidden // remove this hidden properties to debug
              />
              <Editor
                editorState={fullDesc2State}
                onEditorStateChange={this.onFullDesc2StateChange}
                toolbar={toolbar}
              />
            </Grid.Column>

            <Grid.Column width={6}>
              <Form.TextArea
                label="Full Description 3"
                name="fulldesc2"
                value={fulldesc3}
                hidden // remove this hidden properties to debug
              />
              <Editor
                editorState={fullDesc3State}
                onEditorStateChange={this.onFullDesc3StateChange}
                toolbar={toolbar}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <Form.TextArea
                label="Full Description 4"
                name="fulldesc4"
                value={fulldesc4}
                hidden // remove this hidden properties to debug
              />
              <Editor
                editorState={fullDesc4State}
                onEditorStateChange={this.onFullDesc4StateChange}
                toolbar={toolbar}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <Form.Input
                label="Tags"
                name="tags"
                value={tags}
                onChange={this.handleInputChange}
                type="text"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Period"
                name="timePeriod"
                value={timePeriod}
                type="text"
                onChange={this.handleInputChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Price"
                name="price"
                value={price}
                onChange={this.handleInputChange}
                type="number"
                min="0"
                placeholder="amount"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Turn Around Time (days)"
                name="turnAroundTime"
                value={turnAroundTime}
                onChange={this.handleInputChange}
                type="number"
                min="0"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field required>
                <label htmlFor="itemFormatId">Item Format</label>
                <Dropdown
                  onChange={this.handleDropdownChange}
                  name="itemFormatId"
                  value={itemFormatId}
                  fluid
                  selection
                  placeholder={'Select Item Format'}
                  options={itemFormatOptions}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field required>
                <label htmlFor="itemTypeId">Item Type</label>
                <Dropdown
                  onChange={this.handleDropdownChange}
                  name="itemTypeId"
                  value={itemTypeId}
                  fluid
                  selection
                  placeholder={'Select Item Type'}
                  options={itemTypeOptions}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field required>
                <label htmlFor="reportTypeId">Report Type</label>
                <Dropdown
                  onChange={this.handleDropdownChange}
                  name="reportTypeId"
                  value={reportTypeId}
                  fluid
                  selection
                  placeholder={'Select Report Type'}
                  options={reportTypeOptions}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <Form.Input
                label="Sample Size"
                name="sampleSize"
                value={sampleSize}
                onChange={this.handleInputChange}
                type="number"
                min="0"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <Form.Field required>
                <label htmlFor="treeNavigationId">
                  Navigation Tree Location
                </label>
                <Dropdown
                  onChange={this.handleDropdownChange}
                  name="treeNavigationId"
                  value={treeNavigationId}
                  fluid
                  search
                  selection
                  placeholder={'Select Tree Location'}
                  options={navigationTreeOptions}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <Form.Input
                label="Upload Store Item File"
                name="storeItemFile"
                onChange={this.handleSelectedItemFile}
                type="file"
                transparent
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Form.Input
                label="Upload Store Item Sample File"
                name="storeItemSampleFile"
                onChange={this.handleSelectedItemSampleFile}
                type="file"
                transparent
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}>
              <div className="organisation-form__actions">
                <Button className="organisation-form__submit" type="submit">
                  {this.props.onlineStoreItem.id ? 'Update Details' : 'Create'}
                </Button>
                {this.props.children ? (
                  <OverflowMenu dark={true} leftAlign={true}>
                    {this.props.children}
                  </OverflowMenu>
                ) : (
                  ''
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const StaticForm = (props: {
  formData: Object,
  itemFormatOptions: Object[],
  itemTypeOptions: Object[],
  reportTypeOptions: Object[],
  navigationTreeOptions: Object[],
}) => {
  const { formData } = props;
  const {
    skuNumber,
    name,
    shortDescription,
    timePeriod,
    price,
    tags,
    turnAroundTime,
    itemFormatId,
    itemTypeId,
    reportTypeId,
    treeNavigationId,
    sampleSize,
    fulldesc1,
    fulldesc2,
    fulldesc3,
    fulldesc4,
  } = formData;
  return (
    <Form className="organisation-form">
      <Grid columns={3} stackable>
        <Grid.Row>
          <Grid.Column>
            <StaticField label="SKU ID" value={skuNumber} />
          </Grid.Column>
          <Grid.Column width={8}>
            <StaticField label="Name" value={name} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <StaticField label="Meta Description" value={shortDescription} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <StaticField label="Full Description 1" value={fulldesc1} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <StaticField label="Full Description 2" value={fulldesc2} />
          </Grid.Column>

          <Grid.Column width={6}>
            <StaticField label="Full Description 3" value={fulldesc3} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <StaticField label="Full Description 3" value={fulldesc4} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <StaticField label="Tags" value={tags} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField label="Period" value={timePeriod} />
          </Grid.Column>
          <Grid.Column>
            <StaticField label="Price" value={price} />
          </Grid.Column>
          <Grid.Column>
            <StaticField
              label="Turn Around Time (days)"
              value={turnAroundTime}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField label="Sample Size" value={sampleSize} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField
              label="Item Format"
              value={selectedOption(props.itemFormatOptions, itemFormatId)}
            />
          </Grid.Column>
          <Grid.Column>
            <StaticField
              label="Item Type"
              value={selectedOption(props.itemTypeOptions, itemTypeId)}
            />
          </Grid.Column>
          <Grid.Column>
            <StaticField
              label="Report Type"
              value={selectedOption(props.reportTypeOptions, reportTypeId)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <StaticField
              label="Navigation Tree Location"
              value={selectedOption(
                props.navigationTreeOptions,
                treeNavigationId
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default OnlineStoreItemFormFields;
