// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_FEATURE_UPDATE_REQUEST =
  'ORGANISATION_FEATURE_UPDATE_REQUEST';
export const ORGANISATION_FEATURE_UPDATE_SUCCESS =
  'ORGANISATION_FEATURE_UPDATE_SUCCESS';
export const ORGANISATION_FEATURE_UPDATE_FAILURE =
  'ORGANISATION_FEATURE_UPDATE_FAILURE';

const updateOrganisationFeatureRequest = (featureId, isFetching) => ({
  type: ORGANISATION_FEATURE_UPDATE_REQUEST,
  payload: {
    featureId,
    isFetching,
  },
});

const updateOrganisationFeatureSuccess = (feature) => ({
  type: ORGANISATION_FEATURE_UPDATE_SUCCESS,
  payload: feature,
});

const updateOrganisationFeatureFailure = (error, featureId, isFetching) => ({
  type: ORGANISATION_FEATURE_UPDATE_FAILURE,
  error,
  payload: {
    featureId,
    isFetching,
  },
});

export const updateOrganisationFeature = (
  organisationId: number,
  featureId: number,
  body: {},
  updating: string
): any => async (dispatch, getState) => {
  const isFetching = `isFetching${updating}`;
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationFeatureRequest(featureId, isFetching));

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/features/${featureId}`,
      body,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(updateOrganisationFeatureSuccess(response));

    return response;
  } catch (error) {
    dispatch(
      updateOrganisationFeatureFailure(error.message, featureId, isFetching)
    );
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
