// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import { normaliseArrayOfObjects } from '../../../helpers';
// import type { ThunkAction, Dispatch, Action } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_REQUEST =
  'ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_REQUEST';
export const ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_SUCCESS =
  'ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_SUCCESS';
export const ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_FAILURE =
  'ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_FAILURE';

const sendPickupEmailRequest = () => ({
  type: ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_REQUEST,
});

const sendPickupEmailSuccess = (onlineStoreOrder: any) => ({
  type: ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_SUCCESS,
  payload: onlineStoreOrder,
});

const sendPickupEmailFailure = (error) => ({
  type: ONLINE_STORE_ORDER_SEND_PICKUP_EMAIL_FAILURE,
  error,
});

export const sendPickupEmail = (OnlineStoreOrderId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(sendPickupEmailRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/management/orders/${OnlineStoreOrderId}/send-pickup-email`,
    });

    if (response instanceof Error) {
      console.error('error response: ' + response);
      Sentry.captureException(response);
      return response;
    }

    response.items = normaliseArrayOfObjects(response.items);

    dispatch(sendPickupEmailSuccess(response));

    return response;
  } catch (error) {
    dispatch(sendPickupEmailFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
