//@flow
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import SelfRegistrationForm from '../components/SelfRegistrationForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { State as AppState, Dispatch } from '../types';
import { selfRegister } from '../actions/user/selfRegister';

type User = {
  name?: string,
  email?: string,
  phone?: string,
  password?: string,
};

type Props = {
  user: User,
  selfRegister: (formData: User) => Promise<null>,
};

class SelfRegister extends React.Component<Props> {
  handleUpdate = (formData) => {
    this.props.selfRegister(formData);
  };

  render() {
    return (
      <Container data-testid="register-container">
        <Grid className="ui centered">
          <Grid.Column width={6}>
            <div className="single-form register" width={6}>
              <h2>Register</h2>
              <SelfRegistrationForm
                handleFormSubmit={this.handleUpdate}
                user={this.props.user}
                selfRegister={selfRegister}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  user: state.auth.user.user,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selfRegister,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfRegister): any);
