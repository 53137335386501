//@flow
import React from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import OverflowMenu from '../../components/OverflowMenu';
import type { Node } from 'React';

import type {
  OrganisationTeamForm,
  OrganisationTeam,
  AuthTypes,
} from '../../types';

type Props = {
  handleFormSubmit: (formData: OrganisationTeamForm) => void,
  team?: ?OrganisationTeam,
  children?: any,
  permission: ?AuthTypes,
};

type State = {
  formData: OrganisationTeamForm,
};

class OrganisationFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const team = this.props.team;
    this.state = {
      formData: {
        name: team && team.name ? team.name : '',
      },
    };
  }

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  render(): Node {
    const { handleFormSubmit } = this.props;
    const { name } = this.state.formData;

    return (
      <Form
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column width={12}>
              <Form.Input
                label="Team Name"
                name="name"
                value={name ? name : ''}
                onChange={this.handleInputChange}
                type="text"
                required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className="organisation-form__actions">
                <Button className="organisation-form__submit" type="submit">
                  Update Details
                </Button>
                {this.props.children ? (
                  <OverflowMenu dark={true} leftAlign={true}>
                    {this.props.children}
                  </OverflowMenu>
                ) : (
                  ''
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default OrganisationFormFields;
