//@flow
import React from 'react';
import type {
  State as AppState,
  Dispatch,
  OrganisationTeamForm,
  OrganisationTeam,
  AuthTypes,
} from '../../types';
import type { RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OrganisationFormFields from '../../components/OrganisationTeam/OrganisationFormFields';
import { createOrganisationTeam } from '../../actions/organisations/organisationTeams';
import { bindActionCreators } from 'redux';
import TabHeader from '../../components/TabHeader';
import { managementFeatures } from '../../helpers';
import { getPermissionInFeature } from 'roy-morgan-auth';

type Props = {
  organisationId: number,
  history: RouterHistory,
  permission: ?AuthTypes,
  createOrganisationTeam: (
    oid: number,
    formData: OrganisationTeamForm
  ) => Promise<OrganisationTeam>,
};

class NewTeam extends React.Component<Props> {
  handleFormSubmit = (formData) => {
    const organisationId = this.props.organisationId;
    this.props.createOrganisationTeam(organisationId, formData).then(
      (success: OrganisationTeam) => {
        this.props.history.push(
          `/organisations/${organisationId}/teams/${success.id}/details`
        );
      },
      (failure) => {}
    );
  };

  render() {
    const { organisationId, permission } = this.props;
    return (
      <div>
        <TabHeader
          tabHeading="Create New Team"
          backLink={`/organisations/${organisationId}/teams`}
        />
        <OrganisationFormFields
          handleFormSubmit={this.handleFormSubmit}
          permission={permission}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationTeams.isFetching,
  permission: getPermissionInFeature(
    managementFeatures.LIVE_MANAGEMENT,
    state.auth.authorisation
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      createOrganisationTeam,
    },
    dispatch
  );

export default (withRouter(
  // $FlowFixMe
  connect(mapStateToProps, mapDispatchToProps)(NewTeam)
): any);
