// @flow
import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import TableActions from '../../../components/TableActions';
import PaginatedTable from '../../../components/PaginatedTable';
import { NEW_sortData } from '../../../helpers/sort';
import PageLoader from '../../../components/PageLoader';
import InlineSvg from '../../../components/InlineSvg';

type TableLog = {
  org_name: string,
  org_id: string,
  weeklyPings: any[],
  last7Days: boolean,
  last4Weeks: boolean,
  last6Months: boolean,
  last12Months: boolean,
  lastDateAccessed: string,
  recordCount: number,
};

type RowData = {
  org_name: string,
  org_id: string,
  recordCount: number,
  last7Days: boolean,
  last4Weeks: boolean,
  last6Months: boolean,
  last12Months: boolean,
  lastDateAccessed: string,
};

type Props = {
  isFetching: Boolean,
  results: TableLog[],
  rmEmailLogFilter: Boolean,
};

export default function OverallUsageByOrganisationTable({
  isFetching,
  results,
}: Props): any {
  const data = results || [];

  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [filterValue, setFilterValue] = useState('');

  //Extract Organisations Records out of Data
  const unFilteredRows: RowData[] = [];
  if (data && data.length > 0) {
    data.forEach((item) => {
      unFilteredRows.push({
        org_id: item.org_id,
        org_name: item.org_name,
        recordCount: item.recordCount,
        last7Days: item.last7Days,
        last4Weeks: item.last4Weeks,
        last6Months: item.last6Months,
        last12Months: item.last12Months,
        lastDateAccessed: item.lastDateAccessed,
      });
    });
  }

  let rowData = [];
  if (unFilteredRows && unFilteredRows.length > 0) {
    rowData = unFilteredRows.filter((log: RowData) => {
      const orgName = log.org_name.toLowerCase();
      const filter = filterValue.toLowerCase();
      return orgName === filter || orgName.includes(filter) || filter === '';
    });
  }

  const columnClick = (column: string) => {
    if (sortColumn !== column) {
      setSortColumn(column);
      if (
        [
          'last7Days',
          'last4Weeks',
          'last6Months',
          'last12Months',
          'lastDateAccessed',
        ].includes(column)
      ) {
        setSortDirection('descending');
      } else {
        setSortDirection('ascending');
      }
      return;
    }

    const newSort = sortDirection === 'ascending' ? 'descending' : 'ascending';
    setSortDirection(newSort);
  };

  const formatDate = (date) => {
    if (!date) return '';
    const format = new Date(date);

    const month = format.getMonth() + 1;
    const monthNumber = month < 10 ? `0${month}` : month;
    const dayNumber =
      format.getDate() < 10 ? `0${format.getDate()}` : format.getDate();

    return `${format.getFullYear()}-${monthNumber}-${dayNumber}`;
  };

  const columnHeaders = [
    { id: 'organisation', label: 'Organisation', sort: 'org_name' },
    { id: 'last7Days', label: 'L7D', sort: 'lastDateAccessed' },
    { id: 'last4Weeks', label: 'L4W', sort: 'lastDateAccessed' },
    { id: 'last6Months', label: 'L6M', sort: 'lastDateAccessed' },
    { id: 'last12Months', label: 'L12M', sort: 'lastDateAccessed' },
    {
      id: 'lastDateAccessed',
      label: 'Last Date Access',
      sort: 'lastDateAccessed',
    },
  ];

  const TableHeader = (
    <Table.Row>
      {columnHeaders && (
        <>
          {columnHeaders.map((column, key) => {
            return (
              <Table.HeaderCell
                key={key}
                sorted={sortColumn === column.id ? sortDirection : null}
                onClick={() => columnClick(column.sort)}
              >
                {column.label}
              </Table.HeaderCell>
            );
          })}
        </>
      )}
    </Table.Row>
  );

  const rows = rowData
    .sort((a, b) => NEW_sortData(a, b, { sortColumn, sortDirection }))
    .map((log: RowData) => {
      return (
        <Table.Row key={log.org_id}>
          <Table.Cell>
            <span className="usage-table__organisation">
              {createOrgLinkLabel(log.org_name, log.org_id)}
            </span>
          </Table.Cell>
          <Table.Cell>
            <TableIcon value={log.last7Days} />
          </Table.Cell>
          <Table.Cell>
            <TableIcon value={log.last4Weeks} />
          </Table.Cell>
          <Table.Cell>
            <TableIcon value={log.last6Months} />
          </Table.Cell>
          <Table.Cell>
            <TableIcon value={log.last12Months} />
          </Table.Cell>
          <Table.Cell>
            <span className="usage-table__last-login-date">
              {formatDate(log.lastDateAccessed)}
            </span>
          </Table.Cell>
        </Table.Row>
      );
    });

  return (
    <div className="usage-table">
      <TableActions
        title="Engagement By Organisation"
        filterAction={(e) => {
          setFilterValue(e.target.value);
        }}
        filterPlaceholder="Filter Usage Logs..."
      />
      {isFetching ? (
        <PageLoader />
      ) : (
        <PaginatedTable
          perPage={40}
          headerRow={TableHeader}
          rows={rows}
          filterValue={filterValue}
          sortable={true}
        />
      )}
    </div>
  );
}

const TableIcon = ({ value }: { value: boolean }) => {
  if (value) {
    return <InlineSvg className="usage-table__table-icon" name="ok" />;
  }
  return (
    <InlineSvg
      className="usage-table__table-icon"
      name="not-ok"
      colour={'mischka'}
    />
  );
};

const createOrgLinkLabel = (label, id) => {
  return (
    <a href={`/organisations/${id}/usage-log`}>{label}</a>
  );
};
