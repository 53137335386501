//@flow
import * as React from 'react';
import { Grid, Form, Dropdown, Button } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react'; //ToDo: Current version produces warning.  Developer has stated that it will be fixed in next release.  https://github.com/arfedulov/semantic-ui-calendar-react/issues/56
import moment from 'moment';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import OverflowMenu from '../OverflowMenu';
import StaticField from '../StaticField';
import Message from '../../components/Message';
import type { OnlineStoreCoupon, AuthTypes } from '../../types';

type Props = {
  permission: ?AuthTypes,
  couponTypes: Array<any>,
  couponApplyTypes: Array<any>,
  onlineStoreCoupon: OnlineStoreCoupon,
  handleFormSubmit: Function,
  children?: any,
};

type State = {
  formData: any,
  errorMessage: any,
};

const dateFormat = 'DD/MM/YYYY hh:mm A';

const COUPON_TYPE_PERCENTAGE = 1;

const selectedOption = (
  options: ?({ text: string, value: number }[]),
  optionId: ?number
): ?string => {
  const selectedOption =
    options && optionId ? options.find((i) => i.value === optionId) : null;
  return selectedOption ? selectedOption.text : '';
};

class OnlineStoreCouponFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formData: {
        id: props.onlineStoreCoupon.id,
        name: props.onlineStoreCoupon.name,
        amount: props.onlineStoreCoupon.amount,
        validFrom: props.onlineStoreCoupon.validFrom,
        validTo: props.onlineStoreCoupon.validTo,
        isOneOff: props.onlineStoreCoupon.isOneOff,
        couponTypeId: props.onlineStoreCoupon.couponTypeId,
        couponApplyTypeId: props.onlineStoreCoupon.couponApplyTypeId,
      },
      errorMessage: undefined,
    };
  }

  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: any
  ) => void = (event: SyntheticInputEvent<HTMLSelectElement>, result: any) => {
    let formData = Object.assign({}, this.state.formData);
    formData[result.name] = result.value;
    this.setState({ formData });
  };

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleCheckboxChange: (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => void = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.checked;
    this.setState({ formData });
  };

  handleDateChange: (event: any, { name: any, value: any, ... }) => void = (
    event: any,
    { name, value }: { name: any, value: any }
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[name] = value ? moment(value, dateFormat).toISOString() : null;
    this.setState({ formData });
  };

  handleSubmit: (data: any) => void = (data: any) => {
    const { handleFormSubmit } = this.props;
    let { valid, errorMessage } = this.formValidation(data);
    if (!valid) {
      return this.setErrorMessage(errorMessage);
    }

    this.resetErrorMessage();
    handleFormSubmit(data).then((result) => {
      if (!result.id) {
        return this.setErrorMessage(result.message);
      }
    });
  };

  formValidation: (
    data: any
  ) => {| errorMessage: void | string, valid: boolean |} = (data: any) => {
    let valid = true;
    let errorMessage;

    if (!data.couponTypeId || !data.couponApplyTypeId) {
      valid = false;
      errorMessage = 'Coupon Type and Coupon Apply Type should be selected';
    }

    if (/\s/g.test(data.name)) {
      valid = false;
      errorMessage = 'Coupon Name cannot contain spaces';
    }

    return { valid, errorMessage };
  };

  setErrorMessage: (error: any) => void = (error: any) => {
    return this.setState(
      {
        ...this.state,
        errorMessage: error,
      },
      function () {}
    );
  };

  resetErrorMessage: () => void = () => {
    this.setState({
      ...this.state,
      errorMessage: undefined,
    });
  };

  render(): React.Node {
    const { couponTypes, couponApplyTypes, permission } = this.props;
    const { formData, errorMessage } = this.state;
    const {
      name,
      amount,
      validFrom,
      validTo,
      isOneOff,
      couponTypeId,
      couponApplyTypeId,
    } = formData;

    const { INTERNAL_ADMIN, STANDARD_USER } = permissions;

    const validFromFormatted = validFrom
      ? moment(validFrom).format(dateFormat)
      : '';
    const validToFormatted = validTo ? moment(validTo).format(dateFormat) : '';

    const couponTypeOptions = couponTypes.map((format: any) => {
      return {
        text: format.name,
        value: format.id,
      };
    });

    const couponApplyTypeOptions = couponApplyTypes.map((format: any) => {
      return {
        text: format.name,
        value: format.id,
        //     disabled: format.id !== 1 //ToDo: temporarily disabled option
      };
    });

    if (
      !restrictUserPermissions([INTERNAL_ADMIN, STANDARD_USER], permission)
    ) {
      return (
        <StaticForm
          {...this.props}
          formData={this.state.formData}
          couponTypeOptions={couponTypeOptions}
          couponApplyTypeOptions={couponApplyTypeOptions}
        />
      );
    }

    return (
      <React.Fragment>
        <Message
          onDismiss={this.resetErrorMessage}
          content={errorMessage}
          error
          show={errorMessage !== undefined}
        />
        <Form
          className="organisation-form"
          onSubmit={() => {
            this.handleSubmit(this.state.formData);
          }}
        >
          <Grid columns={2} stackable>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Input
                  label="Coupon Code"
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                  type="text"
                  required
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Form.Field required>
                  <label htmlFor="couponTypeId">Coupon Type</label>
                  <Dropdown
                    onChange={this.handleDropdownChange}
                    name="couponTypeId"
                    value={couponTypeId}
                    fluid
                    selection
                    placeholder={'Select Coupon Type'}
                    options={couponTypeOptions}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label htmlFor="couponApplyTypeId">Coupon Apply Type</label>
                  <Dropdown
                    onChange={this.handleDropdownChange}
                    name="couponApplyTypeId"
                    value={couponApplyTypeId}
                    fluid
                    selection
                    placeholder={'Select Coupon Apply Type'}
                    options={couponApplyTypeOptions}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <Form.Input
                  label="Discount"
                  name="amount"
                  value={amount}
                  onChange={this.handleInputChange}
                  type="number"
                  min="0"
                  max={
                    couponTypeId === COUPON_TYPE_PERCENTAGE ? 100 : undefined
                  }
                  placeholder="amount"
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  <Form.Input
                    label="One Off"
                    name="isOneOff"
                    type="checkbox"
                    checked={isOneOff}
                    onChange={this.handleCheckboxChange}
                    className="store-checkbox"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <DateTimeInput
                  label="Valid From"
                  name="validFrom"
                  placeholder="Valid From"
                  value={validFromFormatted}
                  initialDate={validFromFormatted}
                  maxDate={validToFormatted}
                  iconPosition="left"
                  timeFormat="AMPM"
                  onChange={this.handleDateChange}
                />
              </Grid.Column>
              <Grid.Column>
                <DateTimeInput
                  label="Valid To"
                  name="validTo"
                  placeholder="Valid To"
                  value={validToFormatted}
                  initialDate={validToFormatted}
                  minDate={validFromFormatted}
                  iconPosition="left"
                  timeFormat="AMPM"
                  onChange={this.handleDateChange}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={12}>
                <div className="organisation-form__actions">
                  <Button className="organisation-form__submit" type="submit">
                    {this.props.onlineStoreCoupon.id
                      ? 'Update Details'
                      : 'Create'}
                  </Button>
                  {this.props.children ? (
                    <OverflowMenu dark={true} leftAlign={true}>
                      {this.props.children}
                    </OverflowMenu>
                  ) : (
                    ''
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </React.Fragment>
    );
  }
}

const StaticForm = (props: {
  formData: Object,
  couponTypeOptions: Object[],
  couponApplyTypeOptions: Object[],
}) => {
  const { formData } = props;
  const {
    name,
    amount,
    isOneOff,
    validFrom,
    validTo,
    couponTypeId,
    couponApplyTypeId,
  } = formData;
  const validFromFormatted = validFrom
    ? moment(validFrom).format(dateFormat)
    : '';
  const validToFormatted = validTo ? moment(validTo).format(dateFormat) : '';

  return (
    <Form className="organisation-form">
      <Grid columns={2} stackable>
        <Grid.Row>
          <Grid.Column width={12}>
            <StaticField label="Coupon Code" value={name} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField
              label="Coupon Type"
              value={selectedOption(props.couponTypeOptions, couponTypeId)}
            />
          </Grid.Column>
          <Grid.Column>
            <StaticField
              label="Coupon Apply Type"
              value={selectedOption(
                props.couponApplyTypeOptions,
                couponApplyTypeId
              )}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <StaticField label="Discount" value={amount} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField label="One Off" value={isOneOff ? 'Yes' : 'No'} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StaticField label="Valid From" value={validFromFormatted} />
          </Grid.Column>
          <Grid.Column>
            <StaticField label="Valid To" value={validToFormatted} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default OnlineStoreCouponFormFields;
