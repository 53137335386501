// @flow
import type { Action } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';
import type { UsageDomains } from '../types';

/* Initial State */
const initialState: UsageDomains = {
  result: [],
  isFetching: false,
  error: undefined,
};

/* Reducer */
export const usageDomains = (
  state: UsageDomains = initialState,
  action: Action
): UsageDomains => {
  switch (action.type) {
    case 'USAGE_DOMAINS_LOAD_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case 'USAGE_DOMAINS_LOAD_SUCCESS':
      return {
        ...state,
        result: action.payload,
        isFetching: false,
        error: undefined,
      };
    case 'USAGE_DOMAINS_LOAD_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
