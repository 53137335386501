// @flow
import { createSelector } from 'reselect';
import type { OrganisationsWithoutApiSubscription } from '../types';
import * as actions from '../actions/apiSubscriptions';
// $FlowFixMe
import { getItemsSortedByName } from '../helpers';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: OrganisationsWithoutApiSubscription = {
  order: [],
  byId: {},
  isFetching: false,
  error: undefined
};

/* Reducer */

export const organisationsWithoutApiSubscription = (
  state: OrganisationsWithoutApiSubscription = initialState,
  action: Object
): OrganisationsWithoutApiSubscription => {
  switch (action.type) {
    case actions.API_ORGANISATIONS_UNSUBSCRIBED_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.API_ORGANISATIONS_UNSUBSCRIBED_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case actions.API_ORGANISATION_ADD_SUCCESS:
      delete state.byId[action.payload.id];
      state.order = state.order.filter(e => e !== action.payload.id);
      return {
        ...state,
        byId: {
          ...state.byId
        },
        order: state.order,
        isFetching: false,
        error: undefined
      };
    case actions.API_ORGANISATION_REMOVE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload
          }
        },
        isFetching: false,
        error: undefined
      };
    case actions.API_ORGANISATIONS_UNSUBSCRIBED_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOrganisationsWithoutApiSubscription: any = createSelector(
  (state) => state.organisationsWithoutApiSubscription,
  (items) => {
      let organisations = getItemsSortedByName(items);

      return {
        items: organisations, 
        isFetching: items.isFetching, 
        error: items.error
      }
  }
);
