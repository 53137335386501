// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const USAGE_DOMAINS_LOAD_REQUEST =
  'USAGE_DOMAINS_LOAD_REQUEST';
export const USAGE_DOMAINS_LOAD_SUCCESS =
  'USAGE_DOMAINS_LOAD_SUCCESS';
export const USAGE_DOMAINS_LOAD_FAILURE =
  'USAGE_DOMAINS_LOAD_FAILURE';

const usageDomainsRequest = () => ({
  type: USAGE_DOMAINS_LOAD_REQUEST,
});

const usageDomainsSuccess = (response) => ({
  type: USAGE_DOMAINS_LOAD_SUCCESS,
  payload: response,
});

const usageDomainsFailure = (error) => ({
  type: USAGE_DOMAINS_LOAD_FAILURE,
  error
});


export const loadUsageDomains = (
): any => async (dispatch, getState) => {
  try {

    refreshToken(dispatch);
    dispatch(usageDomainsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/usage/available-domains`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(usageDomainsSuccess(response));
    return response;

  } catch (error) {
    dispatch(usageDomainsFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
