//@flow
import React from 'react';
import TabHeader from '../../components/TabHeader';
import type {
  State as AppState,
  OrganisationTeam,
  OrganisationTeamUser,
  Dispatch,
  AuthTypes,
} from '../../types';
import OverflowMenu from '../../components/OverflowMenu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Confirm, Table, Modal } from 'semantic-ui-react';
import PageLoader from '../../components/PageLoader';
import { loadOrganisationTeamUsers } from '../../actions/organisations/organisationTeams/organisationTeamUsers/';
import { removeOrganisationUserTeam } from '../../actions/organisations/organisationUsers/organisationUserTeams';

import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { getUsersSortedAlphabetically } from '../../reducers/organisationTeamUsers';
import TableActions from '../../components/TableActions';
import AddTeamUser from '../../components/OrganisationTeam/AddTeamUser';
import PaginatedTable from '../../components/PaginatedTable';
import {
  // $FlowFixMe
  sortChangeHandler,
  // $FlowFixMe
  sortData,
  formatDate
} from '../../helpers';
import { getIsReady } from '../../reducers/fetchSync';
import { setFetchSyncReady } from '../../actions';

type Props = {
  organisationId: number,
  teamId: number,
  team: ?OrganisationTeam,
  loadOrganisationTeamUsers: (oid: number, tid: number) => Promise<*>,
  permission: ?AuthTypes,
  removeOrganisationUserTeam: (
    oid: number,
    tid: number,
    uid: number
  ) => Promise<*>,
  users: Array<Object>,
  isFetching: boolean,
  isReady: boolean,
  setTeamUsersReady: () => void,
};

type State = {
  filterValue: string,
  addUserOpen: boolean,
  removeUserOpen: boolean,
  removeUserTarget?: {
    id: number,
    name: string,
  },
  sortColumn: string,
  sortDirection: string,
};

class TeamUsers extends React.Component<Props, State> {
  csvLink: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      addUserOpen: false,
      removeUserOpen: false,
      removeUserTarget: undefined,
      sortColumn: '',
      sortDirection: '',
    };

    this.csvLink = React.createRef();
  }

  componentDidMount() {
    const { loadOrganisationTeamUsers, organisationId, teamId } = this.props;
    loadOrganisationTeamUsers(organisationId, teamId).finally(
      this.props.setTeamUsersReady
    );
  }

  handleFilterChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  toggleModal = () => {
    this.setState({
      addUserOpen: !this.state.addUserOpen,
    });
  };

  setRemoveUserModalVisible = (userId: number, userName: string) => {
    this.setState({
      ...this.state,
      removeUserOpen: true,
      removeUserTarget: { id: userId, name: userName },
    });
  };

  handleRemoveUser = () => {
    if (this.state.removeUserTarget) {
      this.props.removeOrganisationUserTeam(
        this.props.organisationId,
        this.props.teamId,
        this.state.removeUserTarget.id
      );
    }
    this.setState({
      ...this.state,
      removeUserOpen: false,
      removeUserTarget: undefined,
    });
  };

  handleSortChange = (clickedColumn: any) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort = (a: any, b: any) => {
    return sortData(a, b, this);
  };


  exportUsers = () => {
    this.csvLink.current.link.click();
  };

  render() {
    const {
      organisationId,
      teamId,
      team,
      users,
      isFetching,
    } = this.props;
    const { addUserOpen, sortColumn, sortDirection } = this.state;


    const csvData = users.map((user: any) => {
      return {
        'Name': user.name,
        'Email': user.email,
        'Last Login': formatDate(user.lastLoginDateTime),
        'Status': user.status
      };
    });

    const filterValue = this.state.filterValue.toLowerCase();

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'userName' ? sortDirection : null}
          onClick={this.handleSortChange('userName')}
        >
          Name
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'email' ? sortDirection : null}
          onClick={this.handleSortChange('email')}
        >
          Email
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'lastLoginDateTime' ? sortDirection : null}
          onClick={this.handleSortChange('lastLoginDateTime')}
        >
          Last Login
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'status' ? sortDirection : null}
          onClick={this.handleSortChange('status')}
        >
          Status
        </Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    );

    let rows = users.filter((user: any) => {
      const userName = user.name.toLowerCase();
      const email = user.email.toLowerCase();
      return (
        userName === filterValue ||
        userName.includes(filterValue) ||
        email === filterValue ||
        email.includes(filterValue) ||
        filterValue === ''
      );
    });
    rows = rows.sort(this.sort).map((user: OrganisationTeamUser) => {
      return (
        <Table.Row key={user.id}>
          <Table.Cell>
            <Link
              to={`/organisations/${organisationId}/users/${user.id}/details`}
            >
              {user.name}
            </Link>
          </Table.Cell>
          <Table.Cell>
            <Link
              to={`/organisations/${organisationId}/users/${user.id}/details`}
            >
              {user.email}
            </Link>
          </Table.Cell>
          <Table.Cell>{formatDate(user.lastLoginDateTime)}</Table.Cell>
          <Table.Cell>{user.status}</Table.Cell>
          <Table.Cell>
            {!user.canAccessAllTeams ? (
              <OverflowMenu>
                <li>
                  {/* // TODO: replace anchor with button // // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onKeyPress={() => {
                      this.setRemoveUserModalVisible(user.id, user.name);
                    }}
                    onClick={() => {
                      this.setRemoveUserModalVisible(user.id, user.name);
                    }}
                  >
                    Remove
                  </a>
                </li>
              </OverflowMenu>
            ) : (
              <span>N/A</span>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <TabHeader
          tabHeading={`${team ? team.name + "'s " : ''}Users`}
          backLink={`/organisations/${organisationId}/teams`}
          backText="Back to Teams"
        />
        <Confirm
          // remove user
          content={`Are you sure you want to remove ${
            this.state.removeUserTarget && this.state.removeUserTarget.name
              ? this.state.removeUserTarget.name
              : 'this user'
          } from the current team?`}
          open={this.state.removeUserOpen}
          onCancel={() =>
            this.setState({ ...this.state, removeUserOpen: false })
          }
          onConfirm={this.handleRemoveUser}
        />

        <Grid centered stackable>
          <Grid.Column>
            <TableActions
              filterAction={this.handleFilterChange}
              filterPlaceholder="Filter Users..."

              buttonText="Add User"
              buttonAction={this.toggleModal}
              downloadButtonAction={csvData.length === 0 ? null : this.exportUsers}
              showDownloadButton
            />
            {isFetching ? (
              <PageLoader />
            ) : (
              <PaginatedTable
                headerRow={headerRow}
                rows={rows}
                sortable={true}
                stackEarly
              />
            )}
          </Grid.Column>
        </Grid>
        <Modal
          open={addUserOpen}
          closeIcon={<button className="modal__close">Close</button>}
          onClose={this.toggleModal}
        >
          <Modal.Header>Add User to Team</Modal.Header>
          <Modal.Content>
            <AddTeamUser organisationId={organisationId} teamId={teamId} />
          </Modal.Content>
        </Modal>
        <CSVLink
          data={csvData}
          filename={`organisation_${organisationId}_team_${teamId}_users`}
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        >
        </CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  team: state.organisationTeams.byId[props.teamId.toString()],
  users: getUsersSortedAlphabetically(state),
  isFetching: state.organisationTeamUsers.isFetching,
  isReady: getIsReady(state, 'teamUsers'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationTeamUsers,
      removeOrganisationUserTeam,
      setTeamUsersReady: setFetchSyncReady('teamUsers'),
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(TeamUsers): any);
