// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import type { OrganisationTeam } from '../../../types/organisationTeams';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_TEAM_ARCHIVE_REQUEST =
  'ORGANISATION_TEAM_ARCHIVE_REQUEST';
export const ORGANISATION_TEAM_ARCHIVE_SUCCESS =
  'ORGANISATION_TEAM_ARCHIVE_SUCCESS';
export const ORGANISATION_TEAM_ARCHIVE_FAILURE =
  'ORGANISATION_TEAM_ARCHIVE_FAILURE';

const archiveOrganisationTeamRequest = () => ({
  type: ORGANISATION_TEAM_ARCHIVE_REQUEST,
});

const archiveOrganisationTeamSuccess = (team: OrganisationTeam) => ({
  type: ORGANISATION_TEAM_ARCHIVE_SUCCESS,
  payload: team,
});

const archiveOrganisationTeamFailure = (error) => ({
  type: ORGANISATION_TEAM_ARCHIVE_FAILURE,
  error,
});

export const archiveOrganisationTeam = (
  organisationId: number,
  teamId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(archiveOrganisationTeamRequest());
    const today = new Date();
    const body = {
      isArchived: true,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}`,
      body,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(archiveOrganisationTeamSuccess(response));

    return true;
  } catch (error) {
    dispatch(archiveOrganisationTeamFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
