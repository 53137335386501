// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';
import type { Organisation } from '../../types';

export const API_ORGANISATION_ADD_REQUEST = 'API_ORGANISATION_ADD_REQUEST';
export const API_ORGANISATION_ADD_SUCCESS = 'API_ORGANISATION_ADD_SUCCESS';
export const API_ORGANISATION_ADD_FAILURE = 'API_ORGANISATION_ADD_FAILURE';

const addOrganisationApiSubscriptionRequest = () => ({
  type: API_ORGANISATION_ADD_REQUEST,
});

const addOrganisationApiSubscriptionSuccess = (subscription) => ({
  type: API_ORGANISATION_ADD_SUCCESS,
  payload: subscription,
});

const addOrganisationApiSubscriptionFailure = (error) => ({
  type: API_ORGANISATION_ADD_FAILURE,
  error,
});

export const addOrganisationApiSubscription = (
  apiId: number, 
  organisation: Organisation
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(addOrganisationApiSubscriptionRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisation.id}/apis`,
      body: {
        apiId
      }
    });

    if (response instanceof Error) {
      throw response;
    }

    let subscription = toOrganisationSubscriptionObject(response, organisation);

    dispatch(addOrganisationApiSubscriptionSuccess(subscription));
  } catch (error) {
    dispatch(addOrganisationApiSubscriptionFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};

function toOrganisationSubscriptionObject(apiSubscription, organisation) {
  return {
    id: organisation.id,
    name: organisation.name,
    apiId: apiSubscription.id,
    token: apiSubscription.token,
    isSubscriptionActive: apiSubscription.isActive
  };
}