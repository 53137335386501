//@flow
import * as React from 'react';
import {
  Grid,
  Form,
  Message as SemanticUIReactMessage,
  Button, GridRow, GridColumn
} from 'semantic-ui-react';
import type { Dispatch, State as AppState } from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

type Props = {
  handleFormSubmit: Function,
  setCanHaveTeamsAction: Function,
  OrganisationId: number
};

type State = {
  formData: any
};

class TransformOrganisationToHaveTeams extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formData : {
        teamNames: ''
      }
    };
  }

  componentDidMount() {
  }

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData['teamNames'] = event.target.value;
    this.setState({ formData });
  };

  handleSubmit: (data: any) => void = (data: any) => {
    const { handleFormSubmit, setCanHaveTeamsAction, OrganisationId } = this.props;
   setCanHaveTeamsAction(
        OrganisationId,
        data.teamNames
      )
      .then(
        () => {
          handleFormSubmit(data)
        },
        (failure) => {
        }
      );
  }

  render() {

    const { formData } = this.state;
    const { teamNames } = formData;
    return (
      <Grid >
        <GridRow>
        <GridColumn>
        <SemanticUIReactMessage warning>
          Transforming this organisation into a Teams-based structure is irreversible, and may involve users losing access to some data.
        </SemanticUIReactMessage>
        <SemanticUIReactMessage info>
          Please enter a list of team names, separated by commas.  At least one Team name is required to start the process.
        </SemanticUIReactMessage>
      </GridColumn>
    </GridRow>
    <GridRow>
      <GridColumn>
        <Form
          className="organisation-form"
        >
        <Form.Input label="Team's names" required type="text" placeholder="Names of teams" value={teamNames}  onChange={this.handleInputChange}/>
        <Button className="organisation-form__submit" onClick={()=>this.handleSubmit(this.state.formData)}>Allow To Have Teams
        </Button>
      </Form>
      </GridColumn>
    </GridRow>
  </Grid>
    )
  }
}

const mapStateToProps = (state: AppState, props) => ({
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(TransformOrganisationToHaveTeams): any);
