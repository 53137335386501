// @flow
import type { AccountManager, State as AppState, Dispatch } from '../types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';
import PaginatedTable from '../components/PaginatedTable';
import PageLoader from '../components/PageLoader';
import { loadAccountManagerCandidates } from '../actions/accountManagers';
import { getAccountManagersSortedAlphabetically } from '../reducers/accountManagers';

type Props = {
  accountManagerCandidates: Array<AccountManager>,
  loadAccountManagerCandidates: () => Promise<*>,
  isFetching: boolean,
  accountManagers: Array<AccountManager>,
  addAccountManager: (id: number, name: ?string) => void,
  removeAccountManager: (id: number) => void
};

class SetAccountManagers extends React.Component<Props> {

  componentDidMount() {
    const { loadAccountManagerCandidates } = this.props;

    loadAccountManagerCandidates();
  }

  render() {
    const { 
      accountManagerCandidates, 
      accountManagers,
      isFetching, 
      addAccountManager, 
      removeAccountManager
    } = this.props;

    let candidatesToAdd = accountManagerCandidates
      .filter(c => 
        !accountManagers.find(m => m.id === c.id)
      )
      .map(c => {
        return {...c, toAdd: true};
      });
    
    let currentManagers = accountManagers
      .map(m => {
        return {...m, toAdd: false};
      });

    let rows = [...currentManagers, ...candidatesToAdd]
      .map((user: any) => {
        return (
          <Table.Row key={user.id}>
            <Table.Cell>{user.name}</Table.Cell>
            <Table.Cell>
              {
                user.toAdd ? (
                  <Button
                    floated="right"
                    onClick={() => addAccountManager(user.id, user.name)}
                  >
                    Add Account Manager
                  </Button>
                )
                : (
                  <Button
                    floated="right"
                    color="red"
                    onClick={() => removeAccountManager(user.id)}
                  >
                    Remove Account Manager
                  </Button>
                )
              }
            </Table.Cell>
          </Table.Row>
        );
      });

    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell colSpan={2}>Name</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        {!isFetching ? (
          <PaginatedTable headerRow={tableHeader} rows={rows} />
        ) : (
          <PageLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  accountManagerCandidates: getAccountManagersSortedAlphabetically(state),
  isFetching: state.accountManagers.isFetching,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadAccountManagerCandidates,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(SetAccountManagers): any);
