//@flow
import React, { useState } from 'react';
import { Table, Modal, Button, Grid, Form } from 'semantic-ui-react';
import type { Element } from 'React';
import { loadOrganisationUsersActivity } from '../actions/organisations/organisationUsers/loadOrganisationUsersActivity';
import { useDispatch } from 'react-redux';

type Props = {
  isOpen: boolean,
  onClose: () => any,
  organisationId: number,
  organisationName: string,
};

export default function DownloadActivityModal(props: Props): Element<any> {
  const date = new Date().toISOString().slice(0, 10);
  const { isOpen, onClose, organisationId, organisationName } = props;
  const title = 'Download Detailed Activity';
  const checkboxClass = 'download-activity-modal__checkbox';
  const dispatch = useDispatch();
  const [includeRMRUsers, setIncludeRMRUsers] = useState(false);
  const [includeArchivedUsers, setIncludeArchivedUsers] = useState(false);
  const [includeBackgroundApiUsage, setIncludeBackgroundApiUsage] = useState(
    false
  );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell width={6}>Options</Table.HeaderCell>
      <Table.HeaderCell width={6}></Table.HeaderCell>
    </Table.Row>
  );

  const downloadDetailedActivity = async () => {
    // Pass all checkboxes and Organisation ID to the API
    const response = await dispatch(
      loadOrganisationUsersActivity(
        organisationId,
        includeRMRUsers,
        includeArchivedUsers,
        includeBackgroundApiUsage
      )
    );

    // If the response is an empty array or undefined, throw error message
    if (response.length === 0 || typeof response === 'undefined') {
      alert('Error downloading CSV\nNo data available for this Organisation');

      return;
    }

    // Start creating the CSV file
    const fields = Object.keys(response[0]);
    const replacer = function (key, value) {
      return value === null ? '' : value;
    };

    let csv = response.map(function (row) {
      return fields
        .map(function (fieldName) {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(',');
    });

    // Adds header column to the CSV file
    csv.unshift(fields.join(','));
    // Places return carriage between each row
    csv = csv.join('\r\n');

    let csvContent = 'data:text/csv;charset=utf-8,' + csv;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `${'detailed_activity'}${'_'}${organisationName.replace(
        / /g,
        '_'
      )}${'_'}${date}${'.csv'}`
    );

    if (document.body) {
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <Modal
      className="download-activity-modal"
      open={isOpen}
      closeIcon={<button className="modal__close">Close</button>}
      onClose={onClose}
    >
      <Modal.Header>{title}</Modal.Header>

      <Modal.Content>
        <Grid centered>
          <Grid.Row centered>
            <Grid.Column>
              <Table striped>
                <Table.Header>{tableHeader}</Table.Header>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid padded className="download-activity-modal__checkbox-container">
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Include Roy Morgan users"
                name="rmUsers"
                type="checkbox"
                className={checkboxClass}
                checked={includeRMRUsers}
                onChange={(e) => setIncludeRMRUsers(e.target.checked)}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Include archived users"
                name="archivedUsers"
                type="checkbox"
                className={checkboxClass}
                checked={includeArchivedUsers}
                onChange={(e) => setIncludeArchivedUsers(e.target.checked)}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Include manage.roymorgan.com (background API usage)"
                name="manageRmUsers"
                type="checkbox"
                className={checkboxClass}
                checked={includeBackgroundApiUsage}
                onChange={(e) => setIncludeBackgroundApiUsage(e.target.checked)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div className="download-activity-modal__submit-button">
          <Button onClick={downloadDetailedActivity}>Download Activity</Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}
