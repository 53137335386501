// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import type {
  ThunkAction,
  Action,
  Industries,
  IndustriesResponse,
} from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const INDUSTRIES_LOAD_REQUEST = 'INDUSTRIES_LOAD_REQUEST';
export const INDUSTRIES_LOAD_SUCCESS = 'INDUSTRIES_LOAD_SUCCESS';
export const INDUSTRIES_LOAD_FAILURE = 'INDUSTRIES_LOAD_FAILURE';

const loadIndustriesRequest = (): Action => ({
  type: INDUSTRIES_LOAD_REQUEST,
});

const loadIndustriesSuccess = (industries: IndustriesResponse): Action => ({
  type: INDUSTRIES_LOAD_SUCCESS,
  payload: industries,
});

const loadIndustriesFailure = (error: string): Action => ({
  type: INDUSTRIES_LOAD_FAILURE,
  error,
});

export const loadIndustries = (): ThunkAction => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadIndustriesRequest());

    const response: Industries[] | Error = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'management')}/industries`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload: IndustriesResponse = normaliseArrayOfObjects(
      response
    );

    dispatch(loadIndustriesSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadIndustriesFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
    return error;
  }
};
