/* eslint-disable react/display-name */
//@flow

import React from 'react';
import { Route } from 'react-router-dom';
import type { Element } from 'React';
import ApiSubscriptionOrganisations from './subTabs/ApiSubscriptionOrganisations';
import { permissions } from 'roy-morgan-auth';

type Props = {
  subItems: Array<any>,
  api: any
};

export default function ApiSubscriptionDetails(props: Props) : Element<'div'> {
  const { subItems, api } = props;

  let items = [
    ...subItems,
    {
      container: () => (
        <ApiSubscriptionOrganisations
          api={api}
        />
      ),
      path: `/api-subscriptions/${api.id || ''}/details`,
      acceptedPermissions: [permissions.INTERNAL_ADMIN]
    }
  ];

  return (
    <div className="tabbed-content">
      {items.map((item, index) => {
        return (
          <Route
            exact={true}
            key={index}
            path={item.path}
            render={item.container}
          />
        );
      })}
    </div>
  );
}
