// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../../middleware';
import type { ThunkAction, Dispatch, Action } from '../../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_REQUEST =
  'ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_REQUEST';
export const ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_SUCCESS =
  'ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_SUCCESS';
export const ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_FAILURE =
  'ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_FAILURE';

const removeOrganisationCollaboratorOrganisationRequest = (): Action => ({
  type: ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_REQUEST,
});

const removeOrganisationCollaboratorOrganisationSuccess = (
  collaboratorId: number,
  organisationId: number
): Action => ({
  type: ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_SUCCESS,
  id: collaboratorId,
  organisationId: organisationId,
});

const removeOrganisationCollaboratorOrganisationFailure = (
  error: string
): Action => ({
  type: ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_FAILURE,
  error,
});

export const removeOrganisationCollaboratorOrganisation = (
  organisationId: number,
  collaboratorId: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOrganisationCollaboratorOrganisationRequest());

    const response = await callAPI({
      method: 'DELETE',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/collaborators/${collaboratorId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(
      removeOrganisationCollaboratorOrganisationSuccess(
        collaboratorId,
        organisationId
      )
    );

    return true;
  } catch (error) {
    dispatch(removeOrganisationCollaboratorOrganisationFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
