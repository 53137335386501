// @flow
import * as React from 'react';
import { Table, Pagination } from 'semantic-ui-react';
import InlineSvg from './InlineSvg';

type Props = {
  perPage?: number,
  headerRow: React.Node,
  rows: Array<any>,
  sortable?: boolean,
  filterValue?: any,
  stackEarly?: boolean,
};

type State = {
  currentPage: number,
};

class PaginatedTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.filterValue !== this.props.filterValue) {
      this.setState({ currentPage: 1 });
    }
  }

  handlePageChange: (
    event: SyntheticMouseEvent<HTMLButtonElement>,
    data: { activePage: number, ... }
  ) => void = (
    event: SyntheticMouseEvent<HTMLButtonElement>,
    data: { activePage: number }
  ) => {
    this.setState({ currentPage: data.activePage });
  };

  render(): React.Element<'div'> {
    const {
      perPage = 10,
      headerRow,
      rows,
      sortable,
      stackEarly = false,
    } = this.props;

    const { currentPage } = this.state;

    const totalRows = rows.length;
    const totalPages = Math.ceil(totalRows / perPage);

    const lowerBoundary =
      totalRows > 0 ? this.state.currentPage * perPage - (perPage - 1) : 0;
    const upperBoundary =
      totalRows > 0 ? Math.min(this.state.currentPage * perPage, totalRows) : 0;

    const visibleRows = rows.slice(lowerBoundary - 1, upperBoundary);

    const paginationCaption =
      totalPages > 0
        ? `Showing ${lowerBoundary} to
      ${upperBoundary} of ${totalRows} results`
        : `No results to display`;

    return (
      <div
        className={`paginated-table-container ${
          stackEarly ? 'stack-at-desktop' : ''
        }`}
      >
        <Table sortable={sortable} striped>
          <Table.Header>{headerRow}</Table.Header>

          <Table.Body>{visibleRows.map((row, index) => row)}</Table.Body>
        </Table>

        <div className="pagination-container--align-right">
          <div className="pagination-container__inner">
            <div className="pagination-caption">
              <em>{paginationCaption}</em>
            </div>

            <Pagination
              className="pagination"
              activePage={currentPage}
              totalPages={totalPages}
              firstItem={null}
              lastItem={null}
              nextItem={{
                content: <InlineSvg name="arrow-right" colour="deepCyan" />,
                icon: true,
              }}
              prevItem={{
                content: <InlineSvg name="arrow-left" colour="deepCyan" />,
                icon: true,
              }}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PaginatedTable;
