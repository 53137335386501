// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ACCOUNT_MANAGERS_LOAD_REQUEST = 'ACCOUNT_MANAGERS_LOAD_REQUEST';
export const ACCOUNT_MANAGERS_LOAD_SUCCESS = 'ACCOUNT_MANAGERS_LOAD_SUCCESS';
export const ACCOUNT_MANAGERS_LOAD_FAILURE = 'ACCOUNT_MANAGERS_LOAD_FAILURE';

const loadAccountManagerCandidatesRequest = () => ({
  type: ACCOUNT_MANAGERS_LOAD_REQUEST,
});

const loadAccountManagerCandidatesSuccess = (users) => ({
  type: ACCOUNT_MANAGERS_LOAD_SUCCESS,
  payload: users,
});

const loadAccountManagerCandidatesFailure = (error) => ({
  type: ACCOUNT_MANAGERS_LOAD_FAILURE,
  error,
});

export const loadAccountManagerCandidates = (): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadAccountManagerCandidatesRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/account-managers?excludeArchived=true`,
    });
    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadAccountManagerCandidatesSuccess(normalisedPayload));
  } catch (error) {
    dispatch(loadAccountManagerCandidatesFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};
