//@flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadOrganisationCollaboratorFeatures,
  updateOrganisationCollaboratorFeature,
} from '../../actions/organisations/organisationCollaborators/organisationCollaboratorFeatures';
import { getOrganisationCollaboratorFeatures } from '../../reducers/organisationCollaboratorFeatures';
import TableActions from '../../components/TableActions';
import PageLoader from '../../components/PageLoader';
import FeatureAcessTable from '../../components/FeatureAccessTable';
import type {
  State as AppState,
  Feature,
  Organisation,
  AuthTypes,
  Dispatch,
} from '../../types';

type Props = {
  organisation: Organisation,
  collaboratorId: number,
  features: Array<Feature>,
  loadOrganisationCollaboratorFeatures: (
    oid: number,
    uid: number
  ) => Promise<null>,
  updateOrganisationCollaboratorFeature: (
    oid: number,
    uid: number,
    pid: number,
    body: { hasAccess: ?boolean }
  ) => Promise<null>,
  isFetching: boolean,
  permission: AuthTypes,
};

class CollaboratorAccess extends React.Component<Props> {
  componentDidMount() {
    this.props.loadOrganisationCollaboratorFeatures(
      this.props.organisation.id,
      this.props.collaboratorId
    );
  }

  updateCollaboratorFeatureAccess = (featureId: number, hasAccess: ?boolean) => {
    this.props.updateOrganisationCollaboratorFeature(
      this.props.organisation.id,
      this.props.collaboratorId,
      featureId,
      { hasAccess }
    );
  };

  render() {
    const { features, isFetching } = this.props;

    return (
      <div>
        <TableActions title="Product Access" />
        {isFetching ? (
          <PageLoader />
        ) : (
          <FeatureAcessTable
            features={features}
            canUpdate={true}
            updateAccess={this.updateCollaboratorFeatureAccess}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationCollaboratorFeatures.isFetching,
  features: getOrganisationCollaboratorFeatures(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadOrganisationCollaboratorFeatures,
      updateOrganisationCollaboratorFeature,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaboratorAccess): any);
