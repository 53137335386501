// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';
// import type { ThunkAction, Dispatch, Action } from '../../types';

export const GLOBAL_USERS_LOAD_REQUEST = 'GLOBAL_USERS_LOAD_REQUEST';
export const GLOBAL_USERS_LOAD_SUCCESS = 'GLOBAL_USERS_LOAD_SUCCESS';
export const GLOBAL_USERS_LOAD_FAILURE = 'GLOBAL_USERS_LOAD_FAILURE';

const loadUsersRequest = () => ({
  type: GLOBAL_USERS_LOAD_REQUEST,
});

const loadUsersSuccess = (users) => ({
  type: GLOBAL_USERS_LOAD_SUCCESS,
  payload: users,
});

const loadUsersFailure = (error) => ({
  type: GLOBAL_USERS_LOAD_FAILURE,
  error,
});

export const loadUsers = (): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadUsersRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/users?excludeArchived=true`,
    });
    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadUsersSuccess(normalisedPayload));
  } catch (error) {
    dispatch(loadUsersFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};
