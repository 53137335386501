// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

type OrganisationUserForm = {
  name: string,
  email: string,
  canAccessAllTeams: boolean,
};

export const ORGANISATION_USER_INVITE_REQUEST =
  'ORGANISATION_USER_INVITE_REQUEST';
export const ORGANISATION_USER_INVITE_SUCCESS =
  'ORGANISATION_USER_INVITE_SUCCESS';
export const ORGANISATION_USER_INVITE_FAILURE =
  'ORGANISATION_USER_INVITE_FAILURE';

const inviteOrganisationUserRequest = (): Object => ({
  type: ORGANISATION_USER_INVITE_REQUEST,
});

const inviteOganisationUserSuccess = (user): Object => ({
  type: ORGANISATION_USER_INVITE_SUCCESS,
  payload: user,
});

const inviteOrganisationUserFailure = (error): Object => ({
  type: ORGANISATION_USER_INVITE_FAILURE,
  error,
});

export const inviteOrganisationUser = (
  organisationId: number,
  body: OrganisationUserForm
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(inviteOrganisationUserRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users`,
      body,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(inviteOganisationUserSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(inviteOrganisationUserFailure(error.message));
    throw error;
  }
};
