// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../../helpers';
import { callAPI, refreshToken } from '../../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_TEAM_FEATURE_UPDATE_REQUEST =
  'ORGANISATION_TEAM_FEATURE_UPDATE_REQUEST';
export const ORGANISATION_TEAM_FEATURE_UPDATE_SUCCESS =
  'ORGANISATION_TEAM_FEATURE_UPDATE_SUCCESS';
export const ORGANISATION_TEAM_FEATURE_UPDATE_FAILURE =
  'ORGANISATION_TEAM_FEATURE_UPDATE_FAILURE';

const updateOrganisationTeamFeatureRequest = (featureId): Object => ({
  type: ORGANISATION_TEAM_FEATURE_UPDATE_REQUEST,
  payload: featureId,
});

const updateOrganisationTeamFeatureSuccess = (features): Object => ({
  type: ORGANISATION_TEAM_FEATURE_UPDATE_SUCCESS,
  payload: features,
});

const updateOrganisationTeamFeatureFailure = (error, featureId): Object => ({
  type: ORGANISATION_TEAM_FEATURE_UPDATE_FAILURE,
  error,
  id: featureId,
});

export const updateOrganisationTeamFeature = (
  organisationId: number,
  teamId: number,
  featureId: number,
  body: {}
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationTeamFeatureRequest(featureId));

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}/features/${featureId}`,
      body,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);
    dispatch(updateOrganisationTeamFeatureSuccess(normalisedPayload));

    return response;
  } catch (error) {
    dispatch(updateOrganisationTeamFeatureFailure(error.message, featureId));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
