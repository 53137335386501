// @flow
import * as React from 'react';
import { resetPassword } from 'roy-morgan-auth';
import { withRouter } from 'react-router-dom';
import { Grid, Container, Message } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import URLSearchParams from 'url-search-params';
import TempResetPasswordForm from '../components/TempResetPasswordForm';
import { getProductUrl } from '../helpers';

type Props = {
  resetPassword: (newPassword: string, token: string) => Promise<?boolean>,
  location: Object,
  isFetching?: ?boolean,
  error?: ?string,
  history: {
    push: (url: string) => void,
  },
};

class ResetPassword extends React.Component<Props> {
  handleSubmit = (newPassword: string) => {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const newToken = searchParams.get('token') || '';
    const productId = searchParams.get('productId') || '';
    const env = process.env.REACT_APP_RM_ENV
      ? process.env.REACT_APP_RM_ENV
      : process.env.REACT_APP_ENV;
    const redirectUrl = getProductUrl(productId, env);

    this.props.resetPassword(newPassword, newToken).then((success) => {
      if (success === true) {
        if (redirectUrl === '/') {
          this.props.history.push('/');
        } else {
          window.location.href = redirectUrl;
        }
      }
    });
  };

  render() {
    const { error } = this.props;

    return (
      <div className="page--multifaceted-bg-grey">
        <Container className="page-container">
          <div className="login-form">
            <Grid stackable centered>
              <Grid.Column width={6}>
                <div className="callout">
                  <h2>Reset Password</h2>
                  <Grid stackable centered>
                    <Grid.Column width={10}>
                      {error && (
                        <Message warning icon={false} content={error} />
                      )}
                      <TempResetPasswordForm handleSubmit={this.handleSubmit} />
                    </Grid.Column>
                  </Grid>
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  isFetching: state.auth.user.isFetching,
  error: state.auth.user.error,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      resetPassword,
    },
    dispatch
  );

export default (withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
): any);
