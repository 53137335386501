// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import {
  readOnlineStoreItem,
  changeOnlineStoreItem,
  setFetchSyncReady,
} from '../../actions';
import type {
  // State as AppState,
  // Dispatch,
  // OnlineStoreItem as OnlineStoreItemType,
  // AccessRole,
  AuthTypes,
} from '../../types';
import Breadcrumb from '../../components/Breadcrumb';
import SideMenu from '../../components/SideMenu';
import { OnlineStoreItemDetails } from '../OnlineStoreItemTabs';
import { getIsReady } from '../../reducers/fetchSync';

type Props = {
  onlineStoreItem: any,
  permission: ?AuthTypes,
  setSelectedOnlineStoreItem: (uid: number) => number,
  match: {
    params: {
      tab?: string,
      onlineStoreItemId: number,
    },
    url: string,
  },
  readOnlineStoreItem: (onlineStoreItemId: number) => Promise<null>,
  changeOnlineStoreItem: () => Promise<boolean>,
  isReady: boolean,
  setStoreItemReady: () => void,
};

type State = {
  contextChange: boolean,
};

class OnlineStoreItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      contextChange: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (typeof nextProps.onlineStoreItem === 'undefined') {
      this.setState({
        ...this.state,
        contextChange: true,
      });
    } else if (nextProps.onlineStoreItem.id !== this.props.onlineStoreItem?.id) {
      this.props.changeOnlineStoreItem();
    }
  }

  componentDidMount() {
    const { match, readOnlineStoreItem } = this.props;
    readOnlineStoreItem(match.params.onlineStoreItemId).finally(
      this.props.setStoreItemReady
    );
  }

  render() {
    const { onlineStoreItem, match, permission, isReady } = this.props;
    const currentUrl = match.url;
    const onlineStoreItemId = match.params.onlineStoreItemId;

    const items = [
      {
        label: 'Details',
        container: () => (
          <OnlineStoreItemDetails onlineStoreItemId={onlineStoreItemId} />
        ),
        path: `/storeitems/${onlineStoreItemId}/details`,
      },
    ];

    if (!isReady) {
      return null;
    }

    if (this.state.contextChange || typeof onlineStoreItem === 'undefined') {
      return <Redirect to="/storeitems" />;
    } else {
      return (
        <Container>
          <Grid>
            <Grid.Column width={12}>
              <Breadcrumb
                titleClass={'breadcrumb__current-title--lower-case'}
                topLevelContext="All Store Items"
                currentContext={onlineStoreItem.name}
                backLink="/storeitems"
              />
            </Grid.Column>
          </Grid>
          <Grid className="side-menu-layout">
            <Grid.Column width={3} className="side-menu-column">
              <SideMenu
                currentPath={currentUrl}
                items={items}
                permission={permission}
              />
            </Grid.Column>
            <Grid.Column width={9}>
              <Grid>
                <Grid.Column>
                  {items.map((item, index) => {
                    return (
                      <Route
                        key={index}
                        path={item.path}
                        render={item.container}
                      />
                    );
                  })}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state: any, props) => {
  return {
    onlineStoreItem:
      state.onlineStoreItems.byId[props.match.params.onlineStoreItemId],
    isReady: getIsReady(state, 'storeItem'),
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      readOnlineStoreItem,
      changeOnlineStoreItem,
      setStoreItemReady: setFetchSyncReady('storeItem'),
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreItem): any);
