// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ONLINE_STORE_COUPONS_LOAD_REQUEST =
  'ONLINE_STORE_COUPONS_LOAD_REQUEST';
export const ONLINE_STORE_COUPONS_LOAD_SUCCESS =
  'ONLINE_STORE_COUPONS_LOAD_SUCCESS';
export const ONLINE_STORE_COUPONS_LOAD_FAILURE =
  'ONLINE_STORE_COUPONS_LOAD_FAILURE';

const loadOnlineStoreCouponsRequest = () => ({
  type: ONLINE_STORE_COUPONS_LOAD_REQUEST,
});

const loadOnlineStoreCouponsSuccess = (onlineStoreCoupons) => ({
  type: ONLINE_STORE_COUPONS_LOAD_SUCCESS,
  payload: onlineStoreCoupons,
});

const loadOnlineStoreCouponsFailure = (error) => ({
  type: ONLINE_STORE_COUPONS_LOAD_FAILURE,
  error,
});

export const loadOnlineStoreCoupons = (): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOnlineStoreCouponsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/management/coupons`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOnlineStoreCouponsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOnlineStoreCouponsFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
