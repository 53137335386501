//@flow
import React from 'react';
import InlineSvg from './InlineSvg';
import { Link } from 'react-router-dom';
import type { Element } from 'React';

type Props = {
  backAction?: Function,
  backLink?: string,
  backText?: string,
  tabHeading?: string,
};

class TabHeader extends React.Component<Props> {
  render(): Element<'div'> {
    const { backLink, backAction, tabHeading, backText = 'Back' } = this.props;
    return (
      <div className="tab-header">
        {backLink ? (
          <Link
            to={backLink}
            data-testid="top-level"
            className="breadcrumb__back-link"
          >
            <InlineSvg name="arrow-left" colour="paleSky" />
            <span>{backText}</span>
          </Link>
        ) : (
          ''
        )}
        {backAction ? (
          <button
            onClick={backAction}
            data-testid="top-level"
            className="breadcrumb__back-link"
          >
            <InlineSvg name="arrow-left" colour="paleSky" />
            <span>Back</span>
          </button>
        ) : (
          ''
        )}
        {tabHeading ? (
          <div>
            <h2 className="tab-header__title">{tabHeading}</h2>
            <hr />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default TabHeader;
