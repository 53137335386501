// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const RESEND_INVITATION_EMAIL_ORGANISATION_USER_REQUEST =
  'RESEND_INVITATION_EMAIL_ORGANISATION_USER_REQUEST';
export const RESEND_INVITATION_EMAIL_ORGANISATION_USER_SUCCESS =
  'RESEND_INVITATION_EMAIL_ORGANISATION_USER_SUCCESS';
export const RESEND_INVITATION_EMAIL_ORGANISATION_USER_FAILURE =
  'RESEND_INVITATION_EMAIL_ORGANISATION_USER_FAILURE';

const resendInvitationEmailOrganisationUserRequest = (): Object => ({
  type: RESEND_INVITATION_EMAIL_ORGANISATION_USER_REQUEST,
});

const resendInvitationEmailOrganisationUserSuccess = (message): Object => ({
  type: RESEND_INVITATION_EMAIL_ORGANISATION_USER_SUCCESS,
  message: message,
});

const resendInvitationEmailOrganisationUserFailure = (error): Object => ({
  type: RESEND_INVITATION_EMAIL_ORGANISATION_USER_FAILURE,
  error,
});

export const resendInvitationEmailOrganisationUser = (
  organisationId: number,
  userId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(resendInvitationEmailOrganisationUserRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users/${userId}/invite`,
    });

    dispatch(resendInvitationEmailOrganisationUserSuccess(response));

    return response;
  } catch (error) {
    dispatch(resendInvitationEmailOrganisationUserFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
