// @flow
import type { MessagesState } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: MessagesState = {
  content: undefined,
  type: undefined,
};

/* Reducer */
export const messages = (
  state: MessagesState = initialState,
  action: Object
): MessagesState => {
  switch (action.type) {
    case 'ORGANISATIONS_LOAD_FAILURE':
    case 'ORGANISATION_UPDATE_FAILURE':
    case 'ORGANISATION_CREATE_FAILURE':
    case 'ORGANISATION_READ_FAILURE':
    case 'ORGANISATION_ARCHIVE_FAILURE':
    case 'ORGANISATION_FORCE_VERIFY_FAILURE':
    case 'ORGANISATION_USER_INVITE_FAILURE':
    case 'ORGANISATION_USER_READ_FAILURE':
    case 'ORGANISATION_USER_UPDATE_FAILURE':
    case 'ORGANISATION_USER_FORCE_VERIFY_FAILURE':
    case 'RESEND_INVITATION_EMAIL_ORGANISATION_USER_FAILURE':
    case 'ORGANISATION_USER_REMOVE_FAILURE':
    case 'ORGANISATION_USERS_LOAD_FAILURE':
    case 'ORGANISATION_TEAMS_LOAD_FAILURE':
    case 'ORGANISATION_TEAM_CREATE_FAILURE':
    case 'ORGANISATION_TEAM_UPDATE_FAILURE':
    case 'ORGANISATION_TEAM_ARCHIVE_FAILURE':
    case 'ORGANISATION_TEAM_FORCE_VERIFY_FAILURE':
    case 'ORGANISATION_FEATURE_UPDATE_FAILURE':
    case 'ORGANISATION_FEATURE_ADD_FAILURE':
    case 'ORGANISATION_FEATURE_REMOVE_FAILURE':
    case 'ORGANISATION_FEATURES_LOAD_FAILURE':
    case 'ORGANISATION_USER_FEATURE_UPDATE_FAILURE':
    case 'ORGANISATION_USER_TEAMS_LOAD_FAILURE':
    case 'ORGANISATION_USER_TEAM_REMOVE_FAILURE':
    case 'ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_REQUEST':
    case 'ORGANISATION_TEAM_USERS_LOAD_FAILURE':
    case 'ORGANISATION_TEAM_FEATURES_LOAD_FAILURE':
    case 'ORGANISATION_TEAM_FEATURE_UPDATE_FAILURE':
    case 'ORGANISATION_USER_ORGANISATION_REMOVE_FAILURE':
    case 'ORGANISATION_COLLABORATOR_ADD_FAILURE':
    case 'ORGANISATION_COLLABORATORS_LOAD_FAILURE':
    case 'ORGANISATION_COLLABORATOR_UPDATE_FAILURE':
    case 'ORGANISATION_COLLABORATOR_REMOVE_FAILURE':
    case 'ORGANISATION_COLLABORATOR_FORCE_VERIFY_FAILURE':
    case 'ORGANISATION_COLLABORATOR_TEAM_REMOVE_FAILURE':
    case 'ORGANISATION_COLLABORATOR_ORGANISATIONS_LOAD_FAILURE':
    case 'ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_FAILURE':
    case 'ORGANISATION_COLLABORATOR_TEAMS_LOAD_FAILURE':
    case 'ORGANISATION_COLLABORATOR_FEATURES_LOAD_FAILURE':
    case 'ORGANISATION_COLLABORATOR_FEATURE_UPDATE_FAILURE':
    case 'ORGANISATION_TEAM_COLLABORATORS_LOAD_FAILURE':
    case 'ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_FAILURE':
    case 'USER_UPDATE_FAILURE':
    case 'INDUSTRIES_LOAD_FAILURE':
    case 'USER_UPDATE_PASSWORD_FAILURE':
    case 'ORGANISATION_ACCOUNT_MANAGER_ADD_FAILURE':
    case 'ORGANISATION_ACCOUNT_MANAGER_REMOVE_FAILURE':
    case 'GLOBAL_USER_READ_FAILURE':
    case 'GLOBAL_USER_UPDATE_FAILURE':
    case 'ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_FAILURE':
    case 'SELF_REGISTER_FAILURE':
    case 'ADMIN_UPDATE_USER_PASSWORD_FAILURE':
    case 'API_ORGANISATION_ADD_FAILURE':
    case 'API_ORGANISATION_UPDATE_FAILURE':
    case 'API_ORGANISATION_REMOVE_FAILURE':
    case 'API_ORGANISATION_UPDATE_KEY_FAILURE':
    case 'API_USER_ADD_FAILURE':
    case 'API_USER_REMOVE_FAILURE':
    case 'ORGANISATION_ALLOW_TO_HAVE_TEAMS_FAILURE':
      return {
        content: action.error,
        type: 'error',
      };
    case 'SELF_REGISTER_SUCCESS':
      return {
        content:
          'Thanks for registering. Please check your email to confirm your email address',
        type: 'success',
      };
    case 'ORGANISATION_UPDATE_SUCCESS':
      return {
        content: 'Updated Organisation',
        type: 'success',
      };
    case 'ORGANISATION_ALLOW_TO_HAVE_TEAMS_SUCCESS': {
      let content = ' has been split into Teams. All users have by default been set to belong to all teams. Please review each team and assign permissions as appropriate. Please review each user and assign their teams as appropriate.';
        if (action && action.payload && action.payload.name){
          content = 'Organisation ' + action.payload.name + content;
        } else {
          content = 'Organisation' + content;
        }
        return {
          content: content,
          type: 'success',
        }
      }
    case 'ORGANISATION_CREATE_SUCCESS':
      return {
        content: 'Organisation created',
        type: 'success',
      };
    case 'ORGANISATION_ARCHIVE_SUCCESS':
      return {
        content: 'Organisation archived',
        type: 'success',
      };
    case 'ORGANISATION_FORCE_VERIFY_SUCCESS':
      return {
        content:
          'All users within the organisation has been forced to verify their email',
        type: 'success',
      };
    case 'ORGANISATION_USER_INVITE_SUCCESS':
      return {
        content: 'User successfully invited',
        type: 'success',
      };
    case 'ORGANISATION_USER_UPDATE_SUCCESS':
    case 'GLOBAL_USER_UPDATE_SUCCESS':
      return {
        content: 'User successfully updated',
        type: 'success',
      };
    case 'ORGANISATION_USER_FORCE_VERIFY_SUCCESS':
    case 'ORGANISATION_COLLABORATOR_FORCE_VERIFY_SUCCESS':
      return {
        content: 'User has been forced to verify their email',
        type: 'success',
      };
    case 'RESEND_INVITATION_EMAIL_ORGANISATION_USER_SUCCESS':
      return {
        content: 'Invitation/Welcome email has been re-sent',
        type: 'success',
      };
    case 'ORGANISATION_USER_REMOVE_SUCCESS':
      return {
        content: 'User has been successfully removed',
        type: 'success',
      };
    case 'ORGANISATION_TEAM_CREATE_SUCCESS':
      return {
        content: 'Organisation Team created successfully',
        type: 'success',
      };
    case 'ORGANISATION_TEAM_UPDATE_SUCCESS':
      return {
        content: 'Organisation Team updated successfully',
        type: 'success',
      };
    case 'ORGANISATION_TEAM_ARCHIVE_SUCCESS':
      return {
        content: 'Team successfully deleted',
        type: 'success',
      };
    case 'ORGANISATION_TEAM_FORCE_VERIFY_SUCCESS':
      return {
        content: 'Verification request successfully sent to all team members',
        type: 'success',
      };
    case 'ORGANISATION_FEATURE_UPDATE_SUCCESS':
    case 'ORGANISATION_USER_FEATURE_UPDATE_SUCCESS':
    case 'ORGANISATION_TEAM_FEATURE_UPDATE_SUCCESS':
    case 'ORGANISATION_COLLABORATOR_FEATURE_UPDATE_SUCCESS':
      return {
        content: 'Product Feature Access successfully updated',
        type: 'success',
      };
    case 'ORGANISATION_FEATURE_ADD_SUCCESS':
      return {
        content: 'Product Feature Access granted to Organisation',
        type: 'success',
      };
    case 'ORGANISATION_FEATURE_REMOVE_SUCCESS':
      return {
        content: 'Product Feature Access revoked for Organisation',
        type: 'success',
      };
    case 'ORGANISATION_USER_TEAM_REMOVE_SUCCESS':
      return {
        content: 'User successfully removed from Team',
        type: 'success',
      };
    case 'ORGANISATION_USER_ORGANISATION_REMOVE_SUCCESS':
    case 'ORGANISATION_COLLABORATOR_ORGANISATION_REMOVE_SUCCESS':
    case 'ORGANISATION_COLLABORATOR_REMOVE_SUCCESS':
      return {
        content: 'User successfully removed from Organisation',
        type: 'success',
      };
    case 'ORGANISATION_COLLABORATOR_UPDATE_SUCCESS':
      return {
        content: 'Collaborator successfully updated',
        type: 'success',
      };
    case 'USER_UPDATE_SUCCESS':
      return {
        content: 'User details successfully updated',
        type: 'success',
      };
    case 'ORGANISATION_COLLABORATOR_TO_ORGANISATION_TEAM_ADD_SUCCESS':
      return {
        content: 'Collaborator successfully added to team',
        type: 'success',
      };
    case 'ORGANISATION_USER_TO_ORGANISATION_TEAM_ADD_SUCCESS':
      return {
        content: 'User successfully added to team',
        type: 'success',
      };
    case 'ORGANISATION_ACCOUNT_MANAGER_ADD_SUCCESS':
    case 'ORGANISATION_ACCOUNT_MANAGER_REMOVE_SUCCESS':
      return {
        content: 'Account Managers updated successfully',
        type: 'success',
      };
    case 'ONLINE_STORE_ORDER_SEND_RECEIPT_EMAIL_SUCCESS':
      return {
        content: 'Email receipt sent',
        type: 'success',
      };
    case 'USER_UPDATE_PASSWORD_SUCCESS':
    case 'ADMIN_UPDATE_USER_PASSWORD_SUCCESS':
      return {
        content: 'User password updated successfully',
        type: 'success',
      };
    case 'INDUSTRIES_LOAD_SUCCESS':
      return {
        ...state,
      };

    case 'ONLINE_STORE_COUPON_UPDATE_SUCCESS':
      return {
        content: 'Coupon updated successfully',
        type: 'success',
      };
    case 'API_ORGANISATION_ADD_SUCCESS':
      return {
        content: 'Organisation successfully subscribed to API',
        type: 'success',
      };
    case 'API_ORGANISATION_UPDATE_SUCCESS':
      return {
        content: 'Subcription activation status successfully updated',
        type: 'success',
      };
    case 'API_ORGANISATION_REMOVE_SUCCESS':
      return {
        content: 'Subscription successfully removed',
        type: 'success',
      };
    case 'API_ORGANISATION_UPDATE_KEY_REQUEST':
      return {
        content: 'API Key successfully regenerated',
        type: 'success',
      };
    case 'API_USER_ADD_SUCCESS':
      return {
        content: 'User successfully given access to API',
        type: 'success',
      };
    case 'API_USER_REMOVE_SUCCESS':
      return {
        content: 'User access successfully removed',
        type: 'success',
      };
    case LOG_OUT_SUCCESS:
      return {
        content: 'Logged out',
        type: 'warning',
      };
    case 'MESSAGES_RESET':
      return initialState;
    default:
      return state;
  }
};
