// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_USERS_LOAD_REQUEST =
  'ORGANISATION_USERS_LOAD_REQUEST';
export const ORGANISATION_USERS_LOAD_SUCCESS =
  'ORGANISATION_USERS_LOAD_SUCCESS';
export const ORGANISATION_USERS_LOAD_FAILURE =
  'ORGANISATION_USERS_LOAD_FAILURE';

const loadOrganisationUsersRequest = (): Object => ({
  type: ORGANISATION_USERS_LOAD_REQUEST,
});

const loadOrganisationUsersSuccess = (users): Object => ({
  type: ORGANISATION_USERS_LOAD_SUCCESS,
  payload: users,
});

const loadOrganisationUsersFailure = (error): Object => ({
  type: ORGANISATION_USERS_LOAD_FAILURE,
  error,
});

export const loadOrganisationUsers = (organisationId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadOrganisationUsersRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/users?excludeArchived=true`,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadOrganisationUsersSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadOrganisationUsersFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
