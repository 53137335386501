//@flow
import React from 'react';
import TableActions from '../../components/TableActions';
import { Table, Confirm } from 'semantic-ui-react';
import PaginatedTable from '../../components/PaginatedTable';
import { getOrganisationTeamsSortedAlphabetically } from '../../reducers/organisationTeams';
import type {
  State as AppState,
  Dispatch,
  OrganisationTeam,
  AuthTypes,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadAllOrganisationTeams,
  archiveOrganisationTeam,
} from '../../actions/organisations/organisationTeams';
import { Route, Link } from 'react-router-dom';
import { NewTeam } from '../TeamTabs';
import OverflowMenu from '../../components/OverflowMenu';
import PageLoader from '../../components/PageLoader';
import {
  // $FlowFixMe
  sortChangeHandler,
  // $FlowFixMe
  sortData,
} from '../../helpers';

type Props = {
  organisationTeams: [],
  teamFilterValue: string,
  organisationId: number,
  subItems: [],
  loadAllOrganisationTeams: (
    oid: number,
    teamAccess: ?boolean
  ) => Promise<boolean>,
  archiveOrganisationTeam: (oid: number, teamId: number) => Promise<boolean>,
  isFetching: boolean,
  permission: ?AuthTypes,
};

type State = {
  teamFilterValue: string,
  archiveTeamOpen: boolean,
  archiveTargetId: ?number,
  archiveTargetName: ?string,
  sortColumn: string,
  sortDirection: string,
};

class Teams extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      teamFilterValue: '',
      archiveTeamOpen: false,
      archiveTargetId: undefined,
      archiveTargetName: undefined,
      sortColumn: '',
      sortDirection: '',
    };
  }

  componentDidMount() {
    const { loadAllOrganisationTeams, organisationId } = this.props;
    loadAllOrganisationTeams(organisationId);
  }

  handleTeamSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      teamFilterValue: e.target.value,
    });
  };

  handleTeamArchive = () => {
    this.setState({ ...this.state, archiveTeamOpen: false });
    if (this.state.archiveTargetId && this.state.archiveTargetName) {
      this.props
        .archiveOrganisationTeam(
          this.props.organisationId,
          this.state.archiveTargetId
        )
        .then((success) => {
          this.setState({
            ...this.state,
            archiveTargetId: undefined,
            archiveTargetName: undefined,
          });
        });
    }
  };

  setTeamArchiveModalVisible = (teamId, teamName) => {
    this.setState({
      ...this.state,
      archiveTeamOpen: true,
      archiveTargetId: teamId,
      archiveTargetName: teamName,
    });
  };

  handleSortChange = (clickedColumn: any) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  render() {
    const { sortColumn, sortDirection } = this.state;

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'teamName' ? sortDirection : null}
          onClick={this.handleSortChange('teamName')}
        >
          Name
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'memberCount' ? sortDirection : null}
          onClick={this.handleSortChange('memberCount')}
        >
          Members
        </Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    );

    const {
      organisationTeams,
      subItems,
      organisationId,
      isFetching,
    } = this.props;
    const filterValue = this.state.teamFilterValue.toLowerCase();

    let rows = organisationTeams.filter((team: OrganisationTeam) => {
      const teamName = team && team.name ? team.name.toLowerCase() : '';
      return (
        teamName === filterValue ||
        teamName.includes(filterValue) ||
        filterValue === ''
      );
    });
    rows = rows.sort(this.sort).map((team: OrganisationTeam) => {
      const canView = team.canView === false ? false : true;
      return (
        <Table.Row key={team.id}>
          <Table.Cell>{team.name}</Table.Cell>
          <Table.Cell>{team.memberCount}</Table.Cell>
          <Table.Cell>
            {canView ? (
              <OverflowMenu>
                <li>
                  <Link
                    to={`/organisations/${organisationId}/teams/${team.id}/details`}
                    tabIndex="-1"
                  >
                    View Team
                  </Link>
                </li>
                <li>
                  {/* // TODO: replace anchor with button // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onClick={() => {
                      this.setTeamArchiveModalVisible(team.id, team.name);
                    }}
                    onKeyPress={() => {
                      this.setTeamArchiveModalVisible(team.id, team.name);
                    }}
                  >
                    Delete Team
                  </a>
                </li>
              </OverflowMenu>
            ) : (
              'N/A'
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    const newSubItems = [
      ...subItems,
      {
        path: `/organisations/${organisationId}/teams`,
        // break this out into own component
        container: () => (
          <div>
            <Confirm
              // This confirmation box handles the Archive Organisation Action
              open={this.state.archiveTeamOpen}
              onCancel={() =>
                this.setState({ ...this.state, archiveTeamOpen: false })
              }
              content={`Are you sure you want to delete ${
                this.state.archiveTargetName ? this.state.archiveTargetName : ''
              }?`}
              onConfirm={this.handleTeamArchive}
            />
            <TableActions
              title="Teams"
              filterAction={this.handleTeamSearch}
              filterPlaceholder="Search Teams..."
              buttonURL={`/organisations/${organisationId}/teams/new`}
              buttonText="Create Team"
            />

            <div className="page-content">
              {isFetching ? (
                <PageLoader />
              ) : (
                <PaginatedTable
                  headerRow={TableHeader}
                  rows={rows}
                  sortable={true}
                />
              )}
            </div>
          </div>
        ),
      },
      {
        path: `/organisations/${organisationId}/teams/new`,
        container: () => <NewTeam organisationId={organisationId} />,
      },
    ];

    return (
      <div className="tabbed-content">
        {newSubItems.map((item, index) => {
          return (
            <Route
              exact={true}
              key={index}
              path={item.path}
              render={item.container}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationTeams.isFetching,
  organisationTeams: getOrganisationTeamsSortedAlphabetically(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      loadAllOrganisationTeams,
      archiveOrganisationTeam
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Teams): any);
