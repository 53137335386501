// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../../helpers';
import { callAPI, refreshToken } from '../../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_TEAMS_LOAD_ALL_REQUEST =
  'ORGANISATION_TEAMS_LOAD_ALL_REQUEST';
export const ORGANISATION_TEAMS_LOAD_ALL_SUCCESS =
  'ORGANISATION_TEAMS_LOAD_ALL_SUCCESS';
export const ORGANISATION_TEAMS_LOAD_ALL_FAILURE =
  'ORGANISATION_TEAMS_LOAD_ALL_FAILURE';

const loadAllOrganisationTeamsRequest = () => ({
  type: ORGANISATION_TEAMS_LOAD_ALL_REQUEST,
});

const loadAllOrganisationTeamsSuccess = (teams) => ({
  type: ORGANISATION_TEAMS_LOAD_ALL_SUCCESS,
  payload: teams,
});

const loadAllOrganisationTeamsFailure = (error) => ({
  type: ORGANISATION_TEAMS_LOAD_ALL_FAILURE,
  error,
});

export const loadAllOrganisationTeams = (
  organisationId: number,
  cantAccessTeams: boolean
): any => async (dispatch, getState) => {
  try {
    const noTeamAccess = cantAccessTeams ? true : false;

    refreshToken(dispatch);
    dispatch(loadAllOrganisationTeamsRequest());

    const endpoint = `${getApiBaseURL(
      'v2',
      'management'
    )}/organisations/${organisationId}/teams?excludeArchived=true`;

    const response = await callAPI({
      method: 'GET',
      endpoint,
    });

    if (response instanceof Error) {
      return response;
    }

    let formattedResponse;

    if (noTeamAccess) {
      formattedResponse = response.map((team) => {
        return {
          ...team,
          canView: false,
        };
      });
    } else {
      formattedResponse = response;
    }

    let normalisedPayload = normaliseArrayOfObjects(formattedResponse);

    dispatch(loadAllOrganisationTeamsSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadAllOrganisationTeamsFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
