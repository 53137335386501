// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadOnlineStoreItems,
  // $FlowFixMe
  loadReportTypes,
  updateOnlineStoreItemsForReportType,
  cloneStoreItem,
} from '../../actions';
import type { AuthTypes } from '../../types';
import { Container, Grid, Table, Modal } from 'semantic-ui-react';
import OverflowMenu from '../../components/OverflowMenu';
import { Link } from 'react-router-dom';
import PaginatedTable from '../../components/PaginatedTable';
import Breadcrumb from '../../components/Breadcrumb';
import TableMultipleActions from '../../components/TableMultipleActions';
import type {} from // OnlineStoreItem,
// State as GlobalState,
// OnlineStoreReportType
'../../types';
import PageLoader from '../../components/PageLoader';
import { managementFeatures } from '../../helpers';
import {
  getPermissionInFeature,
  restrictUserPermissions,
  permissions,
} from 'roy-morgan-auth';
import { getOnlineStoreItemsSortedAlphabetically } from '../../reducers/onlineStoreItems';
// $FlowFixMe
import { sortChangeHandler, sortData } from '../../helpers';
import { getOnlineStoreReportTypesSortedAlphabetically } from '../../reducers/onlineStoreOptions';
import OnlineStoreUpdateTimePeriodForm from '../../components/OnlineStoreItem/OnlineStoreUpdateTimePeriodForm';
import Message from '../../components/Message';
import moment from 'moment';

type Props = {
  loadOnlineStoreItems: () => Promise<boolean>,
  loadReportTypes: () => Promise<any>,
  onlineStoreItems: Array<Object>,
  reportTypes: Array<any>,
  isFetching: boolean,
  permission: ?AuthTypes,
  error: any,
  updateOnlineStoreItemsForReportType: (body: Object, a: any) => Promise<any>,
  cloneStoreItem: (id: number) => Promise<any>,
};

type State = any;
// type State = {
//   filterValue: string,
//   sortColumn: ?string,
//   sortDirection: ?string,
//   updateTimePeriodOpen: boolean
// };

export class OnlineStoreItems extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      sortColumn: '',
      sortDirection: '',
      updateTimePeriodOpen: false,
      message: undefined,
      messageTypeError: false,
      messageTypeSucces: false,
    };
  }

  componentDidMount() {
    const { loadOnlineStoreItems } = this.props;
    loadOnlineStoreItems();
    this.props.loadReportTypes();
  }

  handleFilterChange: (e: SyntheticInputEvent<HTMLInputElement>) => void = (
    e: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({
      filterValue: e.currentTarget.value,
    });
  };

  handleSortChange: (clickedColumn: any) => () => any = (
    clickedColumn: any
  ) => () => {
    return sortChangeHandler(clickedColumn, this);
  };

  sort: (a: any, b: any) => any = (a: any, b: any) => {
    return sortData(a, b, this);
  };

  setUpdateTimePeriodModalVisible: () => void = () => {
    this.setState({
      ...this.state,
      updateTimePeriodOpen: true,
    });
  };

  formValidation: (
    data: any
  ) => {| errorMessage: void | string, valid: boolean |} = (data: any) => {
    let valid = true;
    let errorMessage;
    if (!data.reportTypeId || !data.timePeriod || data.timePeriod === '') {
      valid = false;
      errorMessage = 'Report Type and Time Period should not be empty';
    }
    return { valid, errorMessage };
  };

  handleUpdateOnlineStoreItemsTimePeriod: (data: any) => void = (data: any) => {
    let { valid, errorMessage } = this.formValidation(data);

    if (!valid) {
      return this.setMessage(errorMessage, 'error');
    } else {
      this.resetMessage();
      data.reportRef = ''; //reset reportRef when time period is updated
      this.props
        .updateOnlineStoreItemsForReportType(data.reportTypeId, data)
        .then(
          (result) => {
            if (this.props.error) {
              return this.setMessage(this.props.error, 'error');
            } else {
              return this.setMessage(result.message, 'success');
            }
          },
          (failure) => {
            console.error(failure);
            Sentry.captureException(failure);
          }
        );
    }
  };

  setMessage: (message: any, type: any) => void = (message: any, type: any) => {
    return this.setState(
      {
        ...this.state,
        message: message,
        messageTypeError: type === 'error',
        messageTypeSuccess: type === 'success',
      },
      function () {}
    );
  };

  resetMessage: () => void = () => {
    this.setState({
      ...this.state,
      message: undefined,
      error: false,
      success: false,
    });
  };

  closeUpdateTimePeriodModal: () => void = () => {
    this.resetMessage();
    this.setState({
      ...this.state,
      updateTimePeriodOpen: false,
    });
  };

  handleCloneStoreItem: (storeItemId: number) => void = (
    storeItemId: number
  ) => {
    this.resetMessage();
    this.props.cloneStoreItem(storeItemId).then(
      (result) => {
        if (this.props.error) {
          return this.setMessage(this.props.error, 'error');
        } else {
          return this.setMessage(result.message, 'success');
        }
      },
      (failure) => {
        console.error(failure);
        Sentry.captureException(failure);
      }
    );
  };

  render(): React.Node {
    const {
      onlineStoreItems,
      isFetching,
      permission,
      reportTypes,
    } = this.props;
    const { sortColumn, sortDirection } = this.state;
    const { INTERNAL_ADMIN, STANDARD_USER } = permissions;

    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell
          sorted={sortColumn === 'skuNumber' ? sortDirection : null}
          onClick={this.handleSortChange('skuNumber')}
        >
          SKU ID
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'name' ? sortDirection : null}
          onClick={this.handleSortChange('name')}
        >
          Title
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'country' ? sortDirection : null}
          onClick={this.handleSortChange('country')}
        >
          Country
        </Table.HeaderCell>
        <Table.HeaderCell>Time Period</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'dateUpdated' ? sortDirection : null}
          onClick={this.handleSortChange('dateUpdated')}
        >
          Date Updated
        </Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    );

    const filterValue = this.state.filterValue.toLowerCase() || '';

    let rows = onlineStoreItems
      .map((onlineStoreItem: any) => {
        return {
          ...onlineStoreItem,
          dateUpdated: new Date(onlineStoreItem.dateUpdated),
        };
      })
      .filter((onlineStoreItem: any) => {
        const storeItemTitle = onlineStoreItem.name.toLowerCase();
        const storeItemId = onlineStoreItem.id.toString();
        const storeItemCountry = onlineStoreItem.country.toLowerCase();
        return (
          storeItemTitle === filterValue ||
          storeItemTitle.includes(filterValue) ||
          storeItemId === filterValue ||
          storeItemId.includes(filterValue) ||
          storeItemCountry === filterValue ||
          storeItemCountry.includes(filterValue) ||
          filterValue === ''
        );
      })
      .sort(this.sort);

    rows = rows.map((onlineStoreItem: any) => {
      return (
        <Table.Row key={onlineStoreItem.id}>
          <Table.Cell>{onlineStoreItem.skuNumber}</Table.Cell>
          <Table.Cell>{onlineStoreItem.name}</Table.Cell>
          <Table.Cell>{onlineStoreItem.country}</Table.Cell>
          <Table.Cell>{onlineStoreItem.timePeriod}</Table.Cell>
          <Table.Cell>
            {moment
              .utc(onlineStoreItem.dateUpdated)
              .local()
              .format('YYYY-MM-DD')
              .toString()}
          </Table.Cell>
          <Table.Cell>
            <OverflowMenu>
              <li key="View">
                <Link
                  to={`/storeitems/${onlineStoreItem.id}/details`}
                  tabIndex="-1"
                >
                  View
                </Link>
              </li>
              <li key="Clone">
                {/* // TODO: replace anchor with button // */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  tabIndex="-1"
                  onKeyPress={() => {
                    this.handleCloneStoreItem(onlineStoreItem.id);
                  }}
                  onClick={() => {
                    this.handleCloneStoreItem(onlineStoreItem.id);
                  }}
                >
                  Clone
                </a>
              </li>
            </OverflowMenu>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Container data-testid="organisations-container">
        <Grid centered stackable>
          <Grid.Column>
            <div className="page-content">
              <Breadcrumb currentContext="Store Items" />
              <Modal
                closeIcon
                open={this.state.updateTimePeriodOpen}
                onClose={this.closeUpdateTimePeriodModal}
              >
                <Modal.Header>
                  Update Time Period for All Report Type
                </Modal.Header>
                <Modal.Content>
                  <div>
                    <Message
                      content={this.state.message}
                      error={this.state.messageTypeError}
                      succes={this.state.messageTypeSuccess}
                      show={this.state.message !== undefined}
                    />
                  </div>
                  <OnlineStoreUpdateTimePeriodForm
                    reportTypes={reportTypes}
                    handleFormSubmit={
                      this.handleUpdateOnlineStoreItemsTimePeriod
                    }
                  />
                </Modal.Content>
              </Modal>
              <div className="table-container">
                <TableMultipleActions
                  filterAction={this.handleFilterChange}
                  filterPlaceholder="Filter Store Items..."
                  buttonArray={[
                    {
                      buttonURL: '/storeitems/new',
                      buttonText: 'Create Store Item',
                    },
                    {
                      buttonAction: this.setUpdateTimePeriodModalVisible,
                      buttonText: 'Update Store Item Time Period',
                    },
                  ]}
                  hideButton={
                    !restrictUserPermissions(
                      [INTERNAL_ADMIN, STANDARD_USER],
                      permission
                    )
                  }
                />
                {isFetching ? (
                  <PageLoader />
                ) : (
                  <PaginatedTable
                    headerRow={TableHeader}
                    rows={rows}
                    sortable
                  />
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  onlineStoreItems: getOnlineStoreItemsSortedAlphabetically(state),
  reportTypes: getOnlineStoreReportTypesSortedAlphabetically(state),
  isFetching: state.onlineStoreItems.isFetching,
  permission: getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    state.auth.authorisation
  ),
  error: state.onlineStoreItems.error,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadOnlineStoreItems,
      loadReportTypes,
      updateOnlineStoreItemsForReportType,
      cloneStoreItem,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreItems): any);
