//@flow
import React from 'react';
//import TabHeader from '../../components/TabHeader';
import type {
  // State as AppState,
  OnlineStoreItem,
  // Dispatch,
  AuthTypes,
  // OnlineStoreItemFormat,
  // OnlineStoreItemType,
  // OnlineStoreReportType,
  // OnlineStoreNavigationTreeItem
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  readOnlineStoreItem,
  updateOnlineStoreItem,
  removeOnlineStoreItem,
  // $FlowFixMe
  loadItemFormats,
  // $FlowFixMe
  loadItemTypes,
  // $FlowFixMe
  loadReportTypes,
  // $FlowFixMe
  loadNavigationTree,
} from '../../actions';
import { Grid, Confirm } from 'semantic-ui-react';
import PageLoader from '../../components/PageLoader';
import OnlineStoreItemFormFields from '../../components/OnlineStoreItem/OnlineStoreItemFormFields';
import {
  getOnlineStoreItemFormatsSortedAlphabetically,
  getOnlineStoreItemTypesSortedAlphabetically,
  getOnlineStoreReportTypesSortedAlphabetically,
  getNavigationTree,
} from '../../reducers/onlineStoreOptions';
import { managementFeatures } from '../../helpers';
import { getPermissionInFeature } from 'roy-morgan-auth';
import moment from 'moment';
//import { Confirm } from '../OrganisationTabs/Details';

type Props = {
  onlineStoreItemId: number,
  onlineStoreItem: any,
  itemFormats: Array<any>,
  itemTypes: Array<any>,
  reportTypes: Array<any>,
  navigationTree: Array<any>,
  isFetching: boolean,
  readOnlineStoreItem: (onlineStoreItemId: number) => Promise<null>,
  loadItemFormats: () => Promise<any>,
  loadItemTypes: () => Promise<any>,
  loadReportTypes: () => Promise<any>,
  loadNavigationTree: () => Promise<any>,
  updateOnlineStoreItem: (
    onlineStoreItemId: number,
    {},
    storeItemFile: any,
    storeItemSampleFile: any
  ) => Promise<OnlineStoreItem>,
  removeOnlineStoreItem: (onlineStoreItemId: number) => Promise<null>,
  permission: ?AuthTypes,
};

// type State = {
//   contextChange: boolean
// };

class OnlineStoreItemDetails extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      contextChange: false,
      deleteStoreItemOpen: false,
    };
  }

  componentDidMount() {
    this.props.readOnlineStoreItem(this.props.onlineStoreItemId);
    this.props.loadItemFormats();
    this.props.loadItemTypes();
    this.props.loadReportTypes();
    this.props.loadNavigationTree();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (typeof nextProps.onlineStoreItem === 'undefined') {
      this.setState({
        ...this.state,
        contextChange: true,
      });
    }
  }

  handleOnlineStoreItemUpdate = (
    formData,
    storeItemFile,
    storeItemSampleFile
  ) => {
    this.props.updateOnlineStoreItem(
      this.props.onlineStoreItemId,
      formData,
      storeItemFile,
      storeItemSampleFile
    );
  };

  handleOnlineStoreItemDelete = () => {
    this.setState({ ...this.state, deleteStoreItemOpen: false });
    this.props.removeOnlineStoreItem(this.props.onlineStoreItemId);
  };

  setOnlineStoreItemDeleteModalVisible = () => {
    this.setState({ ...this.state, deleteStoreItemOpen: true });
  };

  render() {
    const {
      onlineStoreItem,
      isFetching,
      itemFormats,
      itemTypes,
      reportTypes,
      navigationTree,
    } = this.props;

    const storeItemFileNameArr = onlineStoreItem.reportRef
      ? onlineStoreItem.reportRef.split('/')
      : [];
    const storeItemFileName =
      storeItemFileNameArr.length === 0 ||
      storeItemFileNameArr[storeItemFileNameArr.length - 1] === 'undefined'
        ? ''
        : decodeURI(storeItemFileNameArr[storeItemFileNameArr.length - 1]);
    const storeItemSampleFileNameArr = onlineStoreItem.sampleRef
      ? onlineStoreItem.sampleRef.split('/')
      : [];
    const storeItemSampleFileName =
      storeItemSampleFileNameArr.length === 0 ||
      storeItemSampleFileNameArr[storeItemSampleFileNameArr.length - 1] ===
        'undefined'
        ? ''
        : decodeURI(
            storeItemSampleFileNameArr[storeItemSampleFileNameArr.length - 1]
          );

    if (!onlineStoreItem) {
      return <div>Loading</div>;
    }
    return (
      <div>
        <h3 className="light-title">Basic Information</h3>
        <Confirm
          // This confirmation box handles the Archiving Store Item Action
          open={this.state.deleteStoreItemOpen}
          onCancel={() =>
            this.setState({ ...this.state, deleteStoreItemOpen: false })
          }
          content="Are you sure you want to delete the current Store Item? This will completely remove the Store Item."
          onConfirm={this.handleOnlineStoreItemDelete}
        />
        {isFetching ? (
          <PageLoader />
        ) : (
          <div>
            <OnlineStoreItemFormFields
              permission={this.props.permission}
              onlineStoreItem={this.props.onlineStoreItem}
              itemFormats={itemFormats}
              itemTypes={itemTypes}
              reportTypes={reportTypes}
              navigationTree={navigationTree}
              handleFormSubmit={this.handleOnlineStoreItemUpdate}
            >
              <li>
                {/* // TODO: replace anchor with button // */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  tabIndex="0"
                  onClick={this.setOnlineStoreItemDeleteModalVisible}
                  onKeyPress={this.setOnlineStoreItemDeleteModalVisible}
                >
                  Delete Store Item
                </a>
              </li>
            </OnlineStoreItemFormFields>
            <hr />
            <div className="additional-details">
              <Grid columns={3} stackable>
                <Grid.Row>
                  <Grid.Column>
                    <div className="additional-details__title">
                      Store Item File
                    </div>
                    {storeItemFileName}
                  </Grid.Column>
                  <Grid.Column>
                    <div className="additional-details__title">Created By</div>
                    {onlineStoreItem.createdBy}
                  </Grid.Column>
                  <Grid.Column>
                    <div className="additional-details__title">
                      Last Changed By
                    </div>
                    {onlineStoreItem.lastChangedBy}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <div className="additional-details__title">
                      Store Item Sample File
                    </div>
                    {storeItemSampleFileName}
                  </Grid.Column>
                  <Grid.Column>
                    <div className="additional-details__title">
                      Date Created
                    </div>
                    {moment
                      .utc(onlineStoreItem.dateCreated)
                      .local()
                      .format('YYYY-MM-DD')
                      .toString()}
                  </Grid.Column>
                  <Grid.Column>
                    <div className="additional-details__title">
                      Date Modified
                    </div>
                    {moment
                      .utc(onlineStoreItem.dateUpdated)
                      .local()
                      .format('YYYY-MM-DD')
                      .toString()}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any, props: Props) => ({
  isFetching: state.onlineStoreItems.isFetching,
  onlineStoreItem: state.onlineStoreItems.byId[props.onlineStoreItemId],
  itemFormats: getOnlineStoreItemFormatsSortedAlphabetically(state),
  itemTypes: getOnlineStoreItemTypesSortedAlphabetically(state),
  reportTypes: getOnlineStoreReportTypesSortedAlphabetically(state),
  navigationTree: getNavigationTree(state),
  permission: getPermissionInFeature(
    managementFeatures.ONLINE_STORE_MANAGEMENT,
    state.auth.authorisation
  ),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      readOnlineStoreItem,
      updateOnlineStoreItem,
      removeOnlineStoreItem,
      loadItemFormats,
      loadItemTypes,
      loadReportTypes,
      loadNavigationTree,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineStoreItemDetails): any);
