// @flow
import * as Sentry from '@sentry/browser';
import { normaliseArrayOfObjects } from '../../helpers';
import { callAPI, refreshToken } from '../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const PENDING_ISSUES_LOAD_REQUEST = 'PENDING_ISSUES_LOAD_REQUEST';
export const PENDING_ISSUES_LOAD_SUCCESS = 'PENDING_ISSUES_LOAD_SUCCESS';
export const PENDING_ISSUES_LOAD_FAILURE = 'PENDING_ISSUES_LOAD_FAILURE';

const loadPendingIssuesRequest = () => ({
  type: PENDING_ISSUES_LOAD_REQUEST,
});

const loadPendingIssuesSuccess = (issues) => ({
  type: PENDING_ISSUES_LOAD_SUCCESS,
  payload: issues,
});

const loadPendingIssuesFailure = (error) => ({
  type: PENDING_ISSUES_LOAD_FAILURE,
  error,
});

export const loadPendingIssues = (): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadPendingIssuesRequest());

    const loadPendingIssuesEndpoint = `${getApiBaseURL(
      'v2',
      'store'
    )}/management/pending-issues?excludeResolved=true`;

    const response = await callAPI({
      method: 'GET',
      endpoint: loadPendingIssuesEndpoint,
    });

    if (response instanceof Error) {
      return response;
    }

    const normalisedPayload = normaliseArrayOfObjects(response);

    dispatch(loadPendingIssuesSuccess(normalisedPayload));

    return normalisedPayload;
  } catch (error) {
    dispatch(loadPendingIssuesFailure(error.message));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
