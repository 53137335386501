//@flow
import React from 'react';
import OverflowMenu from '../../components/OverflowMenu';
import TableActions from '../../components/TableActions';
import { Table, Confirm } from 'semantic-ui-react';
import PaginatedTable from '../../components/PaginatedTable';
import { getOrganisationUserOrganisationsSortedAlphabetically } from '../../reducers/organisationUserOrganisations';
import type {
  State as AppState,
  Dispatch,
  OrganisationUserOrganisation,
  Organisation,
} from '../../types';
import {
  loadOrganisationUserOrganisations,
  removeOrganisationUserOrganisation,
} from '../../actions/organisations/organisationUsers/organisationUserOrganisations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import { getIsReady } from '../../reducers/fetchSync';
import { setFetchSyncReady } from '../../actions';
import { readOrganisationUser } from '../../actions/organisations/organisationUsers';

type Props = {
  organisation: Organisation,
  userId: number,
  organisations: Array<OrganisationUserOrganisation>,
  readOrganisationUser: (oid: number, uid: number) => Promise<boolean>,
  loadOrganisationUserOrganisations: (uid: number) => Promise<boolean>,
  removeOrganisationUserOrganisation: (
    oid: number,
    uid: number
  ) => Promise<null>,
  user: OrganisationUserOrganisation,
  isFetching: boolean,
  isReady: boolean,
  setUserOrganisationsReady: () => void,
};

type State = {
  organisationFilterValue: string,
  removeOrganisationOpen: boolean,
  removeOrganisationTarget?: { id: number, name: string },
  contextChange: boolean,
};

class UserOrganisations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      organisationFilterValue: '',
      removeOrganisationOpen: false,
      removeOrganisationTarget: undefined,
      contextChange: false,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.readOrganisationUser(
        this.props.organisation.id,
        this.props.userId
      ),
      this.props.loadOrganisationUserOrganisations(this.props.userId),
    ]).finally(this.props.setUserOrganisationsReady);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    for (let i = 0; i < this.props.organisations.length; i++) {
      if (this.props.organisations[i].id === this.props.organisation.id) {
        if (nextProps.organisations[i] === undefined) {
          this.setState({
            ...this.state,
            contextChange: true,
          });
        }
      }
    }
  }

  handleOrganisationsSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      organisationFilterValue: e.target.value,
    });
  };

  handleRemoveOrganisation = () => {
    if (this.state.removeOrganisationTarget) {
      this.props.removeOrganisationUserOrganisation(
        this.state.removeOrganisationTarget.id,
        this.props.userId
      );
    }
    this.setState({ ...this.state, removeOrganisationOpen: false });
  };

  setRemoveOrganisationsModalVisible = (organisationId, organisationName) => {
    this.setState({
      ...this.state,
      removeOrganisationOpen: true,
      removeOrganisationTarget: { id: organisationId, name: organisationName },
    });
  };

  render() {
    const { organisations, organisation, isFetching, isReady } = this.props;
    const organisationFilterValue = this.state.organisationFilterValue.toLowerCase();
    const TableHeader = (
      <Table.Row>
        <Table.HeaderCell>Organisations Name</Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    );

    let rows = organisations.filter(
      (organisation: OrganisationUserOrganisation) => {
        const organisationName = organisation.name.toLowerCase();
        return (
          organisationName === organisationFilterValue ||
          organisationName.includes(organisationFilterValue) ||
          organisationFilterValue === ''
        );
      }
    );
    rows = rows.map((organisation: OrganisationUserOrganisation) => {
      return (
        <Table.Row key={organisation.id}>
          <Table.Cell>{organisation.name}</Table.Cell>
          <Table.Cell>
            <OverflowMenu>
              <li>
                {/* // TODO: replace anchor with button // */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  tabIndex="-1"
                  onClick={() => {
                    this.setRemoveOrganisationsModalVisible(
                      organisation.id,
                      organisation.name
                    );
                  }}
                  onKeyPress={() => {
                    this.setRemoveOrganisationsModalVisible(
                      organisation.id,
                      organisation.name
                    );
                  }}
                >
                  Remove Access to Organisation
                </a>
              </li>
            </OverflowMenu>
          </Table.Cell>
        </Table.Row>
      );
    });

    if (!isReady) {
      return null;
    }

    if (typeof this.props.user === 'undefined') {
      return <Redirect to={`/organisations/${organisation.id}/users`} />;
    } else {
      return (
        <div>
          <Confirm
            // This confirmation box handles the Archive Organisation Action
            open={this.state.removeOrganisationOpen}
            onCancel={() =>
              this.setState({ ...this.state, removeOrganisationOpen: false })
            }
            content={`Do you want to remove user access to the following organisation: ${
              this.state.removeOrganisationTarget &&
              this.state.removeOrganisationTarget.name
                ? this.state.removeOrganisationTarget.name
                : ''
            }?`}
            onConfirm={this.handleRemoveOrganisation}
          />
          <TableActions
            title="Collaborating Organisations"
            filterAction={this.handleOrganisationsSearch}
            filterPlaceholder="Search Organisations..."
          />
          {isFetching ? (
            <PageLoader />
          ) : (
            <PaginatedTable headerRow={TableHeader} rows={rows} />
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationUsers.isFetching,
  // $FlowFixMe
  organisations: getOrganisationUserOrganisationsSortedAlphabetically(state),
  user: state.organisationUsers.byId[props.userId],
  isReady: getIsReady(state, 'userOrganisations'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      readOrganisationUser,
      loadOrganisationUserOrganisations,
      removeOrganisationUserOrganisation,
      setUserOrganisationsReady: setFetchSyncReady('userOrganisations'),
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(UserOrganisations): any);
