// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
import type { OrganisationTeamForm } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_TEAM_CREATE_REQUEST =
  'ORGANISATION_TEAM_CREATE_REQUEST';
export const ORGANISATION_TEAM_CREATE_SUCCESS =
  'ORGANISATION_TEAM_CREATE_SUCCESS';
export const ORGANISATION_TEAM_CREATE_FAILURE =
  'ORGANISATION_TEAM_CREATE_FAILURE';

const createOrganisationTeamRequest = () => ({
  type: ORGANISATION_TEAM_CREATE_REQUEST,
});

const createOganisationTeamSuccess = (team) => ({
  type: ORGANISATION_TEAM_CREATE_SUCCESS,
  payload: team,
});

const createOrganisationTeamFailure = (error) => ({
  type: ORGANISATION_TEAM_CREATE_FAILURE,
  error,
});

export const createOrganisationTeam = (
  organisationId: number,
  body: OrganisationTeamForm
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(createOrganisationTeamRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams`,
      body,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(createOganisationTeamSuccess(response));

    return response;
  } catch (error) {
    dispatch(createOrganisationTeamFailure(error.message));
    Sentry.captureException(error);
    throw error;
  }
};
