// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Message, Icon } from 'semantic-ui-react';
import type { Element } from 'React';

type Props = {
  title: string,
  loginUrl: string,
  buttonText: string,
};

function NotAuthenticatedMessage({
  title,
  loginUrl,
  buttonText,
}: Props): Element<'div'> {
  return (
    <div className="page--multifaceted-bg-grey">
      <div className="not-auth-message">
        <Message icon>
          <Icon name="info circle large" />
          <div>
            <h2>{title}</h2>
            <Link
              to={`/${loginUrl}?redirect=${encodeURIComponent(
                // $FlowFixMe
                document.location
              )}`}
            >
              <Button>{buttonText}</Button>
            </Link>
          </div>
        </Message>
      </div>
    </div>
  );
}

export default NotAuthenticatedMessage;
