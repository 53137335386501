// @flow
import * as React from 'react';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from 'react-router-dom';
import { getAuthToken, getUserData } from 'roy-morgan-auth';
import type { Store } from 'redux';

import Header from './containers/Header';
import RootContent from './containers/Root';
import Footer from 'roy-morgan-footer';

import { BrowserRouter as Router } from 'react-router-dom';

import { LIVE_MANAGEMENT_PRODUCT } from './helpers';
import { setFetchSyncReady } from './actions';

type Props = {
  store: Store<*, *>,
  getUserData: (pid: ?number) => Promise<boolean>,
  setUserDataReady: () => void,
};

class App extends React.Component<Props> {
  componentDidMount() {
    const token = getAuthToken();
    if (token && token !== '0') {
      this.props.getUserData(LIVE_MANAGEMENT_PRODUCT).finally(this.props.setUserDataReady);
    } else {
      this.props.setUserDataReady();
    }
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <div>
          <Router>
            <div>
              <Route path="/" component={Header} />
              <Route path="/" component={RootContent} />
              <Footer />
            </div>
          </Router>
        </div>
      </Provider>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getUserData,
      setUserDataReady: setFetchSyncReady('userData'),
    },
    dispatch
  );
};

export default (connect(null, mapDispatchToProps)(App): any);
