//@flow

import React, { useEffect, useState } from 'react';
import type { Element } from 'React';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Table, Confirm, Icon, Popup, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import OverflowMenu from '../../../../components/OverflowMenu';

import {
  loadOrganisationsWithApiSubscription,
  loadOrganisationsWithoutApiSubscription,
  addOrganisationApiSubscription,
  updateOrganisationApiSubscription,
  removeOrganisationApiSubscription,
  regenerateOrganisationApiKey
} from '../../../../actions';

import {
  getOrganisationsWithApiSubscription,
} from '../../../../reducers/organisationsWithApiSubscription';

import {
  getOrganisationsWithoutApiSubscription,
} from '../../../../reducers/organisationsWithoutApiSubscription';

import PaginatedTable from '../../../../components/PaginatedTable';
import TableActions from '../../../../components/TableActions';
import PageLoader from '../../../../components/PageLoader';
import ErrorMessage from '../../../../components/ErrorMessage';
import AddItemModal from '../../../../components/AddItemModal';

type Props = {
  api: any
};

const ACTION_TYPE_UPDATE = 1;
const ACTION_TYPE_REMOVE = 2;
const ACTION_TYPE_REGENERATE = 3;

const API_TYPE_INTEGRATION = 1;
const API_TYPE_GOOGLE_ACCESS = 2;

export default function ApiSubscriptionOrganisations(props: Props) : Element<'div'> {
  const dispatch = useDispatch();
  const { api } = props;
  const isIntegrationApi = api.apiTypeId === API_TYPE_INTEGRATION;
  const isGoogleApiAccess = api.apiTypeId === API_TYPE_GOOGLE_ACCESS; 
  
  useEffect(() => {
    dispatch(loadOrganisationsWithApiSubscription(api.id));
    dispatch(loadOrganisationsWithoutApiSubscription(api.id));
  }, [dispatch, api.id]);

  const {
    items: subscribedOrganisations,
    isFetching: isFetchingSubscribedOrganisations,
    error: subscribedOrganisationsError,
  } = useSelector(getOrganisationsWithApiSubscription);

  const {
    items: unsubscribedOrganisations,
    isFetching: isFetchingUnsubscribedOrganisations,
    error: unsubscribedOrganisationsError,
  } = useSelector(getOrganisationsWithoutApiSubscription);

  const [filterValue, setFilterValue] = useState('');
  const [addOrgModalOpen, setAddOrgModalOpen] = useState(false);
  const [actionOrgConfirmOpen, setActionOrgConfirmOpen] = useState(false);
  const [actionOrgTarget, setActionOrgTarget] = useState(null);
  const [actionOrgType, setActionOrgType] = useState(null);

  const handleFilterChange = e => {
    setFilterValue(e.currentTarget.value.toLowerCase());
  };

  const toggleAddOrgModal = () => {
    setAddOrgModalOpen(!addOrgModalOpen);
  };

  const handleSubscribe = async (organisation) => {
    return dispatch(addOrganisationApiSubscription(api.id, organisation));
  };

  const getActionMessage = () => {
    let orgName = actionOrgTarget && actionOrgTarget.name
      ? actionOrgTarget.name
      : 'this organisation';
    let updateAction = actionOrgTarget && actionOrgTarget.isSubscriptionActive
      ? 'deactivate'
      : 'activate';

    switch (actionOrgType) {
      case ACTION_TYPE_UPDATE:
        return `Are you sure you want to ${updateAction} ${orgName}'s subscription?`;
      case ACTION_TYPE_REMOVE:
        return `Are you sure you want to remove ${orgName}'s subscription (this cannot be undone)?`;
      case ACTION_TYPE_REGENERATE:
        return `Are you sure you want to regenerate the API key for ${orgName} (existing API key will be lost)?`;
      default:
        return null;
    }
  };

  const handleAction = () => {
    if (actionOrgTarget) {
      dispatch(action(actionOrgTarget));
    }
    setActionOrgTarget(null);
    setActionOrgConfirmOpen(false);
  };

  const action = (org) => {
      switch (actionOrgType) {
        case ACTION_TYPE_UPDATE:
          return updateOrganisationApiSubscription(api.id, !org.isSubscriptionActive, org);
        case ACTION_TYPE_REMOVE:
          return removeOrganisationApiSubscription(api.id, org);
        case ACTION_TYPE_REGENERATE:
          return regenerateOrganisationApiKey(api.id, org);
        default:
          return null;
      }
  };

  const handleActionClick = (type, organisation) => {
    setActionOrgTarget(organisation);
    setActionOrgType(type);
    setActionOrgConfirmOpen(true);
  };

  const handleCopyApiKey = organisation => {
    navigator.clipboard.writeText(organisation.token);
  };

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell width={isIntegrationApi ? 4 : 9}>
        Name
      </Table.HeaderCell>
      { isIntegrationApi && (
        <Table.HeaderCell width={6}>
          API Key
        </Table.HeaderCell>
      )}
      <Table.HeaderCell width={2}>
        Status
      </Table.HeaderCell>
      <Table.HeaderCell width={1}>
        Actions
      </Table.HeaderCell>
    </Table.Row>
  );

  const tableRows = subscribedOrganisations
    .filter(org => {
      const name = org.name.toLowerCase();
      return filterValue === '' || name.includes(filterValue);
    })
    .map(org => (
      <Table.Row key={org.id}>
        <Table.Cell>{org.name}</Table.Cell>
        {
          isIntegrationApi && (
            <Table.Cell>
              <div>
                {org.token}
                <Popup 
                  content='Api Key Copied' 
                  trigger={<Icon className="copy-icon" name="copy" link onClick={() => handleCopyApiKey(org)} />} 
                  on='click'
                />
              </div>
            </Table.Cell>
          )
        }
        <Table.Cell>{org.isSubscriptionActive ? 'Active' : 'Inactive'}</Table.Cell>
        <Table.Cell>
          {org.isFetching ? (
            <Loader
              className="overflow--loader"
              active
              inline
              size="small"
            />
          ) : (
            <OverflowMenu>
              {org.hasTeams && isIntegrationApi && (
                <li>
                  <button
                    className="overflow-button"
                    onClick={() => {}}
                  >
                    View Team Access
                  </button>
                </li>
              )}
              {isGoogleApiAccess && (
                <li>
                  <Link 
                    className="overflow-button" 
                    to={`/api-subscriptions/${api.id}/details/${org.id}/users`}
                  >
                    View User Access
                  </Link>
                </li>
              )}
              {isIntegrationApi && (
                <li>
                  <button
                    className="overflow-button"
                    onClick={() => handleActionClick(ACTION_TYPE_REGENERATE, org)}
                  >
                    Regenerate API Key
                  </button>
                </li>
              )}
              {isIntegrationApi && (
                <li>
                  <button
                    className="overflow-button"
                    onClick={() => handleActionClick(ACTION_TYPE_UPDATE, org)}
                  >
                    { org.isSubscriptionActive 
                      ? 'Deactivate Subscription' 
                      : 'Activate Subscription' 
                    }
                  </button>
                </li>
              )}
              <li>
                <button
                  className="overflow-button"
                  onClick={() => handleActionClick(ACTION_TYPE_REMOVE, org)}
                >
                  Remove Subscription
                </button>
              </li>
            </OverflowMenu>
          )}
        </Table.Cell>
      </Table.Row>
    ));

  let err = subscribedOrganisationsError || unsubscribedOrganisationsError;

  if (err) {
    return ErrorMessage({ message: err.msg });
  }

  /* here */
  return (
    <div className="tabbed-content">
      <TableActions
        title="Organisations"
        filterAction={handleFilterChange}
        filterPlaceholder="Filter..."
        buttonText="Add Organisation"
        buttonAction={toggleAddOrgModal}
      />

      <Grid padded centered>
        <Grid.Row centered>
          <Grid.Column>
            {isFetchingSubscribedOrganisations ? (
              <PageLoader />
            ) : (
              <PaginatedTable
                perPage={10}
                headerRow={tableHeader}
                rows={tableRows}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <AddItemModal
        title="Add Organisation"
        items={unsubscribedOrganisations}
        isFetchingItems={isFetchingUnsubscribedOrganisations}
        isOpen={addOrgModalOpen}
        onClose={toggleAddOrgModal}
        onSubmit={handleSubscribe}
      />

      <Confirm
        content={getActionMessage()}
        open={actionOrgConfirmOpen}
        onCancel={() => setActionOrgConfirmOpen(false)}
        onConfirm={handleAction}
      /> 
    </div>
  );
}
