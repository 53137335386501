// @flow
import type { OnlineStoreItem } from '../types/onlineStoreItems';
import * as actions from '../actions/onlineStore';
import { createSelector } from 'reselect';
import type {
  // OnlineStoreCouponItemsActions,
  OnlineStoreItems,
} from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

/* Initial State */

const initialState: OnlineStoreItems = {
  byId: {},
  order: [],
  selectedId: undefined,
  isFetching: false,
  error: undefined,
};

/* Reducer */

export const onlineStoreItemsWithoutCouponAccess = (
  state: OnlineStoreItems = initialState,
  action: any
):
  | any
  | OnlineStoreItems
  | {
      byId: { [key: string | number]: OnlineStoreItem },
      error: void,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    }
  | {
      byId: { [key: string | number]: OnlineStoreItem },
      error: any,
      isFetching: boolean,
      order: Array<number>,
      selectedId: ?number,
      ...
    } => {
  switch (action.type) {
    case actions.ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.ONLINE_STORE_COUPON_ITEMS_NOT_ASSIGNED_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/* Selectors */
export const getOnlineStoreItemsWithoutCouponAccessSortedAlphabetically: any = createSelector(
  (state) => state.onlineStoreItemsWithoutCouponAccess.byId,
  (items) => {
    if (items === undefined) {
      return [];
    }

    return Object.keys(items)
      .sort(function (a, b) {
        let skuNumberA = items[a].skuNumber;
        let skuNumberB = items[b].skuNumber;
        if (skuNumberA < skuNumberB) return -1;
        if (skuNumberA > skuNumberB) return 1;
        return 0;
      })
      .map(function (sortedKey) {
        return items[sortedKey];
      });
  }
);
