// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import type { Organisation } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_ARCHIVE_REQUEST = 'ORGANISATION_ARCHIVE_REQUEST';
export const ORGANISATION_ARCHIVE_SUCCESS = 'ORGANISATION_ARCHIVE_SUCCESS';
export const ORGANISATION_ARCHIVE_FAILURE = 'ORGANISATION_ARCHIVE_FAILURE';

function archiveOrganisationRequest() {
  return {
    type: ORGANISATION_ARCHIVE_REQUEST,
  };
}

function archiveOrganisationSuccess(organisation: Organisation) {
  return {
    type: ORGANISATION_ARCHIVE_SUCCESS,
    payload: organisation,
  };
}

function archiveOrganisationFailure(error: string) {
  return {
    type: ORGANISATION_ARCHIVE_FAILURE,
    error: error,
  };
}

export const archiveOrganisation = (organisationId: number): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(archiveOrganisationRequest());
    const today = new Date();

    const requestBody = {
      isArchived: true,
      dateUpdated: today.toISOString(),
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}`,
      body: requestBody,
    });

    dispatch(archiveOrganisationSuccess(response));
    return response;
  } catch (error) {
    dispatch(archiveOrganisationFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }

    return error;
  }
};
