// @flow
// import type { Action } from '../../types';

export const GLOBAL_USER_SET_SELECTED = 'GLOBAL_USER_SET_SELECTED';

export const setSelectedUser = (
  userId: number
): {| payload: number, type: string |} => ({
  type: GLOBAL_USER_SET_SELECTED,
  payload: userId,
});
