// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const API_SUBSCRIPTIONS_LOAD_REQUEST = 'API_SUBSCRIPTIONS_LOAD_REQUEST';
export const API_SUBSCRIPTIONS_LOAD_SUCCESS = 'API_SUBSCRIPTIONS_LOAD_SUCCESS';
export const API_SUBSCRIPTIONS_LOAD_FAILURE = 'API_SUBSCRIPTIONS_LOAD_FAILURE';

const loadApiSubscriptionsRequest = () => ({
  type: API_SUBSCRIPTIONS_LOAD_REQUEST,
});

const loadApiSubscriptionsSuccess = (apiSubscriptions) => ({
  type: API_SUBSCRIPTIONS_LOAD_SUCCESS,
  payload: apiSubscriptions,
});

const loadApiSubscriptionsFailure = (error) => ({
  type: API_SUBSCRIPTIONS_LOAD_FAILURE,
  error,
});

export const loadApiSubscriptions = (): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadApiSubscriptionsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/apis`,
    });

    dispatch(loadApiSubscriptionsSuccess(response));
  } catch (error) {
    dispatch(loadApiSubscriptionsFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};
