// @flow
import React from 'react';
import { Button, Grid, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import type { Node } from 'React';

type Props = {
  title?: string,
  filterPlaceholder?: string,
  filterAction?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  buttonText?: string,
  buttonURL?: string,
  buttonAction?: Function,
  downloadButtonAction?: Function,
  hideButton?: boolean,
  disableButton?: boolean,
  showDownloadButton?: boolean
};

class TableActions extends React.Component<Props> {
  render(): Node {
    const {
      title,
      filterAction,
      filterPlaceholder,
      buttonText,
      buttonURL,
      buttonAction,
      downloadButtonAction,
      hideButton = false,
      disableButton = false,
      showDownloadButton = false
    } = this.props;
    return (
      <div className="table-actions__container">
        <Grid className="table-actions" stackable columns={2}>
          <Grid.Column
            className={
              title
                ? 'table-actions__title-container'
                : 'table-actions__title-container--hidden'
            }
            width={3}
          >
            <h3 className="table-actions__title" data-testid="title">
              {title}
            </h3>
          </Grid.Column>
          <Grid.Column className="table-actions__right-column" width={9}>
            {filterAction ? (
              <Input
                className="table-actions__input"
                icon="search"
                iconPosition="left"
                onChange={filterAction}
                placeholder={filterPlaceholder}
                data-testid="filter-input"
              />
            ) : (
              ''
            )}
            {!hideButton && buttonURL ? (
              <Button 
                to={buttonURL} 
                as={Link} 
                disabled={disableButton} 
                data-testid="button"
              >
                {buttonText}
              </Button>
            ) : (
              ''
            )}
            {!hideButton && buttonAction ? (
              <Button 
                onClick={buttonAction} 
                disabled={disableButton}
              >
                {buttonText}
              </Button>
            ) : (
              ''
            )}
          </Grid.Column>
        </Grid>
        {showDownloadButton ? (
          <Button
            className={`ui icon button table-actions__download ${downloadButtonAction ? '' : 'disabled'}`}
            onClick={downloadButtonAction}
          >
            <i className="download icon"></i>
          </Button>
        ) : (
          ''
        )}
      </div>




    );
  }
}

export default TableActions;
