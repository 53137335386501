// @flow
import * as Sentry from '@sentry/browser';
import { changeTeamContext } from 'roy-morgan-auth';

import type { Dispatch, State } from '../types';

import { LIVE_MANAGEMENT_PRODUCT } from '../helpers/products';

export const teamChange = (teamId: number): any => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  try {
    await dispatch(changeTeamContext(teamId, LIVE_MANAGEMENT_PRODUCT));
  } catch (error) {
    Sentry.captureException(error);
    // TODO: Handle error
    return false;
  }
};
