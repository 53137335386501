//@flow
import React from 'react';
import TabHeader from '../../components/TabHeader';
import UpdateUserFormFields from '../../components/OrganisationUser/UpdateUserFormFields';
import type {
  State as AppState,
  // OrganisationCollaborator,
  Organisation,
  UpdateOrganisationUserForm,
  AuthTypes,
  Dispatch,
} from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  readOrganisationCollaborator,
  updateOrganisationCollaborator,
  removeOrganisationCollaborator,
} from '../../actions/organisations/organisationCollaborators';
import { Grid, Confirm } from 'semantic-ui-react';
import StaticField from '../../components/StaticField';
import { Redirect } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import moment from 'moment';
import { getIsReady } from '../../reducers/fetchSync';
import { setFetchSyncReady } from '../../actions';

type Props = {
  organisationId: number,
  collaboratorId: number,
  collaborator: any,
  organisation: Organisation,
  readOrganisationCollaborator: (oid: number, uid: number) => Promise<boolean>,
  updateOrganisationCollaborator: (
    oid: number,
    uid: number,
    formData: UpdateOrganisationUserForm
  ) => Promise<boolean>,
  removeOrganisationCollaborator: (
    oid: number,
    uid: number
  ) => Promise<boolean>,
  isFetching: boolean,
  permission: ?AuthTypes,
  isReady: boolean,
  setCollaboratorDetailsReady: () => void,
};

type State = {
  removeCollaboratorOpen: boolean,
  contextChange: boolean,
};

class CollaboratorDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      removeCollaboratorOpen: false,
      contextChange: false,
    };
  }

  componentDidMount() {
    this.props.readOrganisationCollaborator(
      this.props.organisationId,
      this.props.collaboratorId
    ).finally(this.props.setCollaboratorDetailsReady);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (typeof nextProps.collaborator === 'undefined') {
      this.setState({
        ...this.state,
        contextChange: true,
      });
    }
  }

  handleFormSubmit = (formData: UpdateOrganisationUserForm) => {
    this.props
      .updateOrganisationCollaborator(
        this.props.organisationId,
        this.props.collaboratorId,
        formData
      )
      .then(
        (success) => {},
        (failure) => {}
      );
  };

  setRemoveCollaboratorModalVisible = () => {
    this.setState({ ...this.state, removeCollaboratorOpen: true });
  };

  handleRemoveCollaborator = () => {
    this.props.removeOrganisationCollaborator(
      this.props.organisationId,
      this.props.collaboratorId
    );
    this.setState({ ...this.state, removeCollaboratorOpen: false });
  };

  render() {
    const {
      organisationId,
      collaborator,
      organisation,
      isFetching,
      permission,
      isReady,
    } = this.props;

    const {
      INTERNAL_ADMIN,
      BUSINESS_ADMIN,
      SUPPORT_ADMIN,
      ACCOUNT_MANAGER_ADMIN
    } = permissions;

    if (!isReady) {
      return null;
    }

    if (collaborator === undefined) {
      return <Redirect to={`/organisations/${organisationId}/collaborators`} />;
    } else {
      return (
        <div>
          {collaborator ? (
            <TabHeader
              tabHeading={`${collaborator.name}'s Details`}
              backLink={`/organisations/${organisationId}/collaborators`}
              backText="Back to Collaborators"
            />
          ) : (
            ''
          )}
          <Confirm
            content={`Are you sure you want to remove ${
              collaborator && collaborator.email ? collaborator.email : ''
            } from ${organisation.name}?`}
            open={this.state.removeCollaboratorOpen}
            onCancel={() =>
              this.setState({ ...this.state, removeCollaboratorOpen: false })
            }
            onConfirm={this.handleRemoveCollaborator}
          />
          {isFetching ? (
            <PageLoader />
          ) : (
            <div>
              <UpdateUserFormFields
                user={collaborator}
                handleFormSubmit={this.handleFormSubmit}
                canHaveTeams={this.props.organisation.canHaveTeams}
                disableFields={true}
                permission={permission}
              >
                <li>
                  {/* // TODO: replace anchor with button // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onClick={this.setRemoveCollaboratorModalVisible}
                    onKeyPress={this.setRemoveCollaboratorModalVisible}
                  >
                    Remove Collaborator from Organisation
                  </a>
                </li>
              </UpdateUserFormFields>

              {restrictUserPermissions(
                [INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN],
                permission
              ) && (
                <div>
                  <hr />
                  <div className="additional-details">
                    <Grid columns={3} stackable>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticField
                            label="Date Created"
                            value={moment
                              .utc(collaborator.dateCreated)
                              .local()
                              .format('YYYY-MM-DD')
                              .toString()}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <StaticField
                            label="Last Login"
                            value={
                              collaborator.lastLoginDateTime
                                ? moment
                                    .utc(collaborator.lastLoginDateTime)
                                    .local()
                                    .format('YYYY-MM-DD LT')
                                    .toString()
                                : 'Never'
                            }
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <StaticField
                            label="Last Changed By"
                            value={collaborator.lastChangedBy}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticField
                            label="Archived?"
                            value={collaborator.isArchived ? 'Yes' : 'No'}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <StaticField
                            label="Last Account Force Verify"
                            value={
                              collaborator.lastForceVerifyAccountTime
                                ? moment
                                    .utc(
                                      collaborator.lastForceVerifyAccountTime
                                    )
                                    .local()
                                    .format('YYYY-MM-DD LT')
                                    .toString()
                                : 'Never'
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state: AppState, props) => ({
  isFetching: state.organisationCollaborators.isFetching,
  collaborator: state.organisationCollaborators.byId[props.collaboratorId],
  isReady: getIsReady(state, 'collaboratorDetails'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      readOrganisationCollaborator,
      updateOrganisationCollaborator,
      removeOrganisationCollaborator,
      setCollaboratorDetailsReady: setFetchSyncReady('collaboratorDetails'),
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaboratorDetails): any);
