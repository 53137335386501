// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../../middleware';
// import type { ThunkAction, Dispatch, Action } from '../../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const ORGANISATION_TEAM_READ_REQUEST = 'ORGANISATION_TEAM_READ_REQUEST';
export const ORGANISATION_TEAM_READ_SUCCESS = 'ORGANISATION_TEAM_READ_SUCCESS';
export const ORGANISATION_TEAM_READ_FAILURE = 'ORGANISATION_TEAM_READ_FAILURE';

const readOrganisationTeamRequest = (): Object => ({
  type: ORGANISATION_TEAM_READ_REQUEST,
});

const readOrganisationTeamSuccess = (team): Object => ({
  type: ORGANISATION_TEAM_READ_SUCCESS,
  payload: team,
});

const readOrganisationTeamFailure = (error): Object => ({
  type: ORGANISATION_TEAM_READ_FAILURE,
  error,
});

export const readOrganisationTeam = (
  organisationId: number,
  teamId: number
): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(readOrganisationTeamRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/organisations/${organisationId}/teams/${teamId}`,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(readOrganisationTeamSuccess(response));

    return true;
  } catch (error) {
    dispatch(readOrganisationTeamFailure(error.message));
    console.error(error);
    Sentry.captureException(error);
    return error;
  }
};
