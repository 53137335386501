//@flow
import * as Sentry from '@sentry/browser';
import React from 'react';
import NewOrganisationFormFields from '../components/Organisation/NewOrganisationFormFields';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createOrganisation } from '../actions';
import { loadIndustries } from '../actions/industries';
import { loadOrganisationFlags } from '../actions/organisations/organisationFlags';
import { getIndustriesSortedAlphabetically } from '../reducers/industries';
import { getOrganisationFlagsSortedById } from '../reducers/organisationFlags';
import { Grid, Container } from 'semantic-ui-react';
import Breadcrumb from '../components/Breadcrumb';
import { withRouter } from 'react-router-dom';
import {
  mapOrganisationAccessToPermission,
  permissions,
  restrictUserPermissions
} from 'roy-morgan-auth';
import ErrorMessage from '../components/ErrorMessage';

import type {
  Dispatch,
  NewOrganisation as NewOrgType,
  Industries as IndustriesType,
  Industry as IndustryType,
  OrganisationForm,
  AuthTypes,
  OrganisationFlag as OrganisationFlagType,
  AccountManager as AccountManagerType
} from '../types';
import type { RouterHistory } from 'react-router-dom';

type Props = {
  organisationId: number,
  industries: Array<IndustryType>,
  organisationFlags: Array<OrganisationFlagType>,
  permission: string,
  history: RouterHistory,
  permission: ?AuthTypes,
  createOrganisation: (body: Object, accountManagers: Array<AccountManagerType>) => Promise<NewOrgType>,
  loadIndustries: () => Promise<IndustriesType>,
  loadOrganisationFlags: () => Promise<OrganisationFlagType>,
};

type State = {
  formData: OrganisationForm,
};

class NewOrganisation extends React.Component<Props, State> {
  componentDidMount() {
    this.props.loadIndustries();
    this.props.loadOrganisationFlags();
  }

  handleOrganisationCreate = (data, accountManagers) => {
    this.props.createOrganisation(data, accountManagers).then(
      (success: NewOrgType) => {
        this.props.history.push(`/organisations/${success.id}/details`);
      },
      (error) => {
        console.error(error);
        Sentry.captureException(error);
      }
    );
  };

  render() {
    const { industries, permission, organisationFlags } = this.props;
    const organisation = {
      name: '',
      phone: '',
      address: '',
      abn: '',
      acn: '',
      id: 0,
      industryId: 0,
      organisationFlagId: 0,
      notes: '',
      nextReviewDate: undefined,
    };

    const { INTERNAL_ADMIN, BUSINESS_ADMIN } = permissions;

    if (
      !restrictUserPermissions(
        [INTERNAL_ADMIN, BUSINESS_ADMIN],
        permission
      )
    ) {
      return <ErrorMessage message="User is not authorized." />;
    }

    return (
      <Container>
        <Grid>
          <Grid.Column width={12}>
            <Breadcrumb
              topLevelContext="All Organisations"
              currentContext={'Organisation'}
              backLink="/organisations"
            />
            <div className="single-form">
              <NewOrganisationFormFields
                industries={industries}
                organisationFlags={organisationFlags}
                handleFormSubmit={this.handleOrganisationCreate}
                organisation={organisation}
                permission={permission}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  industries: getIndustriesSortedAlphabetically(state),
  organisationFlags: getOrganisationFlagsSortedById(state),
  permission: state.auth.user.user
    ? mapOrganisationAccessToPermission(true, state.auth.user.user.accountTypeId)
    : permissions.NO_ACCESS
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      createOrganisation,
      loadIndustries,
      loadOrganisationFlags,
    },
    dispatch
  );

export default (withRouter(
  // $FlowFixMe
  connect(mapStateToProps, mapDispatchToProps)(NewOrganisation)
): any);
