// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const READ_GLOBAL_USER_REQUEST = 'READ_GLOBAL_USER_REQUEST';
export const READ_GLOBAL_USER_SUCCESS = 'READ_GLOBAL_USER_SUCCESS';
export const READ_GLOBAL_USER_FAILURE = 'READ_GLOBAL_USER_FAILURE';

const readUserRequest = () => ({
  type: READ_GLOBAL_USER_REQUEST,
});

const readUserSuccess = (users) => ({
  type: READ_GLOBAL_USER_SUCCESS,
  payload: users,
});

const readUserFailure = (error) => ({
  type: READ_GLOBAL_USER_FAILURE,
  error,
});

export const readUser = (userId: number): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(readUserRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'management')}/users/${userId}`,
    });

    dispatch(readUserSuccess(response));
  } catch (error) {
    dispatch(readUserFailure(error.message));
    Sentry.captureException(error);
    console.error(error);
  }
};
